import { TextTransform } from "react-native-localized-text";
import Icon from 'react-native-vector-icons/MaterialIcons';
import { SafeAreaView, ScrollView } from 'react-native';
import React, { useMemo, useState } from "react";
import FastImage from "react-native-fast-image";
import styled from "styled-components/native";
import { Flex } from "@kanvas/andromeda";

import fileSystemUploadFiles from "domain/shared/graphql/fileSystemUploadFiles";
import OpportunityService from "domain/shared/services/opportunity-service";
import { File as FSFile } from "domain/shared/types/file.interface";
import { IS_WEB } from "domain/shared/constants";

import { TextInputWithLabel } from "components/molecules/input-with-label";
import ButtonWithIcon from "components/atoms/button-with-icon";
import UploadInput from "components/molecules/upload-input";

import colors from "theme/colors";
import { translate } from "locales";

import PDF from "./pdf";
import usePickFile from "./hooks/use-pick-file";

export type VehicleOrderValue = {
  file: FSFile;
  name: string;
}

type Props = {
  value?: VehicleOrderValue;
  onSave?: (file: VehicleOrderValue) => void;
}

function useVehicleOrder(props: Props) {
  const [name, setName] = useState<string>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { handleUploadFile, handleReset, ...uploadProps } = usePickFile();

  const handleChange = (text: string) => {
    setName(text);
  };

  const uploadFile = async (value: any) => {
    if (!IS_WEB) return await fileSystemUploadFiles([value]);
    const response = await OpportunityService.uploadPhoto(value);
    return response[0];
  } 

  const handleSave = async () => {
    const { value } = uploadProps;
    setLoading(true);

    if (!name || !value) {
      setLoading(false)
      setError(true);
      return;
    }

    try {
      const file = await uploadFile(value);

      if (!file) throw new Error("No file recieved"); 
      // console.log(uploadedFile);
      props.onSave?.({ file, name });
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  };

  return {
    values: {
      name,
      error,
      uploadProps,
      loading,
    },
    operations: {
      handleChange,
      handleUploadFile,
      handleSave,
      handleReset,
    }
  };
}


export default function VehicleOrder(props: Props) {
  const { values, operations } = useVehicleOrder(props);
  const { name, error, uploadProps, loading } = values;
  const { handleUploadFile, handleChange, handleReset, handleSave } = operations;

  const { value } = uploadProps;

  const nameError = useMemo(() => (error && !name) ? 'requireField' : undefined, [error, name]);
  const imageError = useMemo(() => (error && !value) ? 'requireField' : undefined, [error, value]);

  if (props.value?.file) {
    return (
      <Root>
        <ResultContainer>
          <RenderPreview {...props.value.file} />
        </ResultContainer>
      </Root>
    );
  }
  
  return (
    <Root >
      <ScrollView bounces={false} contentContainerStyle={{ flex: 1 }}>
        <Container flex={1}>
          <Flex gap={12}>
            <UploadInput 
              required
              {...uploadProps}
              error={imageError}
              onReset={handleReset}
              onPress={handleUploadFile}
              label={translate('uploadFormat')}
              buttonLabel={translate('upload', TextTransform.CAPITAL)}
              Icon={<Icon name="add" size={16} color={colors.DARK_ORANGE} />}
            />
            

            <TextInputWithLabel
              required
              type="input"
              value={name}
              error={nameError}
              label="description"
              handleChange={handleChange}
            />
          </Flex>

          <SaveButton>
            <ButtonWithIcon 
              loading={loading}
              onPress={handleSave}
              text={translate('save', TextTransform.CAPITAL)}
            />
          </SaveButton>
        </Container>
      </ScrollView>
    </Root>
  );
}

function RenderPreview(props: FSFile) {
  const { url: uri, name } = props;

  if (name.includes('pdf')) {
    return (
      <PDF uri={uri} />
    );
  }

  return (
    <Image source={{ uri }} resizeMode="contain" />
  );
}

const Root = styled(SafeAreaView)`
  flex: 1;
`;

const Container = styled(Flex)`
  padding: 10px 16px 0px;
  justify-content: space-between;
`;

const ResultContainer = styled(Flex)`
  flex: 1;
  padding: 16px;
`;

const SaveButton = styled(Flex)`
  padding: 0 8px;
  padding-bottom: ${IS_WEB ? 16 : 0};
`;

const Image = styled(FastImage)`
  max-height: 580px;
  flex: 1;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${colors.BUTTON_BORDER};
  box-shadow: 0px 4px 5px ${colors.BUTTON_BORDER};
`;