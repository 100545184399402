import { ScannedData } from 'domain/barcode-scanner/types';
import { SCANNED_OPPORTUNITY, ScannerParams } from 'domain/create-opportunity/hooks/use-scanner-screen';
import useEventReciever from 'domain/shared/hooks/use-event-reciever';

interface Props {
  onOpp?: (value: ScannedData) => void;
}

export default function HandleOppParams({ onOpp }: Props) {
  useEventReciever(SCANNED_OPPORTUNITY, (data: ScannerParams) => {
    if (data.type !== 'lead') return;
    if (!data.opp) return;
    onOpp?.(data.opp);
  });

  return null;
}
