import React from 'react';
import { BorderlessButtonProperties } from 'react-native-gesture-handler';
import colors from 'theme/colors';
import { FONTS } from 'theme/typography';
import { Meta2 } from 'molecules/text';
import { BorderlessButton } from 'atoms/touchable';

interface IProps extends BorderlessButtonProperties {
  text: string
  color?: string
  size?: number
}

const LinkButton = (props: IProps) => {
  const { text, color = colors.DARK_ORANGE, size = 12 } = props;
  return (
    // @ts-ignore
    <BorderlessButton {...props} hitSlop={10}>
      <Meta2
        text={text}
        color={color}
        fontFamily={FONTS.SFProText}
        size={size}
      />
    </BorderlessButton>
  );
};

export default LinkButton;
