/* eslint-disable react/no-unused-prop-types */
import { Flex } from '@kanvas/andromeda';
import OpportunitySearch, { openSearchListEvent } from 'components/organisms/OpportunitySearch';
import SearchBarEventHandler from 'domain/opportunity-list/components/search-bar-event-handler';
import { IS_MOBILE, IS_MOBILE_WEB, IS_WEB } from 'domain/shared/constants';
import { AppDispatch, RootState } from 'domain/shared/store';
import { translate } from 'locales';
import React, {
  useCallback, useEffect, useMemo, useReducer,
} from 'react';
import {
  AppState, StatusBar,
} from 'react-native';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import colors from 'theme/colors';
import { TouchableOpacity } from 'components/atoms/touchable';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Lead, SortType } from 'domain/opportunity-list/types';
import OppListFilter from 'components/organisms/opp-list-filter';
import { ScannedData } from 'domain/barcode-scanner/types';
import useListState from 'domain/opportunity-list/state';
import { useNavigation, useRoute } from '@react-navigation/native';
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import { OpportunityScreens as OS } from 'domain/create-opportunity';
import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';
import { OPENING_OPPORTUNITY } from 'domain/shared/types/amplitude-event';
import { setActiveOpportunity } from 'domain/shared/store/slice/opportunity';
import { Opportunity } from 'domain/shared/types/opportunity';
import CRMExtensionService from 'domain/shared/services/crm-extension-service';
import { ACTIVE_SESSION } from 'domain/shared/screens';
import { SearchEmitValues } from 'domain/shared/events/cx-tab-bar';
import { Route, SceneMap } from 'react-native-tab-view';
import TabViewManager, { TapBarProps } from 'domain/opportunity-list/components/tab-view-manager';
import CompleteLeadList from 'domain/opportunity-list/components/complete-lead-list';
import { EventRegister } from 'react-native-event-listeners';
import { User } from 'domain/shared/types/user';
import Spinner from 'components/atoms/spinner';
import { prompIOSNotificationsPermission } from 'domain/shared/utils/config/onesignal';
import { Body4 } from 'components/molecules/text';
import { RouteStack } from 'domain/create-opportunity/add/component/handle-opp-params';
import { populateCxTabBarEvent } from 'components/organisms/CXTabBar';

// const RADIO_OFF = 'radio-button-off';
// const RADIO_ON = 'radio-button-checked';

export const REFRESH_LEAD_LIST_EVENT = 'REFRESH_LEAD_LIST_EVENT';
export const DISABLE_NEED_RELOAD = 'DISABLE_NEED_RELOAD';

export function refreshLeadListEvent() {
  EventRegister.emit(REFRESH_LEAD_LIST_EVENT);
}

export function disableNeedReloadEvent() {
  EventRegister.emit(DISABLE_NEED_RELOAD);
}

const Container = styled(Flex)`
  background-color: ${colors.WHITE};
`;

const FilterContainer = styled(Flex)`
  padding: 10px;
  padding-left: 16px;
  width: 100%;
`;

const ButtonContainer = styled(Flex)`
  border-radius: 24px;
  border-color:${colors.ORANGE};
  background-color: ${colors.ORANGE};
  bottom: 25px;
  right: 30px;
  position:absolute;
  height: 50px;
  width: 50px;
  shadow-opacity: 1;
  shadow-color: ${colors.SHADOW};
  shadow-offset: 0px 5px;
  shadow-radius: 5px;
  elevation: 6;
  ${IS_WEB ? `box-shadow: 0px 3px 5px ${colors.SHADOW}` : ''};
`;

const FooterContainer = styled(Flex)`
  margin-top: 30px;
  margin-bottom: 100px;
`;

function Footer() {
  return (
    <FooterContainer row justify="center">
      <TouchableOpacity onPress={() => openSearchListEvent()}>
        <Body4
          color={colors.BLACK}
          text={translate('dontSeeLatestOpp')}
        />
      </TouchableOpacity>
    </FooterContainer>
  );
}

const routes: Route[] = [
  { key: 'my-opps', title: translate('myOpps') },
  { key: 'open', title: translate('open') },
  // code-bryan: by the ticket SAK-1361 visiting was removed
  // { key: 'visiting', title: translate('visiting') },
];

interface Props {
  flag_create_leads: number;
}

export function useOppList() {
  const { model, operations } = useListState();
  const [sortModal, toggleSortModal] = useReducer((c) => !c, false);
  const user = useSelector<RootState>((redux) => redux.auth.user) as User | undefined;
  const router = useRoute<RouteStack>();

  const navigation = useNavigation();
  const dispatch = useDispatch<AppDispatch>();

  const handleSaveVin = useCallback((current: string, opp?: ScannedData) => {
    if (current.length <= 0) return;
    disableNeedReloadEvent();
    operations.setSearch(current, opp);
  }, []);

  const handleClearInput = () => {
    operations.clear();
  };

  const handleSort = useCallback((sort: SortType) => {
    toggleSortModal();
    operations.setSort(sort);
  }, []);

  const handleItem = useCallback((item: Lead) => {
    sendAmplitudeEvent(OPENING_OPPORTUNITY, {
      event: OPENING_OPPORTUNITY,
      lead_title: item.title,
      lead_id: item.id,
      lead_uuid: item.uuid,
    });

    dispatch(setActiveOpportunity(item as unknown as Opportunity));
    CRMExtensionService.openLead(item.link_sources);
    navigation.navigate(OpportunityScreens.WALL as never, ACTIVE_SESSION as never);
  }, []);

  const handleAdd = useCallback(() => {
    navigation.navigate(OpportunityScreens.CREATE as never, {
      screen: OS.ADD,
      params: { opp: model.opportunity, type: 'lead' },
    } as never);
  }, [model]);

  const handleSearch = useCallback(({ value, opportunity }: SearchEmitValues) => {
    handleSaveVin(value, opportunity);
  }, []);

  const handleRefresh = useCallback(() => {
    refreshLeadListEvent();
  }, []);

  useEffect(() => {
    const appStateListener = AppState.addEventListener('change', (state) => {
      if (state === 'active') {
        handleRefresh();
      }
    });

    return () => {
      appStateListener.remove();
    };
  }, []);

  useEffect(() => {
    if (!IS_MOBILE_WEB) return;
    if (router.params?.type !== 'search') return;
    if (!router.params?.opp) return;
    const { opp: data } = router.params;
    const current = `${data.firstname} ${data.lastname}`;
    populateCxTabBarEvent(current);
    operations.setSearch(current, data);
    disableNeedReloadEvent();
  }, [router.params]);

  return {
    model: {
      user,
      sortModal,
      state: model,
    },
    operations: {
      handleSaveVin,
      handleClearInput,
      toggleSortModal,
      handleSort,
      handleItem,
      handleAdd,
      handleSearch,
      handleRefresh,
    },
  };
}

export function OppList(props: Props) {
  const { flag_create_leads } = props;
  const { model, operations } = useOppList();
  const { state, user } = model;
  const { handleAdd, handleItem } = operations;

  useEffect(() => {
    prompIOSNotificationsPermission();
  }, []);

  const showFabButton = useMemo(() => {
    if (flag_create_leads) {
      if (IS_MOBILE_WEB || IS_MOBILE) {
        return true;
      }
    }
    return false;
  }, [flag_create_leads, state]);

  const LeadList = useCallback(({ route }: { route: Route }) => {
    const getFilter = () => {
      if (route.key === 'my-opps') return `leads_owner_id:${user?.id}`;
      if (route.key === 'visiting') return 'is_chrono_running:1';
      return '';
    };
    return (
      <CompleteLeadList
        state={state}
        filters={getFilter()}
        onAdd={operations.handleAdd}
        onItem={operations.handleItem}
        Footer={route.key === 'my-opps' && <Footer />}
      />
    );
  }, [state, handleAdd, handleItem, user]);

  const Header = useCallback(({ jumpTo }: TapBarProps) => (
    <FilterContainer row align="center" justify="space-between">
      <OppListFilter routes={routes} onChange={(key) => jumpTo(key)} />

      {/* <SortButtomContainer align="center" justify="center">
        <TouchableOpacity onPress={operations.toggleSortModal}>
          <Icon name="sort" size={24} color={colors.ICON_SECONDARY} />
        </TouchableOpacity>
      </SortButtomContainer> */}
    </FilterContainer>
  ), []);

  return (
    <Container flex={1} justify="center">
      <StatusBar barStyle="light-content" />
      <SearchBarEventHandler
        onSearch={operations.handleSearch}
        onClear={operations.handleClearInput}
      />

      {
        !IS_WEB && (
          <OpportunitySearch
            placeholder={translate('searchOpp')}
            saveVin={operations.handleSaveVin}
            clearInput={operations.handleClearInput}
          />
        )
      }

      <Flex flex={1}>
        { !user && (
          <Flex flex={1} align="center" justify="center">
            <Spinner />
          </Flex>
        ) }
        {
          user && (
            <TabViewManager
              routes={routes}
              scene={SceneMap({
                'my-opps': LeadList,
                open: LeadList,
                // visiting: LeadList,
              }) as any}
              renderHeader={Header}
            />
          )
        }
      </Flex>

      {showFabButton && (
        <ButtonContainer align="center" justify="center">
          <TouchableOpacity onPress={operations.handleAdd}>
            <MCIcon name="plus" color={colors.WHITE} size={23} />
          </TouchableOpacity>
        </ButtonContainer>
      )}

      {/* <ModelOption
        isVisible={model.sortModal}
        titleText={translate('sorting')}
        onPressClose={operations.toggleSortModal}
      >
        <View style={{ marginHorizontal: 10, marginBottom: getBottomSpace() }}>
          {
            SORT_CONTROL.map((item: SortType) => (
              <ItemOption
                key={item.NAME}
                optionName={item.NAME}
                handle={() => operations.handleSort(item)}
                iconName={model.state.sort?.FILTER === item.FILTER ? RADIO_ON : RADIO_OFF}
                color={model.state.sort?.FILTER === item.FILTER ? colors.ORANGE : colors.BLACK}
              />
            ))
          }
        </View>
      </ModelOption> */}
    </Container>
  );
}

const mapStateToProps = (state: RootState) => ({
  flag_create_leads: state?.settings?.appSettings?.flag_create_leads || 0,
});
export default connect(mapStateToProps, {})(OppList);
