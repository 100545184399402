import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Keyboard, useWindowDimensions } from "react-native";
import { Flex } from "@kanvas/andromeda";
import styled from "styled-components/native";
import FastImage from "react-native-fast-image";
import { useEffectOnce } from "react-use";
import * as Progress from 'react-native-progress';
import { Aligment } from "@kanvas/andromeda/lib/components/text";
import Icon from 'react-native-vector-icons/MaterialIcons';

import { Body3, Header2 } from "components/molecules/text";

// constants
import colors from "theme/colors";
import { translate } from "locales";
import { DEVICE_WIDTH, IS_WEB } from "domain/shared/constants";

// images
import Initial from '../../../assets/images/search-loading/initial.png';
import Identify from '../../../assets/images/search-loading/second.png';
import Searching from '../../../assets/images/search-loading/third.png';
import Error from '../../../assets/images/search-loading/error.png';
import NoResult from '../../../assets/images/search-loading/no-result.png';
import ButtonWithIcon from "components/atoms/button-with-icon";

enum Stage {
  INIT,
  IDENTIFY,
  SEARCHING,
  NOT_FOUND,
  ERROR,
}

type Strategy = { title: string, image: any, align?: Aligment };

const strategy: Record<Stage, Strategy> = {
  [Stage.INIT]: {
    title: 'connectingWithCrm',
    image: Initial,
  },
  [Stage.IDENTIFY]: {
    title: 'identifyActiveOpportunities',
    image: Identify,
  },
  [Stage.SEARCHING]: {
    title: 'lookingForSearchTerm',
    image: Searching,
  },
  [Stage.NOT_FOUND]: {
    title: 'noResult',
    align: 'center',
    image: NoResult,
  },
  [Stage.ERROR]: {
    title: 'connectingDatabaseInterrupted',
    image: Error,
  },
};

interface Props {
  search: string;
  hideActions?: boolean;

  handleAdd?: VoidFunction;
  handleRetry?:  VoidFunction;
}

export interface LeadHudLoadingRef {
  error: VoidFunction;
  notFound: VoidFunction;
  reset: VoidFunction;
}

function useLeadHudLoading(ref: ForwardedRef<LeadHudLoadingRef>, props: Props) {
  const [stage, setStage] = useState<Stage>(Stage.INIT);
  const [progress, setProgress] = useState(0);

  const retry = stage === Stage.ERROR || stage === Stage.NOT_FOUND;

  const layout = useWindowDimensions();

  const interval = useRef<NodeJS.Timer>();
  const init = useRef<NodeJS.Timeout>();
  const identify = useRef<NodeJS.Timeout>();

  const width = useMemo(() => {
    const currentWidth = IS_WEB ? layout.width : DEVICE_WIDTH;
    return currentWidth - 32;
  }, [layout]);

  const clean = () => {
    setProgress(0);
    clearInterval(interval.current);
    clearTimeout(identify.current);
    clearTimeout(init.current);
  }

  const runSearchingStage = () => {
    setStage(Stage.SEARCHING);
    setProgress(50);

    interval.current = setInterval(() => {
      setProgress((current) => {
        if (current >= 90) {
          clean();
          return current;
        }

        return current += 1;
      })
    }, 1250);
  }

  const runIdentifyStage = () => {
    if (retry) return;

    setStage(Stage.IDENTIFY);
    setProgress(30);

    identify.current = setTimeout(() => {
      runSearchingStage();
      clearTimeout(identify.current);
    }, 2000)
  }

  const runInitStage = () => {
    clean()
    setStage(Stage.INIT);
    Keyboard.dismiss();
    setProgress(10);

    init.current = setTimeout(() => {
      if (retry) return;

      runIdentifyStage();
      clearTimeout(init.current);
    }, 2000);
  }

  const handleButton = () => {
    if (retry) {
      props.handleRetry?.();
      return;
    }

    props.handleAdd?.();
  }

  useEffectOnce(() => {
    runInitStage();

    return () => {
      setProgress(0);
      clearInterval(interval.current);
    }
  });

  useImperativeHandle(ref, () => ({
    error: () => {
      setStage(Stage.ERROR);
      clean();
    },
    notFound: () => {
      setStage(Stage.NOT_FOUND);
      clean();
    },
    reset: () => runInitStage(),
  }));

  return {
    model: {
      stage,
      width,
      progress,
      retry,
    },
    operations: {
      handleButton
    }
  }
}

const LeadHudLoading = forwardRef<LeadHudLoadingRef, Readonly<Props>>(function(props, ref) {
  const { search } = props;
  const { model, operations } = useLeadHudLoading(ref, props);

  const { stage, width, progress, retry } = model;
  const { handleButton } = operations;
  const { title, image, align = 'left' } = strategy[stage];
  
  const total = progress / 100;
  
  const retryAddText = translate(retry ? 'retry' : 'addANewOpp');
  const searchText = !search.length ? translate('activeOpp') : search;
  const text = translate(title).replace('{crm}', 'CRM').replace('{lead}', searchText);

  const icon = !retry ? <Icon name="add" size={16} color={colors.DARK_ORANGE} /> : undefined;

  return (
    <Root>
      <Image source={image} />
      <Title align={align} text={text}/>


      {
        !retry && (
          <Flex gap={8}>
            <LoadingBar width={width} progress={total} />
            <ScannedData stage={stage} progress={progress} />
          </Flex>
        )
      }


      {
        retry && (
          <ButtonContainer>
            <RetryAddButton icon={icon} text={retryAddText} onPress={handleButton} />
          </ButtonContainer>
        )
      }
    </Root>
  );
});

export default LeadHudLoading;

function ScannedData({ progress, stage }: { stage: Stage, progress: number }) {
  if (stage !== Stage.SEARCHING) return null;

  return (
    <Flex row style={{ width: '100%' }}>
      <Body3 text={`${progress}%`} weight="bold" color={colors.BLACK} />
      <Body3 text={` ${translate('searchActiveUsers')}`} color={colors.SECONDARY_TEXT} />
    </Flex>
  );
}

const Root = styled(Flex)`
  gap: 20px;
  padding: 40px 16px;
  align-items: center;
`;

const Image = styled(FastImage)`
  width: 70px;
  height: 70px;
`;

const Title = styled(Header2)
.attrs((props) => ({ ...props, numberOfLines: 2 }))`
  min-height: 48px;
  width: 100%;
`;

const LoadingBar = styled(Progress.Bar)
  .attrs((props) => ({ 
    ...props,
    animated: true,
    height: 10,
    borderWidth: 0,
    color: colors.ORANGE,
    unfilledColor: colors.SUBTILE,
    indeterminateAnimationDuration: 3000,
  }))``;

const ButtonContainer = styled(Flex)`
  width: 100%;
`;

const RetryAddButton = styled(ButtonWithIcon).attrs(
  (props) => ({ ...props, color: colors.WHITE, textColor: colors.DARK_ORANGE })
)``;