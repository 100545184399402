import React, { useCallback } from 'react';
import ItemCard from 'atoms/item-card';
import Colors from 'theme/colors';
import { Flex, Text } from '@kanvas/andromeda';
import moment from 'moment';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import styled from 'styled-components/native';
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import OpportunityService from 'domain/shared/services/opportunity-service';
import { useDispatch } from 'react-redux';
import { ACTIVE_SESSION } from 'domain/shared/screens';
import { navigateToStack } from 'domain/shared/services/navigation-service';
import reactStringReplace from 'react-string-replace';
import { setActiveOpportunity } from 'domain/shared/store/slice/opportunity';
import { DropShadow } from 'domain/shared/styles';
import { TouchableOpacity } from 'components/atoms/touchable';
import { Button2, Meta2 } from '../text';

type MessageVariable = {
  contact: string;
  postTitle: string;
  text: string;
};

interface IProps {
  content: string;
  time: string;
  lead_id: number;
  handle: () => void
  message_variable: MessageVariable
  read: number
}
const IconContainer = styled(Flex)`
  background: ${Colors.WHITE};
  border: 1px solid ${Colors.BORDER_COLOR};
  border-radius: 8px;
  padding:12px;
  margin-left:12px;
  ${DropShadow}
`;

const Message = (props: IProps) => {
  const {
    content = '',
    time = '',
    handle = () => { },
    lead_id = 0,
    message_variable = {} as MessageVariable,
    read,
  } = props;

  const dispatch = useDispatch();
  const { contact = '' } = message_variable;

  const navigateToWall = useCallback(async (oppId: number) => {
    const opp = await OpportunityService.getById(oppId);
    if (opp.data) {
      dispatch(setActiveOpportunity(opp.data));
      navigateToStack(OpportunityScreens.WALL, ACTIVE_SESSION, { openActivities: 1 });
    }
  }, []);

  const navigateToLead = useCallback(() => {
    navigateToWall(lead_id);
  }, [navigateToWall, lead_id]);

  const renderMessage = useCallback(() => {
    if (contact) {
      const message = reactStringReplace(content || '', contact.trim() || '', (match) => (
        <Button2 key={match} text={match} onPress={navigateToLead} />
      ));
      return message;
    }
    return content;
  }, [content, contact]);

  return (
    <ItemCard highlight={read}>
      <Flex row align="flex-start">
        <Flex flex={1} gap={10}>
          <Text style={{ width: '100%' }}>
            {renderMessage()}
          </Text>
          <Meta2 text={moment(time).fromNow()} />
        </Flex>
        <TouchableOpacity onPress={handle}>
          <IconContainer align="center" justify="center">
            <Icon name="share" color={Colors.ORANGE} size={16} />
          </IconContainer>
        </TouchableOpacity>
      </Flex>
    </ItemCard>
  );
};

export default React.memo(Message);
