import React, { forwardRef, useRef } from 'react';
import { TextInputProps, TextInput as TextInputType } from 'react-native';
import styled, { css } from 'styled-components/native';
import Colors from 'theme/colors';
import { TextInputMask } from 'react-native-masked-text';
import CurrencyInputC from 'react-native-currency-input';
import { IS_WEB } from 'domain/shared/constants';
import MaskInput from 'react-native-mask-input';
import DateTimePicker from '../datetime-picker';

interface IProps extends TextInputProps {
  height?: number;
  validate?: string;
  mask?: string;
  InputRefs?:any;
  property?: string,
}

interface DatePickerIProps {
  onChange: (propertyName: string) => void,
  value: string,
  mask: string,
}

const inputStyle = css<IProps>`
    height: ${(props) => (props.multiline ? props.height : 40)}px;
    background-color: ${({ editable = true }) => (editable ? Colors.WHITE : Colors.SUBTILE)};
    color: ${({ editable = true }) => (editable ? Colors.PRIMARY_TEXT : Colors.PRIMARY_TEXT_DISABLED)};
    border:  ${(props) => (props?.validate ? Colors.WARNING : `1px solid ${Colors.BUTTON_BORDER}`)};
    box-shadow: 0px 4px 5px ${Colors.BUTTON_BORDER};
    border-radius: 8px;
    padding-horizontal:16px;
    ${IS_WEB ? 'outline-style: none;' : ''}
    ${(props) => (props.multiline ? ` 
      padding-top: 16px;
    ` : '')}
`;

const TextInput = styled.TextInput<IProps>`
  ${inputStyle}
`;

export const MaskedTextInputStyled = styled(TextInputMask)`
  ${inputStyle}
`;

export const CurrencyInputStyled = styled(CurrencyInputC)`
  ${inputStyle}
`;

export const MaskedTextInput = styled(MaskInput)<IProps>`
  ${inputStyle}
`;

export const MaskedInputText = (props: any) => (
  <MaskedTextInput
    placeholderTextColor={Colors.TEXT_PLACEHOLDER}
    {...props}
  />
);

export const MaskedText = (props: any) => {
  const { InputRefs, property } = props;
  const inputRef = useRef<TextInputType>(null);

  if (InputRefs) {
    InputRefs.current[property] = inputRef;
  }
  return (
    <MaskedTextInputStyled
      ref={InputRefs}
      placeholderTextColor={Colors.TEXT_PLACEHOLDER}
      {...props}
    />
  );
};

export const CurrencyInput = (props: any) => (
  <CurrencyInputStyled
    placeholderTextColor={Colors.TEXT_PLACEHOLDER}
    {...props}
  />
);

const Input = forwardRef<TextInputType, IProps>((props: IProps, ref) => (
  <TextInput
    placeholderTextColor={Colors.TEXT_PLACEHOLDER}
    ref={ref}
    {...props}
  />
));

export const DatePricker = (props: DatePickerIProps) => {
  const { onChange, value, mask } = props;

  return <DateTimePicker value={value} onChange={onChange} mask={mask} />;
};

Input.defaultProps = {
  height: 84,
};

export default Input;
export type { TextInputProps as InputProps };
