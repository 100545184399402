import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'domain/shared/store/slice/auth';
import opportunityReducer from 'domain/shared/store/slice/opportunity';
import settingsReducer from 'domain/shared/store/slice/settings';

const store = configureStore({
  reducer: {
    auth: authReducer,
    opportunity: opportunityReducer,
    settings: settingsReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
