import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Flex } from "@kanvas/andromeda";
import styled from "styled-components/native";
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';

import colors from "theme/colors";

import { IS_CHROME_EXTENSION, IS_WEB } from "domain/shared/constants";
import { RootState } from "domain/shared/store";

import { TouchableOpacity } from 'components/atoms/touchable';

interface Props { onAdd?: VoidFunction };

export default function AddLeadButton({ onAdd }: Props) {
  const flag = useSelector<RootState>((redux) => redux.settings.appSettings?.flag_create_leads);

  const show = useMemo(() => {
    if (flag) return !IS_CHROME_EXTENSION;
    return false;
  }, [flag]);

  if (!show) return null;

  return (
    <Root align="center" justify="center">
      <TouchableOpacity onPress={onAdd}>
        <MCIcon name="plus" color={colors.WHITE} size={23} />
      </TouchableOpacity>
    </Root>
  );
}

const Root = styled(Flex)`
  border-radius: 24px;
  border-color:${colors.ORANGE};
  background-color: ${colors.ORANGE};
  bottom: 25px;
  right: 30px;
  position:absolute;
  height: 50px;
  width: 50px;
  shadow-opacity: 1;
  shadow-color: ${colors.SHADOW};
  shadow-offset: 0px 5px;
  shadow-radius: 5px;
  elevation: 6;
  ${IS_WEB ? `box-shadow: 0px 3px 5px ${colors.SHADOW}` : ''};
`;