import React from 'react';
import { Flex, Spacer } from '@kanvas/andromeda';
import colors from 'theme/colors';
import { Button2 } from 'components/molecules/text';
import { BorderlessButton } from 'atoms/touchable';

interface IProps {
  icon?: React.ReactNode;
  title: string;
  onPress?: () => void;
}

const UserMenuItem = (props: IProps) => {
  const { icon, title, ...rest } = props;
  const menuIcon = icon ? (
    React.cloneElement(icon as any, { size: 24, color: colors.ICON_PRIMARY })
  ) : undefined;

  return (
    <BorderlessButton hitSlop={{ top: 10, bottom: 10 }} {...rest}>
      <Flex row align="center">
        {menuIcon}
        <Spacer size={8} horizontal />
        <Button2 text={title} />
      </Flex>
    </BorderlessButton>
  );
};

export default UserMenuItem;
export type UserMenuItemProps = IProps;
