import { ScannedData } from 'domain/barcode-scanner/types';
import React, { useState } from 'react';

interface ISearchingBarContext {
  visible: boolean,
  setSearchingBarVisible: (val: boolean) => void;
  setText: (val: string) => void;
  text:string,
  showBar:Boolean,
  setshowBar: (val: boolean) => void;
  opp?: ScannedData;
  setOpp: (val: ScannedData) => void;
}

export const SearchingBarContext = React.createContext<ISearchingBarContext>({} as ISearchingBarContext);

export const SearchingBarContextProvider = ({ children }:any) => {
  const [searchingBarVisible, setSearchingBarVisible] = useState(false);
  const [text, setText] = useState('');
  const [showBar, setshowBar] = useState(false);
  const [opp, setOpp] = useState<ScannedData | undefined>(undefined);
  
  return (
    <SearchingBarContext.Provider value={{ 
      visible:searchingBarVisible, 
      setSearchingBarVisible,
      setText,
      text,
      showBar,
      setshowBar,
      opp,
      setOpp
    }}>
      {children}
    </SearchingBarContext.Provider>
  );
};
