import { Platform } from 'react-native';

export default {
  ORANGE: '#FF7C29',
  WHITE: '#FFF',
  BLACK: '#000',
  PRIMARY_TEXT: '#212121',
  SECONDARY_TEXT: '#666666',
  TEXT_ORANGE_LINK: '#E75E18',
  BORDER_COLOR: '#f2f2f2',
  SUCCESS: '#549f56',
  ERROR: '#d43429',
  PURPLE: '#561beb',
  TAB_BAR_GRADIENT_ONE: '#F87129',
  TAB_BAR_GRADIENT_TWO: '#FFA742',
  ICON_SECONDARY: '#666',
  BACKGROUND_DEEMPH: 'rgba(33, 33, 33, 0.08)',
  SUBTILE: '#F5F6F8',
  BUTTON_BORDER: '#0000000d',
  PRIMARY_DEEMPH: '#FDF0E4',
  DARK_ORANGE: '#F1621A',
  ICON_PRIMARY: '#212121',
  DIVIDER: '#E4E4E4',
  DISABLED: '#999',
  SHADOW: Platform.OS === 'android' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,.20)',
  TEXT_PLACEHOLDER: '#8A8D91',
  BORDER_INPUT: '#21212114',
  WARNING: '#E71818',
  BACKGROUND: '#f8f8f8',
  OVERLAY: 'rgba(255,255,255, 1)',
  PROGRESS_UNFILL: '#D9D9D9',
  SWITCH_THUMB_TRACK: '#cccccc',
  PRIMARY_TEXT_DISABLED: '#00000099',
  SPINNER_OVERLAY_COLOR: 'rgba(0,0,0,0.6)',
  WEB_SHADOW: 'rgba(0,0,0,0.1)',
  CAMERA_OVERLAY: 'rgba(0,0,0,0.1)',
  IMAGE_BACKGROUND: 'rgba(33,33,33, 0.08)',
  TRANSPARENT: 'transparent',
  DISABLE_ORANGE: '#ffc29c',
  DISABLED_BUTTON_BACKGROUND: '#E4E6EB',
  DISABLED_BUTTON_TEXT: '#BCC0C4',
  URL_LINK: '#007AFF',
  ID_VERIFICATION: {
    GREEN: '#A7FFBC',
    RED: '#F8000F',
    YELLOW: '#FFF455',
  },
  POSITIVE: '#31A24C',
  GREY: '#727272',
};
