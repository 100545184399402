/* eslint-disable react/no-unused-prop-types */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Flex } from '@kanvas/andromeda';
import * as Progress from 'react-native-progress';
import colors from 'theme/colors';
import { Keyboard, useWindowDimensions, TouchableWithoutFeedback } from 'react-native';
import { DEVICE_WIDTH, IS_WEB } from 'domain/shared/constants';
import { Body3, Header2 } from 'components/molecules/text';
import FastImage from 'react-native-fast-image';
import { Aligment } from '@kanvas/andromeda/lib/components/text';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { translate } from 'locales';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import { delay } from 'lodash';

import Initial from '../../../assets/images/search-loading/initial.png';
import Second from '../../../assets/images/search-loading/second.png';
import Third from '../../../assets/images/search-loading/third.png';
import Error from '../../../assets/images/search-loading/error.png';
import NoResult from '../../../assets/images/search-loading/no-result.png';

interface Props {
  crm: string;
  loading: boolean;
  data: any[];
  noResults?: boolean;
  error?: boolean;
  search: string;
  hideButton?: boolean;
  onComplete?: VoidFunction;
  onRetry?: VoidFunction;
  onAddOpp?: VoidFunction;
}

type Keys = 'initial' | 'second' | 'third' | 'no-result' | 'error';

interface LoadingData {
  key: Keys;
  image: any;
  text: string;
  align: Aligment;
}

const data: LoadingData[] = [
  {
    key: 'initial',
    image: Initial,
    align: 'left',
    text: translate('connectingWithCrm'),
  },
  {
    key: 'second',
    image: Second,
    align: 'left',
    text: translate('identifyActiveOpportunities'),
  },
  {
    key: 'third',
    image: Third,
    align: 'left',
    text: translate('lookingForSearchTerm'),
  },
  {
    key: 'no-result',
    image: NoResult,
    align: 'center',
    text: translate('noResult'),
  },
  {
    key: 'error',
    image: Error,
    align: 'left',
    text: translate('connectingDatabaseInterrupted'),
  },
];

export function useSearchLoading(props: Props) {
  const { loading } = props;
  const [current, setCurrent] = useState(data[0]);
  const [progress, setProgress] = useState(0);

  const layout = useWindowDimensions();
  const intervalRef = useRef<NodeJS.Timer>();
  const intialTimeoutRef = useRef<NodeJS.Timer>();
  const primaryTimoutRef = useRef<NodeJS.Timer>();
  const intervalTimeoutRef = useRef<NodeJS.Timer>();

  const showRetry = useMemo(() => current.key === 'error' || current.key === 'no-result', [current]);

  const width = useMemo(() => {
    const currentWidth = IS_WEB ? layout.width : DEVICE_WIDTH;
    return currentWidth - 32;
  }, [layout]);

  const handleIntervals = () => {
    intervalRef.current = setInterval(() => {
      setProgress((currentProgress) => {
        if (currentProgress >= 90) {
          clearInterval(intervalRef.current);
          return currentProgress;
        }

        return currentProgress + 1;
      });
    }, 1250);
  };

  const handleThirdTimout = () => {
    intervalTimeoutRef.current = setTimeout(() => {
      setProgress((currentProgress) => (currentProgress === 100 ? currentProgress : 50));
      if (props.data.length <= 0) handleIntervals();
    }, 2000);
  };

  const handleInitialTimeout = () => {
    primaryTimoutRef.current = setTimeout(() => {
      setProgress((currentProgress) => (currentProgress === 100 ? currentProgress : 30));
      if (props.data.length <= 0) handleThirdTimout();
    }, 2000);
  };

  const handlePress = () => {
    if (showRetry) {
      props.onRetry?.();
      return;
    }

    props.onAddOpp?.();
  };

  const clearApp = () => {
    setProgress(0);
    if (intervalRef.current) clearInterval(intervalRef.current);
    if (intialTimeoutRef.current) clearTimeout(intialTimeoutRef.current);
    if (primaryTimoutRef.current) clearTimeout(primaryTimoutRef.current);
    if (intervalTimeoutRef.current) clearTimeout(intervalTimeoutRef.current);
  };

  useEffect(() => {
    if (!loading) return clearApp;
    setProgress(10);
    intialTimeoutRef.current = setTimeout(() => handleInitialTimeout(), 200);

    return clearApp;
  }, [loading]);

  useEffect(() => {
    if (props.data.length > 0) {
      setProgress(100);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.noResults) {
      setCurrent(data[3]);
      setProgress(0);
      return;
    }

    if (props.error) {
      setCurrent(data[4]);
      setProgress(0);
      return;
    }

    if (progress <= 10) {
      setCurrent(data[0]);
      return;
    }

    if (progress <= 30) {
      setCurrent(data[1]);
      return;
    }

    if (progress === 100) {
      props.onComplete?.();
      delay(() => {
        setProgress(0);
        props.onComplete?.();
      }, 200);
    }

    setCurrent(data[2]);
  }, [progress, props.noResults, props.error]);

  return {
    model: {
      width,
      current,
      progress,
      showRetry,
    },
    operations: {
      handlePress,
    },
  };
}

export default function SearchLoading(props: Props) {
  const {
    loading, noResults, crm, hideButton, search,
  } = props;
  const { model, operations } = useSearchLoading(props);
  const {
    text, image, align, key,
  } = model.current;

  const currentLoading = loading || noResults;
  const paragraph = useMemo(() => {
    const lead = search.length > 0 ? search : translate('activeOpp');
    return text.replace('{crm}', crm).replace('{lead}', lead);
  }, [crm, text, search]);

  const hideProgressBar = model.current.key === 'no-result' || model.current.key === 'error';
  const icon = !model.showRetry ? <Icon name="add" size={16} color={colors.DARK_ORANGE} /> : undefined;

  return (
    <TouchableWithoutFeedback
      accessible={false}
      onPress={() => Keyboard.dismiss()}
    >
      <Flex
        flex={1}
        gap={20}
        align="center"
        paddingVertical={40}
        marginHorizontal={16}
        style={{ display: currentLoading ? 'flex' : 'none' }}
      >
        <FastImage source={image} style={{ width: 70, height: 70 }} />

        <Header2
          align={align}
          text={paragraph}
          numberOfLines={2}
          style={{ minHeight: 48, width: '100%' }}
        />

        {
          !hideProgressBar && (
            <Flex gap={8}>
              <Progress.Bar
                animated
                height={10}
                borderWidth={0}
                width={model.width}
                color={colors.ORANGE}
                unfilledColor={colors.SUBTILE}
                progress={model.progress / 100}
                indeterminateAnimationDuration={3000}
              />
              {
                key === 'third' && (
                  <Flex row>
                    <Body3 text={`${model.progress}%`} weight="bold" color={colors.BLACK} />
                    <Body3 text={` ${translate('searchActiveUsers')}`} color={colors.SECONDARY_TEXT} />
                  </Flex>
                )
              }
            </Flex>
          )
        }

        {
          (hideProgressBar && !hideButton) && (
            <Flex style={{ width: '100%' }}>
              <ButtonWithIcon
                icon={icon}
                color={colors.WHITE}
                textColor={colors.DARK_ORANGE}
                onPress={operations.handlePress}
                text={translate(model.showRetry ? 'retry' : 'addANewOpp')}
              />
            </Flex>
          )
        }
      </Flex>
    </TouchableWithoutFeedback>
  );
}
