import { css } from 'styled-components/native';
import Colors from 'theme/colors';

const MainStyle = css`
    border-radius: 8px;
    shadow-opacity: .05;
    shadow-color: ${Colors.BLACK};
    shadow-offset: 0px 4px;
    shadow-radius: 8px;
    padding: 12px;
    border-width: 1px;
    border-color: ${Colors.BORDER_COLOR};
    margin-top: 8px;
    margin-vertical: 12px;
    height: 140px;
`;

export default MainStyle;
