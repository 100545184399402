import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { User } from 'domain/shared/types/user';
import { IStore } from 'domain/shared/types/store';
import UserService from 'domain/shared/services/user-service';
import { Me } from 'domain/shared/services/graph-user-service';

interface AuthState {
  user: User | undefined;
  store: IStore | undefined;
  stores: IStore[] | undefined;
  me: Me | undefined;
}

type ChangeUserProfileImageInput = {
  userId: number;
  image: string // Base64 image
};

export const changeUserProfileImage = createAsyncThunk(
  'users/changeUserProfileImage',
  async (input: ChangeUserProfileImageInput) => {
    const { image, userId } = input;
    await UserService.updateUserProfilePicture(userId, { image });
    const user = await UserService.getUser(userId);
    return user;
  },
);

const initialState = {
  user: undefined,
  store: undefined,
  stores: undefined,
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setCurrentStore(state, action: PayloadAction<IStore>) {
      state.store = action.payload;
    },
    setUserStores(state, action: PayloadAction<IStore[]>) {
      state.stores = action.payload;
    },
    setMe(state, action: PayloadAction<Me | undefined>) {
      state.me = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeUserProfileImage.fulfilled, (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    });
  },
});

export const {
  setCurrentStore,
  setCurrentUser,
  setUserStores,
  setMe,
} = authSlice.actions;

export default authSlice.reducer;
