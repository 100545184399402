import React from 'react';
import { ActivityIndicator } from 'react-native';
import colors from 'theme/colors';

interface IProps {
  size?: 'large' | 'small';
  visible?: boolean;
  color?: string;
}

const Spinner = ({
  size = 'large',
  visible = true,
  color = colors.DARK_ORANGE,
}: IProps) => (
  <ActivityIndicator
    size={size}
    color={color}
    animating={visible}
  />
);

export default Spinner;
