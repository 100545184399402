import { KANVAS_PUSHER_API_KEY, PUSHER_API_KEY } from "env";
import Pusher from "pusher-js/react-native";
import { useEffect } from "react";

type Keys = { subscribeKey: string; bindKey: string; };

type Methods = { shouldRun?: () => boolean; handle: Function };

const pusher = new Pusher(PUSHER_API_KEY, {
  cluster: 'us2',
});

export default function usePusher(key: Keys, methods: Methods, deps: any[]) {
  useEffect(() => {
    const shouldRun = methods.shouldRun?.() || true;
    if (!shouldRun) return;
    
    const channel = pusher.subscribe(key.subscribeKey);
    const bind = channel.bind(key.bindKey, methods.handle);

    return () => {
      channel.unsubscribe();
      bind.unbind();
    };
  }, deps);
}

const kanvasPusher = new Pusher(KANVAS_PUSHER_API_KEY, {
  cluster: 'us2',
});

export function useKanvasPusher(key: Keys, methods: Methods, deps: any[]) {
  useEffect(() => {
    const shouldRun = methods.shouldRun?.() || true;
    if (!shouldRun) return;
    
    const channel = kanvasPusher.subscribe(key.subscribeKey);
    const bind = channel.bind(key.bindKey, methods.handle);

    return () => {
      channel.unsubscribe();
      bind.unbind();
    };
  }, deps);
}