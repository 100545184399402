import { IS_WEB } from 'domain/shared/constants';
import UserService from './user-service';
import { CreditAppData } from '../types/creditapp';
import { DetaislsData } from '../types/DetaislsData';
import { CoBuyerData } from '../types/CoBuyerData';
import ACTION_NAME from '../types/ActionName';
import { User } from '../types/user';

// @ts-ignore;
const WINDOW = window;

enum Events {
  openLead = 'openLead',
  goBack = 'goBack',
  setAuthenticatedUser = 'setAuthenticatedUser',
  setNotificationCount = 'setNotificationCount',
  onNewNotification = 'onNewNotification',
  openFileViewer = 'openFileViewer',
  closeToggle = 'closeToggle',
  openCRMMessageTool = 'openCRMMessageTool',
  onLoad = 'onLoad',
  ShowNotificationBanner = 'ShowNotificationBanner',
  OpenCreditApp = 'OpenCreditApp',
  LoadCreditApp = 'LoadCreditApp',
  LoadDriverLincense = 'LoadDriverLincense',
  LoadCoBuyerData = 'LoadCoBuyerData',
}

export enum CRMMessageTool {
  Email = 'Email',
  Message = 'Message',
  Copy = 'Copy',
  onLoad = 'onLoad',
  closeToggle = 'closeToggle',
}

class CRMExtensionService {
  static emitMessage(type: Events, args: any) {
    if (IS_WEB) {
      const message = {
        type,
        args,
      };
      WINDOW.parent.postMessage(JSON.stringify(message), '*');
    }
  }

  static emitMessageCustom(type: string, args: any) {
    if (IS_WEB) {
      const message = {
        type,
        args,
      };
      WINDOW.parent.postMessage(JSON.stringify(message), '*');
    }
  }

  static openLead(link_sources: any) {
    CRMExtensionService.emitMessage(Events.openLead, link_sources);
  }

  static openFileViewer(link: string, title: string) {
    CRMExtensionService.emitMessage(Events.openFileViewer, { link, title });
  }

  static closeToggle() {
    CRMExtensionService.emitMessage(Events.closeToggle, {});
  }

  static loadDriverLincense(data: DetaislsData) {
    CRMExtensionService.emitMessage(Events.LoadDriverLincense, { data });
  }

  static loadCreditApp(data: CreditAppData) {
    CRMExtensionService.emitMessageCustom(`${Events.LoadCreditApp}_${data.lDID}`, { data });
  }

  static LoadCoBuyerData(data: CoBuyerData) {
    CRMExtensionService.emitMessageCustom(`${Events.LoadCoBuyerData}_${data.lDID}`, { data });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static openCRMMessageTool(type: CRMMessageTool, participantId: number, messageToInject: string) {
    // CRMExtensionService.emitMessage(Events.openCRMMessageTool, { type, messageToInject });
    CRMExtensionService.emitMessage(Events.openCRMMessageTool, {
      type,
      participantId,
      messageToInject: '',
    });
  }

  static goBack() {
    CRMExtensionService.emitMessage(Events.goBack, null);
  }

  static async setAuthenticatedUser(user: User) {
    CRMExtensionService.emitMessage(Events.setAuthenticatedUser, {
      userId: user.id,
      companyId: user.default_company,
    });
  }

  static async setNotificationCount(count: number) {
    CRMExtensionService.emitMessage(Events.setNotificationCount, { count });
  }

  static async onNewNotification(notification: any) {
    CRMExtensionService.emitMessage(Events.onNewNotification, notification);
  }

  static async onExtensionLoad() {
    CRMExtensionService.emitMessage(Events.onLoad, {});
  }

  static openLinkOnModal(link: string, title: string) {
    CRMExtensionService.emitMessage(Events.openFileViewer, { link, title });
  }

  static showNotificationBaner(action: ACTION_NAME, notification: any) {
    CRMExtensionService.emitMessage(Events.ShowNotificationBanner, { action, notification });
  } 
}

export default CRMExtensionService;
export { Events as CRMExtensionServiceEvents };
