import React, { useState } from "react";
import { Flex } from "@kanvas/andromeda";
import { Platform } from "react-native";
import styled from "styled-components/native";
import { CodeField, Cursor, useClearByFocusCell } from 'react-native-confirmation-code-field';

import colors from "theme/colors";
import { Meta2 } from "../text";
import { Keyboard } from "react-native";

const AUTOCOMPLETE = Platform.select({ android: 'sms-otp', default: 'one-time-code' });

interface Props {
  value: string;
  amount?: number;
  onChange?: (value: string) => void;
}

export default function CodeValidationInput(props: Props) {
  const { amount = 6, onChange, value } = props;

  const [extraprops, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue: (text) => onChange?.(text),
  });

  const handleChangeText = (current: string) => {
    onChange?.(current);
    if(current.length === amount) Keyboard.dismiss();
  }

  return (
    <Flex gap={12}>
      <Meta2 text="Enter Verification Code" color={colors.PRIMARY_TEXT} />
      {/* @ts-ignore */}
      <CodeField
        value={value}
        {...extraprops}
        cellCount={amount}
        keyboardType="number-pad"
        // returnKeyType="done"
        autoComplete={AUTOCOMPLETE}
        textContentType="oneTimeCode"
        textInputStyle={{ flex: 1 }}
        onChangeText={handleChangeText}
        rootStyle={{ justifyContent: 'space-between' }}
        renderCell={({ symbol, isFocused, index }) => (
          <Input key={`code-${index}`} onLayout={getCellOnLayoutHandler(index)}>
            {symbol || (isFocused ? <Cursor /> : null)}
          </Input>
        )}
      />
    </Flex>
  );
}

const Input = styled.Text`
  width: 52px;
  height: 52px;
  font-size: 20px;
  border-radius: 8px;
  text-align: center;
  overflow: hidden;
  line-height: 48px;
  color: ${colors.PRIMARY_TEXT};
  background-color: ${colors.WHITE};
  border: 1px solid ${colors.BUTTON_BORDER};
  box-shadow: 0px 4px 5px ${colors.BUTTON_BORDER};
`;