/* eslint-disable max-len */
import React, { useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Flex, useDeviceImage } from '@kanvas/andromeda';
import styled from 'styled-components/native';
import { Masks } from 'react-native-mask-input';
import { RouteProp } from '@react-navigation/native';
import { TextTransform } from 'react-native-localized-text';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Icon from 'react-native-vector-icons/MaterialIcons';

// Themes
import colors from 'theme/colors';
import { translate } from 'locales';

// Hooks
import useSubmitForm from 'model/hooks/use-submit-form';
import usePickFile from '../../content-preview/hooks/use-pick-file';

// Types
import { LeadAction } from 'domain/shared/types/lead-action';

// Organisms
import PayoffStatus from 'components/organisms/payoff-status';

// Atoms
import DateTimeInput from 'components/atoms/datetime-picker';
import ButtonWithIcon from 'components/atoms/button-with-icon';

// Molecules
import { FormLabel, InputWithLabel as TextInputWithLabel, TextInputWithLabel as CurrencyInput } from 'components/molecules/input-with-label';
import { Header1 } from 'components/molecules/text';
import PreviewHeader from 'components/molecules/preview-header';
import UploadInput from 'components/molecules/upload-input';
import moment from 'moment';
import { IS_WEB } from 'domain/shared/constants';
import { useSelector } from 'react-redux';
import { RootState } from 'domain/shared/store';

type SetFieldValue = (field: string, value: any, shouldValidate?: boolean | undefined) => void;

type ParamList = {
  Any: { action: LeadAction, onGoBack: VoidFunction }
};

interface Form {
  customerName: string;
  carFinancedBy: string;
  phone: string;
  accountNumber: string;
  netPayoff: string;
  goodUntil: string;
  timeCalled: string;
  perDiem: string;
  spokeTo: string;
  verifiedBy: string;
}

interface Props {
  route: RouteProp<ParamList, 'Any'>;
  navigation: NativeStackNavigationProp<ParamList, 'Any'>;
}

const Container = styled.SafeAreaView`
  flex: 1;
  width: 100%;
  flex: 1;
  background-color: ${colors.WHITE};
`;

const Content = styled(KeyboardAwareScrollView)`
  height: 100%;
  padding: 20px;
`;

const ButtonContainer = styled(Flex)`
  padding-vertical: 16px;
  border-top-width: 1px;
  border-top-color: ${colors.BORDER_COLOR};
  padding-bottom: 100px;  
  bakcground-color: red;
  width: 100%;
`;

const InputContainer = styled(Flex)`
  background-color:${colors.WHITE};
  height: 70px;
  margin-vertical:1px;
`;

const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

const initialValues = {
  payoffStatus_status_id: 0,
  customerName: '',
  carFinancedBy: '',
  phone: '',
  accountNumber: '',
  netPayoff: '',
  goodUntil: '',
  perDiem: '',
  spokeTo: '',
  verifiedBy: '',
  uploadTradeIn: '',
};

function isLessThan10Days(value: string): boolean {
  const today = moment().startOf('day');
  const goodUntilDate = moment(value, 'MM-DD-YY', true);
  const daysDifference = goodUntilDate.diff(today, 'days');
  return daysDifference < 10;
}


const payOffValidation = Yup.object().shape({
  payoffStatus_status_id: Yup.string().required(),
  customerName: Yup.string().required(),
  carFinancedBy: Yup.string().required(),
  phone: Yup.string().trim().matches(phoneRegExp, translate('invalidPhone', TextTransform.CAPITAL)).required(),
  accountNumber: Yup.string().required(),
  netPayoff: Yup.string().required(),
  goodUntil: Yup.string().required(),

  perDiem: Yup.string().when('goodUntil', {
    is: (val: string) => isLessThan10Days(val),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional(),
  }),

  // perDiem: isPerDiemReq ? Yup.string().required() : Yup.string(),
  spokeTo: Yup.string().required(),
  verifiedBy: Yup.string().required(),
});

const noPayoff_validation = Yup.object().shape({
  payoffStatus_status_id: Yup.string().required(),
  customerName: Yup.string().required(),
  uploadTradeIn: Yup.mixed().required(),
});

const options = [
  translate('photoGallery', TextTransform.CAPITAL),
  translate('camera', TextTransform.CAPITAL),
  translate('cancel', TextTransform.CAPITAL),
];

const PayoffIdForm = (props: Props) => {
  // Props
  const { navigation, route } = props;
  const { action, onGoBack } = route.params;

  // State
  const [loading, setLoading] = useState(false);
  const [payoffStatus, setPayoffStatus] = useState(0);
  const [perDiemRequired, setPerDiemRequired] = useState(false);

  // Hooks
  const { submit, separateFilesFromFields } = useSubmitForm(action);
  const { handleUploadFilePromise } = usePickFile();
  const user = useSelector((state: RootState) => state.auth.user)!;
  const activeOpportunity = useSelector((state: RootState) => state.opportunity.activeOpportunity)!;

  const verifiedBy = [user.firstname, user.middlename, user.lastname].join(' ');
  const customerName = activeOpportunity.people.name;
  const schema = useMemo(() => payoffStatus === 0 ? () => payOffValidation : noPayoff_validation, [payoffStatus]);

  const currentValues = useMemo(() => ({ ...initialValues, verifiedBy, customerName }), [])

  const { getImageFromDevice } = useDeviceImage({
    width: 300,
    height: 300,
    forceJpg: true,
    multiple: false,
    mediaType: 'photo',
    includeBase64: true,
  });

  const onSubmit = async (values: Form) => {
    try {
      setLoading(true);
      const { files, form } = separateFilesFromFields(values);
      const fileList = Object.values(files);
      const submitted = await submit(form, fileList);
      if (!submitted) return;
      onGoBack?.();
      navigation.goBack();
    } finally {
      setLoading(false);
    }
  };

  const handleUploadFile = async (inputName: string, setFieldValue?: SetFieldValue) => {
    if (IS_WEB) {
      const file = await handleUploadFilePromise();
      setFieldValue?.(inputName, file);
      return;
    }

    const image = await getImageFromDevice(options);
    if (!image) return;

    const name = image.path.split('/').slice(-1).join();

    const file = {
      uri: image.path,
      type: image.mime,
      file_type: image.mime,
      name: image.filename || name,
    };
    setFieldValue?.(inputName, file);
  };

  const handleReset = (inputName: string, setFieldValue?: SetFieldValue) => {
    setFieldValue?.(inputName, '');
  };

  /**
   * Handles the change event for the "goodUntil" value.
   * also handle if the "perDiem" field is required or not.
   * @param {string} value - The new value for "goodUntil".
   */
  const handleGoodUntil = (value: string, setFieldValue?: SetFieldValue) => {
    setFieldValue?.('goodUntil', value);
    const valid = isLessThan10Days(value);
    setPerDiemRequired(valid);
  };

  return (
    <Container>

      <PreviewHeader
        actionName={translate('payoffVerification', TextTransform.CAPITAL)}
        onBack={() => navigation.goBack()}
      />

      <Formik
        initialValues={currentValues}
        onSubmit={(values) => onSubmit(values as any)}
        validationSchema={schema}
      >
        {
          ({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            errors,
            touched,
            validateForm,
          }) => (
            <Content
              showsVerticalScrollIndicator={false}
              extraScrollHeight={100}
            >

              <InputContainer>
                <FormLabel
                  required
                  validate={errors.payoffStatus_status_id}
                  text={translate('payoffStatus', TextTransform.CAPITALIZE)}
                />

                <PayoffStatus
                  onChange={(item) => setFieldValue('payoffStatus_status_id', item.value)}
                  onItemChange={(item) => setPayoffStatus(item.value)}
                  value={values.payoffStatus_status_id}
                />
              </InputContainer>

              <TextInputWithLabel
                label={translate('customerName', TextTransform.CAPITALIZE)}
                placeholder={translate('customerName', TextTransform.CAPITALIZE)}
                onChangeText={handleChange('customerName')}
                onBlur={handleBlur('customerName')}
                value={values?.customerName}
                errors={touched.customerName ? errors.customerName : ''}
                required
              />

              {values.payoffStatus_status_id === 1 && (
                <UploadInput
                  required
                  value={values.uploadTradeIn}
                  onPress={() => handleUploadFile('uploadTradeIn', setFieldValue)}
                  onReset={() => handleReset('uploadTradeIn', setFieldValue)}
                  label={translate('uploadTradeIn', TextTransform.CAPITAL)}
                  buttonLabel={translate('upload', TextTransform.CAPITAL)}
                  Icon={<Icon name="add" size={24} color={colors.DARK_ORANGE} />}
                  styles={styles.uploadButtonContainer}
                />
              )}

              {values.payoffStatus_status_id === 0 && (
                <>
                  <TextInputWithLabel
                    label={translate('carFinancedBy', TextTransform.CAPITALIZE)}
                    placeholder={translate('carFinancedBy', TextTransform.CAPITALIZE)}
                    onChangeText={handleChange('carFinancedBy')}
                    onBlur={handleBlur('carFinancedBy')}
                    value={values?.carFinancedBy}
                    errors={touched.carFinancedBy ? errors.carFinancedBy : ''}
                    required
                  />

                  <TextInputWithLabel
                    label={translate('phone', TextTransform.CAPITALIZE)}
                    placeholder={translate('phone', TextTransform.CAPITALIZE)}
                    onChangeText={handleChange('phone')}
                    onBlur={handleBlur('phone')}
                    value={values.phone}
                    errors={touched.phone ? errors.phone : ''}
                    keyboardType="phone-pad"
                    mask={Masks.USA_PHONE}
                    required
                  />

                  <TextInputWithLabel
                    label={translate('accountNumber', TextTransform.CAPITALIZE)}
                    placeholder={translate('accountNumber', TextTransform.CAPITALIZE)}
                    onChangeText={handleChange('accountNumber')}
                    onBlur={handleBlur('accountNumber')}
                    value={values?.accountNumber}
                    errors={touched.accountNumber ? errors.accountNumber : ''}
                    required
                  />

                  <CurrencyInput
                    label="netPayoff"
                    placeholder={translate('netPayoff', TextTransform.CAPITALIZE)}
                    handleChange={handleChange('netPayoff')}
                    onBlur={handleBlur('netPayoff')}
                    value={values?.netPayoff}
                    error={touched.netPayoff ? errors.netPayoff : ''}
                    keyboardType="decimal-pad"
                    required
                    type="currency"
                    showErrorMsg={false}
                  />

                  <DateTimeInput
                    label={translate('goodUntil', TextTransform.CAPITALIZE)}
                    placeholder="MM-DD-YY"
                    showIcon={false}
                    onChange={(e) => handleGoodUntil(e, setFieldValue)}
                    value={values?.goodUntil}
                    errors={touched.goodUntil ? errors.goodUntil : ''}
                    required
                  />

                  <CurrencyInput
                    label="perDiem"
                    placeholder={translate('perDiem', TextTransform.CAPITALIZE)}
                    handleChange={handleChange('perDiem')}
                    onBlur={handleBlur('perDiem')}
                    value={values?.perDiem}
                    error={touched.perDiem ? errors.perDiem : ''}
                    keyboardType="decimal-pad"
                    required={perDiemRequired}
                    type="currency"
                    showErrorMsg={false}
                  />

                  <TextInputWithLabel
                    label={translate('spokeTo', TextTransform.CAPITALIZE)}
                    placeholder={translate('spokeTo', TextTransform.CAPITALIZE)}
                    onChangeText={handleChange('spokeTo')}
                    onBlur={handleBlur('spokeTo')}
                    value={values?.spokeTo}
                    errors={touched.spokeTo ? errors.spokeTo : ''}
                    required
                  />

                  <TextInputWithLabel
                    label={translate('verifiedBy', TextTransform.CAPITALIZE)}
                    placeholder={translate('verifiedBy', TextTransform.CAPITALIZE)}
                    onChangeText={handleChange('verifiedBy')}
                    onBlur={handleBlur('verifiedBy')}
                    value={values?.verifiedBy}
                    errors={touched.verifiedBy ? errors.verifiedBy : ''}
                    required
                  />
                </>
              )}
              <ButtonContainer>
                <ButtonWithIcon
                  loading={loading}
                  onPress={async () => {
                    const errorList = await validateForm();
                    if (errorList) {
                      console.log('errorList', errorList);
                    }
                    handleSubmit();
                  }}
                  text={translate('submit', TextTransform.CAPITAL)}
                />
              </ButtonContainer>
            </Content>
          )
        }
      </Formik>

    </Container>
  );
};

const styles = StyleSheet.create({
  uploadButtonContainer: {
    marginVertical: 10,
  },
});

export default React.memo(PayoffIdForm);
