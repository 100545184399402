/* eslint-disable react/no-unused-prop-types */

import React, { useState } from 'react';
import { useDebounce } from 'react-use';

import { FlatList, TextInputProps } from 'react-native';
import { Flex } from '@kanvas/andromeda';
import { FormLabel } from 'components/molecules/input-with-label';

import { IS_WEB } from 'domain/shared/constants';
import { translate } from 'locales';

import Icon from 'react-native-vector-icons/MaterialIcons';

import Colors from "theme/colors";
import styled from 'styled-components/native';

interface IProps extends TextInputProps {
  items: any,
  onChangeSearchText?: (text: string) => void,
  label: string,
  renderItem?: (item: any) => JSX.Element,
  disabled?: boolean;
  validate?: string
  error?: string
}

const SearchInput = ({onChangeSearchText, ...props}: IProps) => {
  const [value, setValue] = useState("");

  function onClear() {
    setValue("");
    onChangeSearchText && onChangeSearchText("");
  }

  useDebounce(() => {
    onChangeSearchText && onChangeSearchText(value)
  }, 300, [value]);

  return (
    <InputContainer>
      <FormLabel validate={props.validate || props.error} text={props.label} />

      <Container
        row
        align="center"
        {...props}
      >
        <Icon size={15} name="search" color={Colors.ICON_SECONDARY} />
        <TextInput
          placeholder={translate('search')}
          placeholderTextColor={Colors.ICON_SECONDARY}
          {...props}
          onChangeText={setValue}
          value={value}
        />
        {value !== '' && <Icon name="close" size={15} color={Colors.ICON_SECONDARY} onPress={onClear} />}
      </Container>
    </InputContainer>
  )
}

const SearchDropdown = (props: IProps) => {
  const {
    items,
    renderItem,
  } = props;

  function handleChangeText(value: string) {
    props.onChangeSearchText && props.onChangeSearchText(value);
  }

  return (
    <Flex>
      <SearchInput
        {...props}
        onChangeSearchText={handleChangeText}
      />

      {items.length > 0 && renderItem && (
        <FlatlistWrapper>
          <ItemsContainer
            data={items}
            renderItem={({ item }) => renderItem(item)}
            keyExtractor={(item: any) => item.id}
            ItemSeparatorComponent={Divider}
          />
        </FlatlistWrapper>
      )}
    </Flex>
  );
};

export default SearchDropdown;

const TextInput = styled.TextInput<IProps>`
  width: ${!IS_WEB ? '90%' : '100%'};
  margin: 0 6px;
`;

const Divider = styled.TextInput`
  width: 100%;
  background-color: ${Colors.DIVIDER};
  height: 1px;
`;

const FlatlistWrapper = styled(Flex)`
  background-color: ${Colors.WHITE};
  border: 2px solid ${Colors.BUTTON_BORDER};
  border-radius: 8px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-width: 0px;
  box-shadow: 0px 4px 5px ${Colors.BUTTON_BORDER};
  margin-top: -5px;
  max-height: 260px;
  width: 100%;
`;

const ItemsContainer = styled(FlatList)`
  border-radius: 8px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
`;

const Container = styled(Flex)<IProps>`
  height: 40px;
  background-color: ${Colors.WHITE};
  color: ${Colors.PRIMARY_TEXT};
  border:  ${(props) => (props?.validate ? Colors.WARNING : `1px solid ${Colors.BUTTON_BORDER}`)};
  box-shadow: 0px 4px 5px ${Colors.BUTTON_BORDER};
  border-radius: 8px;
  border-bottom-right-radius: ${(props) => (props?.items.length > 0 ? '0px' : '8px')};
  border-bottom-left-radius: ${(props) => (props?.items.length > 0 ? '0px' : '8px')};
  padding-horizontal:16px;
  ${IS_WEB ? 'outline-style: none;' : ''};
`;

const InputContainer = styled(Flex)`
  background-color:${Colors.WHITE};
  margin-vertical: 5px;
`;
