/* eslint-disable react/no-unstable-nested-components */
import { Flex } from '@kanvas/andromeda';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import Popup from 'components/molecules/popup';
import { Body3, Button2, Meta2 } from 'components/molecules/text';
import { setActiveOpportunity } from 'domain/shared/store/slice/opportunity';
import { ACTIVE_SESSION } from 'domain/shared/screens';
import OpportunityService from 'domain/shared/services/opportunity-service';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/native';
import Colors from 'theme/colors';
import { translate as translateBase } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { AppDispatch, RootState } from 'domain/shared/store';
import { Opportunity } from 'domain/shared/types/opportunity';
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import { navigateToStack } from 'domain/shared/services/navigation-service';
import { RectButton } from 'components/atoms/touchable';

interface IProps {
  handle: any,
  name: string,
  phone: string,
  email: string,
  id: number,
  contactFoundModal?: any,
  changeActiveSession?: any,
  multiple_lead_flag?: number
}

const Container = styled(Flex)`
    background-color:${Colors.BORDER_COLOR};
    padding-horizontal: 12px;
    padding-vertical: 14px;
    border-radius: 12px;
`;

const Item = (props: IProps) => {
  const {
    changeActiveSession,
    contactFoundModal,
    handle = () => { },
    name,
    phone,
    email,
    id,
    multiple_lead_flag,
  } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [owner, setOwner] = useState<string>('');

  const closeModal = () => {
    setShowModal(!showModal);
  };

  const translate = useCallback((label: string = '') => translateBase(label, TextTransform.CAPITAL), []);

  const searchOppByContact = useCallback(async (contactId: number) => {
    const response = await OpportunityService.searchOppByContact(contactId);

    if (response.length > 0) {
      const [opp] = response;
      setShowModal(true);
      setOwner(opp.owner.full_name);
    } else {
      handle();
    }
  }, []);

  const navigateToWall = useCallback(async (contactId: number) => {
    const response = await OpportunityService.searchOppByContact(contactId);
    const [opp] = response;
    setShowModal(false);
    contactFoundModal(false);
    changeActiveSession(opp);

    setTimeout(() => {
      navigateToStack(OpportunityScreens.WALL, ACTIVE_SESSION, {
        params: { back: true },
      });
    }, 400);
  }, []);

  return (
    <Flex style={{ paddingTop: 10, marginLeft: 35 }}>
      <Container>
        <Flex>
          <Flex row justify="space-between">
            <Button2 text={name} />
            <RectButton onPress={() => searchOppByContact(id)}>
              <Button2 text={translate('confirm')} color={Colors.ORANGE} />
            </RectButton>
          </Flex>
          <Meta2 text={phone} color={Colors.BLACK} />
          <Meta2 text={email} color={Colors.BLACK} />
        </Flex>
      </Container>

      <Popup
        visible={showModal}
        Body={() => (
          <Flex>
            <Body3 text={`${translate('opporunityAssociated')} ${owner}.`} />
            <Flex style={{ marginTop: 16 }}>
              <ButtonWithIcon onPress={() => navigateToWall(id)} text={translate('goToOpp')} />
              {multiple_lead_flag === 0 && (
                <Flex style={{ marginTop: 8 }}>
                  <ButtonWithIcon color={Colors.WHITE} onPress={handle} textColor={Colors.ORANGE} text={translateBase('createOpportunity', TextTransform.CAPITALIZE)} />
                </Flex>
              )}
            </Flex>
          </Flex>
        )}
        close={() => closeModal()}
        font="Header2"
        titleText={translate('openOpp')}
      />
    </Flex>
  );
};

const mapStateToProps = ({ settings }: RootState) => ({
  multiple_lead_flag: settings.appSettings?.flags_people_multiple_open_leads || 0,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changeActiveSession: (item: Opportunity) => dispatch(setActiveOpportunity(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Item);
