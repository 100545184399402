import client from "domain/shared/graphql/client";

import { TaskListQuery, TaskList } from "./queries";

export default class TaskListApi {
  static async get(): Promise<TaskList[]> {
    try {
      const response = await client.query<{ taskLists: { data: TaskList[] } }>({ 
        query: TaskListQuery,
        fetchPolicy: 'network-only',
      });

      const { data } = response.data.taskLists;
      return data;
    } catch (e) {
      console.log(e);
      return [];
    }
  }
}