/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { SafeAreaView } from 'react-native';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { Flex } from '@kanvas/andromeda';
import { KeyboardAwareScrollView as ScrollView } from 'react-native-keyboard-aware-scroll-view';
import Header from 'components/molecules/settings-header';
import Screen from 'components/template/screen';
import useAuth from 'domain/shared/hooks/useAuth';
import UserService from 'domain/shared/services/user-service';
import NotificationSettingsList from 'components/organisms/notification-settings-list';
import { UserNotificationType } from 'domain/shared/types/user-notifications-settings';
import ScheduleSettings from 'components/molecules/schedule-settings';

const NotificationSettings = () => {
  const [settings, setSettings] = useState<UserNotificationType[]>([]);

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      UserService.getAllNotificationSettings(user.id).then((data) => {
        setSettings(data);
      });
    }
  }, []);

  return (
    <Screen flex={1}>
      <SafeAreaView>
        <Header
          title={translate('notificationSettings', TextTransform.CAPITALIZE)}
        />
      </SafeAreaView>
      <ScrollView scrollEnabled>
        <Flex padding={16} gap={10} style={{ zIndex: 1 }}>
          <NotificationSettingsList items={settings} />
          {/* {settings.length > 0 && <ScheduleSettings />} */}
        </Flex>
      </ScrollView>
    </Screen>
  );
};

export default NotificationSettings;
