/* eslint-disable quote-props */
import {
  ApolloClient,
  // createHttpLink,
  ApolloLink,
  InMemoryCache,
  // concat,
} from '@apollo/client';
import { KANVAS_GRAPHQL } from 'env';
import { GRAPH_TOKEN_KEY, APP_SETTING_KEY } from 'domain/shared/constants';
import { setContext } from '@apollo/client/link/context';
import GetToken from 'domain/shared/utils/auth/async-storage';
import { createUploadLink } from 'apollo-upload-client';
import store from 'domain/shared/store';


const httpLink = createUploadLink({
  uri: KANVAS_GRAPHQL,
});

const withToken = setContext(async (_, context) => {
  let token;
  // if (!token) {
  const data = await GetToken(GRAPH_TOKEN_KEY);
  const { auth: { user } } = store.getState();
  const default_company_branch_uuid = user?.default_company_branch_uuid!;

  if (data) {
    const { auth } = data;
    if (auth) {
      token = auth.token || '';
    }
  }

  const headers = {
    ...context.headers,
    'X-Kanvas-App': APP_SETTING_KEY,
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (default_company_branch_uuid) {
    headers['X-Kanvas-Location'] = default_company_branch_uuid;
  }

  return { headers };
});

const client = new ApolloClient({
  link: ApolloLink.from([withToken, httpLink] as any),
  cache: new InMemoryCache(),
});

export default client;
