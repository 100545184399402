import React from "react";
import { Flex } from "@kanvas/andromeda";
import styled from "styled-components/native";
import { Dimensions, Platform } from "react-native";
import Icon from 'react-native-vector-icons/MaterialIcons';

import colors from "theme/colors";

import { BUTTON_HIT_SLOP, IS_WEB } from "domain/shared/constants";

import { TouchableOpacity } from 'components/atoms/touchable';

const { height } = Dimensions.get('window');
const top = Platform.select({ ios: height / 7, android: height / 10, web: height / 24 });

interface Prop { show: boolean, onPress?: VoidFunction };

export default function ReloadButton(prop: Prop) {
  const { show, onPress } = prop;

  if (!show) return null;

  return (
    <Root align="center" justify="center">
      <TouchableOpacity hitSlop={BUTTON_HIT_SLOP} onPress={onPress}>
        <Icon name="refresh" size={25} color={colors.ORANGE} />
      </TouchableOpacity>
    </Root>
  );
}

const Root = styled(Flex)`
  height: 40px;
  width: 40px;
  border-radius: 40px;
  position: absolute;
  top: ${top}px;
  align-self: center;
  background-color: ${colors.WHITE};
  border-color: ${colors.SHADOW};
  shadow-opacity: 1;
  shadow-color: ${colors.SHADOW};
  shadow-offset: 0px 5px;
  shadow-radius: 5px;
  elevation: 5;
  ${IS_WEB ? `box-shadow: 0px 3px 5px ${colors.SHADOW}` : ''};
`;