import React, {
  useState, useImperativeHandle, useCallback, forwardRef,
} from 'react';
import ConfirmModal from 'components/molecules/confirm-modal';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { Alert } from 'react-native';

interface Options {
  text: string;
  okText?: string;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
  hideCancelButton?: boolean;
  body?: React.ReactNode
}

  type ShowOptions = Omit<Options, 'text'>;

interface AlertRef {
  show: (text: string, options?: ShowOptions) => void;
  hide: () => void;
}

const AlertUI = forwardRef((props: any, ref: any) => {
  const [visible, setVisible] = useState(false);
  const [options, setOptions] = useState<Options>({
    text: '',
    okText: translate('ok', TextTransform.CAPITAL),
    cancelText: translate('cancel', TextTransform.CAPITAL),
    hideCancelButton: true,
    onOk: () => setVisible(false),
    onCancel: () => setVisible(false),
  });
  const {
    onOk,
    onCancel,
    okText,
    cancelText,
    text,
    hideCancelButton,
    body,
  } = options;

  const handleOk = useCallback(() => {
    onOk?.();
    setVisible(false);
  }, [onOk]);

  const handleCancel = useCallback(() => {
    onCancel?.();
    setVisible(false);
  }, [onOk]);

  const show = useCallback((msg: string, opts: ShowOptions = {}) => {
    setOptions({
      hideCancelButton: true,
      ...opts,
      text: msg,
      onOk: handleOk,
      onCancel: handleCancel,
    });
    setVisible(true);
  }, []);

  const hide = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, useCallback(() => ({
    show, hide,
  }), [show, hide]));

  return (
    <ConfirmModal
      visible={visible}
      onClose={() => setVisible(false)}
      text={text}
      okText={okText}
      cancelText={cancelText}
      onOk={onOk}
      hideCancelButton={hideCancelButton}
      innerBody={body}
    />
  );
});

const alertRef: { current: AlertRef | null } = {
  current: null,
};

export default function AlertProvider() {
  const setRef = useCallback((ref: AlertRef | null) => {
    alertRef.current = ref;
  }, []);

  return (
    <AlertUI ref={setRef} />
  );
}
export const showNativeAlert = (text: string) => {
  Alert.alert(text);
};
export const showAlert = (text: string, options?: ShowOptions) => {
  alertRef.current?.show(text, options);
};

export const customAlert = (options?: ShowOptions) => {
  alertRef.current?.show('', options);
};

export const hideModal = () => {
  alertRef.current?.hide();
};
