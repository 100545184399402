import { gql } from "@apollo/client";

export const addLeadParticipant = gql`
  mutation addLeadParticipant($input: LeadsParticipantsInput!) {
    addLeadParticipant(input: $input)
  }
`;

export const createPeople = gql`
  mutation createPeople($input: PeopleInput!) {
    createPeople(input: $input) {
      id,
      custom_fields {
        data {
          name,
          value
        }
      }
    }
  }
`