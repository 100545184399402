import React, { PropsWithChildren, useMemo, useState } from "react";
import { Flex } from "@kanvas/andromeda";
import styled from "styled-components/native";
import MaskInput, { Masks } from "react-native-mask-input";

import colors from "theme/colors";
import { IS_WEB } from "domain/shared/constants";

import { Body3, Meta2 } from "../text";

const REGEX = /^(.{3})(.{3})(.{4})$/;

interface Props extends PropsWithChildren {
  label: string;
  value?: string;
  placeholder?: string;
  editable?: boolean;
  phoneNumber?: string;
  onChange?: (value: string) => void;
}

export  const convertToPhone = (text: string) =>  text.replace(REGEX, '($1) $2-$3');

export default function TwoFactorInput({ onChange, value, label, editable = true, phoneNumber, placeholder, children }: Props) {
  const handleChangeText = (_: any, current: string) => {
    onChange?.(current);
  }

  const phoneLabel = useMemo(() => {
    if (!phoneNumber) return '';
    let lastFourDigits = phoneNumber.slice(-4);
    const maskedPhone = '*'.repeat(phoneNumber.length - 4) + lastFourDigits;
    return convertToPhone(maskedPhone);
  }, []);

  return (
    <Flex gap={4}>
      <Meta2 text={label} color={colors.PRIMARY_TEXT} />
      
      <Root>
        <Body3 text="(+1)" link color={colors.PRIMARY_TEXT} />
        {editable ? (
          <Input
            value={value}
            maxLength={14}
            returnKeyType="done"
            keyboardType="numeric"
            mask={Masks.USA_PHONE}
            placeholder={convertToPhone(placeholder || '')}
            onChangeText={handleChangeText}
          />
        ) : <NumberLabel text={phoneLabel || ''} />}
        {children}
      </Root>
    </Flex>
  );
}

const Root = styled(Flex)`
  padding: 12px 16px;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: ${colors.WHITE};
  border: 1px solid ${colors.BUTTON_BORDER};
  box-shadow: 0px 4px 5px ${colors.BUTTON_BORDER};
  ${IS_WEB && 'outline-style: none;'}
`;

const Input = styled(MaskInput)`
  font-size: 15px;
  flex: 1;
  padding: 0 10px;
  color: ${colors.PRIMARY_TEXT};
`;

const NumberLabel = styled(Body3)`
  flex: 1;
  padding: 0 10px;
  color: ${colors.PRIMARY_TEXT};
`;