import { EventRegister } from 'react-native-event-listeners';

export enum OverLayEvents {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
}

type OverlayOptions = {
  opacity?: number;
  text?: string;
};

export const showOverlay = (visible: boolean, options: OverlayOptions = {}) => {
  if (visible) {
    EventRegister.emit(OverLayEvents.SHOW, options);
  } else {
    EventRegister.emit(OverLayEvents.HIDE, {});
  }
};
