import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Opportunity } from 'domain/shared/types/opportunity';

interface OpportunityState {
  activeOpportunity: Opportunity | undefined;
}

const initialState = {
  activeOpportunity: undefined,
} as OpportunityState;

const OpportunitySlice = createSlice({
  name: 'opportunity',
  initialState,
  reducers: {
    setActiveOpportunity(state, action: PayloadAction<Opportunity>) {
      state.activeOpportunity = action.payload;
    },
    clearActiveOpportunity(state) {
      state.activeOpportunity = undefined;
    },
  },
});

export default OpportunitySlice.reducer;
export const {
  setActiveOpportunity,
  clearActiveOpportunity,
} = OpportunitySlice.actions;
