/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/order */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */

// Modules
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Flex, Spacer } from '@kanvas/andromeda';
import {
  FieldArray,
  Formik,
  FormikErrors,
} from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components/native';
import { View } from 'react-native-animatable';
import { ScrollView } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { connect } from 'react-redux';
import Toast from 'react-native-toast-message';
import { TextInput } from 'react-native';
import { TextTransform } from 'react-native-localized-text';
import Sentry from 'sentry';
import { CommonActions, useNavigation, useRoute } from '@react-navigation/native';

// Molecules
import PreviewHeader from 'components/molecules/preview-header';
import { Button1, Header3 } from 'components/molecules/text';
import IdValidationLoading, { IDValidationLoadingState, IdValidationLoadingRef } from 'components/molecules/id-validation-loading';
import ToastMessage from 'components/molecules/Toast';
import DocumentScannerButton from 'components/molecules/documment-scanner-button';
import { FormLabel, TextInputWithLabel } from 'components/molecules/input-with-label';
import Popup from 'components/molecules/popup';

// Atoms
import ViewSave from 'components/atoms/SafeAreaView';
import Input from 'components/atoms/text-input';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import { RectButton } from 'components/atoms/touchable';
import DropDown from 'components/atoms/dropdown-input';

// Services
import OpportunityService, { searchType } from 'domain/shared/services/opportunity-service';
import UserService from 'domain/shared/services/user-service';
import CountryService from 'domain/shared/services/country-service';
import LeadsService from 'domain/shared/services/leads.service';
import ActionService from 'domain/shared/services/action-service';

// Organisms
import OppType from 'components/organisms/OppType';

// Screens
import { ACTIVE_SESSION } from 'domain/shared/screens';
import { OpportunityScreens } from 'domain/opportunity-list/screens';

// Hooks
import { ScannerParams } from '../hooks/use-scanner-screen';
import { IdValidateProcess } from 'domain/shared/hooks/use-validation-process';
import useWindowSize from 'domain/shared/hooks/useWindowSize';
import useAuth from 'domain/shared/hooks/useAuth';
import { useCompanySettings } from 'domain/shared/hooks/useAppSettings';

// Utils
import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';
import getPeopleRelationships from 'domain/shared/utils/getPeopleRelationships';
import { showError, showSuccess } from 'domain/shared/utils/toast';

// Constants
import { IS_MOBILE, IS_MOBILE_WEB, IS_WEB } from 'domain/shared/constants';
import { BUTTON_STYLE } from './constants';

// Types
import { IPropsOpp, Opportunity } from 'domain/shared/types/opportunity';
import { IPeople } from 'domain/shared/types/people';
import { ScannedData } from 'domain/barcode-scanner/types';
import { OPP_CREATED, OPP_EDITED } from 'domain/shared/types/amplitude-actions';
import { ListItem } from './component/types';

// Components
import HandleOppParams from './component/handle-opp-params';
import DropDownSearch from './component/dropdown-users-search';
import Item from './component/contact-found';
import ParticipantsForm from './component/participants-form';

// Store
import { setActiveOpportunity, clearActiveOpportunity } from 'domain/shared/store/slice/opportunity';
import { AppDispatch, RootState } from 'domain/shared/store';

// Events
import { refreshOpportunityList } from 'domain/shared/events/opportunity-list';

// Styles
import { translate as translateBase } from 'locales';
import Colors from 'theme/colors';

const MAX_FOLLOWER = 3;

const Container = styled(Flex)`
  background-color:${Colors.WHITE};
`;

const InputContainer = styled(Flex)`
  background-color:${Colors.WHITE};
  height: 70px;
  margin-vertical:1px;
`;

const SaleInfoContainer = styled(Flex)`
  background-color:${Colors.WHITE}; 
  margin-bottom:15px;
`;

const ButtonContainer = styled(Flex) <{ width: number }>`
  margin-horizontal: 16px;
  position: absolute;
  bottom: 0px;
  width: ${({ width }) => (width)}px;
  background-color: ${Colors.TRANSPARENT};
  border-radius: 8px;
  margin-bottom: 15px;
  ${IS_WEB ? 'box-shadow: 0px 0px 12px #00000040;' : ''} 
`;

const languages: ListItem[] = [
  {
    value: 'EN',
    label: 'English',
  },
];

const validationSchema = Yup.object().shape({
  costumer_type_id: Yup.number().required(),
  // @ts-ignore
  business_name: Yup.string().when('costumer_type_id', {
    is: 1,
    then: Yup.string().required(),
  }),
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  // title: Yup.string().required(),
  language: Yup.string().required(),
  leads_owner_id: Yup.number().required(),
  email: Yup.string().email("invalidEmail"),
  phone_number: Yup.string().required(),
  leads_sources_id: Yup.number().required().min(1),
  leads_types_id: Yup.number().required().min(1),
  participants: Yup.array().of(Yup.object().shape({
    firstname: Yup.string().required("fieldIsRequired"),
    lastname: Yup.string().required("fieldIsRequired"),
    phone: Yup.string().required("fieldIsRequired"),
  }))
});

interface Params {
  opp?: ScannedData;
  editMode?: boolean;
}

const toastConfig = {
  error: ({ text1 }: any) => (
    <ToastMessage type="ERROR" message={text1} />
  ),
};

const AddOpp = (props: any) => {
  // States
  const [ownerList, setOwnerList] = useState<ListItem[]>([]);
  const [managers, setManagers] = useState<ListItem[]>([]);
  const [contries, setContries] = useState<ListItem[]>([]);
  const [states, setStates] = useState<ListItem[]>([]);
  const [peoples, setPeoples] = useState<IPeople[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [messageFound, setMessageFound] = useState<string>('');
  const [oppTypes, setOppTypes] = useState<ListItem[]>([]);
  const [oppSources, setOppSources] = useState<ListItem[]>([]);
  const [relationship, setRelationship] = useState<ListItem[]>([]);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(true);
  const [openIdValidationModal, setOpenIdValidationModal] = useState(false);

  // Refereces
  const scrollViewRef = useRef<ScrollView>(null);
  const errorsRefs = useRef<{ [key: string]: RefObject<View> }>({});
  const InputRefs = useRef<{ [key: string]: RefObject<TextInput> }>({});
  const idVerificationScreenRef = useRef<IdValidationLoadingRef>(null);

  // Hooks
  const { user } = useAuth();
  const route = useRoute<{ key: string; name: string; params: Params; }>();
  const navigation = useNavigation();
  const [width] = useWindowSize();
  const companySettings = useCompanySettings();

  // Constants
  const userId = user?.id || 0;
  const manager_id = user?.manager_id || 0;
  const default_company_branch = user?.default_company_branch || 0;
  const {
    language,
    changeActiveSession,
    activeSession = {},
    clearSession,
    flag_can_delete_lead,
  } = props;

  const {
    id = 0,
    companies: { language: _language = 'EN' } = {},
    firstname,
    lastname,
    middlename,
    title,
    leads_owner_id,
    leads_types_id,
    leads_sources_id,
    participants,
    follows,
    people: {
      phone_clean = '',
      email = '',
      id: _peopleId = 0,
      address: {
        address = '',
        address_2 = '',
        city = '',
        state_id = 0,
        zip = '',
      } = {},
    } = {},
  } = activeSession;

  const opp: IPropsOpp = {
    Id: id,
    language: _language || language,
    business_name: '',
    first_name: firstname || '',
    middlename: middlename || '',
    last_name: lastname || '',
    title: title || '',
    phone_number: phone_clean || '',
    email: email || '',
    costumer_type_id: 0,
    leads_owner_id: leads_owner_id || userId,
    manager_id,
    address1: address || '',
    address2: address_2 || '',
    country: 230,
    state_id: state_id.toString() || '',
    city: city || '',
    zipcode: zip || '',
    follower: follows?.map((item: any) => item.users_id) || [],
    people_id: _peopleId || 0,
    leads_types_id: leads_types_id || 0,
    leads_sources_id: leads_sources_id || 0,
    participants: participants || [],
    description: '',
  };

  const getOwnerList = useCallback(async () => {
    let list: ListItem[] = (await UserService.getAllUser('')).map(({ id = 0, firstname = '', lastname = '' }) => ({
      value: id,
      label: `${firstname.trim()} ${lastname.trim()}`,
      key: id,
    }));

    if ((route?.params as any)?.editMode) {
      const selected = follows.map((item: any) => item.users_id).join('|') || '';

      const userList = (await UserService.getAllUserByIds(selected)).map(({ id = 0, firstname = '', lastname = '' }) => ({
        value: id,
        label: `${firstname.trim()} ${lastname.trim()}`,
        key: id,
      }));

      list = list.concat(userList);
    }

    const currentUser = list.some((a) => a.key === opp.leads_owner_id.toString());

    if (!currentUser) {
      const cuResponse = await UserService.getUser(opp.leads_owner_id);

      if (cuResponse) {
        const cu: ListItem = {
          value: cuResponse.id,
          label: `${cuResponse.firstname.trim()} ${cuResponse.lastname.trim()}`,
          key: cuResponse.id.toString(),
        };

        list = list.concat(cu);
      }
    }
    setOwnerList(list);
  }, []);

  const getManagers = useCallback(async () => {
    const reponse = await UserService.getAllUserManager(default_company_branch.toString());
    const list = reponse.data.map(({ id = 0, firstname = '', lastname = '' }) => ({
      value: id.toString(),
      label: `${firstname.trim()} ${lastname.trim()}`,
      key: id,
    }));
    setManagers(list);
  }, []);

  const getCountries = useCallback(async () => {
    try {
      const list: ListItem[] = (await CountryService.getAllCountry()).map(({ id = 0, name = '' }) => ({
        value: id,
        label: name,
        key: id,
      }));
      setContries(list);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
  }, []);

  const getStates = useCallback(async () => {
    try {
      const list: ListItem[] = (await CountryService.getAllState()).map(({ id = 0, name = '' }) => ({
        value: id,
        label: name,
        key: id,
      }));
      setStates(list);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error.messageFound);
    }
  }, []);

  const getRelationship = useCallback(async () => {
    try {
      const list: ListItem[] = (await getPeopleRelationships()).map(({ id = '0', name = '' }) => ({
        value: id,
        label: name,
        key: id,
      }));
      setRelationship(list);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error.messageFound);
    }
  }, []);

  const getOppTypes = useCallback(async () => {
    try {
      // const response = await OpportunityService.getOppTypes();

      const response = await OpportunityService.getOppTypes();

      const list: ListItem[] = response.map(({ id = 0, name = '' }) => ({
        value: id.toString(),
        label: name,
        key: id.toString(),
      }));

      if (list) setOppTypes(list);
      setStates(list);
    } catch (error: any) {
      console.log(error);
    }
  }, []);

  const getOppSourceById = async (description?: string): Promise<ListItem | undefined> => {
    try {
      const source = await OpportunityService.getOppSourceById(leads_sources_id || 0, description);
      if (!source) return undefined;

      const { name, id } = source;
      return {
        label: name,
        key: id.toString(),
        value: id.toString(),
      };
    } catch {
      return undefined;
    }
  };

  const getOppSources = useCallback(async (oppTypeDescription?: string) => {
    try {
      // const current = await getOppSourceById(oppTypeDescription)

      // const response = await OpportunityService.getOppSources(oppTypeDescription, leads_sources_id || 0);

      const response = await OpportunityService.getOppSources(oppTypeDescription);

      const list: ListItem[] = response.map(({ id = 0, name = '' }) => ({
        value: id.toString(),
        label: name,
        key: id.toString(),
      }));

      setOppSources(list);
      // setOppSources(current ? [current, ...list] : list);
    } catch (error: any) {
      console.log(error);
    }
  }, []);

  const translate = useCallback((label: string = '') => translateBase(label, TextTransform.CAPITALIZE), []);

  useEffect(() => {
    getOwnerList();
    getManagers();
    getCountries();
    getStates();
    getOppTypes();
    getOppSources();
    getRelationship();
  }, []);

  const handleOpenScan = () => {
    const hadIdScannerAccess = !!companySettings?.id_verification;
    const params: ScannerParams = { type: 'lead' };
    const { SCANNER, ID_SCANNER } = OpportunityScreens;
    navigation.navigate(hadIdScannerAccess ? ID_SCANNER : SCANNER, params);
  };

  const findCoincidence = useCallback(async (text: string, label: string, st: searchType) => {
    if (text.trim()) {
      const validateExist = await OpportunityService.PeopleSearch(text, st);

      if (validateExist.length > 0) {
        setShowModal(true);
        setMessageFound(`${translateBase('peopleFoundMessage', TextTransform.CAPITAL)} ${translateBase(label, TextTransform.LOWERCASE)}.`);
        setPeoples(validateExist);
      }
    }
  }, []);

  const closeModal = () => {
    setShowModal(!showModal);
  };

  const onSubmit = useCallback(async (opportunity: IPropsOpp) => {
    let opportunityResponse: Opportunity;
    try {
      if (opportunity) {
        const oppMaped = OpportunityService.opportunityMapper(opportunity) as Opportunity;

        if (!oppMaped.title) {
          oppMaped.title = `${opportunity.first_name} ${opportunity.last_name} Opp`;
        }

        if (route?.params?.editMode) {
          opportunityResponse = await OpportunityService.update(oppMaped, activeSession.id);
          sendAmplitudeEvent(OPP_EDITED, {
            event: OPP_EDITED,
            leadId: activeSession.id,
            leadTitle: oppMaped.title,
          });
        } else {
          opportunityResponse = await OpportunityService.create(oppMaped);
          if (opportunityResponse) {
            const { leads_receivers: { uuid } = {}, uuid: leads_uuid } = opportunityResponse;
            ActionService.postReceiversMessage(uuid || '', {
              verb: 'crm-comment',
              hashtagVisited:
                leads_uuid,
              message: { text: oppMaped.description },
            });
          }
          sendAmplitudeEvent(OPP_CREATED, {
            event: OPP_CREATED, leadTitle: oppMaped.title,
          });

          const source = oppTypes.find(
            // eslint-disable-next-line eqeqeq
            (value) => value.value == opportunity.leads_types_id,
          );

          if (source?.label?.toLowerCase() === 'showroom') {
            LeadsService.toggleChrono(opportunityResponse, true);
          }
        }

        changeActiveSession(opportunityResponse);
        navigation.goBack();

        navigation.dispatch({
          ...CommonActions.navigate({
            name: OpportunityScreens.WALL,
            params: {
              screen: ACTIVE_SESSION,
              params: { refresh: true },
            },
          }),
        });
        showSuccess(translateBase('editSave'));
      }
    } catch (error: any) {
      if (error.response) {
        const { response: { data: { errors: { message = '' } } } } = error;
        showError(`Error: ${message}`);
        return;
      }
      showError(`Error: ${error.message}`);
    }
  }, []);

  const deleteOpportunity = useCallback(async (id: number) => {
    await OpportunityService.delete(id);
    sendAmplitudeEvent(OPP_CREATED, {
      event: OPP_CREATED, leadTitle: activeSession.title,
    });
  }, []);

  const goBack = () => {
    if (!route?.params?.editMode) {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            { name: 'APP.OPPORTUNITIES' },
          ],
        }),
      );
    }
    navigation.goBack();
  };

  const handleOpportunity = async (
    data: ScannedData,
    values: IPropsOpp,
    setValues: (
      values: React.SetStateAction<IPropsOpp>,
      shouldValidate?: boolean | undefined
    ) => void,
  ) => {
    if (values.first_name === data.firstname && values.last_name === data.lastname) return;

    if (!companySettings?.id_verification) {
      findCoincidence(`${data.firstname} ${data.lastname}`, 'name', searchType.NAME);
    }

    const { firstname, middlename, lastname } = data;

    setValues((values) => ({
      ...values,
      first_name: firstname,
      middlename: middlename,
      last_name: lastname,
      address1: data.addressOne,
      address2: data.addressTwo,
      // title: `${firstname} ${lastname} Opp`,
      zipcode: data.zipCode,
    }));

    const state = await CountryService.getStateByCode(data.state.toLowerCase());
    setValues((values) => ({ ...values, state_id: state?.id || '' }));
    const city = await CountryService.getCityByName(state.id, data.city.toLowerCase());
    const { expDate, ...extra } = data.extra;
    const getDocsData = { ...extra, exp_date: expDate, state_id: state?.id, firstname, middlename, lastname, };

    setValues((values) => ({ ...values, city_id: city?.id || '', get_docs_drivers_license: getDocsData }));

    setOpenIdValidationModal(true);
    if (!companySettings?.id_verification) return;
    try {
      idVerificationScreenRef.current?.changeStatus(IDValidationLoadingState.VALIDATING)
      const idVerification = await IdValidateProcess.instance(companySettings).verify(data);

      setValues((values) => ({ ...values, driver_license_images: data.pictures, id_verification: idVerification }));
      idVerificationScreenRef.current?.changeStatus(IDValidationLoadingState.SUCCESS);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      idVerificationScreenRef.current?.changeStatus(IDValidationLoadingState.FAIL);
    }
  };

  const handleIdValidationClose = (props: IPropsOpp) => {
    setOpenIdValidationModal(false);
    const { first_name, last_name } = props;
    findCoincidence(`${first_name} ${last_name}`, 'name', searchType.NAME);
  };

  const focusTextInput = (ref: React.RefObject<TextInput>) => {
    if (ref?.current) {
      ref.current.focus();
    }
  };

  const scrollToAndFocusInvalid = (errors: FormikErrors<IPropsOpp>) => {
    if (scrollViewRef.current && Object.keys(errors).length > 0) {
      const firstErrorKey = Object.keys(errors)[0];
      const firstErrorRef = errorsRefs.current[firstErrorKey];
      const inputRef = InputRefs.current[firstErrorKey];
      if (firstErrorRef && firstErrorRef.current) {
        firstErrorRef.current.measureLayout(
          scrollViewRef.current.getInnerViewNode(),
          (_x, y) => {
            scrollViewRef.current?.scrollTo({ x: 0, y, animated: true });

            firstErrorRef.current?.focus();
            focusTextInput(inputRef);
          },
        );
      }
      Toast.show({
        text1: translateBase('fieldRequired'),
        type: 'error',
      });
    }
  };

  return (
    <Flex flex={1} style={{ backgroundColor: Colors.WHITE }}>
      <ViewSave />

      <Formik
        initialValues={opp}
        enableReinitialize={!!route?.params?.editMode}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values: any, actions: any) => {
          onSubmit(values).then(() => {
            actions.setSubmitting(false);
            setShowModal(false);
          });
        }}
      >
        {
          ({
            handleChange,
            values,
            setFieldValue,
            errors,
            handleSubmit,
            setValues,
            resetForm,
            validateForm,
            isSubmitting,
          }) => (
            <Flex flex={1}>
              <IdValidationLoading
                open={openIdValidationModal}
                ref={idVerificationScreenRef}
                onClose={() => handleIdValidationClose?.(values)}
              />
              <PreviewHeader
                onBack={() => {
                  resetForm();
                  goBack();
                }}
                right={
                  route?.params?.editMode && flag_can_delete_lead === 1 && (
                    <Button1
                      text={translate('delete')}
                      color={Colors.ORANGE}
                      onPress={() => setShowDelete(true)}
                    />
                  )
                }
                borderless
                actionName={translateBase(route?.params?.editMode ? 'editOpp' : 'addOpp', TextTransform.CAPITALIZE)}
                backgroundColor={Colors.WHITE}
              />
              <ScrollView
                contentContainerStyle={{ paddingBottom: 100 }}
                nestedScrollEnabled
                ref={scrollViewRef}
              >
                <Container style={{ backgroundColor: Colors.WHITE, marginHorizontal: 20 }}>
                  <HandleOppParams onOpp={(data) => handleOpportunity(data, values, setValues)} />

                  {!route?.params?.editMode && (
                    <InputContainer>
                      <FormLabel
                        required
                        validate={errors.costumer_type_id}
                        text={translateBase('typeCostumer')}
                      />
                      <OppType
                        onChange={(item) => setFieldValue('costumer_type_id', item.value)}
                        value={values.costumer_type_id}
                      />
                    </InputContainer>
                  )}

                  {values.costumer_type_id === 1 && (
                    <TextInputWithLabel
                      required
                      label="businessName"
                      validate={errors.business_name}
                      handleChange={handleChange('business_name')}
                      value={values.business_name}
                      type="input"
                    />
                  )}
                  <Flex>
                    <TextInputWithLabel
                      required
                      label="firtName"
                      validate={errors.first_name}
                      handleChange={handleChange('first_name')}
                      value={values.first_name}
                      type="input"
                      errorsRefs={errorsRefs}
                      InputRefs={InputRefs}
                      property="first_name"
                    />
                    {(IS_MOBILE || IS_MOBILE_WEB) && (
                      <DocumentScannerButton style={{ ...BUTTON_STYLE }} onPress={handleOpenScan} />
                    )}
                  </Flex>
                  <Flex>
                    <TextInputWithLabel
                      label="middleName"
                      validate={errors.middlename}
                      handleChange={handleChange('middlename')}
                      value={values.middlename}
                      type="input"
                      errorsRefs={errorsRefs}
                      InputRefs={InputRefs}
                      property="middleName"
                    />
                  </Flex>
                  <TextInputWithLabel
                    required
                    label="lastName"
                    validate={errors.last_name}
                    handleChange={handleChange('last_name')}
                    value={values.last_name}
                    type="input"
                    onBlur={() => {
                      // if (!values.title) {
                      //   setFieldValue('title', `${values.first_name} ${values.last_name} Opp`);
                      // }
                      findCoincidence(`${values.first_name} ${values.last_name}`, 'name', searchType.NAME);
                    }}
                    errorsRefs={errorsRefs}
                    InputRefs={InputRefs}
                    property="last_name"

                  />
                  {/* <TextInputWithLabel
                    required
                    validate={errors.title}
                    label="oppTitle"
                    handleChange={handleChange('title')}
                    value={values.title}
                    type="input"
                    errorsRefs={errorsRefs}
                    InputRefs={InputRefs}
                    property="title"
                  /> */}
                  <TextInputWithLabel
                    required
                    validate={errors.phone_number}
                    label="phoneNumber"
                    handleChange={(value: string) => {
                      setFieldValue('phone_number', value.replace(/[^+\d]+/g, ''));
                    }}
                    value={values.phone_number}
                    type="input-mask"
                    mask="(999)-999-9999"
                    onBlur={() => findCoincidence(values.phone_number, 'phoneNumber', searchType.PHONE)}
                    errorsRefs={errorsRefs}
                    InputRefs={InputRefs}
                    property="phone_number"
                  />
                  <TextInputWithLabel
                    validate={errors.email}
                    label="email"
                    handleChange={handleChange('email')}
                    value={values.email}
                    type="input"
                    onBlur={() => findCoincidence(values.email, 'email', searchType.EMAIL)}
                    error={errors.email}
                    errorsRefs={errorsRefs}
                    InputRefs={InputRefs}
                    property="email"
                  />
                  <InputContainer>
                    <Spacer size={2} />
                    <FormLabel
                      required
                      validate={errors.leads_types_id}
                      text={translate('opportunityType')}
                    />
                    <DropDown
                      validate={errors.leads_types_id}
                      zIndexInverse={999}
                      placeHolder={translate('type')}
                      value={values.leads_types_id.toString()}
                      items={oppTypes}
                      onSelectItem={(item: any) => {
                        setFieldValue('leads_types_id', item?.value);
                        getOppSources(item.label);
                      }}
                      errorsRefs={errorsRefs}
                      property="leads_types_id"
                    />
                  </InputContainer>
                  <InputContainer>
                    <Spacer size={2} />
                    <FormLabel
                      required
                      validate={errors.leads_sources_id}
                      text={translate('opportunitySource')}
                    />
                    <DropDown
                      validate={errors.leads_sources_id}
                      zIndexInverse={999}
                      placeHolder={translate('source')}
                      value={values.leads_sources_id.toString()}
                      items={oppSources}
                      onSelectItem={(item: any) => {
                        setFieldValue('leads_sources_id', item?.value);
                      }}
                      disabled={values.leads_types_id.toString() === '0'}
                      errorsRefs={errorsRefs}
                      property="leads_sources_id"
                    />
                  </InputContainer>
                  {showMore && (
                    <InputContainer>
                      <ButtonWithIcon
                        onPress={() => setShowMore(!showMore)}
                        textColor={Colors.ORANGE}
                        text={translateBase('showMoreFields')}
                        color={Colors.WHITE}
                      />

                    </InputContainer>
                  )}
                  {!showMore
                    && (
                      <>
                        <TextInputWithLabel
                          label="addressLine1"
                          validate={errors.address1}
                          handleChange={handleChange('address1')}
                          value={values.address1}
                          type="input"
                        />
                        <TextInputWithLabel
                          label="addressLine2"
                          validate={errors.address2}
                          handleChange={handleChange('address2')}
                          value={values.address2}
                          type="input"
                        />
                        <InputContainer style={{ zIndex: 998 }}>
                          <Spacer size={2} />
                          <FormLabel text={translate('country')} />
                          <DropDown
                            placeHolder={translate('country')}
                            value={values.country}
                            items={contries}
                            onSelectItem={(item: any) => setFieldValue('country', item?.value)}
                          />
                        </InputContainer>
                        <InputContainer style={{ zIndex: 997 }}>
                          <FormLabel text={translate('state')} />
                          <DropDown
                            placeHolder={translate('state')}
                            value={values.state_id.toString()}
                            items={states}
                            onSelectItem={(item: any) => setFieldValue('state_id', item?.value)}
                          />
                        </InputContainer>
                        <InputContainer>
                          <TextInputWithLabel
                            label="city"
                            validate={errors.city}
                            handleChange={handleChange('city')}
                            value={values.city}
                            type="input"
                          />
                        </InputContainer>
                        <InputContainer>
                          <FormLabel text={translate('zipCode')} />
                          <Input
                            placeholder={translate('zipCode')}
                            value={values.zipcode}
                            onChangeText={handleChange('zipcode')}
                            keyboardType="number-pad"
                            returnKeyType="done"
                          />
                        </InputContainer>
                        <InputContainer>
                          <FormLabel text={translate('language')} required />
                          <DropDown
                            placeHolder={translate('language')}
                            value={values.language}
                            items={languages}
                            onSelectItem={(item: any) => setFieldValue('language', item?.value)}
                          />
                        </InputContainer>
                        <InputContainer>
                          <TextInputWithLabel
                            label="comment"
                            validate={errors.description}
                            handleChange={handleChange('description')}
                            value={values.description}
                            type="input"
                          />
                        </InputContainer>

                        <FieldArray
                          name="participants"
                          render={(arrayHelpers) => (
                            <ParticipantsForm
                              values={values}
                              arrayHelpers={arrayHelpers}
                              relationship={relationship}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              contries={contries}
                              states={states}
                              errors={errors}
                            />
                          )}
                        />
                        <Flex
                          style={{ borderBottomWidth: 1, borderColor: Colors.BORDER_INPUT }}
                        />
                        <SaleInfoContainer>
                          <Header3 text={translate('saleInfo')} style={{ marginTop: 24 }} />
                        </SaleInfoContainer>
                        <InputContainer>
                          <FormLabel required validate={errors.leads_owner_id} text={translate('selectOwner')} />
                          <DropDownSearch
                            placeHolder={translate('selectOwner')}
                            value={values.leads_owner_id}
                            defaultList={ownerList}
                            onSelectItem={(item: any) => setFieldValue('leads_owner_id', item?.value)}
                          />
                        </InputContainer>
                        <InputContainer style={{ zIndex: -5 }}>
                          <FormLabel text="Manager" />
                          <DropDown
                            zIndexInverse={999}
                            placeHolder="Select Manager"
                            value={values?.manager_id?.toString() || ''}
                            items={managers}
                            onSelectItem={(item: any) => setFieldValue('manager_id', item?.value)}
                            disabled
                          />

                        </InputContainer>
                        {values.follower.map((item: number, index: number) => (
                          <React.Fragment key={index.toString()}>
                            <Flex />
                            <InputContainer>
                              <FormLabel text={`${translate('follower')} ${index + 1}`} />
                              <Flex row>
                                <Flex flex={1} justify="center">
                                  <DropDownSearch
                                    placeHolder={translate('follower')}
                                    value={values.follower[index]}
                                    defaultList={ownerList}
                                    onSelectItem={(itemFollower: ListItem) => {
                                      values.follower[index] = itemFollower.value;
                                      return setFieldValue('follower', values.follower);
                                    }}
                                  />
                                </Flex>
                                <Flex justify="center" marginHorizontal={5}>
                                  <RectButton onPress={
                                    () => setFieldValue(
                                      'follower',
                                      values.follower
                                        .filter((_follower: any, ifi: number) => index !== ifi),
                                    )
                                  }
                                  >
                                    <Icon color={Colors.WARNING} name="minus-circle" size={20} />
                                  </RectButton>
                                </Flex>
                              </Flex>
                            </InputContainer>
                          </React.Fragment>
                        ))}
                        <InputContainer>
                          <ButtonWithIcon
                            enabled={values.follower.length < MAX_FOLLOWER}
                            onPress={() => setFieldValue('follower', values.follower.concat(0))}
                            icon={<Icon color={Colors.ORANGE} name="plus" size={24} />}
                            textColor={Colors.ORANGE}
                            text={translateBase('addFollower')}
                            color={Colors.WHITE}
                          />
                        </InputContainer>
                      </>
                    )}

                </Container>
              </ScrollView>
              <ButtonContainer
                width={width - 32}
              >
                <ButtonWithIcon
                  textColor={Colors.WHITE}
                  color={Colors.ORANGE}
                  loading={isSubmitting}
                  onPress={async () => {
                    const errorList = await validateForm();
                    if (errorList) {
                      scrollToAndFocusInvalid(errorList);
                    }
                    handleSubmit();
                  }}
                  text={translate('save')}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  enabled
                />
              </ButtonContainer>
              <Popup
                font="Body3"
                visible={showModal}
                // eslint-disable-next-line react/no-unstable-nested-components
                Body={() => (
                  <Flex style={{ maxHeight: 250 }}>
                    <ScrollView style={{ flexGrow: 1 }}>
                      {peoples.length > 0 ? peoples.map((item: any) => (
                        <Item
                          id={item.id}
                          contactFoundModal={setShowModal}
                          key={item.id}
                          email={item.email}
                          phone={item.phone}
                          name={item.name}
                          handle={() => {
                            const address = item.address?.[0];

                            const state = address?.state_id || address?.state;
                            const city = address?.city_id || address?.city;

                            setValues({
                              ...values,
                              first_name: item.firstname,
                              last_name: item.lastName,
                              title: `${item.firstname || ''} ${item.lastName || ''} Opp`,
                              email: item.email,
                              phone_number: item.phone_clean,
                              address1: address?.address,
                              address2: address?.address_2,
                              state_id: parseInt(state, 10),
                              city: city,
                              zipcode: address?.zip,
                              people_id: item.id,
                            });

                            setShowModal(false);
                          }}
                        />
                      )) : <View />}
                    </ScrollView>
                  </Flex>
                )}
                close={() => closeModal()}
                titleText={messageFound}
              />
              <Popup
                font="Body3"
                visible={showDelete}
                // eslint-disable-next-line react/no-unstable-nested-components
                Body={() => (
                  <Flex>
                    <Flex style={{ marginTop: 16 }}>
                      <ButtonWithIcon
                        text={translate('confirm')}
                        onPress={() => {
                          deleteOpportunity(values.Id);
                          resetForm();
                          clearSession();
                          refreshOpportunityList();
                          setShowDelete(false);
                          goBack();
                        }}
                      />
                      <Flex style={{ marginTop: 8 }}>
                        <ButtonWithIcon
                          color={Colors.WHITE}
                          onPress={() => setShowDelete(false)}
                          textColor={Colors.ORANGE}
                          text={translate('cancel')}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                )}
                close={() => setShowDelete(false)}
                titleText={translate('deleteOppConfirmation')}
              />
            </Flex>
          )
        }
      </Formik>
      <Toast config={toastConfig} position="bottom" />
    </Flex>
  );
};

const mapStateToProps = (state: RootState) => ({
  activeSession: state.opportunity.activeOpportunity,
  language: 'EN',
  flag_can_delete_lead: state.settings.companySettings?.flag_can_delete_lead || 0,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changeActiveSession: (item: Opportunity) => dispatch(setActiveOpportunity(item)),
  clearSession: () => dispatch(clearActiveOpportunity()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddOpp);
