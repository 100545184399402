import { gql } from '@apollo/client';
import { MessageInputInterface } from 'domain/shared/types/feedback';
import { TryCatch } from '@sentry/browser';
import client from '../client';

const CREATE_MESSAGE_MUTATION = gql`
  mutation createMessage($input: MessageInput!) {
    createMessage(input: $input) {
      id
      uuid
      message
    }
  }
`;

export default async (input: MessageInputInterface) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_MESSAGE_MUTATION,
      variables: { input },
    });
    return response.data.createMessage as MessageInputInterface;
  } catch (error) {
    console.log(error);
  }
};
