import React, {
  useState, useContext, useCallback, useEffect, useMemo,
} from 'react';
import { Flex } from '@kanvas/andromeda';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import Colors from 'theme/colors';
import styled from 'styled-components/native';
import ProductList from 'organisms/product-list';
import useWindowSize from 'domain/shared/hooks/useWindowSize';
import LinkButton from 'components/atoms/link-button';
import { ContentPreviewContext } from 'domain/opportunity-wall/screens/content-preview/content-preview-context';
import { ProductVariant, ProductVariantMap } from 'domain/shared/types/product-variants';
import ViewTitle from 'components/molecules/view-title';
import { IS_MOBILE } from 'domain/shared/constants';
import { InventoryStackContext } from 'domain/view-inventory/context';
import { ProductListContext } from './product-list-context';

const Container = styled(Flex) <{ height: number }>`
  background-color: ${Colors.WHITE};
  height: ${(props) => props.height}px;
`;

interface EnableMultiSelectionProps {
  onEnableMultiSelect?: () => void;
  onCancelMultiSelect?: () => void;
  enable?: boolean;
}

const EnableMultipleSelection = (props: EnableMultiSelectionProps) => {
  const { onEnableMultiSelect, onCancelMultiSelect, enable = false } = props;
  const { setSelectedProducts } = useContext(ContentPreviewContext);
  const onSelect = useCallback(() => {
    setSelectedProducts([]);
    onEnableMultiSelect?.();
  }, [onEnableMultiSelect]);

  const onCancel = useCallback(() => {
    setSelectedProducts([]);
    onCancelMultiSelect?.();
  }, [onCancelMultiSelect]);

  return (
    enable ? (
      <LinkButton
        text={translate('cancel', TextTransform.CAPITAL)}
        onPress={onCancel}
        size={16}
      />
    ) : (
      <LinkButton
        text={translate('select', TextTransform.CAPITAL)}
        onPress={onSelect}
        size={16}
      />
    )
  );
};

const ShareProduct = () => {
  const [selectedItems, setSelectedItems] = useState<ProductVariantMap>({});
  const [enable, setEnable] = useState(false);
  const [multipleSelectEnabled, setMultipleSelectEnable] = useState(false);
  const {
    setActionState,
    setSelectedProducts,
    leadUUID,
  } = useContext(ContentPreviewContext);
  const { showSelectButton } = useContext(InventoryStackContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, height] = useWindowSize();
  const isShareProduct = leadUUID !== undefined;

  const onItemSelected = useCallback((item: ProductVariant) => {
    setSelectedItems((old) => {
      const items = old;
      if (items[item.id]) {
        delete items[item.id];
      } else {
        items[item.id] = item;
      }
      return { ...items };
    });
  }, [selectedItems.current]);

  const onEnableMultiSelect = useCallback(() => {
    setEnable(true);
    setMultipleSelectEnable(true);
  }, []);

  const onCancelMultiSelect = useCallback(() => {
    setMultipleSelectEnable(false);
    setEnable(false);
    setSelectedItems({});
  }, []);

  const setToolbarItems = useCallback(() => {
    if (!showSelectButton) return;
    setActionState?.({
      title: '',
      toolbarRight: (
        <EnableMultipleSelection
          onEnableMultiSelect={onEnableMultiSelect}
          onCancelMultiSelect={onCancelMultiSelect}
          enable={enable}
        />
      ),
    });
  }, [enable, showSelectButton]);

  useEffect(() => {
    setToolbarItems();
  }, [enable]);

  const contextValue = useMemo(() => ({
    multipleSelectEnabled,
    selectedItems,
    onItemSelected,
    setToolbarItems,
    cancelMultiSelect: onCancelMultiSelect,
  }), [multipleSelectEnabled, selectedItems]);

  useEffect(() => {
    const products = Object.entries(selectedItems).reduce((acc, current) => {
      acc.push(current[1]);
      return acc;
    }, new Array<ProductVariant>());

    setSelectedProducts?.(products);
  }, [selectedItems]);

  return (
    <ProductListContext.Provider
      value={contextValue}
    >
      <Container height={height}>
        {
          !isShareProduct && IS_MOBILE && (
            <ViewTitle title={translate('inventory', TextTransform.CAPITAL)} />
          )
        }
        <Flex marginHorizontal={16} marginVertical={10} flex={1}>
          <ProductList leadId={leadUUID} />
        </Flex>
      </Container>
    </ProductListContext.Provider>
  );
};

export default ShareProduct;
