import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
  CONTACT_LIST,
  CREATE_SESSION,
  CREATE_CONTACT,
  CONTACT_FOUND_LIST,
  CONTACT_MODAL,
  CLOSED_SESSIONS,
  SCANNER,
  ID_SCANNER,
  ADD_OPPORTUNITY,
} from 'domain/shared/screens';
import AddOpp from 'domain/create-opportunity/add';
import { presentation } from 'domain/opportunity-list';
import useHideBottomBar from 'domain/shared/hooks/use-hide-bottom-bar';
import ScannerScreen from './screens/scanner';
import IdScannerScreen from './screens/id-scanner';

export const OpportunityScreens = {
  CREATE: CREATE_SESSION,
  ADD: ADD_OPPORTUNITY,
  CONTACT_LIST,
  CREATE_CONTACT,
  CONTACT_FOUND_LIST,
  CONTACT_MODAL,
  LIST: CLOSED_SESSIONS,
  SCANNER,
  ID_SCANNER,
};

const Stack = createNativeStackNavigator();

export const CreateOpportunityStack = ({ route, navigation }: any) => {
  useHideBottomBar({ route, navigation, screens: [OpportunityScreens.SCANNER] });

  return (
    <Stack.Navigator
      initialRouteName={OpportunityScreens.ADD}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name={OpportunityScreens.ADD} component={AddOpp} />
      <Stack.Screen
        name={OpportunityScreens.SCANNER}
        component={ScannerScreen}
        options={{ presentation, gestureEnabled: false }}
      />
      <Stack.Screen
        name={OpportunityScreens.ID_SCANNER}
        component={IdScannerScreen}
        options={{ presentation, gestureEnabled: false }}
      />
    </Stack.Navigator>
  );
};
