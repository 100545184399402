import { getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { TAB_BER_HEIGHT } from 'domain/index';
import { useLayoutEffect } from 'react';

type Props = {
  route: any;
  navigation: any;
  screens: string[];
};

export default function useHideBottomBar({ route, navigation, screens: screen }: Props) {
  useLayoutEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route);
    if (routeName && screen.includes(routeName)) {
      navigation.setOptions({
        tabBarStyle: { display: 'none' },
      });
    } else {
      navigation.setOptions({
        tabBarStyle: {
          height: TAB_BER_HEIGHT,
        },
      });
    }
  }, [route, navigation]);
}
