/* eslint-disable no-useless-escape */
import React, { useCallback, useState } from 'react';
import { SafeAreaView } from 'react-native';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { Flex, Spacer } from '@kanvas/andromeda';
import { KeyboardAwareScrollView as ScrollView } from 'react-native-keyboard-aware-scroll-view';
import Header from 'components/molecules/settings-header';
import Screen from 'components/template/screen';
import { FeedBack, MessageInputInterface } from 'domain/shared/types/feedback';
import * as Yup from 'yup';
import { Formik } from 'formik';
import styled from 'styled-components';
import Colors from 'theme/colors';
import { FormLabel, TextInputWithLabel } from 'components/molecules/input-with-label';
import DropDown from 'components/atoms/dropdown-input';
import { ListItem } from 'domain/create-opportunity/add/component/types';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import { showSuccess } from 'domain/shared/utils/toast';
import createFeed from 'domain/shared/graphql/mutations/feedback-user-create';
import useAuth from 'domain/shared/hooks/useAuth';
import { useNavigation } from '@react-navigation/native';

const InputContainer = styled(Flex)`
  height: 70px;
  margin-vertical:1px;
`;

const initialValues: FeedBack = {
  id: '0',
  categoryId: 1,
  comment: '',
};

const validationSchema = Yup.object().shape({
  comment: Yup.string().trim().required(),

});

const CATEGORIES: ListItem[] = [
  {
    key: '1',
    label: ' Share Product Feedback',
    value: 1,
  },
  {
    key: '2',
    label: ' Report a Bug',
    value: 2,
  },
];
const FeedBacks = () => {
  const { user } = useAuth();
  const navigation = useNavigation() as any;
  const user_uuid = user?.uuid;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onSubmit = useCallback(async (values:FeedBack) => {
    const data: MessageInputInterface = {
      message_types_id: '54',
      message: values,
      parent_id: '0',
      system_modules_id: '110',
      entity_id: user_uuid || '',
    };
    const response = await createFeed(data);
    return response;
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values: FeedBack, actions: any) => {
        setIsLoading(true);
        onSubmit(values).then(() => {
            setIsLoading(false);
          showSuccess(translate('feedbackThaks', TextTransform.CAPITAL), { visibilityTime: 3000 });
          actions.resetForm();
          navigation.openDrawer();
        });
      }}
    >
      {({
        values,
        setFieldValue,
        handleChange,
        handleSubmit,
      }) => (
        <Screen flex={1}>
          <SafeAreaView>
            <Header
              title={translate('feedback', TextTransform.CAPITALIZE)}
            />
          </SafeAreaView>
          <ScrollView>
            <Flex padding={16} gap={10}>
              <InputContainer>
                <Spacer size={2} />
                <FormLabel
                  required
                  text={translate('selectCategory')}
                />
                <DropDown
                  zIndexInverse={999}
                  placeHolder={translate('selectCategory', TextTransform.CAPITALIZE)}
                  value={values.categoryId}
                  items={CATEGORIES}
                  onSelectItem={(item: ListItem) => {
                    setFieldValue('categoryId', item?.value);
                  }}
                />
              </InputContainer>
              <InputContainer>
                <TextInputWithLabel
                  label="description"
                  handleChange={handleChange('comment')}
                  value={values.comment}
                  type="input"
                  multiline
                  numberOfLines={18}
                />
              </InputContainer>
              <InputContainer paddingVertical={40}>
                <ButtonWithIcon
                  text={translate('submit', TextTransform.CAPITAL)}
                  onPress={() => {
                    handleSubmit();
                  }}
                  loading={isLoading}
                />
              </InputContainer>
            </Flex>
          </ScrollView>
        </Screen>
      )}
    </Formik>
  );
};
export default FeedBacks;
