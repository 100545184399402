import React from 'react';
import { Text as ANText } from '@kanvas/andromeda';
import { ITextProps } from '@kanvas/andromeda/lib/components/text';
import { FONTS } from 'theme/typography';

interface IProps extends ITextProps {
  text: string
}

const Text = ({ text, ...props }: IProps) => (
  <ANText fontFamily={FONTS.SFProText} {...props}>
    {text}
  </ANText>
);

export type { IProps as TextProps };

export default Text;
