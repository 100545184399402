import React, {  } from 'react';
import { Flex } from '@kanvas/andromeda';
import { ScrollView } from 'react-native-gesture-handler';
import styled from 'styled-components/native';
import Colors from 'theme/colors';
import { Opportunity } from 'domain/shared/types/opportunity';
import Toast from 'react-native-toast-message';
import OpportunityListItem from '../opportunity-list-match-item';

const Container = styled(Flex)`
    background-color:${Colors.WHITE};
`;

interface IProps {
  opportunityList: any;
  args:any;
  notification?:boolean;
}
export const showToast = () => {
//   showSuccessSync(translate('syncMessageSuccess', TextTransform.NONE));
};

const OpportunityListMatch = (props: IProps) => {
  const { opportunityList = [], args, notification = true } = props;

  return (
    <Container style={{ marginVertical: 12, flex: 1 }}>
      <ScrollView>
        {
            opportunityList.map((opp:any) => (
              <OpportunityListItem
                key={opp.id}
                opp={opp.data as Opportunity}
                args={args}
                showToast={showToast}
              />
            ))
        }
      </ScrollView>
      {notification && <Toast />}
    </Container>
  );
};

export default OpportunityListMatch;
