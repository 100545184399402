import React from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import { Flex, Spacer } from '@kanvas/andromeda';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from 'theme/colors';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { IS_MOBILE, IS_WEB } from 'domain/shared/constants';

interface IProps {
  onOpen: () => void;
  onShare: () => void;
  isOpenLoading?: boolean;
  isShareLoading?: boolean;
  disabled?: boolean;
}

const Container = styled(Flex)`
  position: absolute;
  bottom: 0px;
  padding-horizontal: 16px;
  width: 100%;
  background-color: ${colors.WHITE};
  padding-top: ${Platform.OS === 'ios' ? 10 : 0}px;
  height: ${Platform.OS === 'android' ? 90 : 100}px;
  ${IS_WEB ? `position: fixed; z-index: 999; box-shadow: 0px -4px 16px ${colors.BUTTON_BORDER};` : ''};
  ${IS_MOBILE ? `padding-bottom: ${getBottomSpace()}px` : ''}
`;

const CXPreviewActions = (props: IProps) => {
  const {
    onOpen,
    onShare,
    isOpenLoading = true,
    isShareLoading = false,
    disabled = false
  } = props;

  const openEnable = isOpenLoading === false && !disabled;
  const shareEnable = isShareLoading === false && !disabled;

  return (
    <Container row align="center">
      <Flex flex={1}>
        <ButtonWithIcon
          icon={<Icon name="open-in-new" size={20} color={colors.WHITE} />}
          text="Open"
          onPress={onOpen}
          loading={isOpenLoading}
          enabled={openEnable}
        />
      </Flex>
      <Spacer horizontal size={6} />
      <Flex flex={1}>
        <ButtonWithIcon
          icon={<Icon name="send" size={20} color={colors.WHITE} />}
          text="Share"
          onPress={onShare}
          loading={isShareLoading}
          enabled={shareEnable}
        />
      </Flex>
    </Container>
  );
};

export default CXPreviewActions;
