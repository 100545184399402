import * as React from 'react';
import Svg, {
  G, Path, Defs, ClipPath,
} from 'react-native-svg';
import Colors from 'theme/colors';

function GetDocs(props: any) {
  const { fillColor = Colors.ICON_SECONDARY } = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={60}
      height={60}
      viewBox="0 0 1080 1080"
      {...props}
    >
      <Defs>
        <ClipPath id="prefix__a">
          <Path d="M0 0h1080v1080H0z" />
        </ClipPath>
      </Defs>
      <G data-name="Custom Size \u2013 4" clipPath="url(#prefix__a)">
        <Path d="M0 0h1080v1080H0z" />
        <G data-name="Group 1976">
          <G data-name="Group 1975">
            <Path
              data-name="Path 586"
              d="M1026.208 254.106L908.179 129.152a15.834 15.834 0 00-11.192-4.636H718.021V55.582A55.645 55.645 0 00662.437 0H93.188a55.646 55.646 0 00-55.584 55.582V899.9a55.645 55.645 0 0055.584 55.582h210.478a15.827 15.827 0 100-31.653H93.188a23.957 23.957 0 01-23.931-23.931V55.582a23.958 23.958 0 0123.931-23.931h523.611L410.09 111a75.443 75.443 0 00-47.4 60.309c-1.057 6.623-.58 10.5-.7 18H135.014a27.973 27.973 0 00-27.94 27.941v141.77a27.973 27.973 0 0027.941 27.941h226.971v42.5H135.014a27.973 27.973 0 00-27.94 27.939v141.77a27.973 27.973 0 0027.941 27.941h226.971v47.783H122.9a15.827 15.827 0 100 31.653h239.086v48.884H122.9a15.827 15.827 0 100 31.653h239.086v237.331A55.646 55.646 0 00417.568 1080h569.247a55.645 55.645 0 0055.582-55.584V269.931c0-4.2-22.419-13.369-16.189-15.824zm-113.4-75.557l75.557 75.557h-51.626a23.96 23.96 0 01-23.931-23.931zm-449.606-54.033l218.032-83.693a23.79 23.79 0 015.134 14.761v68.932zM138.725 355.307V220.962h223.254v134.345zm0 240.149V461.111h223.254v134.345zm848.09 452.886H417.568a23.958 23.958 0 01-23.931-23.931V180.1a23.969 23.969 0 0123.931-23.931h463.589v74.007a55.646 55.646 0 0055.582 55.584h74.007v738.65a23.959 23.959 0 01-23.931 23.933z"
              fill={fillColor}
              stroke={fillColor}
              strokeWidth={20}
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default GetDocs;
