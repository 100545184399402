import { Flex, Spacer } from '@kanvas/andromeda';
import React from 'react';
import Icon from 'react-native-vector-icons/MaterialIcons';
import styled from 'styled-components';
import colors from 'theme/colors';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Meta2 } from '../text';

const Meta2Custom = styled(Meta2)`
  flex: 1;
  flex-wrap: wrap;
`;
interface Iprods {
  feedbacks:[],
  created_at:string
}

const FeedBack = (props:Iprods) => {
  const { feedbacks, created_at } = props;
  const tz = moment.tz.guess();
  const date = moment.utc(created_at).tz(tz || '').format('MM/DD/YYYY [at] hh:mma');
  const lead = useSelector((state: any) => state.opportunity.activeOpportunity);
  const { title = '' } = lead;
  return (
    <>
      {
        feedbacks.length > 0 ? (feedbacks.map(({ id, name, interested }) => (
          <Flex key={id} row>
            <Icon name={interested ? 'thumb-up' : 'thumb-down'} color={colors.SECONDARY_TEXT} size={16} />
            <Spacer horizontal size={2} />
            <Meta2Custom
              text={`${title|| ''} ${interested ? 'is' : 'is not'} interested in the ${name} that you shared ${date}.`}
              style={{ flex: 1, flexWrap: 'wrap' }}
            />
          </Flex>
        ))) : null
          }
    </>
  );
};

export default FeedBack;
