import React, { FC, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
}

const Linkedin: FC<Props> = ({ size = 20, ...props }) => (
  <svg {...props} height={size} id="svg2" version="1.1" width={size} viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
    <defs id="defs4" />
    <g id="layer1" transform="translate(0,-552.36218)">
      <rect height="500" id="rect2990-1" rx="250" ry="250" fill="#0077b5" fillOpacity={1} stroke="none" width="500" x="0" y="552.36218" />
      <g id="layer1-9" transform="translate(-549.00515,80.294372)" />
      <g id="layer1-6" transform="translate(-575.71429,39.999999)" />
      <g id="layer1-92" transform="matrix(0.99533037,0,0,0.99716768,619.96997,-73.128637)">
        <g id="layer1-9-7" transform="translate(-1068,-236)" />
      </g>
      <path d="M 128.76316,642.36224 C 107.34952,642.36224 90,659.75123 90,681.20435 c 0,21.45322 17.34952,38.8421 38.76316,38.8421 21.41364,0 38.76316,-17.38888 38.76316,-38.8421 0,-21.45312 -17.34952,-38.84211 -38.76316,-38.84211 z m 203.02631,101.13158 0,0.0263 c -4.37833,-0.0156 -8.62646,0.15968 -14.18421,0.44737 -12.18891,0.63097 -40.6558,13.83786 -51.07894,34.92106 0,-9.15329 0.10526,-17.55993 0.10526,-30.10527 -12.4852,0 -28.82418,0.0789 -62.94737,0.0789 0,38.30812 -0.42105,173.97085 -0.42105,213.5 l 66.07895,0 c 0,-23.44978 0.31578,-82.35285 0.31578,-119.34211 0,-12.42047 7.50808,-41.10526 38.31579,-41.10526 27.97383,0 35.81579,23.57737 35.81579,41.10526 0,38.52259 -0.28947,92.97632 -0.28947,119.34211 l 66.47368,0 c 0,-25.77235 0.23633,-95.77893 -0.55263,-139.94737 -0.95794,-53.62831 -33.53337,-76.50988 -62.76316,-78.31579 -6.01505,-0.37149 -10.49009,-0.5893 -14.86842,-0.60526 z m -236.210523,6.21053 0,212.55263 66.263163,0 0,-212.55263 -66.263163,0 z" id="rect2989-1-7" fill="#ffffff" fillOpacity={1} stroke="none" />
    </g>
  </svg>
);

export default Linkedin;
