import React, { useEffect } from 'react';
import { Meta2 } from 'molecules/text';
import Colors from 'theme/colors';

interface IProps {
  text: string;
}

const ErrorLabel = ({ text }: IProps) => {
  useEffect(() => {
    console.log('error label');
  }, []);
  return (
    <Meta2 text={text} color={Colors.ERROR} />
  );
};

export default ErrorLabel;
