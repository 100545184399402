import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Flex } from '@kanvas/andromeda';
import styled from 'styled-components/native';
import Colors from 'theme/colors';

const Indicator = styled.View`
  width: 6px;
  height: 6px;
  background-color: ${Colors.WHITE};
  border-radius: 3px;
  position: absolute;
  bottom: -10px;
  align-self: center;

`;

interface IProps {
  icon: React.ReactNode;
  onPress?: () => void;
  selected?: boolean;
}

const TabBarButton = (props: IProps) => {
  const {
    icon,
    onPress,
    selected = false,
  } = props;
  return (
    <TouchableOpacity onPress={onPress}>
      <Flex>
        {icon}
        {
          selected && (
            <Indicator />
          )
        }
      </Flex>
    </TouchableOpacity>
  );
};

export default TabBarButton;
