import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/native';
import CheckList from 'organisms/check-list';
import { ButtonListItem } from 'components/organisms/button-list';
import useForms from 'domain/shared/hooks/useForms';
import { Flex } from '@kanvas/andromeda';

type Item = { label: string, value: any, selected?: boolean };
interface IProps {
  text?: string;
  items: Item[];
  selected: Item[];
  action: string;
  expandable?: boolean;
  notScrollable?: boolean;
  onChange: (items: Item[]) => void;
}

const ContentContainer = styled.View<any>`
  width: 100%;
  border-radius: 5px;
  margin-bottom: 40px;
  flex: 1;
`;

function Container({ children, notScrollable }: { notScrollable: boolean, children: ReactNode }) {
  if (notScrollable) return <Flex flex={1}>{children}</Flex>;
  return <ContentContainer>{children}</ContentContainer>;
}

const ContentMultiSelect = ({ items, selected, onChange, ...props }: IProps) => {
  const [list, setList] = useState<ButtonListItem[]>([]);
  const { setForms, forms } = useForms();

  const parseItems = useCallback((item: Item) => {

    const exist = selected.find(i => i.value == item.value);
    const isSelected = !!exist || item.selected || false;

    return {
      text: item.label,
      value: item.value,
      key: item.value,
      selected: isSelected,
    };
  }, []);

  useEffect(() => {
    setList(items.map(parseItems));
    setForms({});
  }, [items, parseItems]);

  const removeFormFromList = (formList: ButtonListItem[]) => {
    let listForms = {};
    formList.forEach((item) => {
      if (forms[item.value]) {
        listForms = { ...listForms, [item.value]: forms[item.value] };
      } else {
        listForms = { ...listForms, [item.value]: {} }
      }
    });
    setForms(listForms);
  };

  const onSelectionChange = (vals: ButtonListItem[]) => {
    onChange(vals.map((i) => ({ label: i.text, value: i.value })));
    removeFormFromList(vals);
  };

  return (
    <Container notScrollable={!!props.notScrollable}>
      <CheckList
        {...props}
        items={list}
        onChange={onSelectionChange}
      />
    </Container>
  );
};

export default ContentMultiSelect;
