/* eslint-disable max-len */
/* eslint-disable eqeqeq */
import styled from 'styled-components/native';
import React, { useRef, useState } from 'react';
import Spinner from 'atoms/spinner';
import {
  View, FlatList, Text, NativeSyntheticEvent, NativeScrollEvent,
} from 'react-native';
import FastImage from 'react-native-fast-image';

import colors from 'theme/colors';
import { defeaultImages } from 'theme/images-uri';
import { IS_WEB } from 'domain/shared/constants';
import useWindowSize from 'domain/shared/hooks/useWindowSize';
import { Flex } from '@kanvas/andromeda';
import { useDebouncedCallback } from 'use-debounce';

const ITEM_WIDTH = 50;

const Container = styled.View`
  background-color: ${colors.WHITE};
`;

const Image = styled(FastImage)`
  width: 100%;
  height: 100%;
`;

const Touchable = styled.TouchableOpacity<any>`
  margin-top: 2px;
  margin-right: 2px;
  ${(props) => props.selected && 'border-width: 1px'};
  ${(props) => props.selected && `border-color: ${colors.ORANGE}`};
`;

const Carrousel = ({ photos, isLoading }: any) => {
  const [selectedPhoto, setPhoto] = useState(0);
  const [screenWidth] = useWindowSize();
  const thumbRef = useRef<FlatList>(null);

  let sliderRef: any = null;

  const changePhotoIndex = (index: any, scrollToItem = true) => {
    if (index == 0 || index != selectedPhoto) {
      setPhoto(index);
      if (scrollToItem) {
        if (sliderRef) {
          sliderRef.scrollToIndex({ animated: true, index });
        }
      }
    }
  };

  const renderItem = ({ item, index }: any) => (
    <Touchable selected={index === selectedPhoto} onPress={() => changePhotoIndex(index)}>
      <FastImage
        style={{ width: ITEM_WIDTH, height: 38 }}
        source={{
          uri: item.url,
          priority: FastImage.priority.normal,
        }}
        resizeMode={FastImage.resizeMode.cover}
      />
    </Touchable>
  );

  const getItemLayout = (data: any, index: any) => ({
    length: ITEM_WIDTH,
    offset: ITEM_WIDTH * index,
    index,
  });

  const getSliderItemLayout = (data: any, index: any) => ({
    length: screenWidth,
    offset: screenWidth * index,
    index,
  });

  // const openFullCarrousel = (index: any) => {
  //   navigation.navigate(
  //     FULL_CARROUSEL,
  //     {
  //       photos,
  //       vehicleId,
  //       initialPhotoIndex: index,
  //       viewOnlyMode: true,
  //       changePhotoIndex,
  //     },
  //   );
  // };

  const setSliderRef = (ref: any) => {
    sliderRef = ref;
  };

  const sliderKeyExtractor = (item: any) => `SL-${item.url}`;

  const keyExtractor = (item: any) => `PH-${item.url}`;

  const onMomentumScrollEnd = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const { nativeEvent: { contentOffset: { x = 0 } = {} } = {} } = event;

    const index = x / screenWidth;
    changePhotoIndex(index, false);
    thumbRef.current?.scrollToIndex({ index, animated: true });
  };

  const onScroll = useDebouncedCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const { nativeEvent: { contentOffset: { x = 0 } = {} } = {} } = event;
    const offset = 22;
    const index = Math.floor((x - offset) / screenWidth);
    changePhotoIndex(index, false);
    thumbRef.current?.scrollToIndex({ index, animated: true });
  }, 200);

  const sliderRenderItem = ({ item, index }: any) => (
    <Flex>
      <View style={{ width: screenWidth, height: 330 }}>
        <Image
          source={{
            uri: item.url,
            priority: FastImage.priority.high,
            headers: { Accept: '*/*' },
          }}
          resizeMode={FastImage.resizeMode.cover}
        />
      </View>

      <View
        style={{
          borderRadius: 100,
          backgroundColor: colors.ORANGE,
          width: 28,
          height: 28,
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'flex-end',
          marginRight: 8,
          position: 'absolute',
          bottom: 0,
          right: 0,
          marginBottom: 8,
        }}
      >
        <Text style={{ fontWeight: '600', color: 'white' }}>{index + 1}</Text>
      </View>
    </Flex>
  );

  if (isLoading) {
    return <Spinner />;
  } if (photos?.length) {
    return (
      <Container>
        <FlatList
          ref={setSliderRef}
          horizontal
          data={photos}
          renderItem={sliderRenderItem}
          showsHorizontalScrollIndicator={false}
          getItemLayout={getSliderItemLayout}
          keyExtractor={sliderKeyExtractor}
          decelerationRate="fast"
          {...IS_WEB ? { onScroll } : {}}
          {...!IS_WEB ? { onMomentumScrollEnd } : {}}
          pagingEnabled
        />
        <FlatList
          ref={thumbRef}
          horizontal
          data={photos}
          renderItem={renderItem}
          showsHorizontalScrollIndicator={false}
          getItemLayout={getItemLayout}
          keyExtractor={keyExtractor}
        />
      </Container>
    );
  }
  return (
    <FastImage
      style={{ width: screenWidth, height: 184 }}
      source={defeaultImages.carDefault.uri}
      resizeMode={FastImage.resizeMode.cover}
    />
  );
};

export default Carrousel;
