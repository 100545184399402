import React from "react";
import { ReactNode } from "react";
import { ListRenderItemInfo } from "react-native";
import styled from "styled-components/native";
import Icon from 'react-native-vector-icons/MaterialIcons';

import Task, { Status } from "model/data/task";

import colors from "theme/colors";
import { IS_WEB } from "domain/shared/constants";

import { Button2 } from "../text";

interface Props {
  info: ListRenderItemInfo<Task>;
  onPress?: (item: any) => void;
} 

export default function TaskButton(props: Props) {
  const { info, onPress } = props;
  const { item } = info;

  const handlePress = () => onPress?.(item);

  if (item.status === Status.COMPLETED) {
    return (
      <ReviewFile activeOpacity={.5} onPress={handlePress}>
        <Icon name='attach-file' color={colors.WHITE} size={16} />
      </ReviewFile>
    );
  }

  return (
    <Start activeOpacity={.5} onPress={handlePress}>
      <Button2 text='Start' color={colors.TEXT_ORANGE_LINK} />
    </Start>
  );
}

const CommonButton = styled.TouchableOpacity`
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.WHITE};
  border: 1px solid ${colors.BUTTON_BORDER};;
  box-shadow: 0px -4px 16px ${colors.BUTTON_BORDER};
  ${IS_WEB && `box-shadow: 0px -4px 16px ${colors.BUTTON_BORDER};`};
`;

const Start = styled(CommonButton)`
  width: 70px;
  height: 40px;
  padding: 0 8px;
`;

const ReviewFile = styled(CommonButton)`
  width: 40px;
  height: 40px;
  background-color: ${colors.ORANGE};
`;