/* eslint-disable no-useless-escape */
import React from 'react';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Colors from 'theme/colors';
import { Linking } from 'react-native';
import { ContactType } from 'domain/shared/types/contact-types';
import styled from 'styled-components/native';
import { showError } from 'domain/shared/utils/toast';
import { Flex } from '@kanvas/andromeda';
import { ACTION_PANEL } from 'domain/shared/types/amplitude-event';
import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';
import { OPP_WALL_CALL, OPP_WALL_EMAIL, OPP_WALL_TEXT } from 'domain/shared/types/amplitude-actions';
import { TouchableOpacity } from '../touchable';

type IProps = {
  contact: string;
  iconName: string;
  type: ContactType;
  rpadding?: boolean | undefined;
  userId: number;
  company_id: number;
};

const errorMessages: Record<ContactType, string> = {
  0: 'This lead does not have a phone number assigned to the contact',
  1: 'This lead does not have an email address assigned to the contact',
  2: 'This lead does not have a phone number assigned to the contact',
};

const ButtonStyled = styled<any>(TouchableOpacity)`
  height: 48px;
  background-color: ${(props) => (props?.contact !== '' ? Colors.WHITE : Colors.PRIMARY_DEEMPH)};
  border-radius: 100px;
  align-items: center;
  justifyContent: center;
  border-width: 1px;
  border-color: ${Colors.BORDER_COLOR};
`;
const ButtonContainer = styled<any>(Flex)`
    margin-right: ${(props) => (props.rpadding ? 12 : 0)}px;
    cursor: pointer;
`;

const cleanPhoneNumberFormat = (phoneNumber: string) => {
  const reg = /\+|\(|\)|\s|-/g;
  return phoneNumber.replace(reg, '');
};

const openLink = async (contact: string, type: ContactType, userId: number, company_id: number) => {
  if (!contact) {
    const message = errorMessages[type];
    showError(message);
    return;
  }

  if (type === ContactType.PHONE) {
    const phone = cleanPhoneNumberFormat(contact);
    sendAmplitudeEvent(OPP_WALL_CALL, { userId, phone });
    Linking.openURL(`tel:${phone}`);
  }

  if (type === ContactType.MESSAGE) {
    const phone = cleanPhoneNumberFormat(contact);
    sendAmplitudeEvent(OPP_WALL_TEXT, { userId, phone });
    Linking.openURL(`sms:${phone}`);
  }

  if (type === ContactType.EMAIL) {
    sendAmplitudeEvent(OPP_WALL_EMAIL, { userId, contact });
    Linking.openURL(`mailto:${contact}`);
  }
  sendAmplitudeEvent(ContactType[type], { event: ACTION_PANEL, company_id });
};

const ContactButton = (props: IProps) => {
  const {
    iconName,
    type,
    contact,
    rpadding = false,
    userId,
    company_id,
  } = props;

  return (
    <ButtonContainer flex={1} rpadding={rpadding}>
      <ButtonStyled disabled={contact === ''} onPress={() => openLink(contact, type, Number(userId), company_id)} contact={contact}>
        <Icon name={iconName} size={26} color={Colors.ORANGE} />
      </ButtonStyled>
    </ButtonContainer>
  );
};

export default ContactButton;
