import { useState } from 'react';
import { useBetween } from 'use-between';

const UseNavTab = () => {
  const [active, setActive] = useState<number>(0);

  return {
    active,
    setActive,
  };
};

export default () => useBetween(UseNavTab);
