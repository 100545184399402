import * as React from 'react';
import Svg, {
  Defs, ClipPath, Path, G,
} from 'react-native-svg';
import Colors from 'theme/colors';

function DealerContent(props: any) {
  const { fillColor = Colors.ICON_SECONDARY } = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      {...props}
    >
      <Defs>
        <ClipPath id="prefix__a">
          <Path d="M0 0h1080v1080H0z" />
        </ClipPath>
      </Defs>
      <G data-name="Custom Size \u2013 1" clipPath="url(#prefix__a)">
        <Path d="M0 0h1080v1080H0z" />
        <Path
          data-name="Path 575"
          d="M305.246 1050.818a68.176 68.176 0 0036.155-10.072q16.528-10.072 40.287-31.248l168.383-149.264h303.185q72.826 0 123.443-26.6t76.958-77.216q26.341-50.617 26.341-122.927V256.442q0-72.31-26.341-122.927T976.7 56.04q-50.617-26.858-123.444-26.858h-626.51q-72.31 0-122.927 26.6t-77.22 77.475Q0 184.134 0 256.442v377.044q0 72.31 26.6 123.185t76.958 77.217q50.358 26.346 121.635 26.346h21.693v123.96q0 30.473 15.237 48.551t43.123 18.073zm22.209-100.2V810.645q0-20.66-9.3-29.7t-29.44-9.039h-59.906q-71.277 0-105.624-35.638T88.837 631.934V257.991q0-68.694 34.347-104.075t105.625-35.382H851.19q71.277 0 105.624 35.38t34.347 104.075v373.945q0 68.694-34.347 104.333T851.19 771.908H545.423q-21.693 0-34.605 4.907T482.926 796.7zm11.885-310.934h252.568q36.672 0 57.331-20.144c13.773-13.429 20.66-56.3 20.66-56.3V328.234q0-76.958-75.925-76.958H339.34q-35.122 0-56.557 20.143t-21.437 56.815v235.525q0 36.155 20.66 56.04t57.334 19.885zm357.934-126.026l90.9 77.475a62.129 62.129 0 0013.946 9.039 34.088 34.088 0 0014.462 3.357q13.946 0 22.468-9.3t8.522-24.276V321.521q0-14.978-8.522-24.275t-22.468-9.3a34.088 34.088 0 00-14.462 3.357 73.924 73.924 0 00-13.946 8.522l-90.9 77.991z"
          fill={fillColor}
        />
      </G>
    </Svg>
  );
}

export default DealerContent;
