import { TouchableOpacity } from 'components/atoms/touchable';
import React from 'react';
import Icon from 'react-native-vector-icons/Ionicons';
import colors from 'theme/colors';

interface IProps {
  handle?: Function
  clearInput:Function
}

const BackButton = (props: IProps) => {
  const { handle, clearInput } = props;

  const handleBack = (value: Boolean) => {
    clearInput();
    handle?.(value);
  };
  return (
    <TouchableOpacity onPress={() => handleBack(false)}>
      <Icon name="arrow-back" size={25} color={colors.BLACK} />
    </TouchableOpacity>
  );
};

export default BackButton;
