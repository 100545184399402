import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Flex } from "@kanvas/andromeda";
import styled from "styled-components/native";
import { ActivityIndicator, ScrollView } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

// model
import Task from "model/data/task";

// constants
import colors from "theme/colors";
import { RootState } from "domain/shared/store";
import { showError, showWarning } from "domain/shared/utils/toast";
import useDocList, { CommonListContent } from "domain/opportunity-wall/hooks/use-doc-list";

// components
import CheckInput from "components/molecules/check-input";
import ActionPreviewHeader from "components/molecules/action-preview-header";
import CXPreviewActions from "components/molecules/CXPreviewActions";
import { useAsync } from "react-use";

type ParamList = {
  List: { task: Task }
}

interface Props {
  route: RouteProp<ParamList, 'List'>;
  navigation: NativeStackNavigationProp<ParamList, 'List'>;
}

function useDocActionList(props: Props) {
  const { navigation, route } = props;
  const [items, setItems] = useState<CommonListContent[]>([]);
  
  const { task } = route.params;

  const response = useDocList({ 
    action: 'get-docs', 
    onError: (error: any) => showError(error.message), 
    onNoData: (error: string) => {
      showWarning(error);
      navigation.goBack();
    }
  });

  const handleBack = () => navigation.goBack();

  const handlePress = (item: CommonListContent) => {
    const index = items.findIndex(i => i.value === item.value);

    if (index < 0) {
      setItems([...items, item]);
      return;
    }

    items.splice(index, 1);
    setItems([...items]);
  };

  useEffect(() => {
    if (items.length > 0) return;
    if (response.loading) return;
    if (response.items && response.items.length <= 0) return;

    const selected = response.items!.find(i => i.value == task.config?.item);
    if (selected) setItems([selected]);
  }, [response, items]);

  // TODO: get action preview
  // TODO: send action post request

  return {
    model: {
      response,
      items,
    },
    operations: {
      handleBack,
      handlePress,
    }
  }
}

export default function DocActionList(props: Props) {
  const { model, operations } = useDocActionList(props);
  const { response, items } = model;
  const { handleBack, handlePress } = operations;

  if (response.loading) return <Loading />;

  return (
    <Root>
      {/* TODO: use action name */}
      <ActionPreviewHeader title="Get Docs" onBack={handleBack} />

      <ScrollView contentContainerStyle={{ paddingBottom: 65 }}>
        {/* TODO: add bundles */}
        
        {response.items?.map((item) => {
          const exist = !!items.find(i => i.value === item.value);
          return <CheckInput key={item.value} item={item} selected={exist} onPress={handlePress} />;
        })}
      </ScrollView>

      {/* TODO: add prefill  */}

      <ActionButton>
        <CXPreviewActions 
          onOpen={() => {}}
          onShare={() => {}}
          disabled={items.length <= 0}
          isOpenLoading={false}
          isShareLoading={false}
        />
      </ActionButton>
    </Root>
  );
}

function Loading() {
  return (
    <Root>
      <Flex flex={1} justify="center" align="center">
        <ActivityIndicator color={colors.ORANGE} size="large" />
      </Flex>
    </Root>
  );
}

const Root = styled.SafeAreaView`
  flex: 1;
  position: relative;
  background: ${colors.WHITE};
`;

const ActionButton = styled(Flex)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  background: ${colors.WHITE};
  flex-direction: row;
`;

