import DropDown from 'components/atoms/dropdown-input';
import UserService from 'domain/shared/services/user-service';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ListItem } from '../types';

interface IpropsSearch {
  placeHolder : string,
  value:number,
  defaultList:ListItem[]
  onSelectItem:(item: any) => void,
}
const DropDownSearch = (props: IpropsSearch) => {
  const {
    placeHolder,
    value,
    defaultList,
    onSelectItem,
  } = props;

  const [itemList, setItemList] = useState<ListItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setItemList(defaultList);
  }, [defaultList]);

  const searchItem = useDebouncedCallback(async (text: string) => {
    setLoading(true);
    const list: ListItem[] = (await UserService.getAllUser(text)).map(({ id = 0, firstname = '', lastname = '' }) => ({
      value: id,
      label: `${firstname.trim()} ${lastname.trim()}`,
      key: id,
    }));
    setItemList(list);
    setLoading(false);
  }, 600);

  return (
    <DropDown
      indicator={loading}
      placeHolder={placeHolder}
      value={value}
      items={itemList}
      onChangeSearchText={(text:string) => searchItem(text)}
      onSelectItem={onSelectItem}
    />
  );
};

export default DropDownSearch;
