import { useEffect } from 'react';
import { AppState } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { APP_VERSION } from 'env';
import checkVersion from 'react-native-store-version';
import { MainScreen } from 'domain/screens';
import { IS_WEB } from '../constants';
import BrutalService from '../services/brutal.service';

export async function needUpdate() {
  try {
    if (IS_WEB) return false;

    const check = await checkVersion({
      version: APP_VERSION,
      iosStoreURL: 'https://apps.apple.com/us/app/salesassist-app/id1617172663',
      androidStoreURL: 'https://play.google.com/store/apps/details?id=io.shiplove.salesassist.android',
      country: 'us',
    });

    return check.detail === 'remote > local';
  } catch (r) {
    return false;
  } 
}

export default function useUpdate() {
  const navigation = useNavigation();

  useEffect(() => {
    const state = AppState.addEventListener('change', async (type) => {
      if (type !== 'active') return;

      // TODO: refactor this section to use api call 
      const [need, features] = await Promise.all([
        needUpdate(),
        BrutalService.features()
      ]);
      
      if (!IS_WEB && (features.updater && need)) {
        navigation.navigate(MainScreen.Update as never);
      }
    });

    return () => {
      state.remove();
    };
  }, []);
}
