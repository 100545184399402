import React, { useMemo } from "react";
import FastImage from 'react-native-fast-image';

import { Flex } from "@kanvas/andromeda";
import { Body3, Body4, Meta2 } from "components/molecules/text";

import { currencyFromNumber } from '@kanvas/andromeda/lib/components/currency';

import { ProductVariant } from "domain/shared/types/product-variants";

import Colors from "theme/colors";
import styled from "styled-components/native";

interface IProps {
  item: ProductVariant;
  onPress?: () => void;
}

const VehicleItem = ({ item, onPress }: IProps) => {
  const stock = useMemo(() => item.attributes.find((i) => i.name === 'stock_number')?.value || "", [item]);
  const year = useMemo(() => item.attributes.find((i) => i.name === 'year')?.value || "", [item]);
  const mileage = useMemo(() => item.attributes.find((i) => i.name === 'odometer')?.value || "", [item]);
  const price = useMemo(() => {
    const channels = item.channels as any;
    return channels[0]?.price ? currencyFromNumber(
      channels[0]?.price,
      { precision: 0 },
    ) : "-";
  }, [item]);
  
  const cover = item.files.data[0];

  return (
    <Container activeOpacity={0.8} onPress={onPress}>
      <InfoContainer row>
        <Image
          source={{ uri: cover?.url, headers: { Accept: '*/*' } }}
          style={{
            backgroundColor: Colors.BACKGROUND_DEEMPH,
          }}
        />

        <Flex flex={2} marginHorizontal={8}>
          <Body3 text={item.name} link size={15} />
          <Meta2 text={`${year} | ${mileage} miles`} />
          <Meta2 text={`SKU: ${stock}`} />
        </Flex>

        <Flex flex={1}>
          <Body4
            style={{
              textAlign: 'right'
            }}
            text={price}
            link
            size={13}
            color={Colors.ICON_PRIMARY}
          />
        </Flex>
      </InfoContainer>
    </Container>
  )
}

export default VehicleItem;

const Container = styled.TouchableOpacity`
  height: 90px;
  padding: 12px;
  padding-right: 33px;
  background-color: ${Colors.WHITE};
  border-radius: 8px;
  color: ${Colors.PRIMARY_TEXT};
`;

const InfoContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const Image = styled(FastImage)`
  width: 90px;
  height: 100%;
`;
