import { gql } from "@apollo/client";

export type TaskList = {
  id: number;
  name: string;
  config: any;
  tasks: {
    id: number;
    name: string;
  }[];
}

export const TaskListQuery = gql`
  query taskLists {
    taskLists {
      data {
        id
        name
        config
        tasks {
          id
          name
        }       
      }
    }
  }
`;