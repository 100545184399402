import axios from 'domain/shared/services/axios';
import Client from 'domain/shared/graphql/client';
import getProductVariantsPaginated from 'domain/shared/graphql/product-variants/getProductVariantsPaginated';
import getProductVariantsById from 'domain/shared/graphql/product-variants/getProductVariantsById';

import { PaginatedResponse } from '../types/paginated-response';
import { ProductVariant } from '../types/product-variants';
import { Vehicle } from '../types/vehicle';
import getEntityInteractions from '../graphql/product-interactions/getEntityInteractions';
import getAttributes from '../graphql/get-attributes';
import { Attributes } from '../types/attributes';
import getVariantQuery, { Variables, Where } from '../graphql/get-variants';
import getProducts from '../graphql/get-products';

type ProductResponse = { channelVariantsFilterByAttributes: { data: ProductVariant[] } };

class InventoryService {
  static async getAllItems(page: number, query: string = ''): Promise<PaginatedResponse<Vehicle>> {
    const searchParams = query ? `,(model:"*${query}*";make:"*${query}*";vin:"*${query}*";year:"*${query}*";stock_number:"*${query}*")` : '';
    const { data } = await axios.get(`/search/vehicles?lists=make,year,condition,status&page=${page}&sort=date_in_stock|asc&limit=50&q=status>10${searchParams}`);

    return {
      data: data.data,
      limit: data.per_page,
      page: data.current_page,
      total_pages: data.total_pages,
    };
  }

  static async getAllProductVariants(
    channel: string,
    leadId: string = '',
    page: number = 1,
    query: string = '',
    filterProducts: string[] = [],
    operator: string = 'NOT_IN',
    attributesFilter: { id: string, value: string } = { id: '', value: '' },
  ): Promise<ProductVariant[]> {
    const graphQuery = getProductVariantsPaginated(leadId !== '', filterProducts, operator, attributesFilter);
    const { data: { channelVariants: { data = [] } } } = await Client.query({
      query: graphQuery,
      fetchPolicy: 'no-cache',
      variables: {
        page,
        query,
        channel,
        ...leadId ? {
          visitor: {
            id: leadId,
            type: 'Kanvas\\Guild\\Leads\\Models\\Leads',
          },
        } : {},
      },
    });

    return data;
  }

  static async getProductVariantById(channel: string, uuid: string) {
    const { data: { channelVariants: { data = [] } } } = await Client.query({
      query: getProductVariantsById,
      variables: { channel, uuid },
    });

    return data[0];
  }

  static async getProductsWithInteractions(leadId: string) {
    const { data: { entityInteractions: { data = [] } } } = await Client.query({
      query: getEntityInteractions,
      fetchPolicy: 'no-cache',
      variables: {
        entityId: leadId,
        type: 'Kanvas\\Guild\\Leads\\Models\\Leads',
      },
    });

    return data;
  }

  static async getAttributes(value: string) {
    const response = await Client.query<{ attributes: { data: Attributes[] } }>({
      query: getAttributes,
      fetchPolicy: 'no-cache',
      variables: { value },
    });

    return response.data.attributes.data || [];
  }

  static async getProducts(variables: Variables, where?: Where): Promise<ProductVariant[]> {
    const query = getVariantQuery(variables, where);
    const response = await Client.query<ProductResponse>({
      query,
      fetchPolicy: 'no-cache',
      variables,
    });
    return response.data.channelVariantsFilterByAttributes.data || [];
  }

  static async getProductsByVin(vin: string) {
    const query = getProducts;
    const response = await Client.query<{ products: { data: { variants: ProductVariant[] }[] } }>({
      query,
      fetchPolicy: 'no-cache',
      variables: { search: vin },
    })
    const variants: ProductVariant[] = [];

    response.data.products.data.forEach(dt => dt.variants.forEach(variant => variants.push(variant)));

    return variants;
  }

  static injectVisitor(leadId?: string) {
    if (!leadId) return {};

    return {
      visitor: {
        id: leadId,
        type: 'Kanvas\\Guild\\Leads\\Models\\Leads',
      },
    };
  }
}

export default InventoryService;
