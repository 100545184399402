import { gql } from '@apollo/client';

const getProductVariantByIdQuery = gql`
  query getProductVariantById($channel: String!, $uuid: Mixed) {
    channelVariants(id: $channel, where: { column: UUID, operator: EQ, value: $uuid }) {
      data {
            id,
            uuid,
            name,
            description,
            slug,
            sku,
            files {
               data {
                  url
               }
            }
            product{
                name
            },
            warehouses{
                quantity
            },
            attributes {
                name,
                value
            },
            channels{
                discounted_price,
                price,
                warehouses_id,
                is_published
            }
        }
    }
  }
`;

export default getProductVariantByIdQuery;
