/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components/native';
import Colors from 'theme/colors';
import { IS_WEB } from '../constants';

export const DropShadow = css`
  shadow-color: ${Colors.BLACK};
  shadow-offset: 0px 0px;
  shadow-opacity: 0.1;
  shadow-radius: 4px;
  elevation: 2;
  ${IS_WEB ? `box-shadow: 0px 3px 9px ${Colors.WEB_SHADOW};` : ''}
`;
