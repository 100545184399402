import * as React from 'react';
import Svg, {
  Defs, ClipPath, Path, G,
} from 'react-native-svg';
import Colors from 'theme/colors';

function NeedsAnalysis(props: any) {
  const { fillColor = Colors.ICON_SECONDARY } = props;
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <Defs>
        <ClipPath id="prefix__a">
          <Path d="M0 0h48v48H0z" />
        </ClipPath>
      </Defs>
      <G clipPath="url(#prefix__a)">
        <Path d="M0 0h48v48H0z" />
        <Path
          data-name="Path 1023"
          d="M11.547 44.248h11.456a12.616 12.616 0 01-1.551-1.724 9.778 9.778 0 01-1.16-2.006h-8.459a3.144 3.144 0 01-2.3-.77 3.016 3.016 0 01-.781-2.245V6.768a3.087 3.087 0 01.77-2.245 3.085 3.085 0 012.31-.792h21.279a3.131 3.131 0 012.277.781 3.005 3.005 0 01.8 2.256v17.805a10.936 10.936 0 012.009 1.173 11.409 11.409 0 011.724 1.562V6.616a6.756 6.756 0 00-1.648-4.956 6.594 6.594 0 00-4.88-1.659H11.547a6.6 6.6 0 00-4.9 1.672 6.762 6.762 0 00-1.648 4.945v31.015a6.756 6.756 0 001.648 4.956 6.625 6.625 0 004.9 1.659zM41.696 48a1.686 1.686 0 001.269-.531 1.878 1.878 0 00.51-1.356 1.719 1.719 0 00-.152-.694 2.044 2.044 0 00-.434-.629l-5.635-5.617-2.755 2.473 5.683 5.661A2.066 2.066 0 0041.7 48zm-10.884-3.752a8.782 8.782 0 004.587-1.236 9.323 9.323 0 003.3-3.308 8.8 8.8 0 001.225-4.566 8.825 8.825 0 00-1.225-4.6 9.351 9.351 0 00-3.3-3.3 9.129 9.129 0 00-9.175 0 9.412 9.412 0 00-3.308 3.308 9.089 9.089 0 000 9.153 9.412 9.412 0 003.308 3.308 8.782 8.782 0 004.588 1.241zm0-3.037a5.922 5.922 0 01-3.069-.813 6.177 6.177 0 01-2.2-2.2 6.066 6.066 0 010-6.106 6.294 6.294 0 012.2-2.2 5.862 5.862 0 013.069-.824 5.783 5.783 0 013.026.824 6.294 6.294 0 012.2 2.2 6.066 6.066 0 010 6.106 6.205 6.205 0 01-2.191 2.2 5.824 5.824 0 01-3.035.813zM14.869 19.153h15.227a1.274 1.274 0 00.922-.369 1.245 1.245 0 00.38-.933 1.2 1.2 0 00-.38-.889 1.274 1.274 0 00-.922-.369H14.869a1.291 1.291 0 00-.954.369 1.212 1.212 0 00-.368.889 1.264 1.264 0 00.369.933 1.291 1.291 0 00.953.369zm0-7.071h15.227a1.274 1.274 0 00.922-.369 1.245 1.245 0 00.38-.933 1.2 1.2 0 00-.38-.889 1.274 1.274 0 00-.922-.369H14.869a1.291 1.291 0 00-.954.369 1.212 1.212 0 00-.369.889 1.264 1.264 0 00.369.933 1.291 1.291 0 00.954.369z"
          fill={fillColor}
        />
      </G>
    </Svg>
  );
}

export default NeedsAnalysis;
