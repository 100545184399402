import axiosDef from 'axios';
import { ACTION_ENGINE, SLACK_API_URL, SLACK_CHANNEL_ID } from 'env';
import axiosInstance from 'domain/shared/services/axios';
import { eLeadsOpportunity } from '../types/opportunity';

const axios = axiosDef.create({
  baseURL: ACTION_ENGINE,
});
type SendSlackMessageParams = {
  text: string;
  image?: string;
  blocks?: any;
};

export interface IEleadsSyncParam {
  company: string,
  opportunity: {
    phones: string[],
    emails: string[],
    birthday: string,
    name: string,
    firstname: string,
    lastname: string,
    uid?: string,
    popupUuid?:string
  }
  personId: string,
  dealId: string,
}

interface EleadsResponse {
  data: eLeadsOpportunity | eLeadsOpportunity[];
  dataIndex: any;
  id: string;
  indices: string;
}

export default class ActionEngineService {
  static async syncVinsolution(customerId: string, companyUUID: string, formvalues: any = {}) {
    const body = {
      company: companyUUID,
      vinleadId: customerId
    };
    const { data } = await axios.post('/webhooks/vinsolutions', body);
    return data;
  }

  static async syncELeads(params: IEleadsSyncParam, nosync:boolean = false) {
    const sync = nosync ? '?nosync=true' : '';

    const { data } = await axios.post<EleadsResponse | EleadsResponse[]>(`/webhooks/eleadsNew${sync}`, params);
    return data;
  }

  static async syncELeadsClosed(params: IEleadsSyncParam) {
    const { data } = await axiosInstance.get<EleadsResponse[]>(`${ACTION_ENGINE}/webhooks/customer/${params.personId}/leads?phone=${params.opportunity.phones || ''}&email=${params.opportunity.emails || ''}`);
    return data;
  }

  static sendSlackMessage = async (params: SendSlackMessageParams) => {
    const {
      text,
      image,
      blocks = [],
    } = params;

    const messageBlocks = [].concat(blocks);

    if (image) {
      messageBlocks.push({
        type: 'image',
        image_url: `data:image/png;base64, ${image}`,
      });
    }

    await axios.post(SLACK_API_URL, {
      text,
      channel: SLACK_CHANNEL_ID,
    }, {
      headers: {
        'Content-Type': ' application/json',
        Accept: '*/*',
      },
    });
  };
}
