import React from 'react';
import { IS_ANDROID, IS_WEB } from 'domain/shared/constants';
import {
  Platform,
  Pressable,
  TouchableOpacity as RNTouchableOpacity,
  TouchableWithoutFeedbackProps,
} from 'react-native';
import {
  BorderlessButton as GHBorderlessButton,
  RectButton as GHRectButton,
  TouchableOpacity as GHTouchableOpacity,
  TouchableWithoutFeedback as GHTouchableWithoutFeedback,
} from 'react-native-gesture-handler';

export const WebTouchableOpacity = ({ onPress, ...props }: any) => (
  <RNTouchableOpacity onClick={onPress} {...props} />
);

const AndroidTouchableWithoutFeedback = ({ children, ...props }: TouchableWithoutFeedbackProps) => (
  <Pressable
    android_ripple={{
      borderless: false,
      foreground: false,
      radius: 0,
    }}
    {...props}
  >
    {children}
  </Pressable>
);

const Borderless = Platform.OS === 'ios' ? GHBorderlessButton : AndroidTouchableWithoutFeedback;
const Rect = Platform.OS === 'ios' ? GHRectButton : Pressable;
const Opacity = Platform.OS === 'ios' ? GHTouchableOpacity : RNTouchableOpacity;

export const TouchableOpacity = IS_WEB ? RNTouchableOpacity : Opacity;
export const BorderlessButton = IS_WEB ? RNTouchableOpacity : Borderless;
export const RectButton = IS_WEB ? RNTouchableOpacity : Rect;
// eslint-disable-next-line max-len
export const TouchableWithoutFeedback = IS_ANDROID ? AndroidTouchableWithoutFeedback : GHTouchableWithoutFeedback;
