import React, { Ref, useCallback } from "react";
import { Flex, Spacer } from "@kanvas/andromeda";
import { ActivityIndicator, FlatList, StyleProp, ViewStyle } from "react-native";

import colors from "theme/colors";

import Lead from "model/data/lead";

import LeadItem from "components/molecules/lead-item";
import LeadHudLoading, { LeadHudLoadingRef } from "components/organisms/lead-hud-loading";

interface Props {
  items: Lead[];
  loading?: boolean;
  limit?: boolean;

  search?: string;
  
  onLeadPress?: (lead: Lead) => void;
  onRefresh?: VoidFunction;
  onPaginate?: VoidFunction;
  onAdd?: VoidFunction;

  loader: Ref<LeadHudLoadingRef>;
}

export default function LeadList(props: Props) {
  const { items, loading, limit, search, onPaginate, onRefresh, onLeadPress, onAdd, loader } = props;

  const searching = !!search?.length;
  const showGlobalLoading = items.length === 0 && loading;

  const FooterComponent = useCallback(() => {
    if (limit) return null;
    if (showGlobalLoading) return null;
    if (!loading) return null;
    return <FooterLoading />;
  }, [loading, showGlobalLoading, limit]);

  const ItemSeparatorComponent = () => <Spacer size={6} />;

  if (showGlobalLoading) {
    return (
      <LeadHudLoading 
        ref={loader}
        search={search || ''}
        handleAdd={onAdd}
        handleRetry={onRefresh}
      />
    );
  }

  return (
    <FlatList 
      data={items}
      refreshing={false}
      onRefresh={onRefresh}
      onEndReachedThreshold={0.3}
      keyboardDismissMode="on-drag"
      onEndReached={onPaginate}
      ListFooterComponent={FooterComponent}
      ItemSeparatorComponent={ItemSeparatorComponent}
      contentContainerStyle={contentContainerStyleDefault}
      renderItem={({ item }) => <LeadItem lead={item} searching={searching} onPress={onLeadPress} />}
    />
  );
}

function FooterLoading() {
  return (
    <Flex>
      <Spacer size={6} />
      <ActivityIndicator color={colors.ORANGE} size="large" />
      <Spacer size={6} />
    </Flex>
  );
}

const contentContainerStyleDefault: StyleProp<ViewStyle> = {
  paddingHorizontal: 12,
  paddingVertical: 10,
};