import Task, { Status } from "model/data/task";
import TaskInterceptor, { ChangeStatusData } from "model/interceptors/task.interceptor";
import { TaskPresenterInterface, TaskViewInterface } from "model/types/task.presenter";

export default class TaskPresenter implements TaskPresenterInterface {
  private readonly interceptor: TaskInterceptor;

  constructor(private readonly view: TaskViewInterface) {
    this.interceptor = new TaskInterceptor(this);
  }

  // Presenter Methods
  fetch(leadId: number, taskListId?: number) {
    this.interceptor.fetch(leadId, taskListId);
  }
  
  connect(leadId: number, taskListId?: number) {
    return this.interceptor.connect(leadId, taskListId)
  }

  changeStatusToNoApplicable(leadId: number, taskId: number, taskListId?: number) {
    this.interceptor.changeStatus(leadId, { id: taskId, status: Status.NO_APPLICABLE }, taskListId);
  }

  // View Methods
  get(items: Task[]) {
    this.view.get(items);
  }

  error() {
    this.view.error();
  }

}