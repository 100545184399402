import { ActivityIndicator, Keyboard, Pressable } from "react-native";
import { Flex } from "@kanvas/andromeda";
import { TextTransform } from "react-native-localized-text";
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import Icon from "react-native-vector-icons/MaterialIcons";
import { useEffectOnce } from "react-use";

import colors from "theme/colors";
import { translate } from "locales";

import useCountdown from "domain/shared/hooks/use-countdown";
import { showError } from "domain/shared/utils/toast";
import GraphUserService from "domain/shared/services/graph-user-service";

import TwoFactorInput from "components/molecules/two-factor-input";
import TwoFactorButton from "components/molecules/two-factor-button";
import ToastMessage from "components/molecules/toast-message";

import VerificationCodeSection, { VerificationCodeSectionRef } from "./verification-code-section";
import { useDispatch } from "react-redux";
import { AppDispatch } from "domain/shared/store";
import { setMe } from "domain/shared/store/slice/auth";

export enum STATUS {
  verify = 'VERIFY',
  time = 'TIME',
  resend = 'RESEND',
  login = 'LOGIN',
  loading = 'LOADING',
}

interface Props {
  id?: number;
  phoneNumber?: string;
  loading?: boolean;
  type?: 'setup' | 'login';
  onLogin?: (code: string, ask: boolean) => void;
}

export type TwoFactorManagerRef = {
  showError: VoidFunction;
}

function useTwoFactorManager(props: Props) {
  const { type = 'setup', phoneNumber, id } = props;
  const settingUp = type === 'setup';

  const [value, setValue] = useState('');
  const [status, setStatus] = useState<STATUS>(settingUp ? STATUS.verify : STATUS.time);
  const [error, setError] = useState(false);
  const ref = useRef<VerificationCodeSectionRef>(null);

  const dispatch = useDispatch<AppDispatch>();

  const { time, start, reset } = useCountdown(59, {
    finish: () => setStatus(STATUS.resend),
  });

  const handlePress = async () => {
    try {
      Keyboard.dismiss();
      setStatus(STATUS.loading);
      ref.current?.clean();

      if (!id) throw new Error("missing id");
      if (value.length > 0) {
        const me = await GraphUserService.update(id, { two_step_phone_number: `1${value}` });
        if (me) dispatch(setMe(me));
      }
      
      await GraphUserService.sendVerificationCode();

      setStatus(STATUS.time);
      reset();
      start();
    } catch (e: any) {
      console.log(JSON.stringify(e));
      setStatus(STATUS.verify);
      showError("An Error ocurred, try again later");
    }
  }

  const description = useMemo(() => {
    let text = settingUp ? 'two-fact.code-sent' : 'two-fact.code-sent-ending';
    const phone = value || phoneNumber?.slice(-4);

    if (status === STATUS.resend) text = 'two-fact.resend-code';

    return translate(text, TextTransform.CAPITAL, { interpolate: { phone, time } });
  }, [value, time, status, settingUp, phoneNumber])

  const title = translate(settingUp ? 'two-fact.add' : 'two-fact.title');

  const Content = useCallback(() => {
    return <TwoFactorButton status={status} time={time} value={value} onPress={handlePress} />;
  }, [value, time, status]);

  useEffectOnce(() => {
    if (!settingUp) {
      GraphUserService.sendVerificationCode();
      start();
    }
  });

  return {
    model: {
      value,
      error,
      title,
      status,
      Content,
      settingUp,
      description,
      ref,
    },
    operations: {
      setValue,
      setError,
    }
  }
}

const TwoFactorManager = forwardRef<TwoFactorManagerRef, Props>(function(props, ref) {
  const { phoneNumber, loading, onLogin } = props;
  const { model, operations } = useTwoFactorManager(props);

  const { Content, error, status, title, description, settingUp, value } = model;
  const { setValue, setError } = operations;

  useImperativeHandle(ref, () => ({
    showError: () => setError(true)
  }));

  return (
    <Flex flex={1} gap={12}>
      <TwoFactorInput 
        value={value}
        label={title}
        onChange={setValue}
        editable={settingUp}
        phoneNumber={phoneNumber?.slice(1)}
      >
        <Content />
      </TwoFactorInput>

      <Flex gap={20}>
        <VerificationCodeSection 
          ref={model.ref}
          label={description}
          loading={loading} 
          status={status}
          onPress={onLogin}
        />

        <ToastMessage 
          show={error}
          label={translate("two-fact.error")}
          onHide={() => setError(false)}
          Icon={<Icon name="feedback" color={colors.WARNING} size={24} />}
        />
      </Flex>
    </Flex>
  );
})

export default TwoFactorManager;