import { Flex } from '@kanvas/andromeda';
import { TouchableOpacity } from 'components/atoms/touchable';
import { IS_WEB } from 'domain/shared/constants';
import styled from 'styled-components/native';
import colors from 'theme/colors';
import React, {
  forwardRef, useCallback, useEffect, useImperativeHandle, useState,
} from 'react';
import { Body3 } from '../text';

export interface PillFilterItem {
  key?: string;
  title: string;
}

interface Props {
  value?: string;
  only?: string;
  items: PillFilterItem[];
  onChange?: (item: PillFilterItem) => void;
}

export interface PillListFilterRef {
  onReset: () => void;
  value: string;
}

interface ButtonProps {
  selected?: boolean;
}

const Pill = styled(TouchableOpacity)<ButtonProps>`
  height: 32px;
  border-radius: 16px;
  padding-vertical: 6px;
  padding-horizontal: 12px;
  cursor: pointer;
  ${({ selected, disabled }) => {
    if (selected) {
      return `
        background-color: ${colors.ORANGE};
        shadow-opacity: 0.20;
        shadow-color: ${colors.BLACK};
        shadow-offset: 0px 3px;
        elevation: 6;
        ${IS_WEB ? `box-shadow: 0px 3px 5px ${colors.SHADOW}` : ''};
      `;
    }

    if (disabled) {
      return `
      background-color: ${colors.DISABLED_BUTTON_BACKGROUND};
      `;
    }

    return `
      background-color: ${colors.BORDER_COLOR};
    `;
  }
}
`;

function usePillListFilter(props: Props, ref: React.ForwardedRef<PillListFilterRef>) {
  const {
    items, onChange, value, only,
  } = props;
  const [item, setItem] = useState<string | undefined>();

  const isSelected = (key?: string) => key === item;
  const textColor = (key?: string) => {
    if (isSelected(key)) return colors.WHITE;
    if (only) return colors.DISABLED_BUTTON_TEXT;
    return colors.PRIMARY_TEXT;
  };

  const handlePress = (current: PillFilterItem) => {
    if (only) return;
    setItem(current.key);
    onChange?.(current);
  };

  useImperativeHandle(ref, () => ({
    onReset: () => handlePress(items[0]),
    value: item,
  }));

  useEffect(() => {
    if (only) {
      setItem(only);
      return;
    }

    if (!value) {
      setItem(items[0]?.key);
      return;
    }

    setItem(value);
  }, [value, only]);

  return {
    model: {
      item,
    },
    operations: {
      isSelected,
      textColor,
      handlePress,
    },
  };
}

const PillListFilter = forwardRef<PillListFilterRef, Props>((props, ref) => {
  const { only } = props;
  const { operations } = usePillListFilter(props, ref);
  const { items = [] } = props;
  const { isSelected, textColor, handlePress } = operations;

  const iterable = useCallback((item: PillFilterItem) => {
    const { key, title } = item;
    return (
      <Pill
        key={key}
        selected={isSelected(key)}
        onPress={() => handlePress(item)}
        disabled={!!only}
        activeOpacity={0.7}
      >
        <Body3 text={title} color={textColor(key)} />
      </Pill>
    );
  }, [handlePress, isSelected, textColor]);

  return (
    <Flex row gap={4} style={{ flexWrap: 'wrap' }}>
      {items.map(iterable)}
    </Flex>
  );
});

export default PillListFilter;
