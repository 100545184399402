import React, { useState } from 'react';
import { Flex } from '@kanvas/andromeda';
import { useRoute } from '@react-navigation/native';
import WebView from 'react-native-webview';
import styled from 'styled-components/native';
import PreviewHeader from 'components/molecules/preview-header';
import colors from 'theme/colors';
import { ActivityIndicator } from 'react-native';

const View = styled(WebView)`
  overflow: hidden;
  flex: 1;
`;

const Container = styled(Flex)`
  background-color: ${colors.WHITE};
  position: relative;
`;

const LoaderContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 110%;
  width: 100%;
  z-index: 9999;
`;

const INJECTED_JAVASCRIPT = `(function() {
  const meta = document.createElement('meta'); 
  meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'); 
  meta.setAttribute('name', 'viewport'); 
  document.getElementsByTagName('head')[0].appendChild(meta);
})();`;

function Loader({ loading }: { loading: boolean }) {
  if (!loading) return null;
  return (
    <LoaderContainer>
      <ActivityIndicator
        size="large"
        animating
        color={colors.ORANGE}
        style={{ marginTop: '-20%' }}
      />
    </LoaderContainer>
  );
}

export default function InAppBrowser() {
  const route = useRoute();
  const [loading, setLoading] = useState(true);
  const { url = '', title = '' } = route.params as any;

  return (
    <Container flex={1}>
      <PreviewHeader actionName={title} iconName="close" />
      <Loader loading={loading} />

      <View
        scalesPageToFit
        allowsProtectedMedia
        allowsFullscreenVideo
        source={{ uri: url }}
        allowsInlineMediaPlayback
        onLoadEnd={() => setLoading(false)}
        showsHorizontalScrollIndicator={false}
        mediaPlaybackRequiresUserAction={false}
        injectedJavaScript={INJECTED_JAVASCRIPT}
      />
    </Container>
  );
}
