import { I18NProvider, getTranslator } from 'react-native-localized-text';
import EN from './en';
import ES from './es';

export class CurrentLanguage {
  static LANG: 'EN' | 'ES' = 'EN';
}

export const TRANSLATIONS = {
  EN,
  ES,
};

export const translate = getTranslator(TRANSLATIONS, CurrentLanguage.LANG);
export const setTranslations = (lang: string) => I18NProvider.setTranslations(TRANSLATIONS, lang);
