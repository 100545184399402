import {
  useEffect, useCallback, useContext,
} from 'react';
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import { ACTIVE_SESSION, CLOSED_SESSIONS, POSIBLEMATCHES } from 'domain/shared/screens';
import { NotificationScreens } from 'domain/notification';
import { showAlert } from 'components/molecules/alerts';
import { translate } from 'locales';
import { useDispatch } from 'react-redux';
import GetToken from 'domain/shared/utils/auth/async-storage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { CXTabBarContext } from 'components/organisms/CXTabBar/cx-tab-bar-context';
import { MainScreen } from 'domain/screens';
import { refreshLeadListEvent } from 'domain/opportunity-list/screens/opp-list';
import { navigateToStack } from './services/navigation-service';
import UserService from './services/user-service';
import ActionEngineService from './services/actionEngine-service';
import { showOverlay } from './utils/cx-overlay';
import wait from './utils/wait';
import CompanyBranchService from './services/company-branch-service';
import useAuth from './hooks/useAuth';
import TabNavigationScreens from './navigation/tab-nav-screens';
import useNavTab from './hooks/useNavTab';
import axios from './services/axios';
import store, { AppDispatch } from './store';
import { setActiveOpportunity } from './store/slice/opportunity';
import OpportunityService from './services/opportunity-service';
import { DetaislsData } from './types/DetaislsData';
import { CreditAppData } from './types/creditapp';
import ACTION_NAME from './types/ActionName';
import CRMExtensionService from './services/crm-extension-service';
import { DEFAULT_COMPANY_INFO_KEY } from './constants';
import useLead from './hooks/useLead';
import { BANNER, OPENING_OPPORTUNITY } from './types/amplitude-event';
import { sendAmplitudeEvent } from './utils/amplitude';
import { CREDIT_APP_BANNER, DRIVER_LICENSE_BANNER, CREDIT_COSIGNER_APP_BANNER } from './types/amplitude-actions';

enum MESSAGE_TYPES {
  VIEW_OPPORTUNITY = 'VIEW_OPPORTUNITY',
  SWITCH_STORE = 'SWITCH_STORE',
  OPEN_NOTIFICATION = 'OPEN_NOTIFICATION',
  CREDIT_APP_WINDOW_READY = 'CREDIT_APP_WINDOW_READY',
  LOAD_DRIVER_LICENSE = 'LOAD_DRIVER_LICENSE',
  TAB_DRIVER_LICENSE_READY = 'TAB_DRIVER_LICENSE_READY',
  COBUYER_WINDOW_READY = 'COBUYER_WINDOW_READY',
  UPDATE_CRM_SCREEN = 'UPDATE_CRM_SCREEN',
  SYNC_OPPORTUNITY = 'SYNC_OPPORTUNITY',
  CLICK_BANNER = 'CLICK_BANNER',
  LIST = 'LIST',
}

enum NotificationType {
  ActionNotification = 'ActionNotification',
  Alert = 'Alert',
}

enum SupportedHost {
  eLeads = 'eleads',
  vinSolutions = 'vin_solution',
}

enum BANNER_SLACK_MESSAGE {
  CREDIT_APP = 'CREDIT_APP',
  GETDOCS = 'GETDOCS',
}

        type SupportedHostType = keyof typeof SupportedHost;
interface EventMessage {
  type: MESSAGE_TYPES;
  args: any;
  host: SupportedHostType
}

        type oppListMatch = {
          Open?: any,
          Closed?: any
        };

const getActiveSession = async (id: string) => {
  try {
    const { data } = await axios.get(`/leads?q=(link_sources.vin_solution.lead_id:${id}%)`);
    return data;
  } catch (e) {
    return undefined;
  }
};

const smartSync = async (id: string, companyUUID: any, formvalues:any = {}) => {
  await ActionEngineService.syncVinsolution(id, companyUUID.default_company_uuid, formvalues);
};

const syncELeads = async (args: any, companyUUID: any) => {
  // const user = await UserService.getCurrentUser();
  const item = await ActionEngineService.syncELeads(
    {
      ...args,
      company: companyUUID.default_company_uuid,
    },
  );
  return item;
};

const syncELeadsClosed = async (args: any, companyUUID: any) => {
  const item = await ActionEngineService.syncELeadsClosed(
    {
      ...args,
      company: companyUUID.default_company_uuid,
    },
  );

  return item;
};

// @ts-ignore
const WINDOW = window;

export default function ChromeExtensionEvents() {
  const {
    setUser,
    setCurrentStore,
  } = useAuth();
  const { setTabBarVisible, setCRMHost } = useContext(CXTabBarContext);
  const { setActive } = useNavTab();
  const { getLeadId, clearLeadId } = useLead();
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigation();
  let oppMatchs: oppListMatch;
  const leadUuid = '';
  const TITLE = 'titleName';

  const switchCurrentUserStore = async (storeId: string) => {
    try {
      const user = await UserService.getCurrentUser();
      const availableStores = await CompanyBranchService.get();
      const me = await UserService.me();

      // code-bryan: this is force code cuz no time to change everything quick enough to full graphql TODO:
      const stores = availableStores.map((value) => {
        const store = me.branches.find((store) => store.uuid === value.uuid);
        if (store) {
          value.company = store.company;
        }

        return value;
      });

      const storeCustomFieldFilter = stores.map((store) => {
        const key = store.company.custom_fields.data.find((k) => k.name === 'VIN_SOLUTION_COMPANY');

        return {
          uuid: store.uuid,
          ...key,
        };
      });

      const selectedStore = storeCustomFieldFilter.find((store) => store.value == storeId);
      const newStore = stores.find((store) => store.uuid === selectedStore?.uuid);

      if (newStore) {
        const data = await GetToken(DEFAULT_COMPANY_INFO_KEY);
        await AsyncStorage.setItem(DEFAULT_COMPANY_INFO_KEY, JSON.stringify({
          ...data, default_company_branch_uuid: newStore.uuid,
        }));

        const updatedUser = await UserService.updateUser(user.id, {
          default_company_branch: newStore?.id,
        });

        setCurrentStore(newStore);
        setUser(updatedUser);
        refreshLeadListEvent();
      }
    } catch (e: any) {
      console.log(e.message, 'Error Switching store');
      showOverlay(false);
    }
  };

  const switchCurrentUserStoreEleads = async (integrationStoreId?: number) => {
    if (!integrationStoreId) return;

    try {
      const user = await UserService.getCurrentUser();
      const stores = await CompanyBranchService.get();
      const newStore = stores.find((item) => (
        // eslint-disable-next-line eqeqeq
        item.custom_fields.integration_store_id == integrationStoreId
      ));
      if (newStore) {
        const data = await GetToken(DEFAULT_COMPANY_INFO_KEY);
        await AsyncStorage.setItem(DEFAULT_COMPANY_INFO_KEY, JSON.stringify({
          ...data, default_company_branch_uuid: newStore.uuid,
        }));

        const updatedUser = await UserService.updateUser(user.id, {
          default_company_branch: newStore?.id,
        });

        await AsyncStorage.setItem(DEFAULT_COMPANY_INFO_KEY, JSON.stringify({
          default_company_uuid: updatedUser.default_company_uuid,
          default_company_branch_uuid: updatedUser.default_company_branch_uuid,
        }));

        setCurrentStore(newStore);
        setUser(updatedUser);
      }
    } catch (e: any) {
      console.log(e.message, 'Error Switching store');
      showOverlay(false);
    }
  };

  const sendMessageToSlackChannel = useCallback(async (action: ACTION_NAME) => {
    const user = await UserService.getCurrentUser();
    const company = await UserService.getCurrentUserCompany();
    const title = await AsyncStorage.getItem(TITLE) || '';
    const message = translate('BannerMessage');
    let banner = '';

    if (action === ACTION_NAME.CREDIT_APP) {
      banner = 'Credit App';
    }
    if (action === ACTION_NAME.GET_DOCS) {
      banner = 'DL';
    }

    const messageFormated = message
      .replace('{rooftop}', company.name)
      .replace('{email}', user.email)
      .replace('{user}', user.display_full_name)
      .replace('{Banner}', banner)
      .replace('{leadName}', title || '');

    await ActionEngineService.sendSlackMessage({ text: messageFormated });
  }, []);

  const getData = async (action: MESSAGE_TYPES, lDID: string, leadUuidFromExtension: string = '') => {
    const NoteParam = leadUuidFromExtension ? '?redo=true' : '';
    if (!leadUuid) {
      // eslint-disable-next-line no-param-reassign
      //   leadUuid = await getLeadId() || leadUuidFromExtension;
      await clearLeadId();
    }
    switch (action) {
      case MESSAGE_TYPES.CREDIT_APP_WINDOW_READY: {
        const creditapp = await OpportunityService.getActionData(
          lDID,
          ACTION_NAME.CREDIT_APP,
          NoteParam,

        );
        if (creditapp) {
          CRMExtensionService.loadCreditApp(creditapp as CreditAppData);
        }

        sendAmplitudeEvent(BANNER, { event: CREDIT_APP_BANNER, data: creditapp });
        break;
      }
      case MESSAGE_TYPES.LOAD_DRIVER_LICENSE: {
        const data = await OpportunityService.getActionData(
          lDID,
          ACTION_NAME.GET_DOCS,
          NoteParam,
        );
        if (data) {
          CRMExtensionService.loadDriverLincense(data as DetaislsData);
        }
        sendAmplitudeEvent(BANNER, { event: DRIVER_LICENSE_BANNER, data });
        break;
      }
      case MESSAGE_TYPES.COBUYER_WINDOW_READY: {
        const data = await OpportunityService.getActionData(
          lDID,
          ACTION_NAME.CO_SIGNER,
          NoteParam,
        );
        if (data) {
          CRMExtensionService.loadCreditApp(data as CreditAppData);
        }
        sendAmplitudeEvent(BANNER, { event: CREDIT_COSIGNER_APP_BANNER, data });
        break;
      }
      default:
      {
        return undefined;
      }
    }
    return null;
  };

  const viewOpportunityFromHostCRM = useCallback(async (host: SupportedHostType, args: any) => {
    // because there is some clients using old version that does not handler host
    const defaultHost = host || 'vinSolutions';
    switch (defaultHost) {
      case 'vinSolutions': {
        try {
          if (args.useSmartSync) {
            const { customerId, formValues = {} } = args;
            const defaultCompanyUUID = await GetToken(DEFAULT_COMPANY_INFO_KEY);
            await smartSync(customerId, defaultCompanyUUID, formValues);
            await wait(1000);
          }
          const items = await getActiveSession(args.customerId);
          const [item] = items.slice(-1);

          oppMatchs = { Open: item, Closed: [] };
          return oppMatchs;
        } catch {
          return undefined;
        }
      }
      case 'eLeads': {
        try {
          showOverlay(true);
          const { companyId } = args;
          await switchCurrentUserStoreEleads(companyId);
          const defaultCompanyUUID = await GetToken(DEFAULT_COMPANY_INFO_KEY);

          const [openOpp, closeOpp] = await Promise.all([
            syncELeads(args, defaultCompanyUUID),
            syncELeadsClosed(args, defaultCompanyUUID),
          ]);

          oppMatchs = {
            ...oppMatchs,
            Open: openOpp || undefined,
            Closed: closeOpp || undefined,
          };

          return oppMatchs;
        } catch {
          const defaultCompanyUUID = await GetToken(DEFAULT_COMPANY_INFO_KEY);
          const closeOpp = await syncELeadsClosed(args, defaultCompanyUUID);

          if (closeOpp) {
            oppMatchs = { ...oppMatchs, Closed: closeOpp };
          }
          return oppMatchs;
        }
      }
      default:
        return undefined;
    }
  }, [switchCurrentUserStoreEleads]);

  const openNotification = useCallback((type: string) => {
    const activeTab = type.includes(NotificationType.ActionNotification) ? 0 : 1;
    setActive(activeTab);
    navigateToStack(
      TabNavigationScreens.NOTIFICATION,
      NotificationScreens.LIST,
      { activeTab },
    );
  }, [setActive]);

  const preventDefault = (host: string) => {
    if (host !== 'vinSolutions') return false;
    const { user } = store.getState().auth;
    if (!user) return true;
    return false;
  }

  const listener = async (event: any) => {

    const { data } = event;
    if (typeof data === 'string' && data.includes('{')) {
      const { type, args, host } = JSON.parse(data) as EventMessage;
      setCRMHost(host);

      if (type && typeof type === 'string') {
        const { lead_uuid = '', lDID } = args || {};
        switch (type) {
          case MESSAGE_TYPES.VIEW_OPPORTUNITY:
          case MESSAGE_TYPES.SYNC_OPPORTUNITY:
            const { activeOpportunity } = store.getState().opportunity;

            const prevent = preventDefault(host);
            if (prevent) break;

            // PREVENTING: the vinsolution page to show overlay
            // if there is any active opp same as the one selected
            if (host === 'vinSolutions' && !!activeOpportunity) {
              const fields = activeOpportunity.custom_fields as any;
              if (fields?.VIN_SOLUTION_LEADS?.toString() === args.customerId.toString()) break;
            }

            const { opportunity = {} } = args;
            const { uid = '' } = opportunity;
            const mutedArgs = { ...args, opportunity: { ...opportunity, popupUuid: uid, uid: '' } };

            showOverlay(true);
            const matchs = await viewOpportunityFromHostCRM(host, mutedArgs);

            // FIXME: refactor all this part of the component
            if (matchs.Open && matchs.Closed.length === 0) {
              const { Open } = matchs;
              if (Array.isArray(Open) && Open.length > 0) {
                showOverlay(false);
                navigateToStack(
                  OpportunityScreens.WALL,
                  POSIBLEMATCHES,
                  { items: Open, args: mutedArgs, action: 'Open' },
                );
                return;
              }
              if (matchs.Open && !Array.isArray(matchs.Open)) {
                const { Open: item } = matchs;
                dispatch(setActiveOpportunity({ ...item.data || item, sync: mutedArgs }));
                navigateToStack(
                  OpportunityScreens.WALL,
                  ACTIVE_SESSION,
                  item,
                );
                AsyncStorage.setItem(TITLE, item?.data?.title || '');
                showOverlay(false);
              } else {
                showOverlay(false);
                showAlert(translate('noOppRecordFound'));
              }
            }

            if (matchs.Open && matchs.Closed.length === 0) {
              const { Open } = matchs;
              if (Array.isArray(Open) && Open.length > 0) {
                const firstMatch = Open[0];
                const { data: { rank } } = firstMatch;
                const total = Math.round(rank * 100);
                showOverlay(false);
                navigateToStack(
                  OpportunityScreens.WALL,
                  POSIBLEMATCHES,
                  { items: Open, args: mutedArgs, action: total > 65 ? 'Open' : 'Closed' },
                );
                return;
              }
            }
            
            if (matchs.Open && !Array.isArray(matchs.Open.data)) {
              const { Open: item } = matchs;
              dispatch(setActiveOpportunity({ ...item.data || item, sync: mutedArgs }));
              navigateToStack(OpportunityScreens.WALL, ACTIVE_SESSION, item);
              //   leadUuid = item.data.uuid;
              AsyncStorage.setItem(TITLE, item?.data?.title || '');
              showOverlay(false);
            }

            if (matchs.Open && matchs.Closed) {
              const { Open, Closed } = matchs;
              if (Array.isArray(Open) && Open.length > 0) {
                showOverlay(false);
                navigateToStack(
                  OpportunityScreens.WALL,
                  POSIBLEMATCHES,
                  {
                    items: Open, itemsClosed: Closed, args: mutedArgs, action: 'Open',
                  },
                );
                return;
              }
            }

            if (matchs.Closed && !matchs.Open) {
              const { Closed } = matchs;
              if (Array.isArray(matchs.Closed) && matchs.Closed.length > 0) {
                showOverlay(false);
                navigateToStack(
                  OpportunityScreens.WALL,
                  POSIBLEMATCHES,
                  { items: Closed, args: mutedArgs, action: 'Closed' },
                );
              }
            }

            if (matchs.Closed.length === 0 && !matchs.Open) {
              showOverlay(false);
              // showAlert(translate('noOppRecordFound'));
              // setTabBarVisible(true);
              navigateToStack(
                OpportunityScreens.WALL,
                POSIBLEMATCHES,
                { items: [], args: mutedArgs, action: 'Closed' },
              );
            }
            sendAmplitudeEvent(OPENING_OPPORTUNITY, { event: mutedArgs });
            break;
          case MESSAGE_TYPES.SWITCH_STORE: {
            const { integrationStoreId = undefined, storeId = undefined } = args;
            try {
              showOverlay(true);

              if (integrationStoreId) await switchCurrentUserStoreEleads(integrationStoreId);
              if (storeId) await switchCurrentUserStore(storeId);
              showOverlay(false);
            } catch {
              showOverlay(false);
            }
            break;
          }
          case MESSAGE_TYPES.OPEN_NOTIFICATION: {
            const { notificationType } = args;
            openNotification(notificationType);
            break;
          }
          case MESSAGE_TYPES.CREDIT_APP_WINDOW_READY: {
            getData(MESSAGE_TYPES.CREDIT_APP_WINDOW_READY, lDID, lead_uuid);
            break;
          }
          case MESSAGE_TYPES.TAB_DRIVER_LICENSE_READY: {
            getData(MESSAGE_TYPES.LOAD_DRIVER_LICENSE, lDID, lead_uuid);
            break;
          }
          case MESSAGE_TYPES.COBUYER_WINDOW_READY: {
            getData(MESSAGE_TYPES.COBUYER_WINDOW_READY, lDID, lead_uuid);
            break;
          }
          case MESSAGE_TYPES.UPDATE_CRM_SCREEN: {
            navigation.navigate(MainScreen.Update as never);
            break;
          }
          case MESSAGE_TYPES.CLICK_BANNER: {
            const { action = '' } = args;
            sendMessageToSlackChannel(action as ACTION_NAME);
            break;
          }
          case MESSAGE_TYPES.LIST: {
            if (host !== 'vinSolutions') return;
            
            const currentUser = store.getState().auth.user;
            if (!currentUser) return;

            const time = setTimeout(() => {
              setTabBarVisible(true);
              navigation.navigate(OpportunityScreens.LIST as never)

              clearTimeout(time);
            }, 1250);
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  };

  useEffect(() => {
    WINDOW.removeEventListener('message', listener);
    WINDOW.addEventListener('message', listener);
  }, []);

  return null;
}
