export const OneSignalEvents = {
  ON_NOTIFICATION_PERMISSIONS_GRANTED: 'ON_NOTIFICATION_PERMISSIONS_GRANTED',
  ON_NOTIFICATIONS_SUBSCRIPTION_CHANGED: 'ON_NOTIFICATIONS_SUBSCRIPTION_CHANGED',
};

const initOneSignal = async () => {
  // NOT IMPLEMENTED, LEAVE THIS ONE TO AVOID CRASH ON WEB VERSION
};

export const prompIOSNotificationsPermission = () => {
  // NOT IMPLEMENTED, LEAVE THIS ONE TO AVOID CRASH ON WEB VERSION
};

export default initOneSignal;
