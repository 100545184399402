// Modules
import React, { } from 'react';
import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import { Spacer } from '@kanvas/andromeda';

// Themes
import Colors from 'theme/colors';

// Organisms
import ButtonList, { ButtonListItem, RenderItemFn } from 'components/organisms/button-list';

// Molecules
import { Body3 } from 'components/molecules/text';

// Atoms
import { RectButton } from 'components/atoms/touchable';

const styles = StyleSheet.create({
  buttonContainer: {
    flex: 1,
    paddingLeft: 16,
    justifyContent: 'center',
    height: 44,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.BUTTON_BORDER,
    shadowOffset: { height: 4, width: 0 },
    shadowColor: Colors.BUTTON_BORDER,
    borderRadius: 12,
    shadowRadius: 5,
  },
  default: {
    backgroundColor: Colors.WHITE,
  },
  selected: {
    borderColor: Colors.TEXT_ORANGE_LINK,
    backgroundColor: Colors.PRIMARY_DEEMPH,
  },
});

interface IPropss {
  onChange?: (items: ButtonListItem) => void;
  value: number
  onItemChange?: (item: ButtonListItem) => void;
}

const typeOfCustumer: ButtonListItem[] = [
  {
    key: '0', value: 0, text: 'New Vehicle', selected: true,
  },
  { key: '1', value: 1, text: 'Used Vehicle' },
];

const VehicleCondition = (props: IPropss) => {
  
  // Props
  const {
    onChange,
    onItemChange,
    value,
  } = props;

  const handleValueChange = (item: ButtonListItem) => {
    onChange && onChange(item);
    onItemChange && onItemChange(item);
  };

  const renderItem: RenderItemFn = (item, onItemPress, enabled) => {
    const textColor = value === item.value ? Colors.TEXT_ORANGE_LINK : Colors.PRIMARY_TEXT;
    return (
      <React.Fragment key={item.key}>
        <RectButton
          onPress={() => onItemPress(item)}
          enabled={enabled}
          style={[styles.buttonContainer, value === item.value ? styles.selected : styles.default]}
        >
          <Body3 text={item.text} color={textColor} />
        </RectButton>
        <Spacer horizontal size={4} />
      </React.Fragment>
    );
  };

  return (
    <ButtonList
      items={typeOfCustumer}
      onItemSelected={handleValueChange}
      renderItem={renderItem}
      defaultValue={0}
      row
    />
  );
};

export default VehicleCondition;
