import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Icon from 'react-native-vector-icons/Ionicons';

import colors from "theme/colors";
import { BUTTON_HIT_SLOP } from "domain/shared/constants";

import { TouchableOpacity } from "../touchable";

interface Props {
  onPress?: VoidFunction;
  style?: StyleProp<ViewStyle>
}

export function SearchButton(props: Props) {
  const { onPress, style } = props;

  return (
    <TouchableOpacity style={style} hitSlop={BUTTON_HIT_SLOP} onPress={onPress}>
      <Icon name="search" size={28} color={colors.SECONDARY_TEXT} />
    </TouchableOpacity>
  );
}