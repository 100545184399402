import React, { useState } from 'react';
import { ErrorMessage } from 'formik';
import TextInput from 'atoms/text-input';
import { Flex, Spacer } from '@kanvas/andromeda';
import SocialDropdown, { SOCIAL_ICONS } from 'molecules/social-dropdown';
import { TouchableOpacity } from 'atoms/touchable';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from 'theme/colors';
import ErrorLabel from 'components/atoms/error-label';

export type ItemType = { url: string, type: SOCIAL_ICONS };

interface IProps {
  item: ItemType;
  onUrlChange?: (url: string) => void;
  onSocialItemChange?: (type: SOCIAL_ICONS | null) => void;
  onRemove?: () => void;
  invalid?: boolean;
  fieldName: string;
}

const SocialMediaItem = ({
  item,
  onUrlChange,
  onSocialItemChange,
  onRemove,
  invalid = false,
  fieldName,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Flex row align="center" style={{ zIndex: isOpen ? 10 : 0 }}>
      <SocialDropdown
        onChange={(v) => onSocialItemChange?.(v)}
        defaultValue={item.type}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        invalid={invalid}
      />
      <Spacer horizontal size={4} />
      <Flex flex={1} gap={8}>
        <TextInput
          onChangeText={onUrlChange}
          value={item.url}
          validate={invalid ? 'invalid' : ''}
        />
        <ErrorMessage
          name={fieldName}
          render={(err) => (
            <ErrorLabel text={err} />
          )}
        />
      </Flex>
      <Spacer horizontal size={4} />
      <TouchableOpacity onPress={onRemove}>
        <Icon name="remove-circle" size={22} color={colors.ERROR} />
      </TouchableOpacity>
    </Flex>
  );
};

export default SocialMediaItem;
