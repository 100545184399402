import { ScannedData } from 'domain/barcode-scanner/types';
import { useReducer } from 'react';
import { SORT_CONTROL } from '../constants';
import { SortType } from '../types';

export interface State {
  value: string;
  sort: SortType;
  opportunity?: ScannedData;
}

interface Action {
  type: string;
  payload: any;
}

enum ActionType {
  CLEAR = 'CLEAR',
  SET_SEARCH = 'SET_SEARCH',
  SET_SORT = 'SET_SORT',
}

const initialState: State = {
  value: '',
  sort: SORT_CONTROL[0],
};

function reducer(state: State, action: Action) {
  const { type, payload } = action;

  switch (type) {
    case ActionType.SET_SEARCH:
      return { ...state, ...payload };
    case ActionType.SET_SORT:
      return { ...state, sort: payload };
    case ActionType.CLEAR:
      return initialState;
    default:
      return state;
  }
}

export default function useListState() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setSearch = (value: string, opportunity?: ScannedData) => {
    dispatch({
      type: ActionType.SET_SEARCH,
      payload: {
        value,
        opportunity,
        page: 1,
        response: undefined,
      },
    });
  };

  const setSort = (value: SortType) => {
    dispatch({ type: ActionType.SET_SORT, payload: value });
  };

  const clear = () => dispatch({ type: ActionType.CLEAR, payload: undefined });

  return {
    model: state as State,
    operations: {
      setSearch,
      setSort,
      clear,
    },
  };
}
