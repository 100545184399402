import { Flex } from '@kanvas/andromeda';
import LeadList from 'components/organisms/lead-list';
import SearchBarEventHandler from 'domain/opportunity-list/components/search-bar-event-handler';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  ListRenderItemInfo,
  NativeSyntheticEvent,
  StyleProp, TextInputKeyPressEventData,
  TouchableOpacity, ViewStyle,
} from 'react-native';
import styled from 'styled-components/native';
import colors from 'theme/colors';
import { Lead } from 'domain/opportunity-list/types';
import SearchLoading from 'components/organisms/search-loading';
import { useOpportunities } from 'domain/opportunity-list/hooks/useOpportunities';
import Icon from 'react-native-vector-icons/Ionicons';
import OpportunityListMatchItem from 'components/molecules/opportunity-list-match-item';
import { showToast } from 'components/molecules/opportunity-list-match';
import { useRoute } from '@react-navigation/native';
import { emitOnSearch } from 'domain/shared/events/cx-tab-bar';
import _ from 'lodash';
import { FONTS } from 'theme/typography';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { translate } from 'locales';
import { CXTabBarContext } from '../CXTabBar/cx-tab-bar-context';

// @ts-ignore
interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  userId?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  flag_create_leads?: number;
}

const Container = styled(Flex)`
  background-color: ${colors.WHITE};
`;

interface RenderItemProps {
  info: ListRenderItemInfo<Lead>;
  args?:any;
}

const InputWrap = styled(Flex)`
  background-color:${colors.SUBTILE};
  border-radius:8px;
  height: 40px;
  margin-top:20px;
  margin-bottom:5px;
`;
const SearchIcon = styled(Icon)`
  margin-left: 15px;
`;

const InputSearch = styled.TextInput`
  font-size:15px;
  font-family:${FONTS.SFProText};
  font-weight: 400;
  line-height: 20px;  
  flex:1;
`;
const CleanIcon = styled(MCIcon)`
  border-width: 5px;
  margin-right:13px;
  border-radius: 12px;
  border-color:${colors.WHITE};
  background-color: ${colors.WHITE};
  overflow:hidden;
`;
const contentContainerStyleDefault: StyleProp<ViewStyle> = {
  paddingHorizontal: 0,
};

const RenderItem = ({ info, args }: RenderItemProps) => {
  const { item } = info;

  return (
    <OpportunityListMatchItem
      opp={item}
      args={args}
      showToast={showToast}
    />
  );
};

function PosibleMatchSearch(props: Props) {
  const { model, operations } = useOpportunities(props);
  const route = useRoute() as any;
  const [text, setText] = useState('');
  const { args } = route.params;

  const onSearch = useCallback((value: string) => {
    emitOnSearch({ value });
  }, []);

  const onChangeText = useCallback((value: string) => {
    setText(value);
  }, [onSearch]);

  const triggerSearch = useCallback((e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key === 'Enter') {
      onSearch(text);
    }
  }, [text]);

  const { setTabBarVisible } = useContext(CXTabBarContext);

  useEffect(() => {
    setTabBarVisible(false);
  }, []);

  return (
    <Container flex={1} justify="center">
      <SearchBarEventHandler onSearch={operations.handleSearchBar} />
      <InputWrap row align="center">
        <SearchIcon
          name="ios-search"
          size={18}
          color={colors.SECONDARY_TEXT}
        />
        <InputSearch
          placeholder={translate('search')}
          keyboardAppearance="light"
          placeholderTextColor={colors.SECONDARY_TEXT}
          keyboardType="default"
          value={text}
          maxLength={128}
          returnKeyType="go"
          autoCorrect={false}
          autoCapitalize="none"
          onChangeText={onChangeText}
          onKeyPress={triggerSearch}
          returnKeyLabel="search"
        />
        {!_.isEmpty(text) && (
        <TouchableOpacity onPress={() => {
          onChangeText('');
          onSearch('');
        }}
        >
          <CleanIcon name="close" size={13} />
        </TouchableOpacity>
        )}
      </InputWrap>
      <SearchLoading
        crm="eLead"
        data={model.data}
        error={model.fail}
        noResults={model.noResults}
        loading={model.isSearchLoading}
        // searchTerm={model.search.value}
        onComplete={operations.handleComplete}
        onAddOpp={operations.handleAddOpportunity}
        onRetry={() => operations.handleSearchBar(model.search)}
        search={model.search.value}
      />
      <Flex flex={1} style={{ display: model.isSearchLoading ? 'none' : 'flex' }}>
        <LeadList
          data={model.data}
          loading={model.loading}
          onItem={operations.handleItem}
          onPaginate={operations.handlePaginate}
          onRefresh={() => operations.handleSearchBar(model.search)}
          renderItem={(info) => RenderItem({ info, args })}
          contentContainerStyle={contentContainerStyleDefault}
        />
      </Flex>
    </Container>
  );
}

export default PosibleMatchSearch;
