import { gql } from '@apollo/client';
import Client from 'domain/shared/graphql/client';
import { Platform } from 'react-native';

import axios from './axios';
import {
  ChangePasswordInput,
  UpdateUserInput,
  GraphAuthentication,
  Authentication,
  User,
} from '../types/user';
import { UserNotificationType } from '../types/user-notifications-settings';
import { ScheduleSetting } from '../types/schedule-setting';
import client from 'domain/shared/graphql/client';

const platform = Platform.OS;

const AUTH_MUTATION = gql`
  mutation login($data: LoginInput!) {
    login(data: $data) {
      id
      token
      refresh_token
      token_expires
      refresh_token_expires
      time
      timezone
    }
  }
`;


export const FORGOT_PASSWORD_MUTATION = gql`
  mutation forgotPassword($data: ForgotPasswordInput!) {
    forgotPassword(data: $data)
  }
`;

const meQuery = gql`
  query getMe {
    me {
      branches {
        id
        uuid
        company {
          custom_fields {
            data {
              name
              value
            }
          }
        }
      }
    }
  }
`;

export interface Branch {
  id: string;
  uuid: string;
  company: {
    custom_fields: {
      data: { name: string; value: string; }[];
    }
  }
}

class UserService {
  static async me(): Promise<{ branches: Branch[] }> {
    const { data } = await client.query<{ me: { branches: Branch[] } }>({
      query: meQuery
    });
    
    return data.me;
  }

  static async updateUser(id: number, body: any) {
    const { data } = await axios.put(`/users/${id}`, body, {
      headers: { 'Content-Type': 'application/json' },
    });
    return data;
  }

  static async getAllUser(text: string) {
    if (text) {
      const { data } = await axios.get(
        `/users?q=(displayname:${text}%)&page=1&limit=100`,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );
      return data;
    }
    const { data } = await axios.get('/users?page=1&limit=100', {
      headers: { 'Content-Type': 'application/json' },
    });
    return data;
  }

  static async getAllUserByIds(text: string) {
    const { data } = await axios.get(
      `/search/users?text=&filters=(id:${text})`,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    return data;
  }

  static async getAllUserManager(branchId: string) {
    const { data } = await axios.get(
      `companies-branches/${branchId}/users/?page=1&limit=100&format=true&q=(is_deleted:0)`,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    return data;
  }

  static async getManagerByLeadId(leadId: string) {
    const { data } = await axios.get(`/leads/${leadId}/access`, {
      headers: { 'Content-Type': 'application/json' },
    });

    if (data.length > 0) {
      const [adminFollow] = await UserService.getFollowerByType(data, 'Admins');

      return adminFollow;
    }
    return '';
  }

  static async getUser(userId: number) {
    const { data } = await axios.get<User>(`/users/${userId}`);
    return data;
  }

  static async updateUserProfilePicture(
    userId: number,
    input: any,
  ) {
    const { data } = await axios.put(`/users/${userId}/profile-image`, input, {
      headers: { 'Content-Type': 'application/json' },
    });
    return data;
  }

  static async updateUserProfile(userId: number, input: UpdateUserInput) {
    const body = { ...input };
    const { data } = await axios.put(`/users/${userId}`, body);
    return data;
  }

  static async updateScheduleNotificationSettings(userId: number, input: ScheduleSetting) {
    const body = { schedule_settings: input };
    const { data } = await axios.put(`/users/${userId}`, body);
    return data;
  }

  static async getUserInfo(userId: number) {
    const { data } = await axios.get(`/users/${userId}/info`);
    return data;
  }

  static async registerUserDevice(userId: number, deviceId: string) {
    const formData = new FormData();
    formData.append('app', `${platform}app`);
    formData.append('deviceId', deviceId);
    const response = await axios.post(`/users/${userId}/devices`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response;
  }

  static async unregisterDevice(userId: number, deviceId: string) {
    const { data } = await axios.delete(`/users/${userId}/devices/${deviceId}`);
    return data;
  }

  static async getFollowerByType(data = [], type = 'Users') {
    const users = data
      .filter((follow: any) => {
        const { users: { roles = [], id = '' } = {} } = follow;
        const role = roles.some((a: any) => a.name === type);
        if (role) {
          return id;
        }
        return '';
      })
      .map((follow: any) => {
        if (follow) {
          const { users: { id = '' } = {} } = follow;
          return id;
        }
        return '';
      });
    return users;
  }

  static async getCurrentUser() {
    const params = {
      relationships: 'companies',
    };
    const { data } = await axios.get('/users/0', { params });
    return data;
  }

  static async getCurrentUserCompany() {
    const currentUser = await UserService.getCurrentUser();
    const { data } = await axios.get(`/companies/${currentUser.default_company}`);
    return data;
  }

  static async cleanNotification() {
    await axios.post('notifications-read-all', {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  static async deleteAccount(userId: number) {
    const { data } = await axios.post(`/users/${userId}/request-delete-account`);
    return data;
  }

  static async changeUserPassword(userId: number, body: ChangePasswordInput) {
    const { data } = await axios.put(`/users/${userId}`, body);
    return data;
  }

  static async getAllNotificationSettings(userId: number) {
    const { data } = await axios.get<UserNotificationType[]>(`/users/${userId}/notifications`);
    return data;
  }

  static async changeUserNotificationTypeSettings(
    userId: number,
    notification_types_id: number,
    value: boolean,
  ) {
    const { data } = await axios.put(`/users/${userId}/notifications/${notification_types_id}`, { is_enabled: Number(value) });
    return data;
  }

  static async authenticateGraph(email: string, password: string, device_id: string) {
    const data = {
      email,
      password,
      device_id,
    };
    try {
      const { data: { login } } = await Client.mutate(
        { mutation: AUTH_MUTATION, variables: { data } },
      );
      return login as GraphAuthentication;
    } catch (error: any) {
      let onError = error;
      onError.hasError = true;

      if (error.message.includes('Invalid email or password.')) {
        onError.error_type = 'InvalidEmailPassword';
      }
      return onError;
    }
  }

  static async forgotPassword(email: string): Promise<void> {
    await Client.mutate({
      mutation: FORGOT_PASSWORD_MUTATION,
      variables: { data: { email } },
    });
  }

  static async authenticate(email: string, password: string) {
    try {
      const body = {
        email,
        password,
      };
      const { data } = await axios.post<Authentication>('/auth', body);
      return data;
    } catch (e: any) {
      console.log("Auth error: ", e);
      
      return undefined;
    }
  }
}
export default UserService;
