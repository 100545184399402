import React from 'react';
import { StyleProp, TextInputProps, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { Flex } from '@kanvas/andromeda';
import Colors from 'theme/colors';
import Icon from 'react-native-vector-icons/MaterialIcons';
import MatIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { translate } from 'locales';
import { IS_MOBILE } from 'domain/shared/constants';
import DocumentScannerButton from 'components/molecules/documment-scanner-button';


interface IProps extends TextInputProps {
  onClear?: () => void;
  iconSize?: number;
  iconColor?: string;
  textColor?: string;
  backgroundColor?: string;
  containerStyle?: StyleProp<ViewStyle>;
  onScanner?: VoidFunction;
}

type ThemeProps = { $backgroundColor?: string, $color?: string };

const Container = styled(Flex)<ThemeProps>`
  height: ${({ style }: any) => (style?.height || 32)}px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ $backgroundColor = Colors.WHITE }) => $backgroundColor};
  /* code-bryan: not using the padding top-bottom because breaks android */
  padding: ${({ style }: any) => (`0px ${style?.paddingHorizontal || 15}px`)};
`;

const Input = styled.TextInput<ThemeProps>`
  height: 100%;
  width: 100%;
  font-size: 14px;
  background-color: ${({ $backgroundColor = Colors.WHITE }) => $backgroundColor};
  color: ${({ $color = Colors.ICON_SECONDARY }) => $color};
  margin-left: 4px;
  flex: 1;
`;

const CleanIcon = styled(MatIcon)`
  overflow: hidden;
  border-width: 5px;
  text-align: center;
  border-radius: 12px;
  vertical-align: middle;
  border-color:${Colors.WHITE};
  background-color: ${Colors.WHITE};
`;

const SearchInput = (props: IProps) => {
  const {
    value = '',
    onClear,
    onScanner,
    iconSize = 15,
    containerStyle,
    backgroundColor = Colors.WHITE,
    iconColor = Colors.ICON_SECONDARY,
    textColor = Colors.ICON_SECONDARY,
  } = props;

  const emptyText = value.length <= 0;
  const showScanner = !!onScanner && IS_MOBILE && value.length <= 0;

  return (
    <Container
      $backgroundColor={backgroundColor}
      row
      align="center"
      style={containerStyle}
    >
      <Icon size={iconSize} name="search" color={iconColor} />
      <Input
        $color={textColor}
        $backgroundColor={backgroundColor}
        placeholder={translate('search')}
        placeholderTextColor={Colors.ICON_SECONDARY}
        {...props}
      />
      {!emptyText && <CleanIcon name="close" size={13} color={Colors.BLACK} onPress={onClear} />}

      {showScanner && <DocumentScannerButton size={20} onPress={onScanner} />}
    </Container>
  );
};
export default SearchInput;
