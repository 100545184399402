import colors from 'theme/colors';
import * as React from 'react';
import Svg, {
  Defs, ClipPath, Path, G,
} from 'react-native-svg';

const AddTrade = (props: any) => {
  const { fillColor = colors.ICON_SECONDARY } = props;
  return (
    <Svg viewBox="0 0 51 60" xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h48v48H0z" />
        </ClipPath>
      </Defs>
      <G clipPath="url(#a)">
        <Path d="M0 0h48v48H0z" />
        <Path
          d="M47.764 30.2a7.838 7.838 0 0 0-.7-2.5 10.891 10.891 0 0 0-1.4-2.3l-1.8-2.2c-.2-1.1-.5-2.2-.8-3.3s-.6-2-.9-2.9-.6-1.5-.8-2a6.837 6.837 0 0 0-2.4-2.9 9.759 9.759 0 0 0-3.693-1.4c-.5-.1-1.3-.1-2.3-.2-1 0-2.2-.1-3.593-.1h-9.36a9.964 9.964 0 0 1-1.8 4.5h12.978c1.1 0 2.2.1 3.194.2a5.444 5.444 0 0 1 1.8.6 8.276 8.276 0 0 1 1.1 1.5 3.587 3.587 0 0 1 .4 1.1 13.1 13.1 0 0 1 .5 1.5 8.344 8.344 0 0 1 .4 1.5 1.954 1.954 0 0 1-.1 1.3 1.474 1.474 0 0 1-1.3.4c-.7 0-1.6-.1-2.695-.1s-2.4-.1-3.992-.1H18.72c-1.6 0-2.894.1-3.992.1s-2 .1-2.695.1a1.323 1.323 0 0 1-1.5-1 1.187 1.187 0 0 1 .1-.8 10.276 10.276 0 0 1 .4-1.4 14.862 14.862 0 0 1-2.1.2 10.859 10.859 0 0 1-2.595-.3c0 .1-.1.3-.1.4-.3 1.1-.6 2.2-.8 3.3l-1.784 2.2a17.617 17.617 0 0 0-1.4 2.3 10.288 10.288 0 0 0-.7 2.5c-.1 1-.2 2.1-.2 3.2v11.7A2.729 2.729 0 0 0 4.049 48h2.3a2.579 2.579 0 0 0 2.595-2.6v-3.8h.7c1.4.1 2.994.2 4.591.2 1.7.1 3.393.1 5.19.1s3.493.1 5.09.1h5.09c1.8 0 3.493-.1 5.19-.1 1.7-.1 3.194-.1 4.591-.2l.8-.1v3.5a2.579 2.579 0 0 0 2.595 2.6h2.5a2.729 2.729 0 0 0 2.695-2.7V33.3c-.012-1-.112-2.1-.212-3.1Zm-34.733 5.3a3.462 3.462 0 1 1-.1-4.9 3.584 3.584 0 0 1 1.1 2.5 3.5 3.5 0 0 1-1 2.4Zm18.564-.6a2.4 2.4 0 0 1-1.8.7H19.319a2.5 2.5 0 0 1 0-5h10.48a2.4 2.4 0 0 1 1.8.7 2.741 2.741 0 0 1-.003 3.6Zm9.482.5a3.461 3.461 0 0 1-2.5 1 3.347 3.347 0 0 1-3.393-3.3V33a3.1 3.1 0 0 1 1-2.4 3.459 3.459 0 0 1 4.891-.1 3.57 3.57 0 0 1 .002 4.9Z"
          fill={fillColor}
        />
        <Path
          d="M8.8 17.7a7.973 7.973 0 0 0 3.4-.7 10.654 10.654 0 0 0 2.8-1.9 8.692 8.692 0 0 0 1.9-2.8 7.973 7.973 0 0 0 .7-3.4 7.973 7.973 0 0 0-.7-3.4A9.235 9.235 0 0 0 15 2.7 8.692 8.692 0 0 0 12.2.8 8.075 8.075 0 0 0 8.8 0a7.973 7.973 0 0 0-3.4.7 7.735 7.735 0 0 0-2.8 1.9A8.692 8.692 0 0 0 .7 5.4 8.29 8.29 0 0 0 0 8.8a7.973 7.973 0 0 0 .7 3.4A10.653 10.653 0 0 0 2.6 15a8.692 8.692 0 0 0 2.8 1.9 8.075 8.075 0 0 0 3.4.8ZM4.6 8.8a1.421 1.421 0 0 1 .2-.6 1.421 1.421 0 0 1 .6-.2H8V5.5a.845.845 0 0 1 .2-.6c.1-.2.4-.2.6-.2.2-.1.4 0 .6.2a.735.735 0 0 1 .2.6v2.6h2.6a1.421 1.421 0 0 1 .6.2.764.764 0 0 1 .2.5 1.421 1.421 0 0 1-.2.6 1.421 1.421 0 0 1-.6.2H9.6v2.6a1.421 1.421 0 0 1-.2.6.735.735 0 0 1-.6.2 1.421 1.421 0 0 1-.6-.2 1.421 1.421 0 0 1-.2-.6V9.6H5.4a.735.735 0 0 1-.6-.2.845.845 0 0 1-.2-.6Z"
          fill={fillColor}
        />
      </G>
    </Svg>
  );
};

export default AddTrade;
