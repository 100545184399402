import { gql } from '@apollo/client';
import Client from 'domain/shared/graphql/client';
import { Channel } from '../types/channels';

const defaultChannelQuery = gql`
query {
  channels {
    data {
      id,
      name,
      uuid
    }
  }
}`;

const getDefaultProductChannel = async (): Promise<Channel> => {
  try {
    const { data: { channels: { data = [] } } } = await Client.query({
      query: defaultChannelQuery,
      fetchPolicy: 'no-cache',

    });
    const [channel] = data;
    return channel;
  } catch (e) {
    return {};
  }
};

export default getDefaultProductChannel;
