import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
  ACTIVE_SESSION,
  CONTENT_PREVIEW,
  BROWSE_BANKS,
  VIEW_INVENTORY,
  VEHICLE_INFO_SA,
  MODAL_COLLAGE,
  EDIT_CONTACT,
  EDIT_SESSION,
  CONTACT_LIST,
  CREATE_CONTACT,
  CONTACT_FOUND_LIST,
  CONTACT_MODAL,
  ENGAGEMENT,
  CLOSED_SESSIONS,
  CREATE_SESSION,
  FORM,
  POSIBLEMATCHES,
  SCANNER,
  ID_SCANNER,
  ADD_COBUYER,
  INSURANCE_FORM,
  PAYOFF_ID_FORM,
  SOLD_CARD_FORM,
  // CAMERA,
} from 'domain/shared/screens';
import ContentPreview from 'domain/opportunity-wall/screens/content-preview';
import OpportunityWall from 'domain/opportunity-wall/screens/wall';
import { CreateOpportunityStack } from 'domain/create-opportunity';
import DocumentForm from './screens/content-preview/forms';
import PosibleMaches from './screens/posible-matches';
import ScannerScreen from 'domain/create-opportunity/screens/scanner';
import IdScannerScreen from 'domain/create-opportunity/screens/id-scanner';
import { presentation } from 'domain/opportunity-list';
import AddCobuyer from './screens/add-cobuyer';

// Verification forms
import InsuranceForm from './screens/verification-forms/insurance-form';
import DocActionList from 'components/organisms/doc-action-list';
import PayoffId from './screens/verification-forms/payoff-id-form';
import SoldCarForm from './screens/verification-forms/sold-car-form';

export const OpportunityScreens = {
  WALL: ACTIVE_SESSION,
  PREVIEW: CONTENT_PREVIEW,
  INVETORY: VIEW_INVENTORY,
  VEHICLE_INFO: VEHICLE_INFO_SA,
  BROWSE_BANKS,
  MODAL_COLLAGE,
  EDIT_CONTACT,
  EDIT_OPPOTUNITY: EDIT_SESSION,
  CONTACT_LIST,
  CREATE_CONTACT,
  CONTACT_FOUND_LIST,
  CONTACT_MODAL,
  ENGAGEMENT,
  CREATE: CREATE_SESSION,
  LIST: CLOSED_SESSIONS,
  FORM,
  POSIBLEMATCHES,
  SCANNER,
  ID_SCANNER,
  INSURANCE_FORM,
  PAYOFF_ID_FORM,

  SELECTABLE_CONTENT_PREVIEW: 'sa.selectable.content.preview',
  SOLD_CARD_FORM,
  ADD_COBUYER,
};

const Stack = createNativeStackNavigator();

const OpportunityWallStack = () => (
  <Stack.Navigator
    initialRouteName={OpportunityScreens.WALL}
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen name={OpportunityScreens.WALL} component={OpportunityWall} />
    {/* TODO: in refactor */}
    <Stack.Screen name={OpportunityScreens.PREVIEW} component={ContentPreview} />

    <Stack.Screen name={OpportunityScreens.SELECTABLE_CONTENT_PREVIEW} component={DocActionList} />

    <Stack.Screen name={OpportunityScreens.CREATE} component={CreateOpportunityStack} />
    <Stack.Screen
      name={OpportunityScreens.FORM}
      component={DocumentForm}
    />
    <Stack.Screen name={OpportunityScreens.POSIBLEMATCHES} component={PosibleMaches} />

    <Stack.Screen
      name={OpportunityScreens.SCANNER}
      component={ScannerScreen}
      options={{ presentation, gestureEnabled: false }}
    />
    <Stack.Screen
      name={OpportunityScreens.ID_SCANNER}
      component={IdScannerScreen}
      options={{ presentation, gestureEnabled: false }}
    />

    <Stack.Screen
      name={OpportunityScreens.INSURANCE_FORM}
      component={InsuranceForm}
      options={{ gestureEnabled: false }}
    />

    <Stack.Screen
      name={OpportunityScreens.PAYOFF_ID_FORM}
      component={PayoffId}
      options={{ gestureEnabled: false }}
    />

    <Stack.Screen
      name={OpportunityScreens.SOLD_CARD_FORM}
      component={SoldCarForm}
      options={{ gestureEnabled: false }}
    />

    <Stack.Screen
      name={OpportunityScreens.ADD_COBUYER}
      component={AddCobuyer}
      options={{ presentation, gestureEnabled: false }}
    />

  </Stack.Navigator>
);

export default OpportunityWallStack;
