import React, { useEffect, useState } from 'react';
import { StyleSheet, LogBox, View } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';

import Twitter from 'assets/svgs/social/twiter';
import Facebook from 'assets/svgs/social/facebook';
import Instagram from 'assets/svgs/social/instagram';
import Linkedin from 'assets/svgs/social/linkedin';
import Youtube from 'assets/svgs/social/youtube';
import colors from 'theme/colors';

const styles = StyleSheet.create({
  main: {
    backgroundColor: colors.WHITE,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.BORDER_INPUT,
    shadowColor: colors.BUTTON_BORDER,
    shadowOpacity: 1,
    shadowOffset: { width: 4, height: 5 },
    shadowRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    position: 'relative',
    zIndex: 0,
    minHeight: 40,
  },
  dropDownContainerStyle: {
    flexDirection: 'row',
    borderWidth: 0,
    position: 'absolute',
    shadowColor: colors.BUTTON_BORDER,
    shadowOpacity: 1,
    shadowOffset: { width: 4, height: 5 },
    shadowRadius: 8,
    borderTopWidth: 1,
    borderTopColor: colors.BORDER_INPUT,
    borderTopStyle: colors.BORDER_INPUT,
  },
  containerStyle: {
    maxWidth: 40,
  },
});

// box-shadow: 0px 4px 5px ${Colors.BUTTON_BORDER};

export enum SOCIAL_ICONS {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
}

interface IProps {
  onChange?: (value: SOCIAL_ICONS | null) => void;
  onOpen?: () => void;
  onClose?: () => void;
  defaultValue?: SOCIAL_ICONS;
  invalid?: boolean;
}

type ItemType = { icon: any, value: SOCIAL_ICONS };

const SocialMedias: ItemType[] = [
  { icon: () => <Facebook />, value: SOCIAL_ICONS.FACEBOOK },
  { icon: () => <Instagram />, value: SOCIAL_ICONS.INSTAGRAM },
  { icon: () => <Linkedin />, value: SOCIAL_ICONS.LINKEDIN },
  { icon: () => <Twitter />, value: SOCIAL_ICONS.TWITTER },
  { icon: () => <Youtube />, value: SOCIAL_ICONS.YOUTUBE },
];

const DropDownIcon = () => <View />;

const SocialDropdown = (props: IProps) => {
  const {
    defaultValue = SOCIAL_ICONS.FACEBOOK,
    onChange,
    onOpen,
    onClose,
    invalid = false,
  } = props;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [items, setItems] = useState(SocialMedias);

  useEffect(() => {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
  }, []);

  return (
    <DropDownPicker
      open={open}
      value={value}
      items={items}
      setOpen={setOpen}
      setValue={setValue}
      setItems={setItems}
      onChangeValue={(v) => onChange?.(v)}
      // this is to fix the z-index of opened dropdown
      containerStyle={[styles.containerStyle, { zIndex: open ? 9999 : 5000 }]}
      style={styles.main}
      ArrowDownIconComponent={DropDownIcon}
      ArrowUpIconComponent={DropDownIcon}
      dropDownContainerStyle={styles.dropDownContainerStyle}
      selectedItemContainerStyle={{ flexDirection: 'row' }}
      listItemContainerStyle={{ padding: 8, minHeight: 40 }}
      iconContainerStyle={{ ...invalid ? { opacity: 0.6 } : {} }}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};

export default SocialDropdown;
