import axios from 'axios';
import { BRUTAL_API } from 'env';
import { AvailableFeatures } from '../types/available-features';

const instance = axios.create({
  baseURL: BRUTAL_API,
});

export interface IntellicheckKeys {
  url: string;
  key: string;
  customer: string;
}

export interface BrutalConfig {
  version: string;
  scanditLicence: string;
  scandit: {
    ios: string;
    android: string;
  };
  intellicheck: IntellicheckKeys;
}

export default class BrutalService {
  static async getConfig(): Promise<BrutalConfig> {
    const { data } = await instance.get<BrutalConfig>('/config');
    return data;
  }

  static async features() {
    const { data } = await instance.get<AvailableFeatures>('/features');
    return data;
  }
}
