import React from 'react';
import { TouchableOpacity } from 'components/atoms/touchable';
import { Svg, Path } from 'react-native-svg';
import colors from 'theme/colors';
import { StyleProp, View, ViewStyle } from 'react-native';

const HIT_SLOP = {
  top: 20, bottom: 20, left: 20, right: 20,
};

interface Props {
  size?: number;
  color?: string;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
}

export default function DocumentScannerButton({
  size = 20, color = colors.ORANGE, style, onPress,
}: Props) {
  return (
    <View style={style}>
      <TouchableOpacity onPress={onPress} hitSlop={HIT_SLOP}>
        <Svg height={size} width={size} viewBox="0 0 48 48">
          <Path fill={color} d="M2 42v-9.1h3V39h6.1v3Zm34.85 0v-3h6.1v-6.1h3V42Zm-29.2-5.55V11.5h4v24.95Zm6.05 0V11.5h2.1v24.95Zm6.1 0V11.5h4.15v24.95Zm6.25 0V11.5h6.05v24.95Zm8.15 0V11.5h2.1v24.95Zm4.15 0V11.5h1.9v24.95ZM2 15.1V6h9.1v3H5v6.1Zm40.95 0V9h-6.1V6h9.1v9.1Z" />
        </Svg>
      </TouchableOpacity>
    </View>
  );
}
