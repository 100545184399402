import colors from 'theme/colors';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = (props: any) => {
  const { fillColor = colors.ICON_SECONDARY } = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      style={{
        enableBackground: 'new 0 0 48 48',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <Path
        d="M0 13.8V7.9C0 5.3.7 3.3 2 2s3.3-2 6-2h5.9c1.6 0 2.5.8 2.5 2.4s-.8 2.4-2.5 2.4H8.3c-1.1 0-2 .3-2.6.9-.6.6-.9 1.5-.9 2.6v5.4c0 .8-.2 1.4-.6 1.8-.4.4-1 .6-1.8.6-1.6.1-2.4-.7-2.4-2.3zM2 46c-1.3-1.3-2-3.3-2-5.9v-5.9c0-1.6.8-2.4 2.4-2.4 1.6 0 2.4.8 2.4 2.4v5.4c0 1.2.3 2 .9 2.6.6.6 1.4.9 2.6.9h5.6c.8 0 1.4.2 1.8.6.4.4.6 1 .6 1.8 0 1.6-.8 2.4-2.5 2.4H8c-2.7.1-4.6-.6-6-1.9zm41.2-32.2V8.3c0-1.2-.3-2-.9-2.6-.6-.6-1.5-.9-2.6-.9h-5.5c-.8 0-1.4-.2-1.8-.6s-.6-1-.6-1.8c0-1.6.8-2.4 2.4-2.4H40c2.6 0 4.6.7 6 2s2 3.3 2 5.9v5.9c0 1.6-.8 2.4-2.4 2.4-1.6 0-2.4-.8-2.4-2.4zM31.7 45.6c0-1.6.8-2.4 2.4-2.4h5.5c1.1 0 2-.3 2.6-.9.6-.6.9-1.5.9-2.6v-5.4c0-.8.2-1.4.6-1.8.4-.4 1-.6 1.8-.6 1.6 0 2.4.8 2.4 2.4v5.9c0 2.6-.7 4.6-2 5.9-1.3 1.3-3.3 2-6 2H34c-1.5-.1-2.3-.9-2.3-2.5zM13.1 38.1c-.8-.8-1.2-1.9-1.2-3.5V13.4c0-1.5.4-2.7 1.2-3.4.8-.8 1.9-1.2 3.4-1.2h6.8c.6 0 1.1 0 1.6.1s.9.2 1.2.4c.4.2.8.5 1.2.9l7.4 7.5c.4.4.7.8.9 1.2.2.4.4.8.4 1.3.1.5.1 1 .1 1.7v12.8c0 1.5-.4 2.7-1.2 3.5-.8.8-1.9 1.2-3.4 1.2h-15c-1.5-.1-2.7-.5-3.4-1.3zm18.2-1.7c.7 0 1.2-.2 1.5-.5.3-.3.5-.8.5-1.5V22.2h-7.5c-1 0-1.8-.2-2.3-.7-.5-.5-.8-1.3-.8-2.3v-7.7h-6c-.7 0-1.2.2-1.5.5-.3.4-.5.8-.5 1.5v20.9c0 .6.2 1.1.5 1.5.3.3.8.5 1.5.5h14.6zm1.6-16.6-7.7-7.9v7c0 .3.1.5.2.6.1.1.3.2.6.2h6.9z"
        fill={fillColor}
      />
    </Svg>
  );
};

export default SvgComponent;
