import { FilterValues } from 'components/organisms/product-list/filter-modal';
import { createContext } from 'react';

export type ActionInventoryTypes = 'view-vehicle' | 'i-packet';

export interface InventoryStackContextProps {
  showProductListSearchBar?: boolean;
  showProductListFilterButton?: boolean;
  showProductListSortButton?: boolean;
  highlightProductOfInterest?: boolean;
  showMultipleSelectButton?: boolean;
  showSelectButton?: boolean;
  action?: ActionInventoryTypes;
  defaultFilter?: FilterValues;
}

export const InventoryStackContext = createContext({
  highlightProductOfInterest: false,
  showProductListSortButton: true,
  showProductListFilterButton: true,
  showProductListSearchBar: false,
  showMultipleSelectButton: false,
  showSelectButton: true,
  action: 'view-vehicle',
  showFilters: false,
  defaultFilter: {},
} as InventoryStackContextProps);
