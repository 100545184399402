import colors from 'theme/colors';
import * as React from 'react';
import Svg, {
  Defs, ClipPath, Path, G,
} from 'react-native-svg';

const ValidateSold = ({ fillColor = colors.ICON_SECONDARY }: any) => (
  <Svg viewBox="0 -15 51 80" width={48} height={48}>
    <Defs>
      <ClipPath id="a">
        <Path d="M0 0h48v48H0z" />
      </ClipPath>
    </Defs>
    <G clipPath="url(#a)">
      <Path d="M0 0h48v48H0z" />
      <Path
        d="M23.2 47.9a3.552 3.552 0 0 1-.9-.4c-3.4-1.9-6.2-3.5-8.6-5a31.212 31.212 0 0 1-5.6-4.3A13.013 13.013 0 0 1 5 33.7a15.983 15.983 0 0 1-1-5.6V10.3A7.308 7.308 0 0 1 4.4 8a4.177 4.177 0 0 1 1.1-1.5 7.486 7.486 0 0 1 2-1.1c.7-.3 1.6-.6 2.9-1.1s2.6-1 4.1-1.5 2.8-1 4.1-1.5 2.2-.8 2.9-1l1.2-.3H24a6.151 6.151 0 0 1 1.3.1c.4.1.9.2 1.3.3.6.2 1.6.5 2.9 1s2.6.9 4.1 1.5c1.5.5 2.8 1 4.1 1.5s2.2.8 2.9 1.1a7.486 7.486 0 0 1 2 1.1 3.881 3.881 0 0 1 1 1.4 7.308 7.308 0 0 1 .4 2.3v17.8a16.237 16.237 0 0 1-.9 5.7 12.089 12.089 0 0 1-3.1 4.6 35.294 35.294 0 0 1-5.6 4.3 80.811 80.811 0 0 1-8.7 4.8 3.552 3.552 0 0 1-.9.4 3.25 3.25 0 0 1-1.6 0Zm-.6-13.5a2.836 2.836 0 0 0 1-.9L34.8 16a5.9 5.9 0 0 0 .4-.8 2.92 2.92 0 0 0 .2-.9 1.683 1.683 0 0 0-.7-1.5 2.452 2.452 0 0 0-1.6-.6 2.228 2.228 0 0 0-2 1.3l-9.9 15.8-4.6-5.8a2.166 2.166 0 0 0-.9-.8 1.978 1.978 0 0 0-1.1-.2 2.13 2.13 0 0 0-1.6.7 2.026 2.026 0 0 0-.6 1.6 2.92 2.92 0 0 0 .2.9 2.177 2.177 0 0 0 .5.8l5.9 7.1a4.36 4.36 0 0 0 1.1.9 2.663 2.663 0 0 0 1.2.3 2.01 2.01 0 0 0 1.3-.4Z"
        fill={fillColor}
      />
    </G>
  </Svg>
);

export default ValidateSold;
