import React, { ReactNode, useEffect } from "react";
import styled from "styled-components/native";
import { Flex } from "@kanvas/andromeda";

import colors from "theme/colors";

import { Body3 } from "../text";
import withTimeout from "domain/shared/hooks/with-timeout";
import { Pressable } from "react-native";

interface Props {
  Icon: ReactNode;
  label: string;
  show?: boolean;
  onHide?: VoidFunction;
}

export default function ToastMessage({ label, Icon, show, onHide }: Props) {
  useEffect(() => {
    if (!show) return;
    withTimeout(() => onHide?.(), 10000)
  }, [show])
  
  if (!show) return null;
  return (
    <Pressable onPress={onHide}>
      <Root row gap={8}>
        {Icon}
        <Body3 text={label} />
      </Root>
    </Pressable>
  );
}

const Root = styled(Flex)`
  padding: 14px 16px;
  border-radius: 12px;
  background-color: ${colors.WHITE};
  border: 1px solid ${colors.BUTTON_BORDER};
  box-shadow: 0px 4px 5px ${colors.BUTTON_BORDER};
`;