import React, { useCallback } from 'react';
import { Meta2 } from 'molecules/text';
import { UserNotificationType } from 'domain/shared/types/user-notifications-settings';
import useAuth from 'domain/shared/hooks/useAuth';
import NotificationSettingItem from 'molecules/notification-settings-item';
import { Flex } from '@kanvas/andromeda';
import UserService from 'domain/shared/services/user-service';

interface IProps {
  notificationType: UserNotificationType;
}

export default function NotificationSettingsSection(props: IProps) {
  const { notificationType } = props;
  const { user } = useAuth();

  const onNotificationItemChanged = useCallback(async (
    item: UserNotificationType,
    value: boolean,
  ) => {
    if (!user) return;
    await UserService.changeUserNotificationTypeSettings(
      user.id,
      item.notifications_types_id,
      value,
    );
  }, [user]);

  const renderItem = useCallback((item: UserNotificationType) => (
    <NotificationSettingItem
      item={item}
      key={item.notifications_types_id}
      onItemChanged={(value) => onNotificationItemChanged(item, value)}
    />
  ), []);

  return (
    <Flex gap={4}>
      <Meta2 text={notificationType.name} />
      <Flex gap={10}>
        {notificationType.children.map(renderItem)}
      </Flex>
    </Flex>
  );
}
