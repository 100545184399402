import React, { useState } from 'react';
import { View } from 'react-native';

import styled from 'styled-components/native';
import colors from 'theme/colors';
import Icon from 'react-native-vector-icons/Ionicons';
import { FONTS } from 'theme/typography';

const ArrowIcon = styled(Icon)`
  font-size: 14px;
`;

const Text = styled.Text`
  font-family: ${FONTS.SFProText};
  color: ${colors.BLACK};
  min-width: 220px;
`;

const Container = styled.View``;

const TouchableTitle = styled.TouchableOpacity<any>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.WHITE};
  padding-vertical: 8px;
  padding-horizontal: 24px;
  border-top-width: 0.5px;
  border-bottom-width: ${(props) => (props.firstItem && !props.isCollapseOpen ? '0px' : '0.5px')};
  border-color: ${colors.ICON_SECONDARY};
  min-height: 45px;
`;

const Collapse = ({ title, children, firstItem }:any) => {
  const [openCollapse, setCollapse] = useState(false);
  return (
    <Container>
      <TouchableTitle
        firstItem={firstItem}
        isCollapseOpen={openCollapse}
        onPress={() => setCollapse(!openCollapse)}
      >
        <View style={{ flexDirection: 'row' }}>
          <Text>{title}</Text>
        </View>
        <View>
          {openCollapse ? (
            <ArrowIcon name="chevron-up-outline" color={colors.BLACK} />
          ) : (
            <ArrowIcon name="chevron-down-outline" color={colors.BLACK} />
          )}
        </View>
      </TouchableTitle>
      {openCollapse && children}
    </Container>
  );
};

export default Collapse;
