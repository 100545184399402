/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import colors from 'theme/colors';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Flex } from '@kanvas/andromeda';
import { Meta2 } from '../text';

export type DropDownItem = { label: string, value: any };

const styles = StyleSheet.create({
  main: {
    backgroundColor: colors.WHITE,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.BORDER_INPUT,
    shadowColor: colors.BUTTON_BORDER,
    shadowOpacity: 1,
    shadowOffset: { width: 4, height: 5 },
    shadowRadius: 8,
    flexDirection: 'row',
    padding: 12,
    position: 'relative',
    zIndex: 0,
  },
  dropDownContainerStyle: {
    flexDirection: 'row',
    borderWidth: 0,
    position: 'absolute',
    shadowColor: colors.BUTTON_BORDER,
    shadowOpacity: 1,
    shadowOffset: { width: 4, height: 5 },
    shadowRadius: 8,
    borderTopWidth: 1,
    borderTopColor: colors.BORDER_INPUT,
    borderTopStyle: colors.BORDER_INPUT,
  },
  containerStyle: {
    width: '100%',
  },
});

interface IProps {
  items: DropDownItem[];
  label: string;
  errors?: string;
  defaultValue?: string;
  disabled?: boolean;
  onItemSelected?: (value: any) => void;
}

const DropDownIcon = () => <Icon size={18} name="arrow-drop-down" color={colors.ICON_SECONDARY} />;

export default function DropDownWithLabel(props: IProps) {
  const {
    items = [],
    onItemSelected,
    label,
    errors = '',
    defaultValue,
    disabled = false,
  } = props;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [stateItems, setItems] = useState(items);

  useEffect(() => {
    if (stateItems.length > 0) return;
    setItems(items);
  }, [stateItems, items]);

  return (
    <Flex gap={4} style={{ zIndex: 1 }}>
      <Meta2 text={label} color={colors.ICON_SECONDARY} />
      <DropDownPicker
        open={open}
        value={value}
        items={stateItems}
        setOpen={setOpen}
        setValue={setValue}
        setItems={setItems}
        containerStyle={[styles.containerStyle, { zIndex: open ? 9999 : 5000 }]}
        style={[styles.main, { ...errors ? { borderColor: colors.WARNING } : {} }, { ...disabled ? { backgroundColor: colors.BUTTON_BORDER } : {} }]}
        ArrowDownIconComponent={DropDownIcon}
        dropDownContainerStyle={styles.dropDownContainerStyle}
        selectedItemContainerStyle={{ flexDirection: 'row' }}
        listItemContainerStyle={{ padding: 8 }}
        onSelectItem={(val) => onItemSelected?.(val)}
        disabled={disabled}
        props={{ activeOpacity: 1 }}
      />
    </Flex>
  );
}
