/* eslint-disable no-useless-escape */
// Modules
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  SafeAreaView,
  TouchableOpacity,
  Clipboard as RNWClipboard,
} from 'react-native';
import Clipboard from '@react-native-community/clipboard';
import styled from 'styled-components/native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextTransform } from 'react-native-localized-text';
import { Flex } from '@kanvas/andromeda';
import { KeyboardAwareScrollView as ScrollView } from 'react-native-keyboard-aware-scroll-view';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { useNavigation } from '@react-navigation/native';
import { Masks } from 'react-native-mask-input';
import MIcon from 'react-native-vector-icons/MaterialIcons';
import { useDispatch } from 'react-redux';

// Molecules
import UserInfo from 'molecules/user-info';
import Header from 'components/molecules/settings-header';
import { InputWithLabel as TextInputWithLabel } from 'components/molecules/input-with-label';

// Atoms
import ButtonWithIcon from 'components/atoms/button-with-icon';

// Locale
import { translate } from 'locales';

// Themes
import colors from 'theme/colors';

// Templates
import Screen from 'components/template/screen';

// Organisms
import SocialMediaLinks from 'components/organisms/social-media-links';

// Shared
import { UpdateUserInput, User } from 'domain/shared/types/user';
import useAuth from 'domain/shared/hooks/useAuth';
import { showSuccess } from 'domain/shared/utils/toast';
import { setCurrentUser } from 'domain/shared/store/slice/auth';
import UserService from 'domain/shared/services/user-service';
import { IS_WEB } from 'domain/shared/constants';

const UserInfoContainer = styled(Flex)`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.BORDER_COLOR};
  padding-vertical: 20px;
  padding-horizontal: 16px;
`;

const ButtonContainer = styled(Flex)`
  margin-bottom: ${getBottomSpace()}px;
  padding-horizontal: 16px;
  padding-vertical: 16px;
  border-top-width: 1px;
  border-top-color: ${colors.BORDER_COLOR};
`;

const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
const customUrlValidator = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

const initialValues: UpdateUserInput = {
  firstname: '',
  lastname: '',
  display_full_name: '',
  phone_number: '',
  email: '',
  intro: '',
  contact_email: '',
};

const validationSchema = Yup.object().shape({
  firstname: Yup.string().trim().required(),
  lastname: Yup.string().trim().required(),
  display_full_name: Yup.string().trim().required(),
  phone_number: Yup.string().trim().matches(phoneRegExp, translate('invalidPhone', TextTransform.CAPITAL)).required(),
  cell_phone_number: Yup.string().trim().matches(phoneRegExp, translate('invalidPhone', TextTransform.CAPITAL)),
  email: Yup.string().trim().email().required(),
  contact_email: Yup.string().trim().email().required(),
  intro: Yup.string().trim(),
  social_links: Yup.array().of(Yup.object().shape({
    type: Yup.string(),
    url: Yup.string().matches(customUrlValidator).required(),
  })),
});

const CopyComponent = (emailToCopy:string) => {
  const setClipboardData = (str: string) => {
    if (IS_WEB) {
      RNWClipboard.setString(str);
    } else {
      Clipboard.setString(str);
    }
    showSuccess(translate('copyMessageEmail', TextTransform.CAPITAL), { visibilityTime: 2000 });
  };
  return (
    <TouchableOpacity onPress={() => setClipboardData(emailToCopy)}>
      <MIcon
        name="content-copy"
        size={14}
        color={colors.ORANGE}
        style={{
          marginLeft: 5,
          marginBottom: 5,
        }}
      />
    </TouchableOpacity>
  );
};

const UserProfile = () => {
  // Hooks
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigation = useNavigation() as any;

  // States
  const [isSubmitting, setSubmitting] = useState(false);
  

  const onSubmit = useCallback(async (values: UpdateUserInput) => {
    const cleanValues = validationSchema.cast(values) as User;
    setSubmitting(true);
    // remove photo key to avoid image being replaced;
    delete cleanValues.photo;
    delete cleanValues.files;
    const updatedUser = await UserService.updateUser(cleanValues.id, cleanValues);
    dispatch(setCurrentUser(updatedUser));
  }, [dispatch]);

  const currentValues = useMemo(() => {
    if (!user) return initialValues;
    return validationSchema.cast({ contact_email: '', ...user });
  }, [user]);

  useEffect(() => {
    if (isSubmitting) {
      setSubmitting(false);
      navigation.openDrawer();
      showSuccess(translate('userUpdateSuccessfully', TextTransform.CAPITAL));
    }
  }, [user]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={currentValues}
      validationSchema={validationSchema}
    >
      {
        ({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isValid,
        }) => (
          <Screen flex={1}>
            <SafeAreaView>
              <Header
                title={translate('profile', TextTransform.CAPITAL)}
                disabled={isSubmitting}
              />
            </SafeAreaView>
            <ScrollView>
              <UserInfoContainer>
                <UserInfo editPhoto />
              </UserInfoContainer>
              <Flex padding={16} gap={20}>
                <TextInputWithLabel
                  label={translate('name', TextTransform.CAPITALIZE)}
                  required
                  value={`${values.firstname} ${values.lastname}`.trim()}
                  editable={false}
                />
                {/* <TextInputWithLabel
                  label={translate('displayName', TextTransform.CAPITALIZE)}
                  required
                  onChangeText={handleChange('display_full_name')}
                  onBlur={handleBlur('display_full_name')}
                  value={values.display_full_name}
                  errors={touched.display_full_name ? errors.display_full_name : ''}
                /> */}
                <TextInputWithLabel
                  required
                  label={translate('mobile', TextTransform.CAPITALIZE)}
                  onChangeText={handleChange('phone_number')}
                  onBlur={handleBlur('phone_number')}
                  value={values.phone_number}
                  errors={touched.phone_number ? errors.phone_number : ''}
                  keyboardType="phone-pad"
                  mask={Masks.USA_PHONE}
                />
                <TextInputWithLabel
                  label={translate('workPhone', TextTransform.CAPITALIZE)}
                  onChangeText={handleChange('cell_phone_number')}
                  onBlur={handleBlur('cell_phone_number')}
                  value={values.cell_phone_number}
                  errors={touched.cell_phone_number ? errors.cell_phone_number : ''}
                  keyboardType="phone-pad"
                  mask={Masks.USA_PHONE}
                />

                <TextInputWithLabel
                  label={translate('accountUsername', TextTransform.CAPITALIZE)}
                  onChangeText={handleChange('displayname')}
                  onBlur={handleBlur('displayname')}
                  value={values?.displayname}
                  errors={touched.displayname ? errors.displayname : ''}
                  editable={false}
                  required
                />

                <TextInputWithLabel
                  label={translate('accountEmail', TextTransform.CAPITALIZE)}
                  required
                  onChangeText={handleChange('email')}
                  onBlur={handleBlur('email')}
                  value={values.email}
                  errors={touched.email ? errors.email : ''}
                  keyboardType="email-address"
                  editable={false}
                  RightComponent={() => CopyComponent(values?.email || '')}
                />
                <TextInputWithLabel
                  label={translate('contactEmail', TextTransform.CAPITALIZE)}
                  required
                  blurOnSubmit
                  returnKeyType="done"
                  onChangeText={handleChange('contact_email')}
                  onBlur={handleBlur('contact_email')}
                  value={values.contact_email}
                  errors={touched.contact_email ? errors.contact_email : ''}
                  keyboardType="email-address"
                />
                <TextInputWithLabel
                  label={translate('intro', TextTransform.CAPITALIZE)}
                  multiline
                  textAlignVertical="top"
                  returnKeyType="done"
                  blurOnSubmit
                  onChangeText={handleChange('intro')}
                  onBlur={handleBlur('intro')}
                  value={values.intro}
                  errors={touched.intro ? errors.intro : ''}
                  tooltip={translate('ProfileMessagetooltip', TextTransform.NONE)}
                />
                <SocialMediaLinks />
              </Flex>
            </ScrollView>

            <ButtonContainer>
              <ButtonWithIcon
                text={translate('save', TextTransform.CAPITAL)}
                enabled={!isSubmitting}
                loading={isSubmitting}
                onPress={() => {
                  if (isValid) {
                    setSubmitting(true);
                    handleSubmit();
                  }
                }}
              />
            </ButtonContainer>

          </Screen>
        )
      }
    </Formik>
  );
};

export default UserProfile;
