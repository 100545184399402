import React, { useState, useCallback, useEffect } from 'react';
import { Flex, Spacer } from '@kanvas/andromeda';
import ItemCard from 'atoms/item-card';
import { Message } from 'domain/shared/types/message';
import moment from 'moment';
import { Meta2, Body3 } from 'molecules/text';
import AttachmentButton from 'atoms/attachment-button';
import EngagementBadgeList from 'molecules/engagement-badge-list';
import EngagementHistoryList from 'molecules/engagement-history-list';
import styled from 'styled-components/native';
import colors from 'theme/colors';
import LinkButton from 'atoms/link-button';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import Spinner from 'components/atoms/spinner';
import MessagesService from 'domain/shared/services/messages-service';
import { TouchableWithoutFeedback } from 'components/atoms/touchable';
import { useSelector } from 'react-redux';

const ITEMS_LIMIT = 3;

const HistoryItemsContainer = styled(Flex)`
  border-top-width: 1px;
  border-top-color: ${colors.DIVIDER};
  padding-vertical: 8px;
  margin-top: 8px;
`;

interface IProps {
  message: Message;
  leadId: string;
  linkPreview: string;
}

const Engagement = (props: IProps) => {
  const { message, leadId, linkPreview } = props;
  const [showHistory, setShowHistory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [history, setHistory] = useState<Message[]>([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const { title = '' } = useSelector((state: any) => state.opportunity.activeOpportunity);
  
  const { engagement_status, verb } = message.message;
  
  const getMoreItems = useCallback(async () => {
    setLoading(true);
    const {
      data,
      total_pages,
    } = await MessagesService.getAllMessagesPaginated(leadId, page, message.id, ITEMS_LIMIT);
    let items: Message[] = [...history];
    if (page === 1) {
      items = [message, ...data];
    } else {
      items = items.concat(data);
    }
    const newPage = (page < total_pages) ? page + 1 : total_pages;
    setHasMoreData(page < total_pages);
    setPage(newPage);
    setHistory(items);
    setLoading(false);
  }, [page, history]);

  useEffect(() => {
    getMoreItems();
  }, []);

  return (
    <ItemCard>
      <Flex row>
        <Flex flex={1}>
          <TouchableWithoutFeedback
            onPress={() => setShowHistory(!showHistory)}
            hitSlop={{ top: 20, bottom: 20 }}
          >
            <>
              <Meta2 text={moment(message.created_at).format('MMM Do, YYYY')} />
              <Body3 text={message.message.text} />
            </>
          </TouchableWithoutFeedback>
        </Flex>
        <Flex>
          <AttachmentButton
            leadId={leadId}
            messageId={message.uuid}
            engagementStatus={engagement_status}
            linkPreview={linkPreview}
            message={message.message}
            related_messages={message.related_messages}
            title={title || ''}
          />
        </Flex>
      </Flex>

      <TouchableWithoutFeedback
        onPress={() => setShowHistory(!showHistory)}
        // hitSlop={{ top: 20, bottom: 20 }}
      >
        <EngagementBadgeList
          message={message}
        />
      </TouchableWithoutFeedback>

      {
        showHistory && (
          <HistoryItemsContainer pointerEvents="box-none">
            <Spacer size={2} />
            <EngagementHistoryList
              items={history.sort((a, b) => a.id - b.id)}
              action={message.message_types.verb}
            />
            <Spacer size={2} />
            {
              (!loading) ? (
                hasMoreData && (
                  <LinkButton
                    text={translate('seeMore', TextTransform.CAPITALIZE)}
                    onPress={getMoreItems}
                  />
                )
              ) : (
                <Flex align="flex-start">
                  <Spinner size="small" />
                </Flex>
              )
            }

          </HistoryItemsContainer>
        )
      }

    </ItemCard>
  );
};

export default React.memo(Engagement);
