import { gql } from "@apollo/client/core";
import client from "domain/shared/graphql/client";
import { LEAD_SYSTEM_MODULE_UUID } from "env";

export interface CustomFieldData {
  data?: any;
  name: string;
  entity_id: any;
}

export interface CreatedCustomField {
  setCustomField: boolean;
}

export interface FetchedCustomField {
  customFields: any;
}

export const SET_CUSTOM_FIELD = gql`
  mutation setCustomField($input: CustomFieldInput!) {
    setCustomField(input: $input)   
  }
`;

export const GET_CUSTOM_FIELD = gql`
  query getCustomField($name: String!, $system_module_uuid: String!, $entity_id: String!) {
    customFields(name: $name, system_module_uuid: $system_module_uuid, entity_id: $entity_id)   
  }
`;

export default class CustomFieldsApi {
  static async set(data: CustomFieldData): Promise<CreatedCustomField> {
    const input = {
      ...data,
      system_module_uuid: LEAD_SYSTEM_MODULE_UUID,
    }

    const response = await client.mutate({
      mutation: SET_CUSTOM_FIELD, 
      variables: { input }
    });

    return response.data;
  }

  static async get(data: Omit<CustomFieldData, 'data'>): Promise<FetchedCustomField> {
    const response = await client.query({
      query: GET_CUSTOM_FIELD, 
      fetchPolicy: 'network-only',
      variables: { ...data, system_module_uuid: LEAD_SYSTEM_MODULE_UUID }
    });

    return response.data;
  }
}