import { Flex } from "@kanvas/andromeda";
import React, { useCallback } from "react";
import colors from "theme/colors";
import MIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Meta2 } from "components/molecules/text";
import useCheckIdVerification from "../hook/use-check-id-verification";
import OutlineRedBadge from "assets/svgs/outline-red-badge";

type Props = {
  coBuyers: any[];
  isManager?: boolean;
};

function Verified({ verified }: { verified?: any }) {
  const { icon = '', color = '' } = verified || {};
  
  if (!verified) return null;
  if (icon === 'shield-off') return <OutlineRedBadge />;

  return <MIcon size={16} name={icon} color={color} />;
}

export default function CobuyerList(props: Props) {
  const { coBuyers, isManager } = props;
  const check = useCheckIdVerification(!!isManager);

  const isVerified = useCallback((participant: any) => {
    const { id_verification: idVerification = null } = participant.custom_fields;
    return check(idVerification);
  }, [isManager]);

  const renderItem = useCallback((participant: any, index: number) => {
    const verified = isVerified(participant);

    return (
      <Flex key={`participant-${index}`} row gap={2} >
        <Meta2 color={colors.WHITE} text={`Co-buyer: ${participant?.name.trim()}`} />
        <Verified verified={verified} />
      </Flex>
    );
  }, [isVerified])

  if (coBuyers.length <= 0) return null;
  return <>{coBuyers.map(renderItem)}</>;
}