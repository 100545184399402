export const CHANGE_PROFILE_PICTURE = 'Change profile photo';
export const VISITING = 'Visiting';
export const SEARCH_OPP = 'Search Opportunity';
export const SORT_OPP = 'Sort Opportunities';
export const START_CHRONO = 'Start Chronometer';
export const EDIT_OPP = 'Edit Opportunity';
export const EDIT_PEOPLE = 'Edit Opportunity';
export const IN_APP_OPEN = 'In-app open';
export const TEXT_SHARE = 'Text share';
export const EMAIL_SHARE = 'Email share';
export const COPY_TEXT = 'Copy text';
export const SEARCH = 'Search';
export const SCAN = 'Scan';
export const SORT = 'Sort';
export const OPEN_LEAD = 'Open Opportunity';
export const OPEN_PROFILE = 'Open Profile';
export const ACTIVITIES_TAB = 'Activities Tab';
export const ACTION_TAB = 'Action Tab';
export const LOGIN = 'Login';
export const UPLOAD_FILES = 'Upload Files';
export const VIEW_FILES = 'View Files';
export const DOWNLOAD_MERGED = 'Download Merged';
export const VIEW_OPP_LIST = 'View Opportunity List';
export const OPP_WALL_CALL = 'Opportunity wall call';
export const OPP_WALL_TEXT = 'Opportunity wall text';
export const OPP_WALL_EMAIL = 'Opportunity wall email';
export const OPP_EDITED = 'Opportunity Edited';
export const OPP_DELETED = 'Opportunity Deleted';
export const OPP_CREATED = 'Opportunity Created';
export const OPEN_NOTIFICATION = 'Open notification';
export const CREDIT_APP_BANNER = 'Click Credit app banner';
export const DRIVER_LICENSE_BANNER = 'Click driver license  banner';
export const COBUYER_BANER = 'Click on credit app banner';
export const SYNC_OPP = 'Sync Open Opp';
export const OPEN_CLOSE_OPP = 'Click closed opp';
export const CREDIT_COSIGNER_APP_BANNER = 'Click on credit app cobyer banner';
