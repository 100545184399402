import { Flex } from '@kanvas/andromeda';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ActivityIndicator, Modal } from 'react-native';
import styled from 'styled-components/native';
import Color from 'theme/colors';
import useWindowSize from 'domain/shared/hooks/useWindowSize';
import { EventRegister } from 'react-native-event-listeners';
import { OverLayEvents } from 'domain/shared/utils/cx-overlay';
import { Meta2 } from 'components/molecules/text';

interface ContainerProps {
  width: number;
  height: number;
  backgroundColor?: string;
}

const Container = styled(Flex) <ContainerProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: ${({ backgroundColor = Color.OVERLAY }) => (backgroundColor)};
`;

const LoadingOverlay = () => {
  const [loading, setLoading] = useState(false);
  const [overlayText, setOverlayText] = useState('');
  const [backgroundColor, setBackgroundColor] = useState(Color.OVERLAY);
  const [width, height] = useWindowSize();
  const showRef = useRef('');
  const hideRef = useRef('');

  const changeOverlayVisibility = useCallback((visible: boolean) => ({ opacity = 1, text = '' }: any) => {
    setLoading(visible);
    setOverlayText(text);
    setBackgroundColor(`rgba(255, 255, 255, ${opacity})`);
  }, []);

  useEffect(() => {
    showRef.current = EventRegister.on(
      OverLayEvents.SHOW,
      changeOverlayVisibility(true),
    ).toString();
    hideRef.current = EventRegister.on(
      OverLayEvents.HIDE,
      changeOverlayVisibility(false),
    ).toString();

    return () => {
      EventRegister.rm(showRef.current);
      EventRegister.rm(hideRef.current);
    };
  }, []);

  return (
    <Modal visible={loading} transparent>
      <Container
        width={width}
        height={height}
        justify="center"
        align="center"
        backgroundColor={backgroundColor}
        flex={1}
      >
        <Flex row gap={10} align="center">
          <ActivityIndicator size="large" color={Color.ORANGE} animating style={{ minHeight: 50 }} />
          {
            overlayText && (<Meta2 text={overlayText} />)
          }
        </Flex>
      </Container>
    </Modal>
  );
};

export default LoadingOverlay;
