import React, { useState } from "react";
import { Flex } from "@kanvas/andromeda";
import styled from "styled-components/native";
import { TextTransform } from "react-native-localized-text";
import { useNavigation } from "@react-navigation/native";

import { translate } from "locales";
import colors from "theme/colors";
import { IS_WEB } from "domain/shared/constants";
import { SearchEmitValues } from "domain/shared/events/cx-tab-bar";
import { OpportunityScreens } from "domain/opportunity-list/screens";
import SearchBarEventHandler from "domain/opportunity-list/components/search-bar-event-handler";

import { Header2 } from "components/molecules/text";
import ViewTitle from "components/molecules/view-title";
import SearchInput from "components/atoms/search-input";
import { SearchButton } from "components/atoms/search-button";
import MobileStatusBar from "components/molecules/mobile-status-bar";
import BackButton from "components/organisms/OpportunitySearch/Component/BackButton";
import { SCANNED_OPPORTUNITY, ScannerParams } from "domain/create-opportunity/hooks/use-scanner-screen";
import { Keyboard } from "react-native";
import useEventReciever from "domain/shared/hooks/use-event-reciever";
import { ScannedData } from "domain/barcode-scanner/types";

const title = translate('opportunities', TextTransform.CAPITAL);
const searchLabel = translate('search', TextTransform.CAPITALIZE);
const placeholder = translate('searchOpp', TextTransform.CAPITAL);

interface Props {
  onSearch?: (text: string) => void;
  onClear?: VoidFunction;
  onScan?: (search: string, lead: ScannedData) => void;
  onBack?: VoidFunction;
}

export default function ListHeader(props: Props) {
  const { onSearch, onClear, onScan, onBack } = props;

  const navigation = useNavigation();

  // @ts-ignore
  const handlePress = () => navigation.navigate(OpportunityScreens.SEARCH);
  const handleSearch = (data: SearchEmitValues) => onSearch?.(data.value);

  if (IS_WEB) return <SearchBarEventHandler onSearch={handleSearch} onClear={onBack} />;

  if (onSearch) return <HeaderSearch onSearch={onSearch} onScan={onScan} onClear={onClear} />;

  return (
    <>
      <MobileStatusBar />
      
      <HeaderRoot>
        <ViewTitle 
          title={title}
          $padding={0}
          statusBar={null} 
          left={<StyledSearchButton onPress={handlePress} />}
        />
      </HeaderRoot>
    </>
  );
}

interface HeaderSearchProps {
  onSearch?: (text: string) =>  void;
  onScan?: (search: string, lead: ScannedData) => void;
  onClear?: VoidFunction;
}

function HeaderSearch(props: HeaderSearchProps) {
  const [text, setText] = useState('');

  const navigation = useNavigation();

  const handleClear = () => {
    setText('');
    props.onClear?.();
  }

  const handleSubmit = () => props.onSearch?.(text);

  const handleScanner = () => {
    Keyboard.dismiss();
    const params: ScannerParams = { type: 'search' };
    // @ts-ignore
    navigation.navigate(OpportunityScreens.SCANNER, params);
  };

  useEventReciever(SCANNED_OPPORTUNITY, (data: ScannerParams) => {
    if (data.type !== 'search') return;
    if (!data.opp) return;
    
    const { firstname, lastname } = data.opp;
    const current = `${firstname} ${lastname}`;
    setText(current);
    props.onScan?.(current, data.opp);
  });

  return (
    <>
      <MobileStatusBar />
      <HeaderRoot>
        <Flex row gap={6} align="center">
          <BackButton clearInput={navigation.goBack} />
          <Header2 text={searchLabel} />
        </Flex>

        <StyledInput 
          value={text}
          onClear={handleClear}
          onChangeText={setText}
          onScanner={handleScanner}
          placeholder={placeholder}
          onSubmitEditing={handleSubmit}
        />
      </HeaderRoot>
    </>
  )
}

const HeaderRoot = styled(Flex)`
  padding: 16px;
  gap: 16px;
`;

const StyledSearchButton = styled(SearchButton)`
  margin-right: 16px;
`;

const StyledInput = styled(SearchInput)
  .attrs((props) => ({
    ...props,
    iconSize: 18,
    enableBarCodeScanner: true,
    backgroundColor: colors.SUBTILE,
    containerStyle: { height: 40, paddingHorizontal: 16 }
  }))`
    font-size: 15px;
  `;