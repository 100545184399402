import React, { useCallback, useEffect } from 'react';
import {
  SafeAreaView, StatusBar, useWindowDimensions,
} from 'react-native';
import styled from 'styled-components/native';
import Colors from 'theme/colors';
import { Flex, Spacer } from '@kanvas/andromeda';
import ButtonList, { RenderItemFn } from 'components/organisms/button-list';
import NotificationListUpdate from 'components/organisms/notification-list-updates';
import NotificationListMessage from 'components/organisms/notification-list-message';
import { IS_WEB } from 'domain/shared/constants';
import { TabView } from 'react-native-tab-view';
import { Body3 } from 'components/molecules/text';
import useNavTab from 'domain/shared/hooks/useNavTab';
import { useRoute } from '@react-navigation/native';
import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';
import { OPEN_NOTIFICATION } from 'domain/shared/types/amplitude-actions';
import ViewTitle from 'components/molecules/view-title';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { TouchableOpacity } from 'components/atoms/touchable';

const SaveAreaContainer = styled(SafeAreaView)`
  background-color:${Colors.ORANGE};
`;

enum SCREENS {
  UPDATES = 'UPDATES',
  ALERTS = 'ALERTS',
}

const Sheet = styled.View`
  flex: 1;
  width: 100%;
`;

const Button = styled(TouchableOpacity) <any>`
  height: 32px;
  border-radius: 16px;
  padding-vertical: 6px;
  padding-horizontal: 12px;
  ${({ selected }) => (selected ? `
    background-color: ${Colors.ORANGE};
    shadow-opacity: 0.20;
    shadow-color: ${Colors.BLACK};
    shadow-offset: 0px 3px;
    elevation: 6;
    ${IS_WEB
      ? `box-shadow: 0px 3px 5px ${Colors.SHADOW}` : ''};`
    : `background-color: ${Colors.BORDER_COLOR};`
  )};
`;

const ButtonListContainer = styled(Flex)`
  margin-top: 10px;
  margin-bottom: 6px;
`;

const BottonList = [
  {
    key: '0', value: 0, text: 'Updates',
  },
  {
    key: '1', value: 1, text: 'Alerts',
  },
];

const Updates = () => {
  const { active } = useNavTab();
  const { params } = useRoute();
  const activeTab = (params || {} as any).activeTab || active;
  const [index, setIndex] = React.useState(activeTab);
  const layout = useWindowDimensions();
  const [routes] = React.useState([
    {
      key: SCREENS.UPDATES, title: 'Updates', text: 'Updates', index: 0,
    },
    {
      key: SCREENS.ALERTS, title: 'Alerts', text: 'Alerts', index: 1,
    },
  ]);

  const renderItem: RenderItemFn = useCallback((item, onItemPress, enabled) => {
    const textColor = item.selected ? Colors.WHITE : Colors.PRIMARY_TEXT;

    const handle = () => {
      onItemPress(item);
    };
    return (
      <React.Fragment key={item.key}>
        <Button
          selected={item.selected}
          enabled={enabled}
          onPress={handle}
        >
          <Body3 text={item.text} color={textColor} />
        </Button>
        <Spacer horizontal size={4} />
      </React.Fragment>
    );
  }, []);

  const renderScene = useCallback((e: any) => {
    const { route } = e;
    switch (route.key) {
      case SCREENS.UPDATES:
        return (
          <Sheet>
            <NotificationListUpdate />
          </Sheet>
        );
      case SCREENS.ALERTS:
        return (
          <Sheet>
            <NotificationListMessage />
          </Sheet>
        );
      default:
        return null;
    }
  }, []);

  const onIndexChange = useCallback((item: number) => {
    setIndex(item);
  }, []);

  useEffect(() => {
    setIndex(activeTab);
    sendAmplitudeEvent(OPEN_NOTIFICATION, { event: OPEN_NOTIFICATION });
  }, [activeTab]);

  return (
    <Flex flex={1} style={{ backgroundColor: Colors.WHITE }}>
      <StatusBar />
      <SaveAreaContainer />
      {!IS_WEB && (
        <ViewTitle
          title={translate('notifications', TextTransform.CAPITAL)}
        />
      )}
      <TabView
        sceneContainerStyle={{ backgroundColor: Colors.WHITE }}
        renderTabBar={({ navigationState }) => (
          <ButtonListContainer marginHorizontal={16}>
            <ButtonList
              items={BottonList}
              onItemSelected={(item: any) => {
                setIndex(item.value);
              }}
              renderItem={renderItem}
              defaultValue={navigationState.index}
              row
            />
          </ButtonListContainer>
        )}
        swipeEnabled={false}
        renderScene={renderScene}
        navigationState={{ index, routes }}
        onIndexChange={(i) => { onIndexChange(i); }}
        initialLayout={{ width: layout.width }}
      />
    </Flex>
  );
};
export default Updates;
