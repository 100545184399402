import React from 'react';
import ToastMessage from 'components/molecules/Toast';
import RNToast, { ToastPosition } from 'react-native-toast-message';

interface IProps {
  position?: ToastPosition
}

const toastConfig = {
  success: ({ text1 }: any) => (
    <ToastMessage type="SUCCESS" message={text1} />
  ),
  error: ({ text1 }: any) => (
    <ToastMessage type="ERROR" message={text1} />
  ),
  warning: ({ text1 }: any) => (
    <ToastMessage type="WARNING" message={text1} />
  ),

};

const Toast = ({ position = 'bottom' }: IProps) => (
  <RNToast config={toastConfig} position={position} />
);

export default Toast;
