import { useSelector } from 'react-redux';
import { RootState } from '../store';

export const useAppSettings = () => {
  const settings = useSelector((state: RootState) => state.settings.appSettings);
  return settings;
};

export const useCompanySettings = () => {
  const companySettings = useSelector((state: RootState) => state.settings.companySettings);
  return companySettings;
};
