// Molecules
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Flex } from '@kanvas/andromeda';
import styled from 'styled-components/native';
import { TextTransform } from 'react-native-localized-text';
import { Masks } from 'react-native-mask-input';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RouteProp } from '@react-navigation/native';

// Themes
import colors from 'theme/colors';
import { translate } from 'locales';
import useSubmitForm from 'model/hooks/use-submit-form';
import { LeadAction } from 'domain/shared/types/lead-action';

// Molecules
import PreviewHeader from 'components/molecules/preview-header';
import { InputWithLabel as TextInputWithLabel } from 'components/molecules/input-with-label';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import DateTimeInput from 'components/atoms/datetime-picker';
import { useSelector } from 'react-redux';
import { RootState } from 'domain/shared/store';

type ParamList = {
  Any: { action: LeadAction, onGoBack: VoidFunction }
}

interface Props {
  route: RouteProp<ParamList, 'Any'>;
  navigation: NativeStackNavigationProp<ParamList, 'Any'>;
}


type Form = {
  companyName: string;
  address: string;
  phone: string;
  nameInsured: string;
  policy: string;
  salesPerson: string;
  dateCalled: string;
  timeCalled: string;
  personContacted: string;
};

const Container = styled.SafeAreaView`
  flex: 1;
  width: 100%;
  background-color: ${colors.WHITE};
`;

const Content = styled(KeyboardAwareScrollView)`
  padding: 20px;
`;

const ButtonContainer = styled(Flex)`
  margin-bottom: ${getBottomSpace()}px;
  padding-vertical: 16px;
  border-top-width: 1px;
  border-top-color: ${colors.BORDER_COLOR};
`;

const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

const initialValues = {
  companyName: '',
  address: '',
  phone: '',
  nameInsured: '',
  policy: '',
  salesPerson: '',
  dateCalled: '',
  timeCalled: '',
  personContacted: '',
  expirationDate: '',
};

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required(),
  address: Yup.string().required(),
  phone: Yup.string().trim().matches(phoneRegExp, translate('invalidPhone', TextTransform.CAPITAL)).required(),
  nameInsured: Yup.string().required(),
  policy: Yup.string().required(),
  expirationDate: Yup.string().required(),
  salesPerson: Yup.string().required(),
  dateCalled: Yup.string().required(),
  timeCalled: Yup.string().required(),
  personContacted: Yup.string().required(),
});

const InsuranceForm = (props: Props) => {
  const { navigation, route } = props;
  const [loading, setLoading] = useState(false);

  const { action, onGoBack } = route.params;
  const { submit } = useSubmitForm(action);
  const user = useSelector((state: RootState) => state.auth.user)!;
  const activeOpportunity = useSelector((state: RootState) => state.opportunity.activeOpportunity)!;

  const nameInsured = activeOpportunity.people.name;
  const salesPerson = [user.firstname, user.middlename, user.lastname].join(' ');

  const onSubmit = async (values: Form) => {
    try {
      setLoading(true);
      const submitted = await submit(values);
      if (!submitted) return; 
      onGoBack?.();
      navigation.goBack();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>

      <PreviewHeader
        actionName={translate('insuranceVerification', TextTransform.CAPITAL)}
        onBack={() => navigation.goBack()}
      />

      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={{ ...initialValues, salesPerson, nameInsured }}
      >
        {
          ({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            isValid,
          }) => (

            <Content
              showsVerticalScrollIndicator={false}
              extraScrollHeight={100}
            >

              <TextInputWithLabel
                label={translate('companyName', TextTransform.CAPITALIZE)}
                placeholder={translate('companyName', TextTransform.CAPITALIZE)}
                onChangeText={handleChange('companyName')}
                onBlur={handleBlur('companyName')}
                value={values?.companyName}
                errors={touched.companyName ? errors.companyName : ''}
                required
              />
              <TextInputWithLabel
                label={translate('address', TextTransform.CAPITALIZE)}
                placeholder={translate('address', TextTransform.CAPITALIZE)}
                onChangeText={handleChange('address')}
                onBlur={handleBlur('address')}
                value={values?.address}
                errors={touched.address ? errors.address : ''}
                required
              />

              <TextInputWithLabel
                label={translate('phone', TextTransform.CAPITALIZE)}
                placeholder={translate('phone', TextTransform.CAPITALIZE)}
                onChangeText={handleChange('phone')}
                onBlur={handleBlur('phone')}
                value={values.phone}
                errors={touched.phone ? errors.phone : ''}
                keyboardType="phone-pad"
                mask={Masks.USA_PHONE}
                required
              />

              <TextInputWithLabel
                label={translate('nameInsured', TextTransform.CAPITALIZE)}
                placeholder={translate('nameInsured', TextTransform.CAPITALIZE)}
                onChangeText={handleChange('nameInsured')}
                onBlur={handleBlur('nameInsured')}
                value={values?.nameInsured}
                errors={touched.nameInsured ? errors.nameInsured : ''}
                required
              />

              <TextInputWithLabel
                label={translate('policy', TextTransform.CAPITALIZE)}
                placeholder={translate('policy', TextTransform.CAPITALIZE)}
                onChangeText={handleChange('policy')}
                onBlur={handleBlur('policy')}
                value={values?.policy}
                errors={touched.policy ? errors.policy : ''}
                required
              />

              <DateTimeInput
                label={translate('expirationDate', TextTransform.CAPITALIZE)}
                placeholder={'MM-DD-YY'}
                showIcon={false}
                onChange={handleChange('expirationDate')}
                value={values?.expirationDate}
                errors={touched.expirationDate ? !!errors.expirationDate : false}
                required
              />

              <TextInputWithLabel
                label={translate('salesPersonCalling', TextTransform.CAPITALIZE)}
                placeholder={translate('salesPersonCalling', TextTransform.CAPITALIZE)}
                onChangeText={handleChange('salesPerson')}
                onBlur={handleBlur('salesPerson')}
                value={values?.salesPerson}
                errors={touched.salesPerson ? errors.salesPerson : ''}
                required
              />

              <DateTimeInput
                label={translate('dateCalled', TextTransform.CAPITALIZE)}
                placeholder={'MM-DD-YY'}
                showIcon={false}
                onChange={handleChange('dateCalled')}
                value={values?.dateCalled}
                errors={touched.dateCalled ? !!errors.dateCalled : false}
                required
              />

              <DateTimeInput
                label={translate('timeCalled', TextTransform.CAPITALIZE)}
                placeholder={'HH:MM'}
                mode="time"
                is24hourSource="locale"
                showIcon={false}
                onChange={handleChange('timeCalled')}
                value={values?.timeCalled}
                errors={touched.timeCalled ? !!errors.timeCalled : false}
                required
              />

              <TextInputWithLabel
                label={translate('personContacted', TextTransform.CAPITALIZE)}
                placeholder={translate('personContacted', TextTransform.CAPITALIZE)}
                onChangeText={handleChange('personContacted')}
                onBlur={handleBlur('personContacted')}
                value={values?.personContacted}
                errors={touched.personContacted ? errors.personContacted : ''}
                required
              />

              <ButtonContainer>
                <ButtonWithIcon
                  loading={loading}
                  text={translate('submit', TextTransform.CAPITAL)}
                  onPress={() => handleSubmit()}
                />
              </ButtonContainer>

            </Content>
          )}
      </Formik>

    </Container>
  );
};

export default React.memo(InsuranceForm);