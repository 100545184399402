import { useNavigation } from '@react-navigation/native';
import { CRM_TYPES, CXTabBarContext } from 'components/organisms/CXTabBar/cx-tab-bar-context';
import { CLOSED_SESSIONS } from 'domain/shared/screens';
import React, { useContext, useEffect } from 'react';
import { ActivityIndicator } from 'react-native';
import styled from 'styled-components/native';
import colors from 'theme/colors';

const Container = styled.View`
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${colors.WHITE};
`;

export default function ChromeExtension() {
  const { setTabBarVisible, CRMhost } = useContext(CXTabBarContext);
  const navigation = useNavigation();

  useEffect(() => {
    // if (CRMhost === CRM_TYPES.vin) {
    //   navigation.navigate(CLOSED_SESSIONS as never);
    //   return () => {};
    // }

    setTabBarVisible(false);
    return () => {
      setTabBarVisible(true);
    };
  }, []);

  return (
    <Container>
      <ActivityIndicator
        size="large"
        color={colors.ORANGE}
        animating
        style={{ minHeight: 50 }}
      />
    </Container>
  );
}
