import React, { useCallback } from 'react';
import EngagementHistoryItem from 'components/atoms/engagement-history-item';
import { Message } from 'domain/shared/types/message';
import { Flex } from '@kanvas/andromeda';
import { StageType } from 'domain/shared/types/engagement-types';
import FeedBack from '../feedback-engagement-history';

interface IProps {
  items: Message[];
  action: string;
}
const VERB = 'view-vehicle';

const cleanViewVehicleMessages = (items: Message[]) => {
  const cleanItems = items.filter((i) => (i.message.status !== 'submitted'));
  const [shareProductLastMessage] = (
    items
      .filter((i) => i.message.status === 'submitted')
      .sort((a, b) => b.id - a.id)
  );
  cleanItems.push(shareProductLastMessage);
  return cleanItems;
};


const EngagementHistoryList = (props: IProps) => {
  const { items, action } = props;

  const renderItem = useCallback((message: Message) => {
    const {
      message: {
        data: { products = {} } = {},
        status,
        verb,
      } = {},
      created_at,
    } = message;

    const productsFeedback = (status === StageType.submitted && verb === 'view-vehicle');

    const feedbacks = productsFeedback ? products : [];
    return (
      <React.Fragment key={message.id}>
        {feedbacks.length > 0
          ? <FeedBack feedbacks={feedbacks || {}} created_at={created_at} />
          : <EngagementHistoryItem message={message} />}
      </React.Fragment>
    );
  }, []);

  const renderMessages = useCallback(() => {
    const messages = action === VERB ? cleanViewVehicleMessages(items) : items;
    return messages.filter(item=>item!== undefined).map(renderItem);
  }, [items, action]);

  return (
    <Flex>
      {renderMessages()}
    </Flex>
  );
};
export default EngagementHistoryList;
