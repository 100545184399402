/* eslint-disable react/no-unused-prop-types */
// Modules
import React, { useCallback, useState } from 'react';
import { Flex } from '@kanvas/andromeda';
import styled from 'styled-components/native';
import { TextTransform } from 'react-native-localized-text';
import { KeyboardTypeOptions } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

// Molecules
import { Meta2 } from 'molecules/text';
import PressableTooltip from '../Pressable';

// Theme
import Colors from 'theme/colors';
import { translate as translateBase } from 'locales';

// Atoms
import Input, {
  MaskedText,
  InputProps,
  DatePricker,
  CurrencyInput,
  MaskedInputText,
} from 'components/atoms/text-input';
import DropDown from 'components/atoms/dropdown-input';
import { TouchableOpacity } from 'atoms/touchable';

const TextLabel = styled(Meta2) <IPropsLabel>`
    margin-bottom:4px;
    color:${(props) => (props.validate ? Colors.WARNING : Colors.SECONDARY_TEXT)};
`;

const InputContainer = styled(Flex)`
    background-color:${Colors.WHITE};
    margin-vertical: 5px;
`;

const ShowPasswordContainer = styled(Flex)``;

interface IPropsLabel {
  text: string;
  required?: boolean;
  validate?: string;
  tooltip?: string;
  RightComponent?: () => JSX.Element;
}

export const FormLabel = (props: IPropsLabel) => {

  const {
    required,
    validate,
    tooltip,
    RightComponent = null,
  } = props;

  return (
    <Flex row align="center">
      <TextLabel {...props} />
      {required && <TextLabel text="*" validate={validate} />}
      {tooltip && (
        <Flex marginHorizontal={3}>
          <PressableTooltip
            message={tooltip}
          >
            <Icon
              name="information-outline"
              color={Colors.SECONDARY_TEXT}
              size={15}
              style={{
                marginLeft: 5,
                marginBottom: 5,
              }}
            />
          </PressableTooltip>
        </Flex>
      )}

      {
        RightComponent && (
          <RightComponent />
        )
      }
    </Flex>
  );
};

interface IProps {
  value?: string,
  validate?: string,
  handleChange: (propertyName: string) => void,
  label?: string,
  labelText?: string,
  required?: boolean,
  type: 'input' | 'drop-down' | 'input-mask' | 'date' | 'currency' | 'textarea',
  mask?: string,
  onBlur?: (e?: any) => void,
  keyboardType?: KeyboardTypeOptions,
  error?: string;
  errorsRefs?: any
  InputRefs?: any
  property?: string
  multiline?: boolean | undefined
  numberOfLines?: number | undefined
  showErrorMsg?: boolean
}

export const TextInputWithLabel: React.FC<IProps> = (props: IProps) => {
  const {
    validate,
    value = '',
    handleChange,
    label,
    labelText,
    type = 'input',
    mask = '',
    required, onBlur = () => { },
    keyboardType = 'default',
    error = '',
    multiline = false,
    numberOfLines = 1,
    showErrorMsg = true,
  } = props;

  const translate = useCallback((l: string = '') => translateBase(l, TextTransform.CAPITALIZE), []);
  const inputLabel = labelText || (label ? translate(label) : '');

  const currencyHandler = (value: string) => {
    if (value !== null) {
      handleChange?.(value?.toString());
      return;
    }
    handleChange?.('');
  };

  return (
    <InputContainer>
      <FormLabel required={required} validate={validate || error} text={inputLabel} />
      {type === 'input' && (
        <Input
          validate={validate}
          onBlur={onBlur}
          value={value}
          onChangeText={handleChange}
          keyboardType={keyboardType}
          returnKeyType="done"
          multiline={multiline}
          numberOfLines={numberOfLines}
        />
      )}
      {type === 'date' && (
        <DatePricker
          onChange={handleChange}
          value={value}
          mask={mask}
        />
      )}
      {type === 'currency' && (
        <CurrencyInput
          value={parseFloat(value) || 0}
          onChangeValue={currencyHandler}
          placeHolder={inputLabel}
          prefix="$"
          delimiter=","
          separator="."
          precision={2}
          validate={validate || error}
        />
      )}
      {type === 'input-mask' && (
        <MaskedText
          validate={validate}
          value={value}
          onBlur={onBlur}
          onChangeText={handleChange}
          keyboardType="numeric"
          returnKeyType="done"
          type="custom"
          options={{
            mask, // this is a your define formatting you use according to your requirment
          }}
          maxLength={14}
        />
      )}
      {(error.length > 0 && showErrorMsg) && (
        <Meta2 text={translateBase(error, TextTransform.NONE)} color={Colors.ERROR} />
      )}
    </InputContainer>
  );
};

interface IpropsDropDown {
  value: string,
  validate?: string,
  handleChange: (item: any) => void,
  label: string,
  required?: boolean,
  items: any,
  zIndex: number,
  disabled: boolean
}

export const DropDownWithLabel: React.FC<IpropsDropDown> = (props: IpropsDropDown) => {
  const {
    validate, value, handleChange, label, items, required, zIndex, disabled,
  } = props;

  const translate = useCallback((l: string = '') => translateBase(l, TextTransform.CAPITALIZE), []);

  return (
    <InputContainer style={{ zIndex }}>
      <FormLabel required={required} validate={validate} text={translate(label)} />
      <DropDown
        disabled={disabled}
        placeHolder={label}
        value={value}
        items={items}
        onSelectItem={handleChange}
      />
    </InputContainer>
  );
};

interface DropdownWithLabelProps extends InputProps {
  required?: boolean;
  errors?: string;
  label?: string;
  tooltip?: string;
  mask?: any;
  showError?: boolean;
  RightComponent?: () => JSX.Element;
}

export const InputWithLabel = (props: DropdownWithLabelProps) => {
  const {
    errors,
    required,
    label,
    secureTextEntry = false,
    tooltip,
    mask,
    showError = false,
    RightComponent,
  } = props;

  const [hidePassword, setHidePassword] = useState(secureTextEntry);

  return (
    <InputContainer>
      {
        label && (
          <FormLabel
            required={required}
            validate={errors}
            text={label}
            tooltip={tooltip}
            RightComponent={RightComponent}
          />
        )
      }

      {
        !mask ? (
          <Input
            {...props}
            validate={errors}
            secureTextEntry={hidePassword}
          />
        ) : (
          <MaskedInputText
            type={mask}
            validate={errors}
            {...props}
          />
        )
      }

      {
        secureTextEntry && (
          <ShowPasswordContainer style={{ position: 'absolute', right: 10, top: 24 }}>
            <TouchableOpacity onPress={() => setHidePassword(!hidePassword)}>
              <Icon name={hidePassword ? 'eye-off' : 'eye'} size={24} color={Colors.ICON_SECONDARY} />
            </TouchableOpacity>
          </ShowPasswordContainer>
        )
      }

      {showError && errors && errors.length > 0 && (
        <Meta2 text={errors} color={Colors.ERROR} />
      )}
    </InputContainer>
  );
};
