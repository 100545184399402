import { BUTTON_HIT_SLOP } from 'domain/shared/constants';
import React from 'react';
import { TouchableOpacity, Platform, TouchableOpacityProps } from 'react-native';
import { BorderlessButton, BorderlessButtonProperties } from 'react-native-gesture-handler';

type ButtonProps = (TouchableOpacityProps & BorderlessButtonProperties);

interface IProps extends ButtonProps {
  icon: React.ReactElement;
}

const Button = Platform.OS === 'android' ? TouchableOpacity : BorderlessButton;

const IconButton = (props: IProps) => {
  const { icon, onPress, style } = props;
  return (
    <Button
      onPress={onPress}
      // hitSlop={BUTTON_HIT_SLOP} // This breakes the icon hit point
      style={[{ cursor: 'pointer' }, style]}
    >
      {icon}
    </Button>
  );
};

export default IconButton;
