import { SearchEmitValues } from 'domain/shared/events/cx-tab-bar';
import { SEARCH_OPP, SORT_OPP } from 'domain/shared/types/amplitude-actions';
import { OPENING_OPPORTUNITY, OPP_LIST_VIEW } from 'domain/shared/types/amplitude-event';
import _ from 'lodash';
import {
  useEffect, useMemo, useReducer, useState,
} from 'react';
import { ButtonListItem } from 'components/organisms/button-list';
import SearchApi, { FormatedResponse } from 'domain/opportunity-list/api/search';
import { Lead, SortType } from 'domain/opportunity-list/types';
import { useDispatch } from 'react-redux';
import CRMExtensionService from 'domain/shared/services/crm-extension-service';
import { useNavigation } from '@react-navigation/native';
import { ACTIVE_SESSION } from 'domain/shared/screens';
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import { SORT_CONTROL } from 'domain/opportunity-list/constants';
import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';
import { setActiveOpportunity } from 'domain/shared/store/slice/opportunity';
import { AppDispatch } from 'domain/shared/store';
import { Opportunity } from 'domain/shared/types/opportunity';
import { EventRegister } from 'react-native-event-listeners';
import { OpportunityEvents } from 'domain/shared/events/opportunity-list';
import { OpportunityScreens as OS } from 'domain/create-opportunity';

export interface Props {
  userId?: number;
  flag_create_leads?: number;
}

export interface Filters {
  filters: string;
  sort: string;
}

// eslint-disable-next-line import/prefer-default-export
export function useOpportunities(props: Props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Lead[]>([]);
  const [response, setResponse] = useState<FormatedResponse<Lead>>();
  const [modal, toggleOpen] = useReducer((value: boolean) => !value, false);
  const [sort, setSort] = useState(SORT_CONTROL[0]);
  const [search, setSearch] = useState<SearchEmitValues>({ value: '' });
  const [filter, setFilter] = useState<string>('');
  const [fail, setFail] = useState(false);

  const navigation = useNavigation();
  const dispatch = useDispatch<AppDispatch>();

  // eslint-disable-next-line max-len
  const isSearchLoading = useMemo(() => loading && search.value.trim().length > 0 && data.length === 0, [loading, search, data]);
  // eslint-disable-next-line max-len
  const noResults = useMemo(() => !!response && response.data.length <= 0 && response.total_pages === 1 && data.length <= 0, [response, data]);

  const cleanSearch = () => {
    setResponse(undefined);
    setLoading(true);
    setData([]);
    setFail(false);
  };

  const fetch = async (filters?: Filters) => {
    try {
      cleanSearch();
      const currentFilters: Filters = { sort: sort.FILTER, filters: filter, ...filters };
      const currentResponse = await SearchApi.get({ ...currentFilters });
      setData(currentResponse.data);
      setResponse(currentResponse);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = _.debounce(async (current: SearchEmitValues) => {
    setSearch(current);

    if (current.value.length <= 0) {
      fetch({ sort: sort.FILTER, filters: filter });
      return;
    }

    try {
      cleanSearch();
      const currentResponse = await SearchApi.search({ text: current.value });
      setData(currentResponse.data);
      setResponse(currentResponse);
    } catch (e) {
      setFail(true);
    }
  }, 500);

  const handlePaginate = async () => {
    if (loading) { return; }
    if (data.length === 0) { return; }
    try {
      if (response?.page === response?.total_pages) { return; }
      setLoading(true);
      const page = (response?.page || 1) + 1;

      const get = search.value.length > 0
        ? SearchApi.search({ text: search.value, page })
        : SearchApi.get({ page, sort: sort.FILTER, filters: filter });

      const res = await get;
      setData((c) => [...c, ...res.data]);
      setResponse(res);
    } finally {
      setLoading(false);
    }
  };

  const handleComplete = () => setLoading(false);

  const handleSearchBar = (current: SearchEmitValues) => {
    handleSearch(current);
  };

  const handleOppFilterChange = (item: ButtonListItem) => {
    let filters = '';
    if (item.value === 1) { filters = `leads_owner_id:${props.userId}`; }
    if (item.value === 2) { filters = 'is_chrono_running:1'; }

    setFilter(filters);
    sendAmplitudeEvent(SEARCH_OPP, { event: OPP_LIST_VIEW, filters });
    fetch({ sort: sort.FILTER, filters });
  };

  const handleSort = (current: SortType) => {
    setSort(current);
    sendAmplitudeEvent(SORT_OPP, { event: OPP_LIST_VIEW });
    fetch({ sort: current.FILTER, filters: filter });
  };

  const handleItem = (item: Lead) => {
    sendAmplitudeEvent(OPENING_OPPORTUNITY, {
      event: OPENING_OPPORTUNITY,
      lead_title: item.title,
      lead_id: item.id,
      lead_uuid: item.uuid,
    });

    dispatch(setActiveOpportunity(item as unknown as Opportunity));
    CRMExtensionService.openLead(item.link_sources);
    navigation.navigate(OpportunityScreens.WALL as never, ACTIVE_SESSION as never);
  };

  const handleAddOpportunity = () => {
    navigation.navigate(OpportunityScreens.CREATE as never, {
      screen: OS.ADD,
      params: { opp: search.opportunity, type: 'lead' },
    } as never);
  };

  useEffect(() => {
    const refreshListEvent = EventRegister.addEventListener(
      OpportunityEvents.REFRESH,
      () => handleSearchBar(search),
    ).toString();

    return () => {
      EventRegister.rm(refreshListEvent);
    };
  }, [search]);

  return {
    model: {
      loading,
      data,
      modal,
      sort,
      search,
      isSearchLoading,
      response,
      noResults,
      fail,
    },
    operations: {
      fetch,
      handleSort,
      toggleOpen,
      handlePaginate,
      handleSearchBar,
      handleOppFilterChange,
      handleComplete,
      handleItem,
      handleAddOpportunity,
    },
  };
}
