import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import GetToken from 'domain/shared/utils/auth/async-storage';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { KANVAS_API } from 'env';
import { APP_SETTING_KEY, DEFAULT_COMPANY_INFO_KEY, USER_TOKEN_KEY } from 'domain/shared/constants';
import store from '../store';
import { EventRegister } from 'react-native-event-listeners';

export const FORCE_LOGOUT = 'FORCE_LOGOUT';

const instance = axios.create({
  baseURL: KANVAS_API,
});

const refreshAuthLogic = async (failedRequest: any) => {
  const { token } = await GetToken();
  const defaultCompanyUUID = await GetToken(DEFAULT_COMPANY_INFO_KEY);
  return axios.post(
    `${KANVAS_API}/refresh-token`,
    {
      access_token: token?.token || '',
      refresh_token: token?.refresh_token || '',
    },
    {
      headers: {
        'Content-type': 'application/json',
        'X-Kanvas-App': APP_SETTING_KEY,
        ...defaultCompanyUUID ? { 'X-Kanvas-Location': defaultCompanyUUID.default_company_branch_uuid } : {},
        Authorization: token?.token || '',
      },
    },
  ).then(async (tokenResponse: any) => {
    const { refresh_token } = tokenResponse.data;
    // eslint-disable-next-line no-param-reassign
    failedRequest.response.config.headers.Authorization = `${refresh_token}`;
    await AsyncStorage.setItem(
      USER_TOKEN_KEY,
      JSON.stringify({ token: tokenResponse.data }),
    );
    return Promise.resolve();
  }).catch((err: any) => {
    EventRegister.emit(FORCE_LOGOUT);
    return Promise.reject(err);
  });
};

instance.interceptors.request.use(
  async (obj) => {
    const config = { ...obj } as any;
    if (!config?.url?.includes('/auth')) {
      const auth = await GetToken() || {};
      const { auth: { user } } = store.getState();
      const defaultCompanyUUID = await GetToken(DEFAULT_COMPANY_INFO_KEY);

      const default_company_branch_uuid = defaultCompanyUUID
        ? defaultCompanyUUID?.default_company_branch_uuid!
        : user?.default_company_branch_uuid!;

      await AsyncStorage.setItem(DEFAULT_COMPANY_INFO_KEY, JSON.stringify({
        ...defaultCompanyUUID, default_company_branch_uuid,
      }));

      const { token } = auth;

      config.headers = {
        'Content-Type': 'application/json',
        'X-Kanvas-App': APP_SETTING_KEY,
        ...token ? { Authorization: token?.token } : {},
        ...default_company_branch_uuid ? { 'X-Kanvas-Location': default_company_branch_uuid } : {},
        ...config.headers,
      };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

createAuthRefreshInterceptor(instance, refreshAuthLogic);
export default instance;
