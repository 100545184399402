import React, { useEffect, useState } from 'react';
import Tooltip from 'react-native-walkthrough-tooltip';
import styled from 'styled-components/native';
import { translate } from 'locales';
import { Flex } from '@kanvas/andromeda';
import colors from 'theme/colors';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Body3 } from '../text';

const ContentContainer = styled(Flex)`
    align-items: flex-start;
    padding: 12px 16px;
    gap: 12px;
    width: 200px;
    height: 64px;
    background-color: ${colors.PRIMARY_DEEMPH};
`;

interface Iprops {
  children: React.ReactNode
}

interface IpropsConten {
  closeHandle: ()=>void
}

const ATTEMPTS = 2;
const HASSEENTOOLTIP = 'hasSeenTooltip';
const FIRSTTIME = 'firstTime';

const Content = (props: IpropsConten) => {
  const { closeHandle } = props;
  return (
    <ContentContainer row>
      <Body3 text={translate('tooltipMessage')} />
      <MCIcon name="close" size={16} onPress={closeHandle} />
    </ContentContainer>
  );
};

const TooltipMessage = (props:Iprops) => {
  const { children } = props;
  const [visible, setVisible] = useState<boolean>(true);
  const [count, setCount] = useState(0);
  const [firstTimeEver, setFirstTimeEver] = useState<boolean>(false);

  const initilizeCount = async () => {
    const attempts = await AsyncStorage.getItem(HASSEENTOOLTIP);
    const firtTime = await AsyncStorage.getItem(FIRSTTIME) || '';
    if (firtTime) {
      setFirstTimeEver(true);
    }
    setCount(Number(attempts));
  };

  useEffect(() => {
    initilizeCount();
  }, []);

  const handleTooltipClose = async () => {
    setVisible(false);
    const counter = count + 1;
    setCount(count + 1);
    AsyncStorage.removeItem(FIRSTTIME);
    await AsyncStorage.setItem(HASSEENTOOLTIP, counter.toString());
  };

  return (
    <Tooltip
      isVisible={count < ATTEMPTS && visible && firstTimeEver}
      content={<Content closeHandle={handleTooltipClose} />}
      placement="bottom"
      onClose={handleTooltipClose}
      contentStyle={{
        backgroundColor: colors.PRIMARY_DEEMPH,
        borderRadius: 12,
        padding: 0,
        margin: 0,
      }}
      tooltipStyle={{
        shadowOpacity: 0,
        elevation: 0,
      }}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipMessage;
