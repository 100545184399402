import React from 'react';
import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Flex } from '@kanvas/andromeda';
import colors from 'theme/colors';
import { BorderlessButton } from 'atoms/touchable';

interface IProps {
  onPress?: () => void;
}

const SIZE = 24;

const Circle = styled(Flex)`
  width: ${SIZE}px;
  height: ${SIZE}px;
  border-radius: ${SIZE / 2}px;
  border-color: ${colors.BORDER_COLOR};
  border-width: 1px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.WHITE};
`;

export const EditBadge = () => (
  <Circle>
    <Icon name="edit" size={16} color={colors.DARK_ORANGE} />
  </Circle>
);

const EditButton = (props: IProps) => {
  const { onPress } = props;
  return (
    <BorderlessButton onPress={onPress}>
      <EditBadge />
    </BorderlessButton>
  );
};

export default EditButton;
