import React from 'react';
import styled from 'styled-components/native';
import { Flex, Spacer } from '@kanvas/andromeda';
import Colors from 'theme/colors';
import Text from 'atoms/text';
import Icon from 'react-native-vector-icons/MaterialIcons';

enum Type {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

const ICONS: Record<Type, string> = {
  SUCCESS: 'check-circle',
  ERROR: 'cancel',
  WARNING: 'warning',
}

interface IProps {
  message: string;
  type: keyof typeof Type
}

const Container = styled(Flex)`
  padding: 16px;
  background-color: ${Colors.PRIMARY_DEEMPH};
  border-radius: 12px;
  border-width: 1px;
  border-color: ${Colors.BUTTON_BORDER}
  margin-horizontal: 32px;
  margin-bottom: 60px;
`;

const ToastMessage = (props: IProps) => {
  const { message, type = 'SUCCESS' } = props;
  const iconName = ICONS[type];
  return (
    <Container row align="center" >
      <Icon name={iconName} size={24} color={Colors.ORANGE} />
      <Spacer horizontal size={6} />
      <Text text={message} size={15} color={Colors.PRIMARY_TEXT} />
    </Container>
  );
};

export default ToastMessage;
