import { getBottomSpace, isIphoneX } from "react-native-iphone-x-helper";
import Modal, { ModalProps } from 'react-native-modal';
import React from "react";
import styled from "styled-components/native";
import { Flex, Spacer } from "@kanvas/andromeda";
import { Button2 } from 'components/molecules/text';
import { DEVICE_WIDTH } from "domain/shared/constants";
import { FlatList, Platform } from "react-native";
import { translate } from 'locales';
import ButtonWithIcon from "components/atoms/button-with-icon";

// constants
import colors from "theme/colors";
import { TaskList } from "model/api/task-list/queries";

interface Props {
  taskLists: TaskList[];
  changeCheckList: (taskListId: number) => void;
  message: string;
  visible: boolean;
  onClose?: VoidFunction;
}

export default function ChangeChecklistModal(props: Props) {
  const { visible, onClose, message, taskLists, changeCheckList } = props;

  return (
    <Modal {...modalProps} isVisible={visible} onBackdropPress={onClose}>
      <Container gap={12}>
        <Button2
          text={message}
        />

        <FlatList
          data={taskLists}
          keyExtractor={(item) => item.id.toString()}
          ItemSeparatorComponent={() => <Spacer size={6} />}
          renderItem={({ item }) => taskLists.length > 1 ? (
            <Button
              key={item.id}
              text={`${translate('changeTo')} ${item.name}`}
              onPress={() => changeCheckList(Number(item.id))}
            />
          ) : (
            <ButtonOrange
              key={item.id}
              text={`${translate('changeTo')} ${item.name}`}
              onPress={() => changeCheckList(Number(item.id))}
            />
          )}
        />

        <Button
          onPress={onClose}
          text={translate('cancelText')}
        />
      </Container>
    </Modal>
  );
}

const modalProps: Partial<ModalProps> = {
  hasBackdrop: true,
  animationIn: "slideInUp",
  animationOut: "slideOutDown",
  style: { alignItems: 'center' },
  useNativeDriver: Platform.OS === 'android',
  useNativeDriverForBackdrop: true,
}

const Container = styled(Flex)`
  width: 100%;
  max-width: ${DEVICE_WIDTH > 768 ? '60%' : '100%'};
  padding: 20px 16px;
  background-color: ${colors.WHITE};
  border-radius: 8px;
`;

const Button = styled(ButtonWithIcon)
  .attrs((props) => (
    { 
      ...props,
      type: 'button1',
      color: colors.WHITE,
      textColor: colors.TEXT_ORANGE_LINK 
    }
  ))``;

const ButtonOrange = styled(ButtonWithIcon)
  .attrs((props) => (
    { 
      ...props,
      type: 'button1',
      color: colors.ORANGE,
      textColor: colors.WHITE 
    }
  ))``;