import React, {
  useCallback, useEffect, useState, useImperativeHandle, forwardRef, ReactElement, useRef,
} from 'react';
import { FlatList, FlatListProps } from 'react-native';
import Animated from 'react-native-reanimated';

export interface PaginatedResponse<T> {
  data: T[];
  limit: number;
  page: number;
  total_pages: number
}

interface PaginatedFlatListProps<T> extends Partial<FlatListProps<T>> {
  fetchData: (pageNumber: number) => Promise<PaginatedResponse<T>>;
  animated?: boolean;
}

export interface PaginatedFlatListHandler {
  refreshData: () => void;
  scrollToTop: () => void;
  reloadData: () => void;
}

const PaginatedFlatList = <T extends object>(
  props: PaginatedFlatListProps<T>,
  ref: any,
) => {
  const flatlist = useRef<any>(null);
  const items = useRef<T[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefresing] = useState(false);

  const {
    fetchData,
    renderItem,
    keyExtractor,
    animated = false,
    ListFooterComponent,
    ListEmptyComponent,
  } = props;

  const List = animated ? Animated.createAnimatedComponent(FlatList) : FlatList;

  const getData = useCallback(async (pageNumber: number) => {
    setLoading(true);
    const { total_pages, data, page: current_page } = await fetchData(pageNumber);

    if (pageNumber > 1) {
      items.current.push(...data);
    } else {
      items.current = data;
    }
    setLastPage(current_page);
    setTotalPages(total_pages);
    setLoading(false);
    setRefresing(false);
  }, [fetchData]);

  const loadMoreData = useCallback(() => {
    if (!loading) {
      const newPageNumber = page + 1;
      if (newPageNumber > lastPage && newPageNumber <= totalPages) {
        getData(newPageNumber);
        setPage(newPageNumber);
      }
    }
  }, [getData, lastPage, loading, page, totalPages]);

  const refreshData = useCallback(() => {
    if (loading) return;
    if (refreshing) return;
    const pageNumber = 1;
    setRefresing(true);
    getData(pageNumber);
    setPage(pageNumber);
  }, [getData, loading, refreshing]);

  const reloadData = useCallback(() => {
    const pageNumber = 1;
    getData(pageNumber);
    setPage(pageNumber);
  }, [getData]);

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  useImperativeHandle(ref, () => ({
    refreshData,
    reloadData,
    scrollToTop: () => {
      if (animated) {
        flatlist.current?._component.scrollToOffset({ animated: true, offset: 0 });
      } else {
        flatlist.current?.scrollToOffset({ animated: true, offset: 0 });
      }
    },
  }));

  return (
    <List
      {...props}
      data={items.current}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      onEndReached={loadMoreData}
      onRefresh={refreshData}
      refreshing={refreshing}
      onEndReachedThreshold={0.1}
      ListEmptyComponent={refreshing ? null : ListEmptyComponent}
      ListFooterComponent={refreshing ? null : ListFooterComponent}
      ref={flatlist}
    />
  );
};

const RefComponent = (
  forwardRef(PaginatedFlatList) as <T extends Object>(
    props: PaginatedFlatListProps<T>, ref: any
  ) => ReactElement
);
export default RefComponent;
