import { useEffect, useRef, useState } from "react";

type Config = { finish?: () => void; };

export default function useCountdown(current: number, config?: Config) {
  const [time, setTime] = useState(current);
  const intervalRef = useRef<any>(null);

  const clear = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  }

  const handleCountdown = () => {
    setTime(current => {
      if (current === 0) {
        clear();
        config?.finish?.();
        return 0;
      }
      return current - 1;
    })
  };

  const start = () => intervalRef.current = setInterval(handleCountdown, 1000);
  const reset = () => setTime(current);

  useEffect(() => {
    return () => clear();
  }, []);

  return {
    time,
    start,
    clear,
    reset,
  }
}