import DropDown from 'components/atoms/dropdown-input';
import ListItem from 'domain/opportunity/screens/list/components/ListItem';
import CountryService from 'domain/shared/services/country-service';
import React, { useCallback, useEffect, useState } from 'react';

interface Iprops {
  id: number,
  city: number,
  onSelectItem: any
}

// // const translate = useCallback((label: string = '')
// => translateBase(label, TextTransform.CAPITALIZE), []);

const Cities = (props: Iprops) => {
  const {
    onSelectItem,
    city, id,
  } = props;

  const [cities, setCities] = useState<ListItem[]>([]);

  const getAllCities = useCallback(async (idCity: number) => {
    try {
      if (idCity !== 0) {
        const list: ListItem[] = (
          await CountryService.getAllCities(idCity)).map((item:any) => ({
          value: item.id,
          label: item.name,
          key: item.id,
        }));
        setCities(list);
      }
    } catch (error:any) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
  }, [id]);

  useEffect(() => {
    getAllCities(id);
  }, [id]);

  return (
    <DropDown
      disabled={id === 0}
      placeHolder="City"
      value={city}
      items={cities}
      onSelectItem={onSelectItem}
    />
  );
};

export default Cities;
