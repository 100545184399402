/* eslint-disable react/no-unstable-nested-components */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Linking, ListRenderItemInfo, Platform, StyleSheet,
} from 'react-native';

import Message from 'components/molecules/notification-message';
import { Flex, Spacer } from '@kanvas/andromeda';
import PaginatedFlatList, { PaginatedFlatListHandler } from 'organisms/paginated-flatlist';
import MessagesService from 'domain/shared/services/messages-service';
import colors from 'theme/colors';
import { EventRegister } from 'react-native-event-listeners';
import { ActivityListEvents } from 'domain/shared/events/activities-list';
import { Notification } from 'domain/shared/types/notification';
import NotificationNotFound from 'components/molecules/notification-not-found';
import { IS_IOS_WEB_DEVICE } from 'domain/shared/constants';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { IPeople } from 'domain/shared/types/people';
import OpportunityService from 'domain/shared/services/opportunity-service';
import { Feed } from 'domain/shared/types/feed';
import ActionService from 'domain/shared/services/action-service';
import { LeadAction } from 'domain/shared/types/lead-action';
import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';
import { OPENING_ACTION } from 'domain/shared/types/amplitude-event';
import { COPY_TEXT, EMAIL_SHARE, TEXT_SHARE } from 'domain/shared/types/amplitude-actions';
import Toast from 'react-native-toast-message';
import useAuth from 'domain/shared/hooks/useAuth';
import Spinner from 'components/atoms/spinner';
import ShareModal from '../share-modal';

const styles = StyleSheet.create({
  ListFooterComponentStyle: { paddingVertical: 16 },
  contentContainerStyle: { paddingTop: 16, paddingBottom: 20 },
  container: { backgroundColor: colors.WHITE, flex: 1 },
});

const NotificationListUpdate = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(true);
  const listRef = useRef<PaginatedFlatListHandler>(null);

  const { user, currentStore } = useAuth();

  const reloadEventHandler = useRef('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [people, setPeople] = useState<IPeople[]>([]);
  const [feed, setFeed] = useState<Feed>();
  const [actions, setActions] = useState<LeadAction[]>();
  const [message, setMessage] = useState<string>('');
  const setReplayData = useCallback(async (peopleId: number, feedId: number) => {
    const reponsePeople = await OpportunityService.getPeopleById(peopleId);
    if (reponsePeople) {
      setPeople(reponsePeople);
    }
    const reponseFeed = await MessagesService.getFeedById(feedId);
    if (reponseFeed) {
      setFeed(reponseFeed);
    } else {
      Toast.show({
        text1: translate('oppsNoFound', TextTransform.NONE),
        type: 'error',
      });
    }
  }, [feed, people]);

  const renderItem = useCallback((e: ListRenderItemInfo<Notification>) => {
    const { item } = e;

    const handleShare = (people_id: number, entity_id: number) => {
      setReplayData(people_id, entity_id);
      setShowModal(true);
    };
    return (
      <Message
        content={item.content}
        time={item.created_at}
        lead_id={item.entity.leads_id}
        handle={() => handleShare(item.entity.people_id || 1802, item.entity.message_id)}
        message_variable={item.entity.message_variable}
        read={item.read}
      />
    );
  }, []);

  const getAction = useCallback(async () => {
    if (currentStore) {
      const actionResponse = await ActionService.getAllActions(currentStore?.id);
      if (actionResponse) {
        setActions(actionResponse);
      }
    }
  }, [currentStore]);

  const keyExtractor = useCallback((notification: Notification) => `${notification.id.toString()}`, []);

  const fetchData = useCallback(async (pageNumber: number) => {
    if (pageNumber > 1) {
      setLoading(true);
    }

    const notifications = await MessagesService.getAllMessagePaginated(
      pageNumber,
      currentStore?.id!,
    );

    setLoading(false);
    return notifications;
  }, [currentStore]);

  useEffect(() => {
    reloadEventHandler.current = EventRegister.on(ActivityListEvents.ACTIVITY_LIST_RELOAD, () => {
      setTimeout(() => {
        listRef.current?.refreshData();
      }, 1000);
    }).toString();
    getAction();
    return () => {
      EventRegister.rm(reloadEventHandler.current);
    };
  }, [feed, currentStore]);

  const openShareOption = useCallback(async (url: string) => {
    try {
      await Linking.openURL(url);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getEmailSubject = () => `Follow up: ${user?.firstname} from ${currentStore?.name} shared a link with you`;

  const getMessage = useCallback(() => message, [message]);

  const processSharing = useCallback((index: any, name: string = '', byContact: string = '') => {
    const { firstname } = user!;
    // eslint-disable-next-line no-nested-ternary
    const share_by = index === 0
      ? TEXT_SHARE : index === 1 ? EMAIL_SHARE : COPY_TEXT;

    const messageAction = actions?.find(
      (action: LeadAction) => (action.slug === feed?.message?.verb),
    );

    const replayMessage = translate('replayMessage', TextTransform.NONE);
    const formatMessage = replayMessage.replace('{contactName}', name)
      .replace('{user}', firstname)
      .replace('{store}', currentStore?.name || '')
      .replace('{action}', messageAction?.description || '')
      .replace('{oldMessage}', messageAction?.reasonEn || '')
      .replace('{url}', feed?.message.link || '');

    setMessage(formatMessage);
    const messageShareString = Platform.select({
      ios: () => `sms:/open?addresses=${byContact}&body=${formatMessage}`,
      android: () => `sms:${byContact}?body=${formatMessage}`,
      web: () => `sms:${byContact}${IS_IOS_WEB_DEVICE ? '&' : '?'}body=${formatMessage}`,
    })?.();

    sendAmplitudeEvent(share_by, { event: OPENING_ACTION });

    switch (index) {
      case 0:
        openShareOption(messageShareString!);
        break;
      case 1:
        openShareOption(
          `mailto:${byContact}?subject=${getEmailSubject()}&body=${formatMessage}`,
        );
        break;
      default:
        break;
    }
  }, [feed, currentStore]);

  useEffect(() => {
    const [conctactFound] = people;
    processSharing(3, conctactFound?.name, '');
  }, [people, feed, message]);

  return (
    <Flex flex={1} paddingHorizontal={16} style={styles.container}>
      {(currentStore !== undefined) && (
        <PaginatedFlatList
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          ItemSeparatorComponent={() => <Spacer size={6} />}
          fetchData={fetchData}
          showsVerticalScrollIndicator={false}
          ListFooterComponent={loading ? <Spinner /> : null}
          ListFooterComponentStyle={styles.ListFooterComponentStyle}
          contentContainerStyle={styles.contentContainerStyle}
          ListEmptyComponent={!loading ? <NotificationNotFound /> : null}
        />
      )}

      <ShareModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        contacts={people}
        onSharePress={processSharing}
        message={getMessage()}
        onOpen={() => setShowModal(true)}
      />
    </Flex>

  );
};

export default NotificationListUpdate;
