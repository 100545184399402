import { Flex } from '@kanvas/andromeda';
import { ButtonBase } from 'components/atoms/button-with-icon';
import React from 'react';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import colors from 'theme/colors';
import { RectButton } from 'atoms/touchable';
import styled from 'styled-components/native';

import Modal from 'react-native-modal';
import { IS_WEB } from 'domain/shared/constants';
import { Body3 } from '../text';

const Container = styled(Flex)`
  background-color: ${colors.WHITE};
  padding: 20px;
  width: 100%;
  border-radius: 12px;
  ${IS_WEB ? `box-shadow: 0px -4px 16px ${colors.BUTTON_BORDER}` : ''};
`;

interface IProps {
  text: string,
  visible: boolean,
  onClose: () => void;
  okText?: string,
  cancelText?: string,
  icon?: React.ReactNode,
  onOk?: () => void,
  onCancel?: () => void,
  hideCancelButton?: boolean;
  innerBody?: React.ReactNode
}

const ConfirmModal = (props: IProps) => {
  const {
    cancelText = 'Cancel',
    okText = 'Ok',
    icon = <Icon name="message-alert-outline" size={24} color={colors.WARNING} />,
    onOk,
    onCancel,
    text,
    visible,
    onClose,
    hideCancelButton = false,
    innerBody = null,
  } = props;

  return (
    <Modal
      isVisible={visible}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      style={{ alignItems: 'center' }}
    >
      {innerBody}
      {
        !innerBody && (
          <Container gap={16}>
            <Flex row gap={8}>
              <Flex>
                {icon}
              </Flex>
              <Flex flex={1} style={{ paddingBottom: 15 }}>
                <Body3 text={text} />
              </Flex>
              <Flex>
                <RectButton onPress={onClose}>
                  <Icon name="close" size={24} />
                </RectButton>
              </Flex>
            </Flex>
            <Flex gap={8}>
              <ButtonBase
                color={colors.ORANGE}
                textColor={colors.WHITE}
                text={okText}
                onPress={onOk}

              />
              {
                !hideCancelButton && (
                  <ButtonBase
                    text={cancelText}
                    onPress={onCancel}
                    color={colors.WHITE}
                    textColor={colors.ORANGE}
                  />
                )
              }
            </Flex>
          </Container>
        )
      }
    </Modal>
  );
};

export default ConfirmModal;
