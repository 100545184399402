/* eslint-disable react/jsx-no-useless-fragment */
// Modules
import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components/native';
import { Flex } from '@kanvas/andromeda';
import Ionicons from 'react-native-vector-icons/Ionicons';

// Theme
import colors from 'theme/colors';

// Molecules
import { Body3 } from '../text';

interface Props {
  Icon: ReactNode;
  label: string;
  onHide?: VoidFunction;
  show: boolean;
  customText?: JSX.Element;
}

const Root = styled(Flex)`
  padding: 14px 14px;
  border-radius: 12px;
  background-color: ${colors.WHITE};
  border: 1px solid ${colors.BUTTON_BORDER};
  box-shadow: 0px 4px 5px ${colors.BUTTON_BORDER};
  width: 100%;
  align-items: center;
  flex-direction: row;
`;

const ToastText = styled(Body3)`
  width: 80%;
`;

const IconContainer = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
`;

export default function ModalMessage(props: Props) {
  const {
    label,
    Icon,
    show,
    onHide,
    customText,
  } = props;

  if (!show) return null;

  return (
    <Root row gap={10}>

      {Icon}

      {customText ? (
        <>{customText}</>
      ) : (
        <ToastText
          text={label}
        />
      )}

      <IconContainer
        onPress={onHide}
      >
        <Ionicons
          name="ios-close"
          size={22}
          color={colors.BLACK}
        />
      </IconContainer>
    </Root>
  );
}
