import { AppSettingsQuery, CompanySettingsQuery } from 'domain/shared/graphql/settings';
import client from 'domain/shared/graphql/client';

import { APP_SETTING_KEY } from '../constants';
import { AppSettings, CompanySettings } from '../types/settings';

type AppSettingsResponse = {
  getAppSettings: {
    name: string;
    settings: AppSettings
  }
};

type CompanySettingsResponse = {
  companySettings: {
    name: string;
    settings: CompanySettings;
  }
};

export default class AppSettingsService {
  static async getAppSettings() {
    try {
      const { data: { getAppSettings } } = await client.query<AppSettingsResponse>({
        query: AppSettingsQuery,
        variables: { appKey: APP_SETTING_KEY },
        fetchPolicy: 'no-cache',
      });
      return getAppSettings;
    } catch {
      return undefined;
    }
  }

  static async getCompanySettings() {
    try {
      const { data: { companySettings } } = await client.query<CompanySettingsResponse>({
        query: CompanySettingsQuery,
        fetchPolicy: 'no-cache',
      });
      return companySettings;
    } catch {
      return undefined;
    }
  }
}
