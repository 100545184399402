import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import EngagementBadge from 'components/atoms/engagement-badge';
import { Message } from 'domain/shared/types/message';
import Colors from 'theme/colors';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Flex, Spacer } from '@kanvas/andromeda';
import { ActivityListContext } from 'components/context/activity-list-context';
import { Stage } from 'domain/shared/types/engagement-types';

interface IProps {
  message: Message
}

const EngagementBadgeList = (props: IProps) => {
  const { message } = props;
  const { engagementTypes } = useContext(ActivityListContext);
  const [items, setItems] = useState<Stage[]>([]);

  const renderItem = useCallback((stage: Stage, index: number) => {
    const last = index === items.length - 1;
    return (
      <React.Fragment key={stage.id}>
        <EngagementBadge
          status={stage.slug}
          done={stage.done}
          name={stage.name}
        />
        {
          !last && (
            <>
              <Icon name="chevron-right" size={14} color={Colors.DARK_ORANGE} />
              <Spacer size={8} />
            </>
          )
        }
      </React.Fragment>
    );
  }, [items]);

  useEffect(() => {
    const { message_types, message: { engagement_status } } = message;
    const { verb } = message_types;
    const engagement = engagementTypes.find((i) => i.slug === verb);
    if (engagement) {
      const stages = engagement.stages.sort((a, b) => a.weight - b.weight);
      const names = stages.map((s) => s.slug);
      const index = names.indexOf(engagement_status);
      setItems(stages.map((s, i) => ({ ...s, done: index >= i })));
    } else {
      setItems([]);
    }
  }, [message, engagementTypes]);

  return (
    <Flex row align="center">
      {items.map(renderItem)}
    </Flex>
  );
};

export default EngagementBadgeList;
