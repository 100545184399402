import React from 'react';
import { Flex } from '@kanvas/andromeda';
import * as Animatable from 'react-native-animatable';
import styled from 'styled-components';
import colors from 'theme/colors';
import Text from 'components/atoms/text';

const HeaderContainer = styled(Animatable.View)` 
    align-item:center;   
    flex-direction: row;
    align-items: center;
    margin-horizontal: 16px;
    padding-top: 16px;
    margin-bottom: 16px;
`;

const TitleText = styled<any>(Text)`
  margin-left: 8px;
`;

interface IProps {
  RightSide?: React.ReactNode | null,
  Title?: string | null,
  LeftSide?: React.ReactNode | null
}

const TEXT_SIZE = 20;

const HeaderSearch = (props: IProps) => {
  const { RightSide, Title, LeftSide }: IProps = props;
  return (
    <HeaderContainer animation="fadeIn">
      {RightSide}
      <Flex row align="center" justify="center">
        <TitleText
          size={TEXT_SIZE}
          fontStyle="semibold"
          weight="600"
          color={colors.BLACK}
          text={Title || ''}
        />
      </Flex>
      <Flex row flex={1} justify="flex-end">
        {LeftSide}
      </Flex>
    </HeaderContainer>
  );
};

export default HeaderSearch;
