import React, { Component } from 'react';
import styled from 'styled-components/native';

import Timer from 'atoms/Timer';
import axios from 'domain/shared/services/axios';
import moment from 'moment';
import Color from 'theme/colors';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Flex } from '@kanvas/andromeda';

import { RectButton } from 'atoms/touchable';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { START_CHRONO } from 'domain/shared/types/amplitude-actions';
import { ACTION_PANEL } from 'domain/shared/types/amplitude-event';
import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';
import { showAlert, showNativeAlert } from 'components/molecules/alerts';
import { IS_MOBILE } from 'domain/shared/constants';
import LeadsService from 'domain/shared/services/leads.service';

const ContainerPlace = styled(RectButton)<any>`
    flex:1;
    flex-direction:row;
    align-items:center;
    aling-content:center;
    background: transparent;
    justify-content: center;
    border-radius: 12px;
    z-index: 1;
  `;

const Container = styled.View`
    flex: 1;
    align-items: flex-end;
  `;

const ArrowIcon = styled(Icon)`
      margin-right:6px;
    `;
const IS_CHRONO_RUNNING = 1;

class Chronometer extends Component<any, any> {
  timer: any;

  constructor(props: any) {
    super(props);
    this.state = {
      timerStarted: false,
      isRunning: false,
    };
    this.timer = React.createRef();
  }

  componentDidMount() {
    const { contact } = this.props;
    this.setInitialValuesOnTimer();

    if (contact?.walkIn) {
      this.handleStart();
    }
  }

  componentWillUnmount() {
    this.timer.current.kill();
  }

  handleStart = () => {
    const { timerStarted, isRunning } = this.state;
    if (timerStarted) {
      if (isRunning) {
        const message = translate('endVisitCRM', TextTransform.CAPITAL);
        if (IS_MOBILE) {
          showNativeAlert(message);
        } else {
          showAlert(translate('endVisitCRM', TextTransform.CAPITAL));
        }
      }
    } else {
      this.setState({ timerStarted: true }, () => {
        this.timer.current.start();
        this.setState({ isRunning: true });
      });

      sendAmplitudeEvent(START_CHRONO, { event: ACTION_PANEL });
    }
  };

  setInitialValuesOnTimer = () => {
    const { contact: { is_chrono_running = false, chrono_start_date = '' } = {} } = this.props;
    if (is_chrono_running === IS_CHRONO_RUNNING) {
      this.setState({ isRunning: true });
      if (chrono_start_date) {
        const now = moment(); // todays date
        const end = moment(chrono_start_date); // another date
        const duration = moment.duration(now.diff(end));
        const hours = duration.get('hour');
        const minutes = duration.get('minute');
        const seconds = duration.get('second');

        this.setState({ timerStarted: true }, () => {
          this.timer.current.setTimerValues(seconds, minutes, hours);
          this.timer.current.start();
        });
      }
    }
  };

  requestChronoStarted = async (started: boolean) => {
    const { contact } = this.props;

    try {
      const { contact: { is_chrono_running = false } = {} } = this.props;
      if (!is_chrono_running) {
        return await LeadsService.toggleChrono(contact, started);
      }
      return undefined;
    } catch (e) {
      return undefined;
    }
  };

  onTimerStarted = () => {
    this.requestChronoStarted(true);
  };

  render() {
    const { isRunning } = this.state;
    return (
      <Container>
        <ContainerPlace onPress={this.handleStart} isRunning={isRunning}>
          <Flex>
            {
              !isRunning && (
                <Flex row align="center">
                  <ArrowIcon name="play-arrow" size={24} color={Color.WHITE} />
                  {/* <Text text="Start" size={15} fontStyle="extrabold" color={Color.ORANGE} /> */}
                </Flex>
              )
            }
            <Timer
              ref={this.timer}
              onTimerStarted={this.onTimerStarted}
            />
          </Flex>
        </ContainerPlace>
      </Container>
    );
  }
}

export default Chronometer;
