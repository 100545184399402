import { useCompanySettings } from "domain/shared/hooks/useAppSettings";
import { useCallback } from "react";
import colors from "theme/colors";

export default function useCheckIdVerification(isManager: boolean) {
  const { id_verification: companyIdVerification = undefined } = useCompanySettings()!;

  const check = useCallback((id_verification: any) => {
    const isVerified = !!companyIdVerification;

    if (!id_verification) return null;

    const { intelicheck = false, scandit = false, expired = false } = id_verification || {};
    const isValid = !!(companyIdVerification === 'scandit' ? scandit : intelicheck);
    // const isValid = scandit || intelicheck;

    if (isVerified && !isManager) {
      return { icon: 'shield-check', color: colors.WHITE };
    }

    const checked = isManager && isVerified;

    if (checked && isValid) {
      return { icon: 'shield-check', color: !expired ? colors.ID_VERIFICATION.GREEN : colors.ID_VERIFICATION.YELLOW };
    }

    if (checked && !isValid) {
      return { icon: 'shield-off', color: colors.ID_VERIFICATION.RED };
    }

    return null;
  }, [companyIdVerification, isManager]);

  return check;
}