import React from "react";
import { ActivityIndicator, Pressable } from "react-native";

import colors from "theme/colors";
import { translate } from "locales";

import { STATUS } from "components/organisms/two-factor-manager";

import { Body3 } from "../text";

interface Props {
  status: STATUS;
  value: string;
  time: number;
  onPress?: VoidFunction;
}

export default function TwoFactorButton(props: Props) {
  const { status, time, onPress, value } = props;

  if (status === STATUS.loading) return <ActivityIndicator size="small" color={colors.ORANGE} />
  if (status === STATUS.time) return <Body3 text={`${time}s`} link color={colors.TEXT_PLACEHOLDER} />;
  if (status === STATUS.resend) return <Button valid label={translate('resend')} onPress={onPress} />
  return <Button valid={value.length === 10} label={translate('verify')} onPress={onPress} />
}

interface ButtonProps { 
  valid?: boolean;
  label: string;
  onPress?: VoidFunction
}

function Button(props: ButtonProps) {
  const { onPress, valid, label } = props;
  const verifyColor = valid ? colors.TEXT_ORANGE_LINK : colors.TEXT_PLACEHOLDER;

  return (
    <Pressable disabled={!valid} onPress={onPress}>
      <Body3 text={label} link color={verifyColor} />
    </Pressable>
  );
}