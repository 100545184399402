/* eslint-disable react/no-unused-prop-types */

// Modules
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import DatePicker from 'react-native-date-picker';
import moment from 'moment';
import { Flex } from '@kanvas/andromeda';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

// Theme
import colors from 'theme/colors';
import { FONTS } from 'theme/typography';

// Utils
import { showSuccess } from 'domain/shared/utils/toast';

// Molecules
import { Meta2 } from 'components/molecules/text';

// Atoms
import { TouchableOpacity } from '../touchable';

// Constants
import { IS_MOBILE, IS_MOBILE_WEB, IS_WEB } from 'domain/shared/constants';

interface Props {
  value: string,
  onChange: (propertyName: string) => void,
  mask?: string
  errors?: boolean,
  validate?: string,
  label: string,
  placeholder?: string,
  showIcon?: boolean,
  mode?: 'date' | 'time',
  required?: boolean;
  is24hourSource?: 'locale';
}

interface IPropsLabel {
  text: string;
  required?: boolean;
  validate?: string;
  tooltip?: string;
  RightComponent?: () => JSX.Element;
}

const Container = styled(Flex)`
  margin-vertical: 5px;
  background-color: ${colors.WHITE};
`;

const InputContainer = styled(Flex) <IPropsLabel>`
  height: 40px;
  background-color: ${colors.WHITE};
  color: ${colors.PRIMARY_TEXT};
  border: 1px solid ${(props) => (props.validate ? colors.WARNING : colors.BUTTON_BORDER)};
  box-shadow: 0px 4px 5px ${colors.BUTTON_BORDER};
  border-radius: 8px;
  padding-horizontal:16px;
`;

const InputTextContainer = styled.TouchableOpacity`
  flex: 1;
`;

const InputText = styled.Text`
  color: ${colors.PRIMARY_TEXT};
`;

const TextLabel = styled(Meta2) <IPropsLabel>`
  margin-bottom:4px;
  color:${(props) => (props.validate ? colors.WARNING : colors.SECONDARY_TEXT)};
`;

const styles = StyleSheet.create({
  main: {
    backgroundColor: colors.WHITE,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.BORDER_INPUT,
    shadowColor: colors.BUTTON_BORDER,
    shadowOpacity: 1,
    shadowOffset: { width: 4, height: 5 },
    shadowRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 12,
    height: 40,
    color: colors.TEXT_PLACEHOLDER,
    fontFamily: FONTS.SFProText,
    fontSize: 15,
    cursor: 'pointer',
    width: `calc(100% - ${IS_MOBILE_WEB ? 15 : 0}px)`,
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
  },
});

const COMMON_FORMAT = 'MM-DD-YY';

export const FormLabel = (props: IPropsLabel) => {
  const {
    required,
    validate,
    tooltip,
    RightComponent = null,
  } = props;

  const onInfoPress = () => {
    showSuccess(tooltip);
  };

  return (
    <Flex row align="center">
      <TextLabel {...props} />
      {required && <TextLabel text="*" validate={validate} />}
      {tooltip && (
        <Flex marginHorizontal={3}>
          <TouchableOpacity
            onPress={onInfoPress}
          >
            <Icon
              name="information-outline"
              color={colors.SECONDARY_TEXT}
              size={15}
              style={{
                marginLeft: 5,
                marginBottom: 5,
              }}
            />
          </TouchableOpacity>
        </Flex>
      )}

      {
        RightComponent && (
          <RightComponent />
        )
      }
    </Flex>
  );
};

const DateTimeInput = (props: Props) => {
  // Props
  const {
    onChange,
    mode = 'date',
    required,
    label,
    placeholder = mode !== 'time' ? 'mm/dd/yyyy' : 'hh:mm',
    showIcon = true,
    errors,
    value,
  } = props;

  // States
  const [open, setOpen] = useState(false);
  const [current, setDate] = useState<Date | null>(null);
  const [formattedDate, setFormattedDate] = useState('');

  // think will work all the time but basically i'm trying to have the first ref value so i can return it
  const valueRef = useRef(value);

  const date = useMemo(() => {
    if (current) return current;
    if (value) return moment(value, "YYYY-MM-DD").toDate();
    return new Date();
  }, [current, value]);

  const onConfirm = useCallback((selectedDate: Date) => {
    let value = '';

    if (mode === 'date') {
      value = moment(selectedDate).format(COMMON_FORMAT);
    }

    if (mode === 'time') {
      value = moment(selectedDate).format('LT');
    }

    const format = mode === 'time' ? value : moment(selectedDate).format('YYYY-MM-DD')

    setDate(selectedDate);
    setOpen(false);
    onChange?.(value);
    setFormattedDate(format);
  }, []);
  
  const onClear = useCallback(() => {
    setDate(new Date());
    setFormattedDate('');
    onChange?.(valueRef.current);
  }, []);

  const handleWebInput = (e: any) => {
    const { value } = e.target as { value: string };

    
    if (!value.length) {
      onClear();
      return;
    } 
    
    const current = moment(value, 'YYYY-MM-DD').format(COMMON_FORMAT);
    onChange?.(current);
    setFormattedDate(value);
  };

  const dateToShow = formattedDate || value;
  const currentValue = useMemo(() => {
    if (!dateToShow) return null;
    if (mode === 'time') return dateToShow;
    return moment(dateToShow, "YYYY-MM-DD").format(COMMON_FORMAT);
  }, [dateToShow, mode]);

  return (
    <Container>
      <FormLabel
        required={required}
        validate={errors}
        text={label}
      />

      {IS_MOBILE && (
        <InputContainer row align="center" validate={errors}>
          <InputTextContainer
            onPress={() => setOpen(true)}
          >
            <InputText
              style={{ color: !!dateToShow ? colors.PRIMARY_TEXT : colors.TEXT_PLACEHOLDER }}
            >
              {currentValue || placeholder}
            </InputText>
          </InputTextContainer>

          {showIcon && (
            <TouchableOpacity
              onPress={formattedDate ? () => onClear() : () => setOpen(true)}
            >
              <Ionicons
                name={formattedDate ? 'close-circle' : 'calendar'}
                size={18}
                color={colors.ICON_SECONDARY}
              />
            </TouchableOpacity>
          )}

        </InputContainer>
      )}

      {IS_MOBILE && (
        <DatePicker
          modal
          mode={mode}
          date={date}
          open={open}
          onConfirm={onConfirm}
          onCancel={() => setOpen(false)}
          {...props}
        />
      )}

      {IS_WEB && (
        <input
          type={mode}
          value={dateToShow}
          style={styles.main}
          placeholder={placeholder}
          onInput={(e: any) => handleWebInput(e)}
        />
      )}

    </Container>
  );
};

export default DateTimeInput;
