import React from 'react';
import Text, { TextProps } from 'atoms/text';
import Colors from 'theme/colors';

const TextLink = (props: TextProps) => (
  <Text
    size={12}
    lineHeight={16}
    color={Colors.TEXT_ORANGE_LINK}
    {...props}
  />
);

export default TextLink;
