import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Flex, Spacer } from "@kanvas/andromeda";

import { Meta2 } from "components/molecules/text";
import CodeValidationInput from "components/molecules/code-validation-input";
import ButtonWithIcon from "components/atoms/button-with-icon";

import { STATUS } from "../index";
import Checkbox from "components/atoms/checkbox";
import { translate } from "locales";

const CODE_AMOUNT = 6;

interface Props { 
  label: string;
  status: STATUS;
  loading?: boolean;
  ask?: boolean;
  buttonLabel?: string;
  onPress?: (code: string, dontAsk: boolean) => void;
};

export interface VerificationCodeSectionRef {
  clean: () => void;
}

const VerificationCodeSection = forwardRef<VerificationCodeSectionRef, Props>(function (props, ref) {
  const { label, loading, onPress, status, ask = true, buttonLabel = translate('login') } = props;
  const [code, setCode] = useState('');
  const [dontAsk, setDontAsk] = useState(false);

  const handlePress = () => {
    const current = code.length < CODE_AMOUNT ? '' : code;
    onPress?.(current, dontAsk);
  }

  useImperativeHandle(ref, () => ({
    clean: () => setCode(''),
  }));

  if (status == STATUS.verify || status === STATUS.loading) return null;

  return (
    <Flex>
      <Meta2 text={label} />
      <Spacer size={ask ? 14 : 4} />
      <Flex gap={20}>
        <CodeValidationInput value={code} onChange={setCode} amount={CODE_AMOUNT} />
        {ask && (
          <Checkbox
            gap={2}
            value={dontAsk}
            onChange={setDontAsk}
            text={translate('two-fact.do-not-ask')}
          />
        )}
        <ButtonWithIcon loading={loading} text={buttonLabel} onPress={handlePress} />
      </Flex>
    </Flex>
  );
});

export default VerificationCodeSection;