import { createNavigationContainerRef } from '@react-navigation/native';

export const navigationRef = createNavigationContainerRef() as any;

export function navigate(name: string, params: object = {} as never) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(
      name as never,
      params as never,
    );
  }
}

export function navigateToStack(
  stackName: string,
  screenName: string,
  params: object = {} as never,
) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(
      stackName as never,
      {
        screen: screenName,
        params,
      },
    );
  }
}
