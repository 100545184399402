import React, { useCallback, useEffect, useState } from 'react';
import { Flex } from '@kanvas/andromeda';
import { UserNotificationType } from 'domain/shared/types/user-notifications-settings';
import styled from 'styled-components/native';
import colors from 'theme/colors';
import Switch from '@samcode/react-native-switch';
import { Body3 } from '../text';

interface IProps {
  item: UserNotificationType;
  onItemChanged?: (value: boolean) => void;
}

const Container = styled(Flex)`
  padding-vertical: 12px;
  padding-horizontal: 16px;
  background-color: ${colors.WHITE};
  border-width: 1px;
  border-color: ${colors.BORDER_INPUT};
  border-radius: 8px;
  box-shadow: 0px 4px 5px ${colors.BUTTON_BORDER};
`;

export default function NotificationSettingItem(props: IProps) {
  const { item, onItemChanged } = props;
  const [value, setValue] = useState(Boolean(item.is_enabled));

  const trackColor = {
    true: colors.ORANGE,
    false: colors.SWITCH_THUMB_TRACK,
  };

  const thumbColor = {
    true: colors.WHITE,
    false: colors.WHITE,
  };

  const onChange = useCallback((itemValue) => {
    setValue(itemValue);
    onItemChanged?.(itemValue);
  }, []);

  useEffect(() => {
    setValue(Boolean(item.is_enabled));
  }, [item.is_enabled]);

  return (
    <Container row>
      <Flex flex={1}>
        <Body3 color={colors.ICON_PRIMARY} text={item.name} />
      </Flex>
      <Switch
        onValueChange={onChange}
        value={value}
        trackHeight={14}
        thumbSize={20}
        trackColor={trackColor}
        thumbColor={thumbColor}
        style={{ width: 34 }}
        thumbStyle={{
          shadowColor: colors.ICON_PRIMARY,
          shadowOpacity: 0.55,
          shadowOffset: { width: 1, height: 1 },
          shadowRadius: 3,
          backgroundColor: colors.WHITE,
          top: -4,
        }}
      />
    </Container>
  );
}
