import axios from 'domain/shared/services/axios';
import { PaginatedResponse } from 'domain/shared/types/paginated-response';
import { LeadAction } from '../types/lead-action';

export type ActionPreviewBody = {
  lead_id: string;
  receiver_id: string | 0;
  contact_id: string | 0;
  extraField: any;
  company_action_id: string | 0;
};

interface MessageContent {
  ENG: string;
  ES: string;
}

interface Params {
  actions_slug: string;
  bcid: string;
  cid: string;
  company_action_id: string;
  contact_id: string;
  contacts_id: string;
  extraField: any[];
  lead_id: string;
  leads_id: string;
  receiver_id: string;
  receivers_id: string;
  request: string;
  users_id: number;
  vehicle_id: string | null;
  visitor_id: string;
  visitors_id: string;
}

export interface ContentPreviewResponse {
  data: any[];
  link: string;
  link_full: string;
  link_full_preview: string;
  link_preview: string;
  message_content: MessageContent;
  params: Params;
  preview_image: string;
}


export default class ActionService {
  public static async getAllActions(branchId: number) {
    const { data } = await axios.get<PaginatedResponse<LeadAction>>(`/actions?page=1&format=true&q=(companies_branches_id:${branchId})`);
    return data.data;
  }

  public static async getActionPreview(key: string, form: ActionPreviewBody): Promise<ContentPreviewResponse> {
    const { data } = await axios.post(`/actions/${key}/preview`, form, { headers: { 'Content-Type': 'application/json' } });
    return data;
  }

  public static async getDocs() {
    const { data } = await axios.get('/contents/stipulations');
    return data;
  }

  public static async getActionDocs(key: string = 'c81f941c-4d35-46ee-a02e-122895e692b1', actionKey: string = 'esign-docs') {
    // TODO: WHY IS THIS KEY HARDCODED??
    const { data } = await axios.get(`/actions-content/${actionKey}?key=${key}`);
    return data;
  }

  public static async getLinkedFields(leadId: string = '', companyUUID: string = '') {
    // TODO: WHY IS THIS KEY HARDCODED??
    const { data } = await axios.get(`/leads/${leadId}/pdf-linked-fields?key=${companyUUID}`);
    return data;
  }

  public static async postReceiversMessage(leads_receivers_uuid: string, body: any) {
    await axios.post(`receivers/${leads_receivers_uuid}/messages`, body);
  }
}
