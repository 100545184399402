import { useFocusEffect } from '@react-navigation/native';
import { CXTabBarEvents, SearchEmitValues } from 'domain/shared/events/cx-tab-bar';
import { EventRegister } from 'react-native-event-listeners';

interface Props {
  onSearch?: (event: SearchEmitValues) => void;
  onClear?: VoidFunction;
}

export default function SearchBarEventHandler(props: Props) {
  const { onSearch, onClear } = props;

  useFocusEffect(() => {
    const eventId = EventRegister.addEventListener(
      CXTabBarEvents.ON_SEARCH,
      (e: SearchEmitValues) => onSearch?.(e),
    ).toString();

    const cleartId = EventRegister.addEventListener(
      CXTabBarEvents.ON_CLEAR,
      () => onClear?.(),
    ).toString();

    return () => {
      EventRegister.rm(eventId);
      EventRegister.rm(cleartId);
    };
  });

  return null;
}
