import React from 'react';

export const CRM_TYPES = {
  vin: 'vinSolutions',
};

interface ICXTabBarContext {
  visible: boolean,
  setTabBarVisible: (val: boolean) => void;
  CRMhost:string,
  setCRMHost: (val: string) => void;
}

export const CXTabBarContext = React.createContext<ICXTabBarContext>({} as ICXTabBarContext);
export const CSTabBarContextProvider = CXTabBarContext.Provider;
