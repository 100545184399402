import React, { useCallback, useState } from 'react';
import { Document, Page } from 'react-pdf';

import type { PDFDocumentProxy } from 'pdfjs-dist';
import { Flex } from '@kanvas/andromeda';


import { pdfjs } from 'react-pdf';
import styled from 'styled-components/native';
import colors from 'theme/colors';
import { ScrollView } from 'react-native-gesture-handler';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PageContainer = styled(Flex)`
  max-height: 580px;
  overflow: hidden;
  box-shadow: 0px 4px 5px ${colors.BUTTON_BORDER};

`;

type Props = {
  uri: string;
}

export default function PDF(props: Props) {
  const { uri } = props;
  const [pages, setPages] = useState(1);

  const handleLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
    setPages(numPages)
  };

  const RenderPages = useCallback(() => {
    return Array.from(new Array(pages), (_, index) => (
      <PageContainer>
        <Page 
          height={580}
          loading={false}
          pageNumber={index + 1}
        />
      </PageContainer>
    ));
  }, [pages])

  return (
    <ScrollView>
      <Document file={uri} onLoadSuccess={handleLoadSuccess}>
        <Flex flex={1} align='center' justify='center' gap={20}>
          {/* @ts-ignore */}
          <RenderPages />
        </Flex>
      </Document>
    </ScrollView>
  );
}