/* eslint-disable no-nested-ternary */
import { Flex } from '@kanvas/andromeda';
import React, { useCallback } from 'react';
import Icon from 'react-native-vector-icons/MaterialIcons';
import Colors from 'theme/colors';
import { TouchableOpacity } from 'atoms/touchable';
import { Meta2 } from 'components/molecules/text';
import { TextProps } from 'atoms/text';

interface IProps {
  onChange?: (value: boolean) => void;
  value?: boolean;
  disabled?: boolean;
  text?: string;
  textProps?: TextProps;
  gap?: number;
}

export default function Checkbox(props: IProps) {
  const {
    onChange,
    value = false,
    disabled = false,
    text,
    textProps = {},
    gap = 10,
  } = props;
  const iconName = value ? 'check-box' : 'check-box-outline-blank';
  const iconColor = value
    ? Colors.DARK_ORANGE : !disabled
      ? Colors.SECONDARY_TEXT : Colors.DISABLED;

  const onPress = useCallback(() => {
    onChange?.(!value);
  }, [value]);

  return (
    <TouchableOpacity onPress={onPress}>
      <Flex row align="center" gap={gap}>
        <Icon size={24} color={iconColor} name={iconName} />
        {
          text && (
            <Meta2 {...textProps} text={text} />
          )
        }
      </Flex>
    </TouchableOpacity>
  );
}
