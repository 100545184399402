import IntelicheckApi, { DocumentData } from "domain/barcode-scanner/api/intelicheck.api";
import { ScannedData } from "domain/barcode-scanner/types";
import { CompanySettings } from "domain/shared/types/settings";

type Result = { scandit: boolean | undefined, intelicheck: boolean | undefined };

export class IdValidateProcess {
  private idVerification: "scandit" | "intellicheck" | undefined
  private static _instance: IdValidateProcess;

  constructor(companySettings?: CompanySettings) {
    this.idVerification = this.getSettings(companySettings);
  }

  public static instance(companySettings?: CompanySettings): IdValidateProcess {
    if (!this._instance) this._instance = new IdValidateProcess(companySettings);
    return this._instance;
  }

  isIntellicheck = () => this.idVerification === 'intellicheck';
  // isScandit = () => this.idVerification === 'scandit';

  private getSettings(companySettings?: CompanySettings) {
    if (!companySettings) return undefined;
    const { id_verification } = companySettings;
    return id_verification;
  }

  private verifyIntellicheck(data: DocumentData) {
    if (!this.isIntellicheck()) return undefined;

    const { processResult } = data.idcheck.data;
    return processResult == 'DocumentProcessOK';
  }

  async verify(scandit: ScannedData): Promise<Result | undefined> {
    if (!this.idVerification) throw new Error('No companies settings');

    let intellicheckResponse: DocumentData | undefined = undefined;
    const { isVerify, pictures } = scandit;

    if (this.isIntellicheck()) {
      intellicheckResponse = await IntelicheckApi.run(pictures);
      if (!intellicheckResponse) throw new Error('Intelicheck Failed');
    }

    if (isVerify == null) throw new Error('Scandit Failed');

    const intelicheckValid = this.verifyIntellicheck(intellicheckResponse!);
    return  { scandit: isVerify, intelicheck: intelicheckValid };
  }
}