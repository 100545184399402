import { Flex } from '@kanvas/andromeda';
import styled from 'styled-components';
import Color from 'theme/colors';
import Modal from 'react-native-modal';
import React, { FC } from 'react';
import MIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { RectButton } from 'atoms/touchable';
import { IS_WEB } from 'domain/shared/constants';
import { Body3, Header2, Header3 } from '../text';

const FormContainer = styled(Flex)`
  backgroundColor: ${Color.WHITE};
  padding: 16px;
  border-radius: 12px;

`;

const CenteredView = styled(Flex)`
  
  justify-content: center;
  align-items: center;
  box-shadow: 0px -4px 16px ${Color.BUTTON_BORDER};
  padding: 20px;
  border-radius: 12px;
`;

const MessageModalContainer = styled(Flex)`
    width: 260px;
    marginHorizontal: 10px;
`;

type BodyType = FC;

interface IProps {
  visible: boolean;
  Body: BodyType;
  titleText?: string;
  close: () => void;
  font?: 'Body3' | 'Header2' | 'Header3';
  showIconTitle?: boolean;
  showCloseButton?: boolean;
}
const Popup = (props: IProps) => {
  const {
    visible, titleText, Body, close, font,
    showIconTitle = true,
    showCloseButton = true,
  } = props;
  return (
    <Modal
      isVisible={visible}
      animationIn="fadeIn"
      style={{ alignItems: 'center' }}
      useNativeDriver
      backdropTransitionOutTiming={0}
      hideModalContentWhileAnimating
      backdropOpacity={0.7}
    >
      {IS_WEB && (
      <CenteredView
        style={{
          padding: 20,
          borderRadius: 12,
          backgroundColor: Color.WHITE,
          minWidth: 250,
          shadowOffset: { height: 0, width: 0 },
          shadowColor: Color.SHADOW,
          shadowOpacity: 1,
          shadowRadius: 16,
        }}
      >
        <FormContainer>
          <Flex row justify="space-between">
            <Flex marginHorizontal={4}>
              {showIconTitle && <MIcon name="comment-alert-outline" color="red" size={24} />}
            </Flex>
            <Flex flex={1}>
              {font === 'Body3' && <Body3 text={titleText} />}
              {font === 'Header2' && <Header2 style={{ paddingBottom: 8 }} text={titleText} />}
              {font === 'Header3' && <Header3 style={{ paddingBottom: 8 }} text={titleText} />}
            </Flex>
            <Flex>
              {
                showCloseButton && (
                  <RectButton onPress={close}>
                    <MIcon name="close" size={24} />
                  </RectButton>
                )
              }
            </Flex>
          </Flex>
          <Flex justify="center" style={{ width: '100%' }}>
            <Body />
          </Flex>
        </FormContainer>
      </CenteredView>
      )}

      {!IS_WEB && (
        <CenteredView>
          <FormContainer>
            <Flex row justify="space-between">
              <MIcon name="comment-alert-outline" color="red" size={24} />
              <MessageModalContainer>
                {font === 'Body3' && <Body3 text={titleText} />}
                {font === 'Header2' && <Header2 style={{ paddingBottom: 8 }} text={titleText} />}
              </MessageModalContainer>
              <RectButton onPress={close}>
                <MIcon name="close" size={24} />
              </RectButton>
            </Flex>

            <Flex>
              <Body />
            </Flex>

          </FormContainer>
        </CenteredView>
      )}
    </Modal>
  );
};

export default Popup;
