import { gql } from '@apollo/client';

export interface Variables {
  channel: string;
  page: number;
  query?: string;
  attributes?: any;
  visitor?: any;
}

export interface Where {
  products: { operator: string, items: string[] };
}

const getProductsArray = (productsIn: string[]) => (
  productsIn.map((p) => `"${p}"`).join(',')
);

function params(variables: Variables) {
  const { attributes, visitor } = variables;
  let current = '$channel: ID!, $page: Int, $query: String';

  if (attributes) current += ', $attributes: Mixed!';
  if (visitor) current += ', $visitor: VisitorEntityInput!';

  return current;
}

function includeInteractions(visitor?: boolean) {
  if (!visitor) return '';

  return `
    interactions (visitor: $visitor) {
      like
      dislike
      save
    }
  `;
}

function whereFilters(where?: Where) {
  if (!where) return '';
  let conditions = '';

  if (where.products) {
    const { operator, items } = where.products;
    conditions += `, where: { column: UUID, operator: ${operator}, value: [${getProductsArray(items)}]}`;
  }

  return conditions;
}

function attributesFilter(attributes?: boolean) {
  if (!attributes) return '';
  return 'attributes: $attributes';
}

function getVariantQuery(variables: Variables, where?: Where) {
  const { attributes, visitor } = variables;

  return gql`
  query allVariantsPublishedInChannelFilterByAttributes(${params(variables)}) {
    channelVariantsFilterByAttributes(
      id: $channel
      first: 25
      page: $page,
      search: $query,
      ${attributesFilter(!!attributes)}
      ${whereFilters(where)}
    ) {
      data {
        id
        uuid
        name
        description
        slug
        sku
        files {
          data {
            url
          }
        }
        product {
          name
        }
        warehouses {
          quantity
        }
        attributes {
          name
          value
        }
        channels {
          discounted_price
          price
          warehouses_id
          is_published
        }
        ${includeInteractions(visitor)}
      }
    }
  }
  `;
}

export default getVariantQuery;
