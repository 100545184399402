// Modules
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Flex, Spacer, useDeviceImage } from '@kanvas/andromeda';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import { TextTransform } from 'react-native-localized-text';

// Atoms
import Avatar from 'atoms/AvatarButton/AvatarButton';
import { EditBadge } from 'atoms/edit-button';
import { TouchableWithoutFeedback } from 'components/atoms/touchable';

// Hooks
import useAuth from 'domain/shared/hooks/useAuth';

// Constants
import { IS_WEB } from 'domain/shared/constants';

// Locales
import { translate } from 'locales';

// Utils
import { showError } from 'domain/shared/utils/toast';
import convertFileToBase64String from 'domain/shared/utils/convertFileToBase64String';

// Store
import { changeUserProfileImage } from 'domain/shared/store/slice/auth';
import { AppDispatch } from 'domain/shared/store';

// Molecules
import { Header3, Body4 } from '../text';

interface IProps {
  editPhoto?: boolean;
}

const options = [
  translate('photoGallery', TextTransform.CAPITAL),
  translate('camera', TextTransform.CAPITAL),
  translate('cancel', TextTransform.CAPITAL),
];

const Float = styled.View`
  top: 20px;
  right: -10px;
  position: absolute;
`;

const Touchable = styled(TouchableWithoutFeedback)`
  ${IS_WEB ? 'cursor: pointer;' : ''};
`;

const HITSLOP_AREA = 30;

const UserInfo = (props: IProps) => {
  // Props
  const { editPhoto } = props;

  // Hooks
  const { getImageFromDevice } = useDeviceImage({
    cropping: true,
    width: 300,
    height: 300,
    forceJpg: true,
    multiple: false,
    mediaType: 'photo',
    includeBase64: true,
  });
  const { user, currentStore } = useAuth();
  const dispatch = useDispatch<AppDispatch>();

  // States
  const [loading, setLoading] = useState(false);

  // Refs
  const fileInput = useRef<any>();

  useEffect(() => {
    setLoading(false);
  }, [user]);

  const handleMobileDeviceImage = useCallback(async () => {
    const image = await getImageFromDevice(options);

    if (image) {
      setLoading(true);
      try {
        if (user && image.data) {
          dispatch(changeUserProfileImage({
            image: image.data,
            userId: user.id,
          }));
        }
      } catch (err: any) {
        showError(err.message);
        setLoading(false);
      }
    }
  }, [getImageFromDevice]);

  const openEditImage = useCallback(async () => {
    if (editPhoto) {
      if (IS_WEB) {
        fileInput?.current?.click();
      } else {
        handleMobileDeviceImage();
      }
    }
  }, []);

  const onImageSelected = useCallback(async (e: any) => {
    const target = e.target as any;
    const file = target.files[0];
    if (file) {
      setLoading(true);
      const base64 = await convertFileToBase64String(file);
      const image = (base64 as string).split(',')[1];
      try {
        if (user) {
          dispatch(changeUserProfileImage({
            image,
            userId: user.id,
          }));
        }
      } catch (err: any) {
        showError(err.message);
        setLoading(false);
      }
    }
  }, [dispatch, changeUserProfileImage, convertFileToBase64String]);

  const EditBadgeContent = useCallback(() => {
    if (!editPhoto) return null;

    return (
      <Float pointerEvents="none">
        <EditBadge />
      </Float>
    );
  }, [editPhoto]);

  const AvatarContent = useCallback(() => (
    <Flex>
      <Avatar size={40} disabled loading={loading} />
      <EditBadgeContent />
    </Flex>
  ), [EditBadgeContent]);

  const AvatarButton = useCallback(() => {
    if (!editPhoto) return <AvatarContent />;

    return (
      <Touchable
        onPress={openEditImage}
        hitSlop={{
          right: HITSLOP_AREA,
          left: HITSLOP_AREA,
          top: HITSLOP_AREA,
          bottom: HITSLOP_AREA,
        }}
      >
        <AvatarContent />
      </Touchable>
    );
  }, [editPhoto]);

  return (
    <Flex row align="center">
      <AvatarButton />
      <Spacer horizontal size={8} />
      <Flex flex={1}>
        <Header3 text={`${user?.firstname} ${user?.lastname}` || ''} />
        <Flex row gap={4}>
          <Body4 text={currentStore?.name || ''} link />
          <Body4 text="•" size={8} />
          <Body4 text={user?.title || ''} />
          {IS_WEB && (
            <input
              type="file"
              onChange={onImageSelected}
              accept="image/*"
              style={{ display: 'none' }}
              ref={fileInput}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default UserInfo;
