import { ProductVariant, ProductVariantMap } from 'domain/shared/types/product-variants';
import { createContext } from 'react';

export interface ProductListContextType {
  multipleSelectEnabled?: boolean;
  selectedItems: ProductVariantMap;
  onItemSelected?: (vehicle: ProductVariant) => void;
  setToolbarItems?: () => void;
  cancelMultiSelect?: () => void;
}

export const ProductListContext = (
  createContext<ProductListContextType>({} as ProductListContextType)
);
