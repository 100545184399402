import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import Colors from 'theme/colors';

function VehicleBrochure(props: any) {
  const { fillColor = Colors.ICON_SECONDARY } = props;
  return (
    <Svg
      width={60}
      height={60}
      data-name="Layer 1"
      viewBox="0 0 58.12 58.12"
      {...props}
    >
      <Path
        d="M38.59 16.15l-16.94-6a6 6 0 00-1.47-.23 4.55 4.55 0 00-1.39.23l-14.39 5a1.8 1.8 0 00-1 1.52v33l16.82-6.17 16.94 6a6 6 0 001.47.23 6.3 6.3 0 001.42-.24l14.36-5a1.79 1.79 0 001-1.52v-33zm-18.38 25.7l-1 .36L5 47.46V16.65l15.22-5.3zM37 47.76l-15.2-5.37V11.9L37 17.28zm1.58.56V17.84l1-.36 14.25-5.27V43z"
        fill={fillColor}
      />
    </Svg>
  );
}

export default VehicleBrochure;
