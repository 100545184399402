import { Flex } from "@kanvas/andromeda";
import styled from "styled-components/native";
import React, { useEffect, useState } from "react";

import { TextInputWithLabel } from "components/molecules/input-with-label";

interface Props {
  config?: any;
  onChange?: (value: string) => void;
}

export default function ShareCode(props: Props) {
  const { onChange, config } = props;
  const lengthLimit = (config?.criteria?.max_length as number) || 4 as const;

  const [value, setValue] = useState('');

  const handleValue = (value: string) => {
    if (value.length > lengthLimit) return;
    setValue?.(value.toUpperCase())
  } 

  useEffect(() => {
    if (value.length < lengthLimit) {
      onChange?.('');
      return;
    }
    
    onChange?.(value);
  }, [value])

  return (
    <Root>
      <Content flex={1}>
        <TextInputWithLabel
          required
          label="code"
          type="input"
          value={value}
          handleChange={handleValue}
        />
      </Content>
    </Root>
  );
}

const Root = styled.SafeAreaView`
  flex: 1;
`;

const Content = styled(Flex)`
  padding: 10px 16px 0px;
`;