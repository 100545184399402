/* eslint-disable max-len */
// Modules
import React, { useEffect, useState, useCallback } from 'react';
import { Alert, SafeAreaView } from 'react-native';
import { Flex } from '@kanvas/andromeda';
import styled from 'styled-components/native';
import {
  FieldArray,
  Formik,
  FormikErrors,
} from 'formik';
import * as Yup from 'yup';
import { TextTransform } from 'react-native-localized-text';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { gql } from '@apollo/client';
import Client from 'domain/shared/graphql/client';

// Molecules
import Header from 'components/molecules/settings-header';
import { FormLabel, InputWithLabel as TextInputWithLabel } from 'components/molecules/input-with-label';

// Atoms
import ButtonWithIcon from 'components/atoms/button-with-icon';

// Locales
import { translate } from 'locales';

// Theme
import colors from 'theme/colors';

// Templates
import Screen from 'components/template/screen';
import PreviewHeader from 'components/molecules/preview-header';
import CountryService from 'domain/shared/services/country-service';
import getPeopleRelationships from 'domain/shared/utils/getPeopleRelationships';
import ParticipantsForm from 'domain/create-opportunity/add/component/participants-form';
import DropDown from 'components/atoms/dropdown-input';

// Hooks
import { useCompanySettings } from 'domain/shared/hooks/useAppSettings';
import { ListItem } from 'domain/create-opportunity/add/component/types';
import { Masks } from 'react-native-mask-input';
import OpportunityService from 'domain/shared/services/opportunity-service';
import { useDispatch } from 'react-redux';
import { setActiveOpportunity } from 'domain/shared/store/slice/opportunity';
import wait from 'domain/shared/utils/wait';

interface IAddCobuyerProps {
  navigation: any;
  route: any;
}

const Container = styled(Flex)`
  background-color: ${colors.WHITE};
`;

const ButtonContainer = styled(Flex)`
  margin-bottom: 80px;
  padding-horizontal: 16px;
  padding-vertical: 16px;
  border-top-width: 1px;
  border-top-color: ${colors.BORDER_COLOR};
`;

const InputContainer = styled(Flex)`
  background-color:${colors.WHITE};
  height: 70px;
  margin-vertical:1px;
`;

const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

const validationSchema = Yup.object().shape({
  cobuyer: Yup.string().trim().required(translate('fieldIsRequired', TextTransform.CAPITAL)),
  mobile: Yup.string().trim().matches(phoneRegExp, translate('invalidPhone', TextTransform.CAPITAL)).required(translate('fieldIsRequired', TextTransform.CAPITAL)),
  email: Yup.string().trim().required(translate('fieldIsRequired', TextTransform.CAPITAL)),
  address: Yup.string().required(translate('fieldIsRequired', TextTransform.CAPITAL)),
  country: Yup.string().trim().required(translate('fieldIsRequired', TextTransform.CAPITAL)),
  state: Yup.string().trim().required(translate('fieldIsRequired', TextTransform.CAPITAL)),
  city: Yup.string().trim().required(translate('fieldIsRequired', TextTransform.CAPITAL)),
});

const CREATE_COBUYER = gql`
  mutation($input: PeopleInput!){
    createPeople(input: $input){
        id,
        uuid,
        name,
        dob
    }
  }
`;

const ADD_COBUYER_TO_LEAD = gql`
  mutation($input: LeadsParticipantsInput!){
    addLeadParticipant(input: $input)
  }
`;

const AddCobuyer = (props: IAddCobuyerProps) => {
  // Props
  const {
    navigation,
    route,
  } = props;

  // Params
  const scanData = route?.params?.scanData;
  const activeSession = route?.params?.activeSession;

  const initialValues = {
    cobuyer: `${scanData?.firstname} ${scanData?.lastname}` || '',
    mobile: '',
    email: '',
    address: scanData?.addressOne || '',
    country: 'USA',
    state: scanData?.state,
    city: scanData?.city || '',
    relationship: { value: '13', label: 'Co-buyer' },
  };

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [relationship, setRelationship] = useState<ListItem[]>([]);

  const dispatch = useDispatch();

  const getRelationship = useCallback(async () => {
    try {
      const list: ListItem[] = (await getPeopleRelationships()).map(({ id = '0', name = '' }) => ({
        value: id,
        label: name,
        key: id,
      }));
      setRelationship(list);
      setValues({ ...values, relationship: list[0] });
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log('Error getting the relantionships', error.messageFound);
    }
  }, []);

  useEffect(() => {
    getRelationship();
  }, []);

  const saveCobuyer = async (data: any) => {
    setIsLoading(true);

    // Split the name to get the first and last name becuase the API requires it
    const firstname = data.cobuyer.split(' ')[0];
    const lastname = data.cobuyer.split(' ')[1];

    const graphData = {
      input: {
        firstname,
        lastname,
        contacts: [
          {
            value: data.email,
            contacts_types_id: 1, // Email Type
            weight: 0,
          },
          {
            value: data.mobile,
            contacts_types_id: 2, // Phone Type
            weight: 0,
          },
        ],
        address: [
          {
            address: data.address,
            city: data.city,
            state: data.state,
          },
        ],
        custom_fields: [],
      },
    };

    try {
      const response = await Client.mutate({ mutation: CREATE_COBUYER, variables: graphData });
      if (response.data.createPeople.id) {
        const leadParticipantData = {
          input: {
            lead_id: activeSession?.id,
            people_id: parseInt(response.data.createPeople.id),
            relationship_id: parseInt(data.relationship.value),
          },
        };

        try {
          await Client.mutate({ mutation: ADD_COBUYER_TO_LEAD, variables: leadParticipantData });
          const lead = await OpportunityService.getById(activeSession!.id);

          dispatch(setActiveOpportunity(lead.data));
          setIsLoading(false);
          
          Alert.alert(translate('success', TextTransform.CAPITAL), translate('cobuyerSuccessMsg'), [
            {
              text: 'OK',
              onPress: () => navigation.popToTop(),
            },
          ]);
        } catch (error) {
          setIsLoading(false);
          console.log('Error adding participant to lead', error);
          Alert.alert(translate('error', TextTransform.CAPITAL), translate('creatingLeadErrorMsg'));
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      Alert.alert(translate('error', TextTransform.CAPITAL), translate('creatingParticipantErrorMsg'));
    }
  };

  return (
    <Container>

      <SafeAreaView>
        <PreviewHeader
          actionName={translate('addCobuyer', TextTransform.CAPITAL)}
          onBack={() => navigation.popToTop()}
        />
      </SafeAreaView>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={saveCobuyer}
      >
        {({
          values, handleChange, handleSubmit, errors, touched, setFieldValue, validateForm,
        }) => (
          <KeyboardAwareScrollView
            contentContainerStyle={{ paddingBottom: getBottomSpace() }}
          >
            <Flex padding={16} gap={20}>
              <TextInputWithLabel
                label={translate('cobuyerName', TextTransform.CAPITAL)}
                placeholder={translate('cobuyerName', TextTransform.CAPITAL)}
                value={values.cobuyer}
                onChangeText={handleChange('cobuyer')}
                errors={touched.cobuyer ? errors.cobuyer : ''}
                showError
              />

              <TextInputWithLabel
                label={translate('mobile', TextTransform.CAPITAL)}
                placeholder={translate('mobile', TextTransform.CAPITAL)}
                value={values.mobile}
                onChangeText={handleChange('mobile')}
                keyboardType="phone-pad"
                errors={touched.mobile ?  errors.mobile : ''}
                showError
                mask={Masks.USA_PHONE}
              />

              <TextInputWithLabel
                label={translate('email', TextTransform.CAPITAL)}
                placeholder={translate('email', TextTransform.CAPITAL)}
                value={values.email}
                autoCapitalize="none"
                onChangeText={handleChange('email')}
                errors={touched.email ?  errors.email : ''}
                showError
              />

              <TextInputWithLabel
                label={translate('address', TextTransform.CAPITAL)}
                placeholder={translate('address', TextTransform.CAPITAL)}
                value={values.address}
                onChangeText={handleChange('address')}
                errors={touched.address ?  errors.address : ''}
                showError
              />

              <TextInputWithLabel
                label={translate('country', TextTransform.CAPITAL)}
                placeholder={translate('country', TextTransform.CAPITAL)}
                value={values.country}
                onChangeText={handleChange('country')}
                errors={touched.country ?  errors.country : ''}
                showError
              />

              <TextInputWithLabel
                label={translate('state', TextTransform.CAPITAL)}
                placeholder={translate('state', TextTransform.CAPITAL)}
                value={values.state}
                onChangeText={handleChange('state')}
                errors={touched.state ?  errors.state : ''}
                showError
              />

              <TextInputWithLabel
                label={translate('city', TextTransform.CAPITAL)}
                placeholder={translate('city', TextTransform.CAPITAL)}
                value={values.city}
                onChangeText={handleChange('city')}
                errors={touched.city ?  errors.city : ''}
                showError
              />

              <InputContainer>
                <FormLabel text={translate('relationship', TextTransform.CAPITAL)} />
                <DropDown
                  validate={errors.relationship}
                  placeHolder={translate('relationship')}
                  value={values.relationship.value}
                  items={relationship}
                  onSelectItem={(item) => setFieldValue('relationship', item)}
                />
              </InputContainer>

              <ButtonContainer>
                <ButtonWithIcon
                  text={translate('save', TextTransform.CAPITAL)}
                  loading={isLoading}
                  onPress={async () => {
                    const errorList = await validateForm();
                    if (errorList) {
                      console.log('errorList', errorList);
                    }
                    handleSubmit();
                  }}
                />
              </ButtonContainer>

            </Flex>
          </KeyboardAwareScrollView>
        )}
      </Formik>
    </Container>
  );
};

export default AddCobuyer;
