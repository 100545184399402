import React from 'react';
import styled from 'styled-components/native';
import { TouchableOpacity } from 'atoms/touchable';
import { Flex } from '@kanvas/andromeda';
import colors from 'theme/colors';
import { Body3 } from 'molecules/text';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import Icon from 'react-native-vector-icons/MaterialIcons';

interface IProps {
  amount?: string;
  onPress?: () => void;
}


const Button = styled(TouchableOpacity)`
  height: 32px;
  min-width: 90px;
  max-width: 120px;
`;

const Container = styled(Flex)`
  border-width: 1px;
  border-color: ${colors.BORDER_COLOR};
  border-radius: 16px;
  shadow-color: ${colors.SHADOW};
  shadow-offset: 0px -4px;
  shadow-radius: 16px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${colors.WHITE};
`;

const Dot = styled.Text`
  background-color: ${colors.ORANGE};
  color: ${colors.WHITE};
  padding: 1px 6px;
`;

const FilterButton = ({ onPress, amount }: IProps) => (
  <Button onPress={onPress}>
    <Container gap={4} row>
      {/* <Image source={FilterIcon} /> */}
      <Icon name="tune" size={20} />

      <Body3 text={translate('filter', TextTransform.CAPITAL)} />

      {Number(amount) > 0 && (
        <Flex style={{ borderRadius: 50, overflow: 'hidden' }}>
          <Dot>{amount}</Dot>
        </Flex>
      )}
    </Container>
  </Button>
);

export default FilterButton;
