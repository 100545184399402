import { IStore } from 'domain/shared/types/store';
import axios from './axios';

export default class CompanyBranchService {
  static async get(): Promise<IStore[]> {
    const { data } = await axios.get<IStore[]>('/companies-branches');
    return data;
  }

  public static async getCompanyBranchById(branchId: number) {
    const { data } = await axios.get<IStore>(`/companies-branches/${branchId}`);
    return data;
  }
}
