import styled from 'styled-components/native';
import React from 'react';

const Container = styled.View`
  margin-horizontal: 24px;
  margin-vertical: 16px;
`;

const Title = styled.Text<any>`
  font-size: 18px;
  color: black;
`;

const Text = styled.Text<{ bold?: boolean }>`
  font-size: 12px;
  color: black;
  ${({ bold = false }) => (bold ? 'font-weight: bold' : '')}
`;

const TextLine = styled.View`
  flex-direction: row;
  margin-top: 4px;
`;

const Cont = styled.View`
  flex: 1;
`;

const ColumnContainer = styled.View`
  flex-direction: column;
  width: 50%;
`;

const Body = styled.View`
  flex-direction: row;
  margin-top: 12px;
  width: 100%;
`;

const Info = ({
  engine, seats, transmission, exteriorColor, interiorColor, fuelType,
}:any) => {
  const getValue = (value:any) => {
    if (value) {
      return value;
    }
    return 'N/A';
  };
  return (
    <Container>
      <Title>SNAPSHOT</Title>
      <Body>
        <ColumnContainer>
          <TextLine>
            <Cont>
              <Text>Engine</Text>
              <Text bold>{getValue(engine)}</Text>
            </Cont>
          </TextLine>
          <TextLine>
            <Cont>
              <Text>Seats</Text>
              <Text bold>{getValue(seats)}</Text>
            </Cont>
          </TextLine>
          <TextLine>
            <Cont>
              <Text>Exterior Color</Text>
              <Text bold>{getValue(exteriorColor)}</Text>
            </Cont>
          </TextLine>
        </ColumnContainer>
        <ColumnContainer>
          <TextLine>
            <Cont>
              <Text>MPG / Fuel Type</Text>
              <Text bold>{getValue(fuelType)}</Text>
            </Cont>
          </TextLine>
          <TextLine>
            <Cont>
              <Text>Transmission</Text>
              <Text bold>{getValue(transmission)}</Text>
            </Cont>
          </TextLine>
          <TextLine>
            <Cont>
              <Text>Interior Color</Text>
              <Text bold>{getValue(interiorColor)}</Text>
            </Cont>
          </TextLine>
        </ColumnContainer>
      </Body>
    </Container>
  );
};

export default Info;
