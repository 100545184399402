import React from 'react';
import styled from 'styled-components/native';
import Modal from 'react-native-modal';
import { View } from 'react-native';
import Colors from 'theme/colors';
import Icon from 'react-native-vector-icons/Ionicons';
import { Header2 } from 'molecules/text';
import { Flex } from '@kanvas/andromeda';
import IconButton from 'atoms/icon-button';
import ModalMessage from 'components/molecules/modal-message';

const Container = styled.View`
  justify-content: flex-end;
  /* flex: 1px; */
`;

const Content = styled.View`
  background-color: ${Colors.WHITE};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Header = styled.View`
  align-items: center;
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 20px;
  padding-horizontal: 16px;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.WHITE};
`;

const Body = styled.View`
  margin-top: 0px;
`;

const ToastContainer = styled.View`
  width: 100%;
  padding: 16px;
`;

const IconsContainer = styled(Flex)`
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const CloseComponent = ({ onPressClose }: any) => (
  <IconButton
    onPress={onPressClose}
    icon={<Icon style={{ color: Colors.ORANGE }} size={22} name="ios-close" />}
  />
);

const Title = ({ titleText }: any) => (
  <View style={{ width: 300, alignItems: 'flex-start' }}>
    <Header2
      numberOfLines={1}
      ellipsizeMode="tail"
      text={titleText}
    />
  </View>
);

const ModalContainer = ({
  isVisible = false,
  children,
  onPressClose,
  titleText,
  showMsg,
  header = true,
  toastMessage = '',
  icon,
  onMsgClose,
  customText,
  customHeaderIcon = null,
}: any) => (
  <Modal
    hasBackdrop
    isVisible={isVisible}
    animationIn="slideInUp"
    animationOut="slideOutDown"
    onBackdropPress={() => onPressClose()}
    onBackButtonPress={() => onPressClose()}
    style={{ margin: 0, justifyContent: 'flex-end' }}
    avoidKeyboard
  >
    <Container>

      <ToastContainer>
        <ModalMessage
          label={toastMessage}
          Icon={icon}
          show={showMsg}
          customText={customText}
          onHide={() => onMsgClose(!showMsg)}
        />
      </ToastContainer>

      <Content>
        {header && (
          <Header>
            {
              titleText && <Title titleText={titleText} />
            }
            <Flex flex={1} align="flex-end">
              <IconsContainer>
                {customHeaderIcon}

                <CloseComponent onPressClose={() => onPressClose()} />
              </IconsContainer>
            </Flex>
          </Header>
        )}
        <Body>
          {children}
        </Body>
      </Content>
    </Container>
  </Modal>
);

export default ModalContainer;
