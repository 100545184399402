import { useNavigation } from '@react-navigation/native';
import FastImage from 'react-native-fast-image';
import { TouchableOpacity } from 'atoms/touchable';
import React, { useCallback } from 'react';
import styled from 'styled-components/native';
import Colors from 'theme/colors';
import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';
import { SETTIGNS } from 'domain/shared/types/amplitude-event';
import { OPEN_PROFILE } from 'domain/shared/types/amplitude-actions';
import useAuth from 'domain/shared/hooks/useAuth';
import { BUTTON_HIT_SLOP } from 'domain/shared/constants';

interface IProps {
  photoProfile?: any;
  size?: number;
  disabled?: boolean;
  loading?: boolean;
}

type SIZE = Pick<IProps, 'size'>;

const DEFAULT_SIZE = 40;

const ThumbnailStyled = styled(FastImage) <SIZE>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => (props.size || DEFAULT_SIZE) / 2}px;
  background-color: ${Colors.BORDER_COLOR};
`;

const Spinner = styled.ActivityIndicator<{ circleSize: number }>`
  position: absolute;
  align-self: center;
  z-index: 99;
  background-color: ${Colors.SHADOW};
  width: ${(props) => props.circleSize}px;
  height: ${(props) => props.circleSize}px;
  border-radius: ${(props) => (props.circleSize || DEFAULT_SIZE) / 2}px;
`;

const AvatarButton = (props: IProps) => {
  const {
    size = DEFAULT_SIZE, disabled = false, loading = false,
  } = props;

  const navigation = useNavigation() as any;
  const { user } = useAuth();

  const gotoSettings = useCallback(() => {
    navigation.openDrawer();
    sendAmplitudeEvent(OPEN_PROFILE, {
      event: SETTIGNS,
    });
  }, [navigation]);

  return (
    <TouchableOpacity hitSlop={BUTTON_HIT_SLOP} onPress={gotoSettings} disabled={disabled}>
      {
        loading ? (
          <Spinner
            size="small"
            color={Colors.WHITE}
            circleSize={size}
          />
        ) : (
          null
        )
      }

      <ThumbnailStyled size={size} source={{ uri: user?.photo?.url }} />
    </TouchableOpacity>
  );
};

export default AvatarButton;
