import { gql } from '@apollo/client';

const getProducts = gql`
query getProducts($search: String) {
  products(variantAttributeValue: $search) {
      data {
          id,
          name,
          variants {
            id
            uuid
            name
            slug
            files {
              data {
                url
              }
            }
            attributes {
              name
              value
            }
            channels {
              price
            }
          }
      }
  }  
}
`;

export default getProducts;
