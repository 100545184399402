import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { Flex, Spacer } from '@kanvas/andromeda';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { TextTransform } from 'react-native-localized-text';
import { ActivityIndicator, FlatList, Linking, ListRenderItemInfo, Modal } from 'react-native';

// model
import Task, { OptionDoc, Status, Type } from 'model/data/task';
import TaskPresenter from 'model/presenters/task.presenter';
import { TaskList } from 'model/api/task-list/queries';
import TaskListApi from 'model/api/task-list/task-list.api';

// Constants
import colors from 'theme/colors';
import { translate } from 'locales';
import { ACTION_ENGINE_FILE_VIEWER } from 'env';

// Domain
import wait from 'domain/shared/utils/wait';
import { RootState } from 'domain/shared/store';
import { CONTENT_PREVIEW } from 'domain/shared/screens';
import { LeadAction } from 'domain/shared/types/lead-action';
import { File as FSFile } from 'domain/shared/types/file.interface';
import { IS_CHROME_EXTENSION, IS_DESKTOP_WEB, IS_MOBILE, IS_MOBILE_WEB, IS_WEB } from 'domain/shared/constants';
import OpportunityService from 'domain/shared/services/opportunity-service';
import { setActiveOpportunity } from 'domain/shared/store/slice/opportunity';
import { OpenInAppModalBrowser } from 'domain/shared/utils/openInAppBrowser';
import CRMExtensionService from 'domain/shared/services/crm-extension-service';
import usePickFile from 'domain/opportunity-wall/screens/content-preview/hooks/use-pick-file';
import { showSuccess } from 'domain/shared/utils/toast';
import CustomFieldsApi from 'domain/shared/services/custom-fields';

// Components
import ButtonWithIcon from 'components/atoms/button-with-icon';
import { Body3, Meta2 } from 'components/molecules/text';
import TaskItem from 'components/molecules/task-item';
import TaskButton from 'components/molecules/task-button';
import ChecklistModal from 'components/molecules/checklist-modal';
import ChecklistButton from 'components/atoms/checklist-button';
import ChangeChecklistModal from 'components/molecules/change-checklist-modal';

// Bad Practice Components
import UploadLeadFiles, { UploadLeadFilesRef } from '../upload-lead-files';
import Spinner from 'components/atoms/spinner';
import ChecklistComplete, { playChecklistComplete } from 'components/atoms/lottie/checklist-complete';
import checklistCompleted from 'model/use-cases/checklist-completed';

interface Props {
  leadId: string;
}

function useWallCheckList(props: Props) {
  const [loading, setLoading] = useState(true);
  const [loadedChecklist, setLoadedChecklist] = useState(false);

  const [changeChecklistModalOpen, setChangeChecklistModalOpen] = useState(false);

  const [item, setItem] = useState<Task | null>(null);
  const [items, setItems] = useState<Task[]>([]);
  
  const [optional, setOptional] = useState(false);
  const [shareable, setShareable] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [formSheet, setFormSheet] = useState<LeadAction[]>([]);
  const [optionsDocs, setOptionsDocs] = useState<OptionDoc[]>([]);

  const [activeTaskList, setActiveTaskList] = useState<{ mode: string, activeTaskListId: number }>();
  const [taskLists, setTaskLists] = useState<TaskList[]>([]);

  const { activeOpportunity } = useSelector((state: RootState) => state.opportunity);

  const id = activeOpportunity?.id;

  const presenter = useMemo(() => new TaskPresenter({
    get: (items) => {
      // i don't get it but it does refresh the data like this if the subscription refresh it
      setItems([]);
      setItems([...items]);

      if (checklistCompleted(items)) playChecklistComplete();

      setLoading(false);
    },
    error: () => {
      setLoading(false);
    },
  }), [])

  const activeChecklistName = useMemo(() => taskLists?.find(list => list.id.toString() === activeTaskList?.activeTaskListId.toString())?.name, [activeTaskList, taskLists]);

  const vehicleType = useMemo(() => {
    if (!activeOpportunity) return null;

    const { vehicle_of_interest = null } = activeOpportunity?.custom_fields as Record<string, any> || {} as Record<string, any>;
    if (!vehicle_of_interest) return undefined;

    const { isNew } = vehicle_of_interest;

    return isNew ? 'New' : 'Used';
  }, [activeOpportunity]);

  const changeChecklistMessage = useMemo(() => {
    let message = translate('checklist.changeChecklistDefaultMessage');

    if (activeTaskList?.mode === "automatic" && vehicleType) {
      message = `${translate('checklist.primaryVehicleCheckMessage', undefined, {
        interpolate: {
          vehicleType: vehicleType
        }
      })} ${translate('checklist.changeChecklistDefaultMessage')}`
    }

    return message;
  }, [vehicleType, activeTaskList]);

  const uploadRef = useRef<UploadLeadFilesRef>(null);

  const { handleUploadFilePromise } = usePickFile();

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const changeActiveChecklist = async (taskListId: number) => {
    setLoading(true);
    setChangeChecklistModalOpen(false);

    if (!id) return setLoading(false);

    try {
      await CustomFieldsApi.set({ entity_id: activeOpportunity!.uuid, name: 'check_list_status', data: { mode: "manual", activeTaskListId: taskListId } });

      await fetchActiveTaskList(taskLists);

      presenter.fetch(id, taskListId)
    } catch(err) {
      console.log(err)
    }
  }

  const fetchTaskLists = async () => {
    try {
      setLoadedChecklist(false);
      
      const taskListResponse = await TaskListApi.get()

      setTaskLists(taskListResponse ?? [])

      await fetchActiveTaskList(taskListResponse);
    } catch(err) {
      console.log(err)
    }
  }

  const fetchActiveTaskList = async (taskListsResponse: TaskList[]) => {
    if (!activeOpportunity) return;

    try {
      const { customFields } = await CustomFieldsApi.get({ entity_id: activeOpportunity.uuid, name: 'check_list_status' });

      if (customFields.check_list_status) {
        setActiveTaskList(customFields.check_list_status)
        return;
      }

      // Default checklist
      setActiveTaskList({ mode: "automatic", activeTaskListId: taskListsResponse[0]?.id })
    } catch(err) {
      console.log(err)
    } finally {
      setLoadedChecklist(true);
    }
  }

  const toggleChecklistModalOpen = () => setChangeChecklistModalOpen(!changeChecklistModalOpen);

  const openPreview = (item: Task, action?: LeadAction) => {
    setFormSheet([]);

    const { 
      slug,
      name,
      id = 0,
      description,
      form_config,
    } = action || item.config.action;

    const props = {
      id,
      title: name,
      description,
      form_config,
      key: slug,
      contact: activeOpportunity,
      selected: item.config.item,
      should_open_in_browser: false,
      multiSelect: item.type === Type.LIST,
    }

    // @ts-ignore
    navigation.navigate(CONTENT_PREVIEW, { props });
  }

  const handleForm = (item: Task) => {
    if (!item.config.options) {
      openPreview(item);
      return;
    }
    
    setFormSheet(item.config.options);
  };

  const handleCloseForm = () => {
    setFormSheet([]);
    setOptionsDocs([]);
  };

  const reloadScreen = () => {
    setLoading(true);
    presenter.fetch(activeOpportunity!.id, activeTaskList?.activeTaskListId);
  }

  // TODO: can be refactor
  const openFileViewer = (leadUuid: string, messageUuid: string, title?: string) => {
    const url = `${ACTION_ENGINE_FILE_VIEWER}?lid=${leadUuid}&mid=${messageUuid}`;

    if (IS_MOBILE_WEB || IS_MOBILE) {
      OpenInAppModalBrowser(url);
      return;
    }

    if (IS_CHROME_EXTENSION) {
      CRMExtensionService.openFileViewer(url, title || '');
      return;
    }


    if (IS_DESKTOP_WEB) {
      Linking.openURL(url);
      return;
    }
  }

  // TODO: it need to be refactor in the future
  const getFileFromWeb = async (item: Task) => {
    try {
      if (!activeOpportunity) return;
      
      const { files = [] } = activeOpportunity;
      
      const name = item!.title.split('').map((item, index) => index === 0 ? item.toUpperCase() : item).join('').split(' ').join('_');
      const sequence = files.filter((file: FSFile) => file.field_name?.includes(name)).length + 1;
      const completeName = `${name}_${sequence.toString().padStart(3, '0')}`;

      const file = await handleUploadFilePromise();
      setFileUpload(true);
      const extension = file.name.split('.').slice(-1);
      const [response] = await OpportunityService.uploadPhoto(file, completeName, `${completeName}.${extension}`);

      const { id, title } = activeOpportunity;
      const newFiles = [...files, { field_name: completeName, filesystem_id: response.id, id: 0, title }] as any;
      const opp = await OpportunityService.associateFileToOpp(newFiles, id, title, item.id);

      dispatch(setActiveOpportunity(opp));
      reloadScreen();

      setFileUpload(false);
      await wait(400);
      showSuccess(`${completeName} ${translate('uploadMessage')}`);
    } catch (err: any) {
      console.log(err);
    }
  }

  const handleCamera = async (item: Task) => {
    await wait(200);

    if (IS_WEB) {
      getFileFromWeb(item);
      return;
    }

    uploadRef.current?.openCamera();
  }

  const executeStrategy = (item: Task) => {
    const strategy = {
      [Type.LIST.toString()]: () => setShareable(true),
      [Type.FORM.toString()]: () => handleForm(item),
      [Type.CAMERA.toString()]: () => handleCamera(item),
      default: () => openPreview(item),
    }

    const execute = strategy[item.type.toString()] || strategy.default;

    execute();
  }

  const handleOptionDoc = (option: OptionDoc) => {
    if (!item) return;

    setOptionsDocs([]);
    const { id } = option;
    const itemTask = {
      ...item,
      config: {
        ...item.config,
        item: id.toString(),
      }
    }
    
    openPreview(itemTask)
  }

  const handleItemPress = (item: Task) => {
    setItem(item);
    const { optional, direct, screen, messageUuid, optionsDocs } = item.config;

    if (item.status === Status.COMPLETED && messageUuid) {
      openFileViewer(activeOpportunity!.uuid, messageUuid, item.title);
      return;
    }

    if (!!optionsDocs) {
      setOptionsDocs(optionsDocs);
      return;
    }

    if (optional) {
      setOptional(true);
      return;
    }

    if (direct) {
      openPreview(item);
      return;
    }

    if (screen) {
      // @ts-ignore
      navigation.navigate(screen, { action: item.config.action, onGoBack: async () => {
        setLoading(true);
        await wait(400);
        reloadScreen();
      } });
      return;
    }

    executeStrategy(item);
  };

  const handleAction = (action: 'remote' | 'upload') => {
    const strategy = {
      'remote': () => {
        if (!item) return;
        openPreview(item)
      },
      'upload': async () => await handleCamera(item!),
    }

    setShareable(false);
    strategy[action]?.();
  };

  const handleFileSelected = () => {
    // Making the app a bit slower just for better EX
    wait(400).then(() => reloadScreen())
  };

  const handleOptional = async (yes?: boolean) => {
    setOptional(false);
    if (!item) return;
    if (!yes) {
      setLoading(true);
      presenter.changeStatusToNoApplicable(activeOpportunity!.id, item.id, activeTaskList?.activeTaskListId);
      return;
    }

    const { screen, optional, direct } = item.config;

    if (screen) {
      // @ts-ignore
      navigation.navigate(screen, { action: item.config.action, onGoBack: async () => {
        setLoading(true);
        await wait(400);
        reloadScreen();
      }  });
      return;
    }

    if (optional && !direct) {
      wait(400).then(() => executeStrategy(item))
      return;
    }

    openPreview(item);
  };

  const closeModal = () => {
    setItem(null);
    setShareable(false);
    setOptional(false);
  };

  useFocusEffect(
    useCallback(() => {
      fetchTaskLists()
    }, [])
  )

  useFocusEffect(
    useCallback(() => {
      if (!id || (!activeTaskList && taskLists.length <= 0) || !loadedChecklist) return;

      presenter.fetch(id, activeTaskList?.activeTaskListId)
      const unsubscribe = presenter.connect(id, activeTaskList?.activeTaskListId);

      return () => {
        setLoading(true);
        setItems([]);
        unsubscribe();
      }
    }, [id, activeTaskList, taskLists, loadedChecklist])
  );

  return {
    model: {
      item,
      items,
      loading,
      optional,
      shareable,
      uploadRef,
      activeOpportunity,
      formSheet,
      fileUpload,
      optionsDocs,
      changeChecklistModalOpen,
      activeChecklistName,
      activeTaskList,
      taskLists,
      changeChecklistMessage,
    },
    operations: {
      closeModal,
      handleItemPress,
      handleOptional,
      handleAction,
      handleFileSelected,
      reloadScreen,
      handleCloseForm,
      openPreview,
      handleOptionDoc,
      toggleChecklistModalOpen,
      changeActiveChecklist
    }
  };
}

export default function WallCheckList(props: Props) {
  const { model, operations } = useWallCheckList(props);

  const { item, items, optional, shareable, uploadRef, activeOpportunity, loading, formSheet, fileUpload, optionsDocs, changeChecklistModalOpen, activeTaskList, activeChecklistName, taskLists, changeChecklistMessage } = model;
  const { handleItemPress, closeModal, handleOptional, handleAction, handleFileSelected, reloadScreen, handleCloseForm, openPreview, handleOptionDoc, toggleChecklistModalOpen, changeActiveChecklist } = operations;
  
  const upload = translate('checklist.shareable.upload');
  const remote = translate('checklist.shareable.remote', TextTransform.NONE, { interpolate: { action: item?.config.action.name } });
  const optionalMessage = item?.config.slug === 'co-signer' ? 'checklist.co-signer.description' : 'checklist.trade-in.description';

  const renderItem = useCallback((info: ListRenderItemInfo<Task>) => (
    <TaskItem 
      info={info}
      TaskButton={<TaskButton info={info} onPress={handleItemPress} />}
    />
  ), [items]);

  if (loading) {
    return (
      <Root>
        <Flex flex={1} align='center' justify='center'>
          <ActivityIndicator color={colors.ORANGE} size="large" />
        </Flex>
      </Root>
    )
  }

  return (
    <Root>
      <ChecklistComplete />
      
      {!!activeChecklistName && taskLists && taskLists.length > 1 ? (
        <Flex paddingHorizontal={16} style={{ paddingTop: 16 }}>
          <ChecklistButton
            text={activeChecklistName}
            style={{ maxWidth: IS_DESKTOP_WEB && !IS_CHROME_EXTENSION && !IS_MOBILE_WEB ? 328 : '100%' }}
            onPress={toggleChecklistModalOpen}
          />
        </Flex>
      ) : null}

      <FlatList
        data={items}
        refreshing={false}
        renderItem={renderItem}
        onRefresh={reloadScreen}
        ItemSeparatorComponent={() => <Spacer size={10} />}
        contentContainerStyle={{ paddingTop: 16, paddingBottom: 60 }}
        keyExtractor={(item, i) => item.id.toString() + i.toString()}
      />

      <ChecklistModal visible={shareable} onClose={closeModal}>
        <Button text={upload} onPress={() => handleAction?.('upload')} />
        <Button text={remote} onPress={() => handleAction?.('remote')} />
      </ChecklistModal>

      <ChecklistModal visible={optional} onClose={closeModal}>
        <Body3 link text={item?.config?.optionalMessage ?? translate(optionalMessage)} />

        <Button text={translate('yes')} onPress={() => handleOptional?.(true)} />
        <Button text={translate('no')} onPress={() => handleOptional?.(false)} />
      </ChecklistModal>

      <ChecklistModal visible={optionsDocs.length > 0} onClose={handleCloseForm}>
        {
          optionsDocs.map((option) => {
            const { name, id } = option;
            return <Button key={`doc-${id}`} text={name} onPress={() => handleOptionDoc(option)} />;
          })
        }
      </ChecklistModal>

      <ChecklistModal visible={formSheet.length > 0} onClose={handleCloseForm}>
        {
          formSheet.map((action) => {
            const { name, id } = action;
            return <Button key={`subaction-${id}`} text={name} onPress={() => openPreview(item!, action)} />;
          })
        }
      </ChecklistModal>

      <UploadLeadFiles
        closeOnCapture
        ref={uploadRef}
        taskId={item?.id}
        onClose={handleFileSelected}
        opportunity={activeOpportunity!}
        defaultOption={item?.config?.item}
      />

      <ChangeChecklistModal
        visible={changeChecklistModalOpen}
        onClose={toggleChecklistModalOpen}
        changeCheckList={changeActiveChecklist}
        message={changeChecklistMessage}
        taskLists={taskLists.filter(list => list.id.toString() !== activeTaskList?.activeTaskListId.toString())}
      />

      <Modal transparent visible={fileUpload} animationType='fade'>
        <SpinnerContainer>
          <Spinner size="small" color={colors.WHITE} />
          <Meta2 text={translate('uploading')} color={colors.WHITE} />
        </SpinnerContainer>
      </Modal>
    </Root>
  );
};

const Root = styled(Flex)`
  flex: 1;
  background-color: ${colors.WHITE};
`;

const SpinnerContainer = styled(Flex)
  .attrs((props) => (
    {
      ...props,
      gap: 8,
      flex: 1,
      row: true,
      align: 'center',
      justify: 'center',
    }
  ))`
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  `;

const Button = styled(ButtonWithIcon)
  .attrs((props) => (
    { 
      ...props,
      type: 'button1',
      color: colors.WHITE,
      textColor: colors.TEXT_ORANGE_LINK 
    }
  ))``;