import styled from 'styled-components';
import Colors from 'theme/colors';
import MainStyle from './styles';

const MainContainer = styled.div<any>`
    ${MainStyle}
    display: flex;
    flex-direction: column;
    border: 1px solid ${Colors.BORDER_COLOR};
    box-shadow: 0px 4px 16px ${Colors.BORDER_COLOR};
    border-radius: 8px;
    background-color: ${({ id, leadId }) => (id === leadId ? Colors.PRIMARY_DEEMPH : Colors.WHITE)};
    &:hover {
        background-color: ${Colors.PRIMARY_DEEMPH};
        cursor: pointer;
    }
`;

export default MainContainer;
