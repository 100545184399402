import React, { useEffect, useRef, useState } from "react";
import LottieView from 'lottie-react-native';
import styled from "styled-components/native";
import { EventRegister } from "react-native-event-listeners";
import withTimeout from "domain/shared/hooks/with-timeout";

const animation = require('./animation.json');

const PLAY_EVENT = 'checklist-complete.PLAY_EVENT';

export function playChecklistComplete() {
  withTimeout(() => {
    EventRegister.emitEvent(PLAY_EVENT, undefined);
  }, 200)
}

export default function ChecklistComplete() {
  const [zindex, setZindex] = useState(0);
  const lottie = useRef<LottieView>(null);
  
  function handleAnimationFinish() {
    setZindex(0);
  }

  useEffect(() => {
    if (!lottie.current) return () => {};
    const event = EventRegister.on(PLAY_EVENT, () => {
      setZindex(9999);
      lottie.current?.play();
    });

    return () => {
      EventRegister.removeEventListener(event.toString())
    }
  }, [lottie.current]);

  return (
    <Lottie 
      loop={false}
      ref={lottie}
      source={animation}
      style={{ zIndex: zindex }}
      onAnimationFinish={handleAnimationFinish} 
    />
  ); 
};

const Lottie = styled(LottieView)`
  width: 100%;
  height: 100%;
  position: absolute;
`;