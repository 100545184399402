/* eslint-disable react/no-unstable-nested-components */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { ListRenderItemInfo, StyleSheet } from 'react-native';
import Engagement from 'components/molecules/engagement';
import { Message } from 'domain/shared/types/message';
import { Flex, Spacer } from '@kanvas/andromeda';
import EngagementTypesService from 'domain/shared/services/engegament-types-service';
import { EngagementType } from 'domain/shared/types/engagement-types';
import { ActivityListContextProvider } from 'components/context/activity-list-context';
import PaginatedFlatList, { PaginatedFlatListHandler } from 'organisms/paginated-flatlist';
import MessagesService from 'domain/shared/services/messages-service';
import Spinner from 'atoms/spinner';
import colors from 'theme/colors';
import { EventRegister } from 'react-native-event-listeners';
import { ActivityListEvents } from 'domain/shared/events/activities-list';

const styles = StyleSheet.create({
  ListFooterComponentStyle: { paddingVertical: 16 },
  contentContainerStyle: { paddingBottom: 50, paddingTop: 16 },
  container: { backgroundColor: colors.WHITE, flex: 1 },
});

interface IProps {
  leadId: string;
}

const ActivityList = (props: IProps) => {
  const { leadId } = props;
  const [loading, setLoading] = useState(false);
  const listRef = useRef<PaginatedFlatListHandler>(null);
  const reloadEventHandler = useRef('');

  const [engagementTypes, setEngagementTypes] = useState<EngagementType[]>([]);
  const renderItem = useCallback((e: ListRenderItemInfo<Message>) => {
    const { item } = e;
    const { message_types } = item;
    if (message_types.verb !== 'lead') {
      return (
        <Engagement message={item} leadId={leadId} linkPreview={item.message.link} />
      );
    }
    return null;
  }, [leadId]);

  const keyExtractor = useCallback((message: Message) => `${message.id.toString()}-${message.uuid}`, []);

  const fetchData = useCallback(async (pageNumber: number) => {
    if (pageNumber > 1) {
      setLoading(true);
    }
    const messages = await MessagesService.getAllMessagesPaginated(leadId, pageNumber);
    setLoading(false);
    return messages;
  }, [leadId]);

  useEffect(() => {
    EngagementTypesService.getAllEngagementTypes().then((response) => {
      setEngagementTypes(response);
    });

    reloadEventHandler.current = EventRegister.on(ActivityListEvents.ACTIVITY_LIST_RELOAD, () => {
      setTimeout(() => {
        listRef.current?.refreshData();
      }, 1000);
    }).toString();

    return () => {
      EventRegister.rm(reloadEventHandler.current);
    };
  }, []);
  return (
    <ActivityListContextProvider
      value={{
        engagementTypes,
      }}
    >
      <Flex paddingHorizontal={16} style={styles.container}>
        <PaginatedFlatList
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          ItemSeparatorComponent={() => <Spacer size={6} />}
          fetchData={fetchData}
          showsVerticalScrollIndicator={false}
          ListFooterComponent={loading ? <Spinner /> : null}
          ListFooterComponentStyle={styles.ListFooterComponentStyle}
          contentContainerStyle={styles.contentContainerStyle}
        />
      </Flex>
    </ActivityListContextProvider>
  );
};

export default ActivityList;
