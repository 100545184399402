import { Flex, Spacer } from '@kanvas/andromeda';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  SafeAreaView, useWindowDimensions,
} from 'react-native';

import Colors from 'theme/colors';
import styled from 'styled-components/native';
import { IS_WEB } from 'domain/shared/constants';
import { TabView } from 'react-native-tab-view';
import ButtonList, { RenderItemFn } from 'components/organisms/button-list';
import {
  Body3, Button2, Header2, Meta1,
} from 'components/molecules/text';
import { CXTabBarContext } from 'components/organisms/CXTabBar/cx-tab-bar-context';
import OpportunityListMatch from 'components/molecules/opportunity-list-match';
import { useRoute } from '@react-navigation/native';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import PreviewHeader from 'components/molecules/preview-header';
import TabNavigationScreens from 'domain/shared/navigation/tab-nav-screens';
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import { navigateToStack } from 'domain/shared/services/navigation-service';
import { ACTIVE_SESSION } from 'domain/shared/screens';
import PosibleMatchSearch from 'components/organisms/posible-match-search';
import { RectButton, TouchableOpacity, TouchableWithoutFeedback } from 'components/atoms/touchable';
import Icon from 'react-native-vector-icons/MaterialIcons';
import Popup from 'components/molecules/popup';
import MIcon from 'react-native-vector-icons/MaterialCommunityIcons';

const SaveAreaContainer = styled(SafeAreaView)`
  background-color:${Colors.ORANGE};
`;

enum SCREENS {
  MATCHES = 'MATCHES',
  SEARCH = 'SEARCH',
}
const ButtonListContainer = styled(Flex)<any>`
  ${({ action }) => (action ? 'marginTop: 20px;' : 'marginTop: 0px;')};
  marginBottom: 6px;
`;

const ShowClosedOpp = styled(Flex)`
  width: 328px;
  padding-horizontal: 10px;
  padding-vertical: 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  margin-bottom: 20px;
  border: ${Colors.BORDER_COLOR};
  background-color: ${Colors.PRIMARY_DEEMPH};
  cursor: pointer;
`;

const Sheet = styled(Flex)`
  flex: 1;
  width: 100%;
  height: 100%;
`;

const Button = styled(TouchableOpacity) <any>`
  height: 32px;
  border-radius: 16px;
  padding-vertical: 6px;
  padding-horizontal: 12px;
  cursor:pointer;
  ${({ selected }) => (selected ? `
    background-color: ${Colors.ORANGE};
    shadow-opacity: 0.20;
    shadow-color: ${Colors.BLACK};
    shadow-offset: 0px 3px;
    elevation: 6;
    ${IS_WEB
      ? `box-shadow: 0px 3px 5px ${Colors.SHADOW}` : ''};`
    : `background-color: ${Colors.BORDER_COLOR};`
  )};
`;

const BottonList = [
  {
    key: '0', value: 0, text: `${translate('smartRecomendation')}`,
  },
  {
    key: '1', value: 1, text: `${translate('search')}`,
  },
];

const ShowNotificationClosedOpp = ({ handle }:any) => (
  <ShowClosedOpp>
    <TouchableWithoutFeedback onPress={() => handle(true)}>
      <Flex row justify="space-between">
        <Icon name="contact-page" size={24} color={Colors.ORANGE} style={{ marginRight: 4 }} />
        <Meta1 text="Can’t find what your looking for? Visit closed opportunities." size={14} style={{ marginLeft: 8 }} />
        <Button2 text="View" color={Colors.ORANGE} />
      </Flex>
    </TouchableWithoutFeedback>
  </ShowClosedOpp>
);

const PosibleMaches = () => {
  const [index, setIndex] = useState<number>(0);
  const layout = useWindowDimensions();
  const routeParams = useRoute() as any;
  const [showModal, setShowModal] = useState<boolean>(false);
  const {
    args, items = [], FromOppWall = false, action,
    itemsClosed = [],
  } = routeParams.params;

  const { setTabBarVisible } = useContext(CXTabBarContext);
  const [routes] = useState([
    {
      key: SCREENS.MATCHES, title: `${translate('smartRecomendation')}`, text: `${translate('smartRecomendation')}`, index: 0,
    },
    {
      key: SCREENS.SEARCH, title: `${translate('search')}`, text: `${translate('search')}`, index: 1,
    },
  ]);

  useEffect(() => {
    const firstMatch = items[0];
    if (firstMatch) {
      const { data: { rank = 0 } = {} } = firstMatch;
      const porcentage = Math.round(rank * 100);
      if (porcentage < 65 && itemsClosed.length > 0) {
        setShowModal(true);
      }
      setTabBarVisible(true);
    }
  }, []);

  const onIndexChange = (itemSelecte: number) => {
    setIndex(itemSelecte);
  };

  const goBack = () => {
    if (FromOppWall) {
      navigateToStack(OpportunityScreens.WALL, ACTIVE_SESSION);
      return;
    }

    setTabBarVisible(true);
    navigateToStack(TabNavigationScreens.OPPORTUNITIES, OpportunityScreens.LIST);
  };

  const getTextHeader = (_action: string, _items: []) => {
    if (_action === 'Open') {
      return 'matchListMessage';
    }
    return _items?.length > 0 ? 'closedText' : 'closedTextNotFound';
  };

  const renderItem: RenderItemFn = useCallback((item, onItemPress, enabled) => {
    const textColor = item.selected ? Colors.WHITE : Colors.PRIMARY_TEXT;

    const handle = () => {
      onItemPress(item);
    };

    return (
      <React.Fragment key={item.key}>
        <Button
          selected={item.selected}
          enabled={enabled}
          onPress={handle}
        >
          <Body3 text={item.text} color={textColor} />
        </Button>
        <Spacer horizontal size={4} />
      </React.Fragment>
    );
  }, []);

  const renderScene = useCallback((e: any) => {
    const { route } = e;
    switch (route.key) {
      case SCREENS.MATCHES:
        return (
          <Sheet>
            <OpportunityListMatch
              opportunityList={items || []}
              args={{ ...args, setShowModal }}
            />
          </Sheet>
        );
      case SCREENS.SEARCH:
        return (
          <Sheet>
            <PosibleMatchSearch />
          </Sheet>
        );
      default:
        return null;
    }
  }, []);

  return (
    <Flex flex={1} style={{ backgroundColor: Colors.WHITE }}>
      <SaveAreaContainer />
      <PreviewHeader
        onBack={goBack}
        // disabled={!FromOppWall}
        showBackButton
        style={{ marginBottom: 12 }}
        actionName={translate(action === 'Open' ? 'syncTitle' : 'closeTitle')}
      />
      <Flex style={{ marginHorizontal: 16 }}>
        <Body3 text={translate(getTextHeader(action, items), TextTransform.NONE)} />
      </Flex>
      <Flex flex={1} style={{ marginHorizontal: 16 }}>
        <TabView
          sceneContainerStyle={{ backgroundColor: Colors.WHITE }}
          renderTabBar={({ navigationState }) => (
            <ButtonListContainer action={action === 'Open'}>
              <ButtonList
                items={action === 'Open' ? BottonList : []}
                onItemSelected={(item:any) => {
                  setIndex(item.value);
                }}
                renderItem={renderItem}
                defaultValue={navigationState.index}
                row
              />
            </ButtonListContainer>
          )}
          swipeEnabled={false}
          renderScene={renderScene}
          navigationState={{ index, routes }}
          onIndexChange={(i) => { onIndexChange(i); }}
          initialLayout={{ width: layout.width }}
        />
        <Popup
          visible={showModal}
          Body={() => (
            <Flex>
              <Flex row justify="space-between">
                <Flex flex={1}>
                  <Header2 style={{ paddingBottom: 8 }} text={translate('closeTitle')} />
                  <Body3 text={translate('closedText')} />
                </Flex>
                <Flex>
                  <RectButton onPress={() => setShowModal(false)}>
                    <MIcon name="close" size={24} />
                  </RectButton>
                </Flex>
              </Flex>
              <OpportunityListMatch
                opportunityList={itemsClosed}
                args={{ ...args, setShowModal }}
                notification={false}
              />
            </Flex>
          )}
          showCloseButton={false}
          close={() => {}}
          showIconTitle={false}
        />

        {(!showModal && itemsClosed.length > 0) && (
        <ShowNotificationClosedOpp handle={setShowModal} />
        )}
      </Flex>

    </Flex>

  );
};

export default PosibleMaches;
