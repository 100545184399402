import React, { useCallback } from 'react';
import styled from 'styled-components/native';
import { Flex, Spacer } from '@kanvas/andromeda';
import { Header2 } from 'molecules/text';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from 'theme/colors';
import { useNavigation } from '@react-navigation/native';
import { BorderlessButton } from 'atoms/touchable';
import { ViewStyle } from 'react-native';
import { BUTTON_HIT_SLOP } from 'domain/shared/constants';

interface IProps {
  actionName?: string
  right?: React.ReactNode;
  style?: ViewStyle;
  showBottomBorder?: boolean;
  borderless?: boolean,
  onBack?: () => void;
  disabled?: boolean;
  backgroundColor?: string;
  showBackButton?: boolean;
  iconName?: string;
}

const Container = styled(Flex)<Pick<IProps, 'showBottomBorder' | 'borderless'>>`
  ${(props) => (props.showBottomBorder ? `
    border-color: ${colors.BORDER_COLOR}; 
  ` : '')}
  padding: 16px;
  ${(props) => !props.borderless && 'border-bottom-width: 2px;'}
  background-color: ${colors.WHITE};
`;

const PreviewHeader = (props: IProps) => {
  const {
    actionName,
    right,
    style,
    showBottomBorder = true,
    onBack,
    disabled = false,
    borderless = false,
    showBackButton = true,
    iconName = 'arrow-back',
  } = props;
  const navigation = useNavigation();
  const goBack = useCallback(() => {
    if (onBack) {
      onBack();
      return;
    }
    navigation.goBack();
  }, [onBack, navigation]);

  return (
    <Container row align="center" style={style} borderless={borderless} showBottomBorder={showBottomBorder}>
      <BorderlessButton hitSlop={BUTTON_HIT_SLOP} onPress={goBack} disabled={disabled}>
        <Flex row align="center">
          {showBackButton && <Icon name={iconName} size={24} color={colors.BLACK} />}
          <Spacer size={showBackButton ? 4 : 0} horizontal />
          {
            actionName && (
              <Header2 text={`${actionName}`} />
            )
          }
        </Flex>
      </BorderlessButton>
      {
        right && (
          <Flex flex={1} align="flex-end">
            {right}
          </Flex>
        )
      }
    </Container>
  );
};

export default PreviewHeader;
