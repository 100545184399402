import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Flex } from "@kanvas/andromeda";
import styled from "styled-components/native";
import Modal from 'react-native-modal';
import { Meta2 } from "../text";
import colors from "theme/colors";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

export enum IDValidationLoadingState {
  VALIDATING = 'VALIDATING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

type Props = {
  open?: boolean;
  onClose?: () => void;
}

export type IdValidationLoadingRef = {
  open: () => void;
  changeStatus: (key: IDValidationLoadingState) => void;
}; 

const Root = styled(Flex)`
  background-color: rgba(0, 0, 0, 0.6);
  padding: 12px 16px;
  border-radius: 12px;
`;

function useIdValidationLoading(props: Props, ref: ForwardedRef<IdValidationLoadingRef>) {
  const [state, setState] = useState(IDValidationLoadingState.VALIDATING);

  const open = () => {}
  
  const handleClose = () => {
    if (state === IDValidationLoadingState.VALIDATING) return;
    props.onClose?.();
  };

  const changeStatus = (key: IDValidationLoadingState) => {
    setState(key);
  }

  const renderState = useMemo(() => {
    const choose = {
      [IDValidationLoadingState.VALIDATING]: {
        icon: 'rotate-right',
        text: 'Validating ID...',
      },
      [IDValidationLoadingState.SUCCESS]: {
        icon: 'check-circle',
        text: 'Validation completed.'
      },
      [IDValidationLoadingState.FAIL]: {
        icon: 'close-circle',
        text: 'Validation failed. Please rescan.'
      }
    }

    return choose[state];
  }, [state]);

  useImperativeHandle(ref, () => ({ open, changeStatus }));

  useEffect(() => {
    if (state === IDValidationLoadingState.VALIDATING) return;

    const timeout = setTimeout(() => {
      handleClose();
    }, 5000);

    return () => { clearTimeout(timeout) }
  }, [state])

  return {
    model: {
      renderState,
    },
    operations: {
      handleClose,
    }
  }
}

const IdValidationLoading = forwardRef<IdValidationLoadingRef, Props>((props, ref) => {
  const { model, operations } = useIdValidationLoading(props, ref);


  const { open } = props;
  const { handleClose } = operations;
  const { icon, text } = model.renderState;

  return (
    <Modal
      isVisible={open}
      backdropOpacity={.4}
      animationIn="fadeIn"
      animationOut="fadeOut"
      onBackdropPress={handleClose}
      style={{ alignItems: 'center', justifyContent: 'center' }}
    >
      <Root row gap={8}>
        <Icon name={icon} color={colors.WHITE} size={16} /> 
        <Meta2 text={text} color={colors.WHITE} />
      </Root>
    </Modal>
  );
})

export default IdValidationLoading;