import { gql } from '@apollo/client';

const getAttributes = gql`
query getAttributes($value: Mixed!) {
  attributes(where: { column: NAME, operator: EQ, value: $value }) {
      data{
          id,
          name, 
          values {
              id,
              value
          }
      }
  }  
}
`;

export default getAttributes;
