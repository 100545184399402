import { Flex } from "@kanvas/andromeda";
import styled from "styled-components/native";
import React from "react";
import colors from "theme/colors";

const Root = styled(Flex)`
  padding: 0 4px;
  border-width: 2px;
  border-color: ${colors.WHITE};
  border-radius: 4px;
  cursor: pointer;
`;

const Text = styled.Text`
  size: 10px;
  line-height: 24px;
  font-weight: 800;
  color: ${colors.WHITE};
`;

export default function OfacButton() {
  return (
    <Root>
      <Text>OFAC</Text>
    </Root>
  );
}