import React, { useState } from 'react';
import styled from 'styled-components/native';
import { RectButton } from 'react-native-gesture-handler';
import Colors from 'theme/colors';
import { Body3 } from 'components/molecules/text';
import { Flex } from '@kanvas/andromeda';
import { IS_WEB } from 'domain/shared/constants';
import { Route } from 'react-native-tab-view';

interface IProps {
  onChange?: (key: string) => void;
  routes: Route[];
}

interface ButtonProps {
  selected?: boolean;
}

const Button = styled(RectButton) <ButtonProps>`
  height: 32px;
  border-radius: 16px;
  padding-vertical: 6px;
  padding-horizontal: 12px;
  ${IS_WEB && 'cursor: pointer;'}
  ${({ selected }) => (selected ? `
    background-color: ${Colors.ORANGE};
    shadow-opacity: 0.20;
    shadow-color: ${Colors.BLACK};
    shadow-offset: 0px 3px;
    elevation: 6;
    ${IS_WEB ? `box-shadow: 0px 3px 5px ${Colors.SHADOW}` : ''};
  ` : `
    background-color: ${Colors.BORDER_COLOR};
  `)
}
`;

const OppListFilter = (props: IProps) => {
  const { onChange, routes } = props;
  const [current, setCurrent] = useState(routes[0].key);

  const handleChange = (route: Route) => {
    onChange?.(route.key);
    setCurrent(route.key);
  };

  return (
    <Flex row gap={4}>
      {routes?.map((route) => {
        const selected = current === route.key;
        const textColor = selected ? Colors.WHITE : Colors.PRIMARY_TEXT;
        return (
          <Button 
            activeOpacity={0}
            selected={selected}
            onPress={() => handleChange(route)}
            key={`opp-list-filter-${route.key}`}
          >
            <Body3 text={route.title || ''} color={textColor} />
          </Button>
        );
      })}
    </Flex>
  );
};

export default OppListFilter;
