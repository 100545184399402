import Sentry from "sentry";

import Lead from "model/data/lead";

import { DEFAULT_COMPANY_INFO_KEY, IS_CHROME_EXTENSION } from "domain/shared/constants";
import CRMExtensionService from "domain/shared/services/crm-extension-service";
import GetToken from 'domain/shared/utils/auth/async-storage';
import ActionEngineService from "domain/shared/services/actionEngine-service";

export default function crmLinking(lead: Lead) {
  const { link_sources } = lead.backendEntity;

  CRMExtensionService.openLead(link_sources);

  const { vin_solution } = link_sources;
  
  const customerId = vin_solution.lead_id as string;
  if (IS_CHROME_EXTENSION && customerId.length > 0) {
    GetToken(DEFAULT_COMPANY_INFO_KEY).then((token) => {
      ActionEngineService.syncVinsolution(customerId, token.default_company_uuid)
        .catch((error) => Sentry.captureException(error));
    });
  }
}