import axios from 'domain/shared/services/axios';
import { PaginatedResponse } from 'organisms/paginated-flatlist';
import { Feed } from '../types/feed';
import { Message } from '../types/message';
import { Notification } from '../types/notification';

export default class MessagesService {
  public static async getAllMessages(leadUuid: string) {
    const { data } = await axios.get<Message[]>(`/messages?q=(chs.slug:${leadUuid},parent_id:0)`);
    return data;
  }

  public static async getFeedById(id: number) {
    const { data } = await axios.get<Feed>(`/feeds/${id}`);
    return data;
  }

  public static async getAllMessagesPaginated(
    leadUuid: string,
    page: number,
    parentId: number = 0,
    limit: number = 25,
  ) {
    const params = {
      format: true,
      page,
      limit,
    };
    const { data } = await axios.get<PaginatedResponse<Message>>(`/messages?q=(chs.slug:${leadUuid},parent_id:${parentId})`, { params });
    return data;
  }

  public static async getAllUpdatePaginated(
    page: number,
    company_branch:number,
  ) {
    const params = {
      format: true,
      page,
    };
    const { data } = await axios.get<PaginatedResponse<Notification>>(`/notifications?format=true&sort=created_at|DESC&limit=25&page=${page}&q=(is_deleted:0,notification_type_id~17,companies_branches_id:${company_branch})`, { params });
    return data;
  }

  public static async getAllMessagePaginated(
    page: number,
    company_branch:number,
  ) {
    const params = {
      format: true,
      page,
    };
    const { data } = await axios.get<PaginatedResponse<Notification>>(`notifications?format=true&sort=created_at|DESC&limit=25&page=${page}&q=(is_deleted:0,notification_type_id:17,companies_branches_id:${company_branch})`, { params });
    return data;
  }
}
