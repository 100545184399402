export const MainScreen = {
  Auth: 'dac.Auth',
  Update: 'OPP.UPDATE.SCREEN',
};

export enum DrawerScreens {
  ROOT = 'ROOT',
  SETTINGS = 'SETTINGS',
  PROFILE = 'PROFILE',
  PASSWORD_AND_PRIVACY = 'PASSWORD_AND_PRIVACY',
  PREFERENCES = 'PREFERENCES',
  NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS',
  SWITCH_STORE = 'SWITCH_STORE',
  SECURITY = 'SECURITY',
  FEEDBACK = 'FEEDBACK',
}
