/* eslint-disable react/prop-types */
import React from 'react';
import { SvgFromXml } from 'react-native-svg';

type Props = {
  size?: number;
}

const xml = `
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_10991_97837)">
  <path d="M12.2068 11.4343L12.7018 11.9314L13.0102 11.3013C13.2822 10.7454 13.4868 10.1705 13.6233 9.57692C13.7595 8.98483 13.8277 8.38612 13.8277 7.78151V3.71484V3.36834L13.5033 3.24668L8.16996 1.24668L7.9944 1.18084L7.81884 1.24668L4.35217 2.54668L3.62676 2.81871L4.17345 3.36766L12.2068 11.4343ZM12.8408 15.8017L13.1944 16.1553L13.548 15.8017L14.4813 14.8684L14.8348 14.5148L14.4813 14.1613L2.21462 1.89462L1.86107 1.54107L1.50751 1.89462L0.574181 2.82796L0.220628 3.18151L0.574181 3.53506L2.16107 5.12195V7.78151C2.16107 9.57745 2.69814 11.2162 3.76496 12.6838C4.8339 14.1544 6.20559 15.1134 7.87231 15.533L7.99989 15.5652L8.12676 15.5303C8.73415 15.3636 9.31448 15.1224 9.86697 14.8076C10.2794 14.5725 10.6637 14.2966 11.0195 13.9804L12.8408 15.8017Z" fill="#E71818" stroke="white"/>
  </g>
  <defs>
  <clipPath id="clip0_10991_97837">
  <rect width="16" height="16" fill="white" transform="translate(-0.00585938 0.382812)"/>
  </clipPath>
  </defs>
  </svg>
`;

export default function OutlineRedBadge(props: Props) {
  const { size = 16 } = props;
  return (
    <SvgFromXml 
      width={size}
      height={size}
      xml={xml}
    />
  );
}
