import React from 'react';
import ListCardItem from 'domain/opportunity-list/screens/list/components/ListCardItem';
import { Lead } from 'domain/opportunity-list/types';
import { ListRenderItemInfo } from 'react-native';

interface Props {
  info: ListRenderItemInfo<Lead>;
  searching?: boolean;
  onItem?: (lead: Lead) => void;
}

export default function RenderItem({ info, onItem, searching }: Props) {
  const { item } = info;
  const handlePress = () => onItem?.(item);
  return <ListCardItem {...item} searching={searching} onPress={handlePress} />;
}
