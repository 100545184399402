/* eslint-disable react/no-unused-prop-types */
import { Flex } from '@kanvas/andromeda';
import LeadList from 'components/organisms/lead-list';
import SearchLoading from 'components/organisms/search-loading';
import SearchApi, { FormatedResponse } from 'domain/opportunity-list/api/search';
import {
  refreshLeadListEvent, REFRESH_LEAD_LIST_EVENT, disableNeedReloadEvent, DISABLE_NEED_RELOAD,
} from 'domain/opportunity-list/screens/opp-list';
import { State } from 'domain/opportunity-list/state';
import { Lead } from 'domain/opportunity-list/types';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { EventRegister } from 'react-native-event-listeners';
import { User } from 'domain/shared/types/user';
import { useSelector } from 'react-redux';
import { RootState } from 'domain/shared/store';
import Pusher from 'pusher-js/react-native';
import { PUSHER_API_KEY } from 'env';
import styled from 'styled-components/native';
import colors from 'theme/colors';
import { TouchableOpacity } from 'components/atoms/touchable';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { IS_WEB } from 'domain/shared/constants';
import RenderItem from '../lead-item';

const pusher = new Pusher(PUSHER_API_KEY, {
  cluster: 'us2',
});

const ReloadButton = styled(Flex)`
  height: 40px;
  width: 40px;
  border-radius: 40px;
  position: absolute;
  top: 0;
  align-self: center;
  background-color: ${colors.WHITE};
  border-color: ${colors.SHADOW};
  shadow-opacity: 1;
  shadow-color: ${colors.SHADOW};
  shadow-offset: 0px 5px;
  shadow-radius: 5px;
  elevation: 5;
  ${IS_WEB ? `box-shadow: 0px 3px 5px ${colors.SHADOW}` : ''};
`;

interface Props {
  state: State;
  filters?: string;
  onAdd?: VoidFunction;
  onItem?: (lead: Lead) => void;
  onPaginate?: VoidFunction;
  Footer?: JSX.Element | false;
}

interface CurrentState {
  page: number;
  loading?: boolean;
}

export function useCompleteLeadList(props: Props) {
  const { filters, state } = props;
  const [data, setData] = useState<Lead[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSteps, setShowSteps] = useState(false);
  const [current, setCurrent] = useState<FormatedResponse<Lead>>();
  const [needReload, setNeedReload] = useState(false);

  const user = useSelector<RootState>((redux) => redux.auth.user) as User | undefined;

  const noResults = useMemo(() => !showSteps && data.length <= 0, [showSteps, data]);
  const lastPage = useMemo(() => {
    if (!current) return false;
    const { page, total_pages } = current;
    return page >= total_pages;
  }, [current]);

  const controller = useRef(new AbortController());

  const paginateData = useCallback(async (params: any) => {
    const requests = [
      await SearchApi.search({ ...params, page: params.page + 1 }),
      await SearchApi.search({ ...params, page: params.page + 2 }),
      await SearchApi.search({ ...params, page: params.page + 3 }),
      await SearchApi.search({ ...params, page: params.page + 4 }),
      await SearchApi.search({ ...params, page: params.page + 5 }),
    ];

    const promises = await Promise.allSettled(requests);
    const responses = promises.map((value) => {
      if (value.status === 'rejected') return null;
      return value.value as FormatedResponse<Lead>;
    }).filter((value) => !!value) as FormatedResponse<Lead>[];

    const lastResponse = responses[responses.length - 1];

    const users = responses.map((response) => response.data)
      .reduce((prev, currentLead) => [...prev, ...currentLead]);

    if (!users.length && lastResponse.total_pages > lastResponse.page) {
      paginateData({ ...params, page: lastResponse.page });
      return;
    }

    if (users.length <= 0) setShowSteps(false);
    setData((c) => [...c, ...users]);
    setCurrent(lastResponse);
    setLoading(false);
  }, []);

  const fetch = useCallback(async (currentParams: CurrentState) => {
    if (loading) return;
    try {
      disableNeedReloadEvent();
      setLoading(true);
      setShowSteps(!!currentParams.loading);
      controller.current = new AbortController();
      const params = {
        ...currentParams,
        text: state.value,
        sort: state.sort?.FILTER,
        filters,
      };

      const response = await SearchApi.search(params, controller.current.signal);

      if (!response.data.length && response.total_pages > response.page) {
        paginateData(params);
        return;
      }

      if (response.data.length <= 0) setShowSteps(false);
      setData((c) => [...c, ...response.data]);
      setCurrent(response);
      setLoading(false);
    } catch (e: any) {
      if (e.message === 'canceled') return;
      setError(true);
      setLoading(false);
      setShowSteps(false);
    } finally {
      disableNeedReloadEvent();
    }
  }, [loading, state]);

  const handlePaginate = useCallback(() => {
    if (loading) return;
    if (!data.length) return;
    if (!current) return;
    if (!current.total_pages) return;

    const page = current.page + 1;
    if (page > current.total_pages) return;

    fetch({ page, loading: false });
  }, [current, loading, data]);

  const handleComplete = useCallback(() => {
    setShowSteps(false);
  }, []);

  const handleRefresh = useCallback(() => {
    refreshLeadListEvent();
  }, []);

  useEffect(() => {
    fetch({ page: 1, loading: true });

    const event = EventRegister.addEventListener(REFRESH_LEAD_LIST_EVENT, () => {
      setCurrent(undefined);
      setError(false);
      setData([]);
      fetch({ page: 1, loading: true });
      disableNeedReloadEvent();
    }).toString();

    return () => {
      controller.current.abort();
      EventRegister.removeEventListener(event);
    };
  }, []);

  useEffect(() => {
    const needRoloadEvent = EventRegister.addEventListener(DISABLE_NEED_RELOAD, () => {
      setNeedReload(false);
    }).toString();

    if (!user) return () => {}; // i don't now why but TS is complaning
    const channel = pusher.subscribe(`leads_list_${user.default_company_branch}`);

    channel.bind('newLead', () => {
      if (!loading) return;
      setNeedReload(true);
    });

    return () => {
      channel.unsubscribe();
      EventRegister.removeEventListener(needRoloadEvent);
    };
  }, [user, loading]);

  return {
    model: {
      data,
      error,
      loading,
      showSteps,
      noResults,
      lastPage,
      needReload,
    },
    operations: {
      handlePaginate,
      handleRefresh,
      handleComplete,
    },
  };
}

export default function CompleteLeadList(props: Props) {
  const { model, operations } = useCompleteLeadList(props);
  const {
    onAdd, onItem, Footer, state,
  } = props;

  return (
    <Flex flex={1}>
      <SearchLoading
        hideButton
        crm="eLead"
        data={model.data}
        error={model.error}
        onAddOpp={onAdd}
        search={state.value}
        loading={model.showSteps}
        onRetry={operations.handleRefresh}
        onComplete={operations.handleComplete}
        noResults={model.noResults}
      />

      {
        (!model.noResults && !model.showSteps) && (
          <LeadList
            onItem={onItem}
            data={model.data}
            loading={model.loading}
            Footer={model.lastPage && Footer}
            onRefresh={operations.handleRefresh}
            onPaginate={operations.handlePaginate}
            renderItem={(info) => <RenderItem info={info} onItem={onItem} />}
          />
        )
      }

      {model.needReload && (
        <ReloadButton align="center" justify="center">
          <TouchableOpacity onPress={operations.handleRefresh}>
            <Icon name="refresh" size={25} color={colors.ORANGE} />
          </TouchableOpacity>
        </ReloadButton>
      )}
    </Flex>
  );
}
