/* eslint-disable @typescript-eslint/no-unused-vars */
// Molecules
import React, { useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Flex, useDeviceImage } from '@kanvas/andromeda';
import styled from 'styled-components/native';
import { Formik, FormikErrors } from 'formik';
import * as Yup from 'yup';
import { TextTransform } from 'react-native-localized-text';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RouteProp } from '@react-navigation/native';

// Themes
import colors from 'theme/colors';

// Molecules
import PreviewHeader from 'components/molecules/preview-header';
import { translate } from 'locales';
import { FormLabel, InputWithLabel as TextInputWithLabel } from 'components/molecules/input-with-label';
import UploadInput from 'components/molecules/upload-input';

// Atoms
import ButtonWithIcon from 'components/atoms/button-with-icon';

// Organisms
import VehicleCondition from 'components/organisms/vehicle-condition';

// Types
import { UploadFile } from 'domain/shared/types/file.interface';
import { LeadAction } from 'domain/shared/types/lead-action';

// Hooks
import useSubmitForm from 'model/hooks/use-submit-form';
import { IS_WEB } from 'domain/shared/constants';
import usePickFile from '../../content-preview/hooks/use-pick-file';

type SetFieldValue = (field: string, value: any, shouldValidate?: boolean | undefined) => void;

type ParamList = {
  Any: { action: LeadAction, onGoBack: VoidFunction }
};

interface IProps {
  route: RouteProp<ParamList, 'Any'>;
  navigation: NativeStackNavigationProp<ParamList, 'Any'>;
}

interface Form {
  vehicle_type_id: number;
  odometer: string;
  batteryCheck: string;
  uploadOdometerPhoto: UploadFile;
  uploadBatteryPhoto: UploadFile;
  uploadFrontLicensePlate: UploadFile;
  uploadBackLicensePlate: UploadFile;
  vinNumber: UploadFile;
  pdiSlip: UploadFile;
  numberofKeys: string;
}

const Container = styled.View`
  flex: 1px;
  background-color: ${colors.WHITE};
`;

const Content = styled(KeyboardAwareScrollView)`
  padding: 20px;
`;

const ButtonContainer = styled(Flex)`
  margin-bottom: ${getBottomSpace()}px;
  padding-vertical: 16px;
  border-top-width: 1px;
  border-top-color: ${colors.BORDER_COLOR};
  padding-bottom: 60px;
`;

const InputContainer = styled(Flex)`
  background-color:${colors.WHITE};
  height: 70px;
  margin-vertical:1px;
`;

const initialValues = {
  vehicle_type_id: 0,
  odometer: '',
  batteryCheck: '',
  uploadOdometerPhoto: '',
  uploadBatteryPhoto: '',
  uploadFrontLicensePlate: '',
  uploadBackLicensePlate: '',
  vinNumber: '',
  pdiSlip: '',
  numberofKeys: '',
};

const new_vehicle_validation = Yup.object().shape({
  vehicle_type_id: Yup.string().required(),
  odometer: Yup.string().required(),
  batteryCheck: Yup.string().required(),
  uploadOdometerPhoto: Yup.mixed().required(),
  uploadBatteryPhoto: Yup.mixed().required(),
  uploadFrontLicensePlate: Yup.mixed().required(),
  uploadBackLicensePlate: Yup.mixed().required(),
  vinNumber: Yup.mixed().required(),
  pdiSlip: Yup.mixed().required(),
});

const used_vehicle_validation = Yup.object().shape({
  vehicle_type_id: Yup.string().required(),
  odometer: Yup.string().required(),
  uploadOdometerPhoto: Yup.mixed().required(),
  batteryCheck: Yup.string().required(),
  uploadBatteryPhoto: Yup.mixed().required(),
  numberofKeys: Yup.string().required(),
  uploadFrontLicensePlate: Yup.mixed().required(),
  uploadBackLicensePlate: Yup.mixed().required(),
  vinNumber: Yup.mixed().required(),
});

const options = [
  translate('photoGallery', TextTransform.CAPITAL),
  translate('camera', TextTransform.CAPITAL),
  translate('cancel', TextTransform.CAPITAL),
];

const SoldCarForm = (props: IProps) => {

  // Props
  const { navigation, route } = props;
  const { action, onGoBack } = route.params;

  // State
  const [loading, setLoading] = useState(false);
  const [soldCardType, setSoldCardType] = useState(0);

  // Hooks
  const { submit, separateFilesFromFields } = useSubmitForm(action);
  const { handleUploadFilePromise } = usePickFile();

  const { getImageFromDevice } = useDeviceImage({
    width: 300,
    height: 300,
    forceJpg: true,
    multiple: false,
    mediaType: 'photo',
    includeBase64: true,
  });

  const onSubmit = async (values: Form) => {
    try {
      setLoading(true);
      

      const { files, form } = separateFilesFromFields(values);

      const fileList = Object.values(files);
      const submitted = await submit(form, fileList);
      if (!submitted) return;
      onGoBack?.();
      navigation.goBack();
    } finally {
      setLoading(false);
    }
  };

  const handleUploadFile = async (inputName: string, setFieldValue?: SetFieldValue) => {
    if (IS_WEB) {
      const file = await handleUploadFilePromise();
      setFieldValue?.(inputName, file);
      return;
    }

    const image = await getImageFromDevice(options);

    if (!image) return;

    const name = image.path.split('/').slice(-1).join();

    const file = {
      uri: image.path,
      type: image.mime,
      file_type: image.mime,
      name: image.filename || name,
    };
    setFieldValue?.(inputName, file);
  };

  const handleReset = (inputName: string, setFieldValue?: SetFieldValue) => {
    setFieldValue?.(inputName, '');
  };

  const config = useMemo(() => {
    if (!action) return [];
    if (action.form_config === null) return [];
    const config = JSON.parse(action.form_config) as Record<string, unknown>;
    return config['hidden'] as string[];
  }, [action]);

  const validationSchema = useMemo(() => {
    const batteryCheck = !config.includes('batteryCheck') ? Yup.string().required() : Yup.string();
    const pdiSlip = !config.includes('pdiSlip') ? Yup.mixed().required() : Yup.string();
    const uploadBatteryPhoto = !config.includes('uploadBatteryPhoto') ? Yup.mixed().required() : Yup.string();
    const uploadFrontLicensePlate = !config.includes('uploadFrontLicensePlate') || soldCardType !== 0 ? Yup.mixed().required() : Yup.string();
    const uploadBackLicensePlate = !config.includes('uploadBackLicensePlate') || soldCardType !== 0 ? Yup.mixed().required() : Yup.string();

    const new_vehicle_validation = Yup.object().shape({
      vehicle_type_id: Yup.string().required(),
      odometer: Yup.string().required(),

      uploadBatteryPhoto,
      batteryCheck,
      pdiSlip,
      uploadFrontLicensePlate,
      uploadBackLicensePlate,

      uploadOdometerPhoto: Yup.mixed().required(),
      vinNumber: Yup.mixed().required(),
    });
    
    const used_vehicle_validation = Yup.object().shape({
      vehicle_type_id: Yup.string().required(),
      odometer: Yup.string().required(),
      uploadOdometerPhoto: Yup.mixed().required(),
      
      batteryCheck,
      uploadBatteryPhoto,
      uploadFrontLicensePlate,
      uploadBackLicensePlate,

      numberofKeys: Yup.string().required(),
      vinNumber: Yup.mixed().required(),
    });

    return soldCardType === 0 ? new_vehicle_validation : used_vehicle_validation;
  }, [config, soldCardType]);

  return (
    <Container>

      <PreviewHeader
        actionName={translate('soldCarVerification', TextTransform.CAPITAL)}
        onBack={() => navigation.goBack()}
      />

      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues as any}
        validationSchema={validationSchema}
      >
        {
          ({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            validateForm,
            values,
            errors,
            touched,
          }) => (

            <Content
              showsVerticalScrollIndicator={false}
              extraScrollHeight={100}
            >

              <InputContainer>
                <FormLabel
                  required
                  validate={errors.vehicle_type_id}
                  text={translate('vehicleCondition', TextTransform.CAPITALIZE)}
                />
                <VehicleCondition
                  onChange={(item) => setFieldValue('vehicle_type_id', item.value)}
                  onItemChange={(item) => setSoldCardType(item.value)}
                  value={values.vehicle_type_id}
                />
              </InputContainer>

              <TextInputWithLabel
                label={translate('odometer', TextTransform.CAPITALIZE)}
                placeholder="0"
                onChangeText={handleChange('odometer')}
                onBlur={handleBlur('odometer')}
                value={values?.odometer}
                errors={touched.odometer ? errors.odometer : ''}
                keyboardType='numeric'
                required
              />

              <UploadInput
                required
                value={values.uploadOdometerPhoto}
                onPress={() => handleUploadFile('uploadOdometerPhoto', setFieldValue)}
                onReset={() => handleReset('uploadOdometerPhoto', setFieldValue)}
                label={translate('uploadOdometerPhoto', TextTransform.CAPITAL)}
                buttonLabel={translate('upload', TextTransform.CAPITAL)}
                Icon={<Icon name="add" size={24} color={colors.DARK_ORANGE} />}
                styles={styles.uploadButtonContainer}
              />

              {
                !config.includes('batteryCheck') && (
                  <TextInputWithLabel
                    label={translate('batteryCheck', TextTransform.CAPITALIZE)}
                    placeholder="0"
                    onChangeText={handleChange('batteryCheck')}
                    onBlur={handleBlur('batteryCheck')}
                    value={values?.batteryCheck}
                    errors={touched.batteryCheck ? errors.batteryCheck : ''}
                    keyboardType='numeric'
                    required
                  />
                )
              }

              {
                !config.includes('uploadBatteryPhoto') && (
                  <UploadInput
                    required
                    value={values.uploadBatteryPhoto}
                    onPress={() => handleUploadFile('uploadBatteryPhoto', setFieldValue)}
                    onReset={() => handleReset('uploadBatteryPhoto', setFieldValue)}
                    label={translate('uploadBatteryPhoto', TextTransform.CAPITAL)}
                    buttonLabel={translate('upload', TextTransform.CAPITAL)}
                    Icon={<Icon name="add" size={24} color={colors.DARK_ORANGE} />}
                    styles={styles.uploadButtonContainer}
                  />
                )
              }

              {values.vehicle_type_id === 1 && (
                <TextInputWithLabel
                  label={translate('numberofKeys')}
                  placeholder="0"
                  onChangeText={handleChange('numberofKeys')}
                  onBlur={handleBlur('numberofKeys')}
                  value={values?.numberofKeys}
                  errors={touched.numberofKeys ? errors.numberofKeys : ''}
                  keyboardType='numeric'
                  required
                />
              )}

              {
                soldCardType !== 0 ? !config.includes('uploadFrontLicensePlate') && (
                  <UploadInput
                    required
                    value={values.uploadFrontLicensePlate}
                    onPress={() => handleUploadFile('uploadFrontLicensePlate', setFieldValue)}
                    onReset={() => handleReset('uploadFrontLicensePlate', setFieldValue)}
                    label={translate('uploadFrontLicensePlate', TextTransform.CAPITAL)}
                    buttonLabel={translate('upload', TextTransform.CAPITAL)}
                    Icon={<Icon name="add" size={24} color={colors.DARK_ORANGE} />}
                    styles={styles.uploadButtonContainer}
                  />
                ) : null
              }

              {
                soldCardType !== 0 ? !config.includes('uploadBackLicensePlate') && (
                  <UploadInput
                    required
                    value={values.uploadBackLicensePlate}
                    onPress={() => handleUploadFile('uploadBackLicensePlate', setFieldValue)}
                    onReset={() => handleReset('uploadBackLicensePlate', setFieldValue)}
                    label={translate('uploadBackLicensePlate', TextTransform.CAPITAL)}
                    buttonLabel={translate('upload', TextTransform.CAPITAL)}
                    Icon={<Icon name="add" size={24} color={colors.DARK_ORANGE} />}
                    styles={styles.uploadButtonContainer}
                  />
                ) : null
              }

              <UploadInput
                required
                value={values.vinNumber}
                onPress={() => handleUploadFile('vinNumber', setFieldValue)}
                onReset={() => handleReset('vinNumber', setFieldValue)}
                label={translate('vinNumber', TextTransform.CAPITAL)}
                buttonLabel={translate('upload', TextTransform.CAPITAL)}
                Icon={<Icon name="add" size={24} color={colors.DARK_ORANGE} />}
                styles={styles.uploadButtonContainer}
              />

              {
                !config.includes('pdiSlip') && values.vehicle_type_id === 0 && (
                  <UploadInput
                    required
                    value={values.pdiSlip}
                    onPress={() => handleUploadFile('pdiSlip', setFieldValue)}
                    onReset={() => handleReset('pdiSlip', setFieldValue)}
                    label={translate('pdiSlip', TextTransform.CAPITAL)}
                    buttonLabel={translate('upload', TextTransform.CAPITAL)}
                    Icon={<Icon name="add" size={24} color={colors.DARK_ORANGE} />}
                    styles={styles.uploadButtonContainer}
                  />
                )
              }

              <ButtonContainer>
                <ButtonWithIcon
                  loading={loading}
                  text={translate('submit', TextTransform.CAPITAL)}
                  onPress={async () => {
                    const errorList = await validateForm();
                    if (errorList) {
                      console.log('errorList', errorList);
                    }
                    handleSubmit();
                  }}
                />
              </ButtonContainer>
            </Content>
          )
        }
      </Formik>

    </Container>
  );
};

const styles = StyleSheet.create({
  uploadButtonContainer: {
    marginVertical: 10,
  },
});

export default React.memo(SoldCarForm);
