/* eslint-disable no-nested-ternary */
import { Flex, Spacer } from '@kanvas/andromeda';
import { ActivityIndicator, TouchableOpacity } from 'react-native';
import { Button1, Button2 } from 'components/molecules/text';
import { IS_WEB } from 'domain/shared/constants';
import React, { useMemo } from 'react';
import { RectButtonProperties } from 'react-native-gesture-handler';
import styled from 'styled-components/native';
import colors from 'theme/colors';

interface IProps extends RectButtonProperties {
  icon?: React.ReactNode;
  text: string;
  loading?: boolean;
  height?:number;
  color?:string;
  textColor?:string;
  type?: 'button1' | 'button2';
  disabled?:boolean;
  paddingVertical?: number;
  maxWidth?: number;
}

const Container = styled(TouchableOpacity)<{ height?: number, color?: string, enabled: boolean, paddingVertical: number }>`
  justify-content: center;
  align-items: center;
  ${(props) => `height: ${props.height || 44}px`};
  background-color: ${(props) => (props.color ? props.color : colors.ORANGE)};
  border-radius: 8px;
  width: 100%;
  flex-direction: row;
  ${(props) => (props.enabled === false && 'opacity: 0.7')}
  border: 1px solid ${colors.BUTTON_BORDER};;
  box-shadow: 0px -4px 16px ${colors.BUTTON_BORDER};
  padding-vertical: ${(props) => props.paddingVertical}px;
  ${IS_WEB ? `box-shadow: 0px -4px 16px ${colors.BUTTON_BORDER};` : ''};
`;

export const ButtonBase = (props: IProps) => {
  const {
    text,
    loading = false,
    textColor,
    type = 'button2',
    enabled = true,
    paddingVertical = 12,
    ...rest
  } = props;

  const textProps = useMemo(() => ({ text, color: textColor || colors.WHITE }), [text, textColor]);

  return (
    <Container disabled={enabled === false} {...rest} paddingVertical={paddingVertical}>
      {
        loading ? (
          <Flex>
            <ActivityIndicator size="small" color={colors.WHITE} />
          </Flex>
        ) : (
          type === 'button2' ? <Button2 {...textProps} /> : <Button1 {...textProps} />
        )
      }
    </Container>
  );
};

const ButtonWithIcon = (props: IProps) => {
  const {
    icon, text, loading = false, textColor, enabled, paddingVertical = 12, maxWidth = 80
  } = props;

  return (
    <Container
      testID="btnLogin"
      disabled={enabled === false}
      {...props}
      paddingVertical={paddingVertical}
    >
      {
        loading ? (
          <Flex>
            <ActivityIndicator size="small" color={textColor || colors.WHITE} />
          </Flex>
        ) : (
          <>
            {icon}
            <Spacer horizontal size={3} />
            <Button2 text={text} color={textColor || colors.WHITE} numberOfLines={1} style={{ maxWidth: `${maxWidth}%` }} />
          </>
        )
      }
    </Container>
  );
};

export default ButtonWithIcon;
