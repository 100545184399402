/* eslint-disable react/no-array-index-key */
import React from 'react';
import SocialMediaItem from 'molecules/social-media-item';
import { Flex, Spacer } from '@kanvas/andromeda';
import { useFormikContext, FieldArray } from 'formik';
import Button from 'atoms/button-with-icon';
import { translate } from 'locales';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from 'theme/colors';
import { TextTransform } from 'react-native-localized-text';
import { SOCIAL_ICONS } from 'components/molecules/social-dropdown';
import { Meta2 } from 'molecules/text';

const FIELD_NAME = 'social_links';

const SocialMediaLinks = () => {
  const { values, setFieldValue, errors } = useFormikContext<{ social_links: any[] }>();
  const { social_links = [] } = values;
  const { social_links: social_links_errors = [] } = errors;

  return (
    <FieldArray
      name={FIELD_NAME}
      render={(helpers) => (
        <Flex>
          <Meta2
            text={translate('social_media', TextTransform.CAPITALIZE)}
            color={colors.ICON_SECONDARY}
          />
          <Spacer size={8} />
          {social_links.map((item, index) => (
            <React.Fragment key={index}>
              <SocialMediaItem
                onUrlChange={(url) => setFieldValue(`${FIELD_NAME}.${index}.url`, url)}
                onSocialItemChange={(type) => setFieldValue(`${FIELD_NAME}.${index}.type`, type)}
                onRemove={() => helpers.remove(index)}
                item={item}
                invalid={Boolean(social_links_errors[index])}
                fieldName={`${FIELD_NAME}.${index}.url`}
              />
              <Spacer size={8} />
            </React.Fragment>
          ))}
          <Button
            text={translate('add_social_media', TextTransform.CAPITAL)}
            icon={<Icon name="add" size={18} color={colors.DARK_ORANGE} />}
            textColor={colors.DARK_ORANGE}
            color={colors.WHITE}
            onPress={() => helpers.push({ type: SOCIAL_ICONS.FACEBOOK, url: '' })}
          />
        </Flex>
      )}
    />
  );
};

export default SocialMediaLinks;
