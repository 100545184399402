// Modules
import React, { useState } from 'react';
import Tooltip from 'react-native-walkthrough-tooltip';
import { Pressable } from 'react-native-web-hover';
import { Flex } from '@kanvas/andromeda';

import { GestureDetector, Gesture } from 'react-native-gesture-handler';

// Atoms
import Text from 'components/atoms/text';
import { TouchableOpacity } from 'atoms/touchable';

interface IProps {
  children: JSX.Element
  message: string
}

const SHOWTIME = 5000;

const PressableTooltip = (props: IProps) => {

  const {
    children,
    message,
  } = props;

  // States
  const [showTip, setTip] = useState<boolean>(false);

  function handleShowTip() {
    setTip(true);
    setTimeout(() => {
      setTip(false);
    }, SHOWTIME);
  }

  const hover = Gesture.Hover()
    .onStart(() => {
      'worklet';
      
      handleShowTip()
    })

  return (
    <GestureDetector gesture={hover}>
      <TouchableOpacity
        onPress={handleShowTip}
      >

        <Tooltip
          isVisible={showTip}
          contentStyle={{ width: 300 }}
          disableShadow
          content={(
            <Flex align="center" justify="center">
              <Text
                text={message}
              />
            </Flex>
          )}
          placement="top"
          onClose={() => setTip(false)}
        >

          {children}
        </Tooltip>
      </TouchableOpacity>
    </GestureDetector>
  );
};

export default PressableTooltip;
