import React, { useEffect } from 'react';
import { Flex } from '@kanvas/andromeda';
import { Button1 } from 'components/molecules/text';
import styled from 'styled-components/native';
import UserService from 'domain/shared/services/user-service';
import Colors from 'theme/colors';
import { connect } from 'react-redux';
import CRMExtensionService from 'domain/shared/services/crm-extension-service';
import { Platform } from 'react-native';

const SIZE = 22;

interface Iprops {
  unRead: number,
  userId?: number,
  setUnRead: React.Dispatch<React.SetStateAction<number>>

}

const Circle = styled(Flex)`
    width: ${SIZE}px;
    height: ${SIZE}px;
    border-radius: ${SIZE / 2}px;
    border-color: ${Colors.WARNING};
    border-width: 1px;
    
    background-color: ${Colors.WARNING};
    left:10px;
    bottom:10px;
    z-index:999;
    position: absolute;
    overflow: hidden;
`;

const PADDING = Platform.OS === 'ios' ? 50 : 0;

const Badge = ({ userId = 0, unRead = 99, setUnRead }:Iprops) => {
  const getNotificationUnread = async () => {
    const response = await UserService.getUser(userId);
    if (response) {
      const { new_notification } = response;
      CRMExtensionService.setNotificationCount(new_notification);
      setUnRead(new_notification);
    }
  };

  useEffect(() => {
    getNotificationUnread();
  });

  return (unRead ? (
    <Circle align="center">
      <Button1 text={`${unRead}`} color={Colors.WHITE} size={10} style={{ paddingBottom: PADDING }} />
    </Circle>
  ) : (<Flex />)

  );
};

const mapStateToProps = ({ auth }: any) => ({
  userId: auth?.data?.user?.id,
});

export default connect(mapStateToProps, null)(Badge);
