import { Keyboard } from "react-native";
import { Flex } from "@kanvas/andromeda";
import styled from "styled-components/native";
import Icon from 'react-native-vector-icons/MaterialIcons';
import { TextTransform } from "react-native-localized-text";
import React, { useCallback, useMemo, useRef, useState } from "react";

import colors from "theme/colors";
import { translate } from "locales";

import useCountdown from "domain/shared/hooks/use-countdown";
import GraphUserService from "domain/shared/services/graph-user-service";

import { Header3 } from "components/molecules/text";
import ToastMessage from "components/molecules/toast-message";
import SettingsHeader from "components/molecules/settings-header";
import TwoFactorButton from "components/molecules/two-factor-button";
import TwoFactorInput, { convertToPhone } from "components/molecules/two-factor-input";

import { STATUS } from "components/organisms/two-factor-manager";
import VerificationCodeSection, { VerificationCodeSectionRef } from "components/organisms/two-factor-manager/verification-code-section";
import { showError, showSuccess } from "domain/shared/utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "domain/shared/store";
import { setMe } from "domain/shared/store/slice/auth";

function useSecurity() {
  const [phone, setPhone] = useState<string>();
  const [status, setStatus] = useState<STATUS>(STATUS.verify);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const me = useSelector((state: RootState) => state.auth.me);

  const ref = useRef<VerificationCodeSectionRef>(null);

  const { time, start, reset } = useCountdown(59, {
    finish: () => setStatus(STATUS.resend),
  });

  const handleReset = () => {
    Keyboard.dismiss();
    setStatus(STATUS.verify);
    setPhone(undefined);
    setLoading(false);
    reset();
  }

  const handlePress = async () => {
    Keyboard.dismiss();

    try {
      setStatus(STATUS.loading);
      ref.current?.clean();
      if (!me) throw new Error("missing id");
      await GraphUserService.update(me.id, { two_step_phone_number: `1${phone}` });
      await GraphUserService.sendVerificationCode();
      
      setStatus(STATUS.time);
      reset();
      start();
    } catch (e) {
      handleReset();
      showError("An Error ocurred, try again later");
    }
  }

  const handleSave = async(code: string) => {
    if (code.length < 6) {
      setError(true);
      return;
    }

    Keyboard.dismiss();
    setLoading(true);

    try {
      const valid = await GraphUserService.verifyCode(code);
      if (!valid) throw new Error('invalid code');

      const updatedMe = await GraphUserService.me();
      dispatch(setMe(updatedMe));

      showSuccess(translate("two-fact.success"));
      handleReset();
    } catch(e: any) {
      setError(true);
    } finally {
      setLoading(false)
    }
  }
  
  const description = useMemo(() => {
    const text = status === STATUS.resend ? 'two-fact.resend-code' : 'two-fact.code-sent';
    const interpolate = { phone: convertToPhone(phone || ''), time };
    return translate(text, TextTransform.NONE, { interpolate });
  }, [phone, time, status])

  const InputButton = useCallback(() => {
    if (!phone || phone.length <= 0) return null;
    return (
      <TwoFactorButton 
        status={status}
        time={time}
        value={phone || ''}
        onPress={handlePress}
      />
    );
  }, [phone, time, status]);

  return {
    model: {
      me,
      status,
      description,
      InputButton,
      error,
      phone,
      loading,
      ref,
    },
    operations: {
      setPhone,
      handleReset,
      handleSave,
      setError,
    }
  }
}

export default function Security() {
  const { model, operations } = useSecurity();

  const { description, status, InputButton, error, phone, loading, me, ref } = model;
  const { setPhone, handleReset, setError, handleSave } = operations;

  return (
    <SafeArea>
      <SettingsHeader title={translate('security')} onBack={handleReset} />
      <Container flex={1} gap={16}>
        <Header3 text={translate('two-fact.title')} />

        <Flex gap={12}>
          <TwoFactorInput
            value={phone}
            onChange={setPhone}
            placeholder={me?.contact.two_step_phone_number.slice(1)}
            label={translate('two-fact.update-title')}
          >
            <InputButton />
          </TwoFactorInput>

          <Flex gap={20}>
            <VerificationCodeSection 
              ref={ref}
              ask={false}
              status={status}
              loading={loading} 
              label={description}
              onPress={handleSave}
              buttonLabel={translate('save', TextTransform.CAPITAL)}
            />

            <ToastMessage 
              show={error}
              onHide={() => setError(false)}
              label={translate("two-fact.error")}
              Icon={<Icon name="feedback" color={colors.WARNING} size={24} />}
            />
          </Flex>
        </Flex>
      </Container>
    </SafeArea>
  );
}

const SafeArea = styled.SafeAreaView`
  flex: 1;
  background-color: ${colors.WHITE};
`;

const Container = styled(Flex)`
  padding: 0 16px;
  padding-top: 16px;
  flex: 1;
`;