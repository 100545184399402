import React, { useMemo, useState } from 'react';
import colors from 'theme/colors';
import { translate } from 'locales';
import styled from 'styled-components/native';
import { Flex, Spacer } from '@kanvas/andromeda';
import { View } from 'react-native';
import { Header1 } from 'components/molecules/text';
import { TextTransform } from 'react-native-localized-text';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import SettingsHeader from 'components/molecules/settings-header';
import DropDownWithLabel, { DropDownItem } from 'components/molecules/dropdown-with-label';
import useAuth from 'domain/shared/hooks/useAuth';
import Avatar from 'atoms/AvatarButton/AvatarButton';
import UserService from 'domain/shared/services/user-service';
import Spinner from 'components/atoms/spinner';
import { useNavigation } from '@react-navigation/native';
import { ACTIVE_SESSION } from 'domain/shared/screens';
import TabNavigationScreens from 'domain/shared/navigation/tab-nav-screens';
import { showError, showSuccess } from 'domain/shared/utils/toast';
import { IStore } from 'domain/shared/types/store';
import { refreshOpportunityList } from 'domain/shared/events/opportunity-list';
import SafeAreaView from 'atoms/SafeAreaView';
import GetToken from 'domain/shared/utils/auth/async-storage';
import { DEFAULT_COMPANY_INFO_KEY } from 'domain/shared/constants';
import AsyncStorage from '@react-native-async-storage/async-storage';

const AVATAR_SIZE = 80;

const AvatarContainer = styled.View`
  height: ${AVATAR_SIZE}px;
  width: ${AVATAR_SIZE}px;
  background-color: ${colors.ORANGE};
  border-radius: ${AVATAR_SIZE}px;
`;

const PageScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    flex: 1,
    backgroundColor: colors.WHITE,
    paddingHorizontal: 16,
    alignItems: 'center',
  },
})``;

export function useSwitchStore() {
  const {
    user,
    currentStore,
    setAuthenticatedUser,
    stores = [],
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selectedStore, setSelectedStore] = useState<IStore>(currentStore!);
  const navigation = useNavigation();

  const items = useMemo(() => (
    stores.map<DropDownItem>(({ id, name }) => ({ label: name, value: id.toString() }))
  ), [stores]);

  const screenName = TabNavigationScreens.OPPORTUNITIES.toString();
  const navigate = () => (
    navigation.navigate(screenName as never, {
      screen: ACTIVE_SESSION,
      initial: true,
    } as never)
  );

  const handlerItemSelected = (value: DropDownItem) => {
    const store = stores.find(({ id }) => id.toString() === value.value);
    if (store) {
      setSelectedStore(store);
    }
  };

  const saveIntoNewStore = async () => {
    if (!selectedStore) return;
    const { companies_id: default_company, id: default_company_branch, uuid } = selectedStore;

    const data = await GetToken(DEFAULT_COMPANY_INFO_KEY);
    await AsyncStorage.setItem(DEFAULT_COMPANY_INFO_KEY, JSON.stringify({
      ...data, default_company_branch_uuid: uuid,
    }));

    if (user) {
      const updatedUser = await UserService.updateUser(
        user.id,
        { ...user, default_company, default_company_branch },
      );
      setAuthenticatedUser(updatedUser);
    }
  };

  const handleSave = async () => {
    try {
      if (!selectedStore) {
        navigate();
        return;
      }

      setLoading(true);
      await saveIntoNewStore();
      if (currentStore && currentStore.id !== selectedStore.id) {
        await saveIntoNewStore();
        showSuccess(
          translate(
            'switchToStore',
            TextTransform.CAPITAL,
            { interpolate: { store: selectedStore.name } },
          ),
        );
      }

      refreshOpportunityList();
      navigate();
    } catch (e) {
      showError(translate('notSave'));
    } finally {
      setLoading(false);
    }
  };

  return {
    model: {
      stores: items,
      name: user?.firstname,
      currentStore: selectedStore?.id || user?.default_company_branch,
      loading,
    },
    operations: {
      handlerItemSelected,
      handleSave,
    },
  };
}

const SwitchStore = () => {
  const { model, operations } = useSwitchStore();
  if (model.loading) {
    return (
      <Flex flex={1} style={{ backgroundColor: colors.WHITE }}>
        <SafeAreaView>
          <SettingsHeader
            title={translate('switchStore', TextTransform.CAPITALIZE)}
          />
        </SafeAreaView>

        <Flex flex={1} align="center" justify="center">
          <Spinner />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex flex={1}>
      <SafeAreaView>
        <SettingsHeader
          title={translate('switchStore', TextTransform.CAPITALIZE)}
        />
      </SafeAreaView>

      <PageScrollView>
        <Spacer size={30} />
        <AvatarContainer>
          <Avatar size={AVATAR_SIZE} disabled />
        </AvatarContainer>
        <Spacer size={8} />
        <Header1 text={translate('welcomeUser', TextTransform.CAPITALIZE, {
          interpolate: {
            user: model.name,
          },
        })}
        />

        <Spacer size={17} />

        <View style={{ flex: 1, width: '100%' }}>
          <DropDownWithLabel
            items={model.stores}
            defaultValue={model.currentStore!.toString()}
            label={translate('selectStore')}
            onItemSelected={operations.handlerItemSelected}
          />

          <Spacer size={10} />

          <ButtonWithIcon
            type="button1"
            text={translate('go')}
            onPress={operations.handleSave}
          />
        </View>
      </PageScrollView>
    </Flex>
  );
};

export default SwitchStore;
