import colors from 'theme/colors';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const EsignDocs = (props:any) => {
  const { fillColor = colors.ICON_SECONDARY } = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      style={{
        enableBackground: 'new 0 0 48 48',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <Path
        d="M9.4 39.4c1.5 1.5 3.1 2.8 4.9 3.9 1.6 1 3.3 1.8 5 2.5 1.5.6 3 1.1 4.6 1.4 1.2.3 2.4.5 3.6.6.5.1 1.1 0 1.6-.3.4-.3.6-.7.6-1.2 0-.4-.1-.9-.4-1.2-.3-.4-.8-.7-1.3-.7-.9-.1-1.9-.3-3.2-.7-1.4-.3-2.8-.8-4.1-1.3-1.6-.6-3-1.3-4.5-2.2-1.5-.9-3-2.1-4.2-3.4-2.1-2.1-3.9-4.5-5.2-7.2-1.2-2.5-2-5.2-2.4-8-.3-2.6-.1-5.2.6-7.7.7-2.4 2-4.6 3.7-6.3 1.4-1.4 3.1-2.5 4.9-3.1 1.9-.7 3.8-1 5.8-1.1 2 0 4.1.2 6 .7 2 .5 3.9 1.3 5.7 2.2l2.6-2.6c-2.2-1.3-4.6-2.3-7-3-2.4-.7-4.9-1-7.4-.9-2.4 0-4.8.5-7.1 1.4-2.2.9-4.3 2.2-6 3.9-1.7 1.8-3 3.7-3.9 5.9-.9 2.2-1.4 4.5-1.6 6.9-.2 2.5.1 5 .6 7.4.6 2.6 1.6 5.1 2.9 7.4 1.4 2.5 3.1 4.7 5.2 6.7zm8.3-6.7c1.4 1.5 3.1 2.8 4.8 3.8 1.7 1.1 3.6 1.9 5.5 2.5 1.8.6 3.8.9 5.7 1 1.8 0 3.6-.2 5.4-.8 1.7-.6 3.3-1.6 4.6-2.9 1.3-1.3 2.4-3 3-4.8.6-1.9.9-3.9.8-5.9-.1-2.2-.6-4.4-1.4-6.4-.8-2.3-2-4.4-3.4-6.4L40 15.3c1.4 2.1 2.5 4.3 3.2 6.7.6 2.1.8 4.3.5 6.5-.3 2-1.2 3.8-2.6 5.2-1.2 1.3-2.7 2.1-4.4 2.6-1.7.4-3.4.4-5.1.1-1.8-.4-3.6-1-5.3-1.9-1.7-.9-3.3-2.1-4.7-3.4l-3.9 1.6zm3.3-3.6L41.1 9l-3.6-3.6-20.1 20.1-1.9 4.5c-.1.2-.1.5.2.7.2.2.5.3.7.2l4.6-1.8zM42.8 7.3l1.9-1.9c.4-.4.7-1 .7-1.6 0-.6-.2-1.2-.7-1.6l-.6-.6c-.4-.4-.9-.6-1.5-.6s-1.1.3-1.5.7l-1.9 1.9 3.6 3.7z"
        fill={fillColor}
        stroke={fillColor}
      />
    </Svg>
  );
};

export default EsignDocs;
