/* eslint-disable react/no-unused-prop-types */
import React, { forwardRef, useCallback } from 'react';
import Spinner from 'components/atoms/spinner';
import {
  FlatList, ListRenderItem, StyleProp, ViewStyle,
} from 'react-native';
import { Flex, Spacer } from '@kanvas/andromeda';

import { Lead } from 'domain/opportunity-list/types';

const ITEMS_PER_PAGE = 20;

const contentContainerStyleDefault: StyleProp<ViewStyle> = {
  paddingHorizontal: 12,
  paddingVertical: 10,
};

interface Props {
  data: Lead[];
  loading?: boolean;
  onRefresh?: VoidFunction;
  onPaginate?: VoidFunction;
  onItem?: (lead: Lead) => void;
  renderItem: ListRenderItem<Lead>;
  Footer?: JSX.Element | false;
  contentContainerStyle?: StyleProp<ViewStyle>
}

function RenderFooter({ loading, data, Footer }: Props) {
  if (!loading) return Footer || null;

  const marginVertical = data?.length > 0 ? 40 : 60;
  return (
    <Flex flex={1} justify="center" align="center" style={{ marginVertical }}>
      <Spinner />
    </Flex>
  );
}

export type LeadListRef = FlatList<Lead>;

const LeadList = forwardRef<LeadListRef, Props>((props, ref) => {
  const {
    data,
    onRefresh,
    onPaginate,
    renderItem,
    contentContainerStyle,
  } = props;

  const ListFooterComponent = useCallback(() => <RenderFooter {...props} />, [props]);
  const ItemSeparatorComponent = useCallback(() => <Spacer size={6} />, []);

  return (
    <FlatList
      ref={ref}
      data={data}
      windowSize={2}
      refreshing={false}
      onRefresh={onRefresh}
      renderItem={renderItem}
      onEndReached={onPaginate}
      onEndReachedThreshold={0.3}
      keyExtractor={(item) => item.uuid}
      initialNumToRender={ITEMS_PER_PAGE}
      maxToRenderPerBatch={ITEMS_PER_PAGE}
      ListFooterComponent={ListFooterComponent}
      ItemSeparatorComponent={ItemSeparatorComponent}
      keyboardDismissMode="on-drag"
      contentContainerStyle={contentContainerStyle || contentContainerStyleDefault}
    />
  );
});

export default LeadList;
