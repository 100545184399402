import { gql } from '@apollo/client';

const getProductsArray = (productsIn: string[]) => (
  productsIn.map((p) => `"${p}"`).join(',')
);

const whereParam = (productsIn: string[], operator = 'NOT_IN') => {
  if (productsIn.length) {
    return `, where: { column: UUID, operator: ${operator}, value: [${getProductsArray(productsIn)}]}`;
  }
  return '';
};

const attributesFilters = (id: string, value: string) => {
  if (value === 'all') return '';

  if (value.length) {
    return `, hasAttributesValues: {
      AND: [
        { column: VALUE, operator: EQ, value: "${value}" },
        { column: ATTRIBUTES_ID, operator: EQ, value: ${id} },
      ]
    }`;
  }

  return '';
};

const getProductVariantsPaginatedQuery = (withVisitor?: boolean, productsIn: string[] = [], operator = 'NOT_IN', attributesFilter = { id: '', value: '' }) => gql`
  query getAllProductVariants($channel: String!, $query: String, $page: Int ${withVisitor ? ', $visitor: VisitorEntityInput!' : ''}) {
    channelVariants(
      id: $channel,
      search: $query,
      first: 25,
      page: $page
      ${whereParam(productsIn, operator)}
      ${attributesFilters(attributesFilter.id, attributesFilter.value)}
    ) {
      data {
            id,
            uuid,
            name,
            description,
            slug,
            sku,
            ${withVisitor ? `
              interactions (visitor: $visitor) {
                like
                dislike
                save
              }
            ` : ''}
            files {
               data {
                  url
               }
            }
            product{
                name
            },
            warehouses{
                quantity
            },
            attributes {
                name,
                value
            },
            channels{
                discounted_price,
                price,
                warehouses_id,
                is_published
            }
        }
    }
  }
`;

export default getProductVariantsPaginatedQuery;
