/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import { ScannedData } from 'domain/barcode-scanner/types';

interface Props {
  licence: string;
  onScan?: (data: ScannedData) => void;
  onBack?: VoidFunction;
}

export default function Scanner(props: Props) {
  return null;
}
