import React, { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from 'theme/colors';
import { useNavigation } from '@react-navigation/native';
import { BUTTON_HIT_SLOP, IS_WEB } from 'domain/shared/constants';
import { BorderlessButton } from '../touchable';

const TouchButton = IS_WEB ? TouchableOpacity : BorderlessButton;

interface IProps {
  onBack?: () => void;
}

const BackButton = (props: IProps) => {
  const navigation = useNavigation();
  const { onBack } = props;

  const onPress = useCallback(() => {
    navigation.goBack();
    onBack?.();
  }, []);

  return (
    <TouchButton onPress={onPress} hitSlop={BUTTON_HIT_SLOP}>
      <Icon name="arrow-back" size={24} color={colors.WHITE} />
    </TouchButton>
  );
};

export default BackButton;
