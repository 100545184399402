import { Platform } from 'react-native';

const IS_WEB = Platform.OS === 'web';
// @ts-ignore
const NAVIGATOR = IS_WEB ? navigator : undefined;
// @ts-ignore
const WINDOW = IS_WEB ? window : undefined;

export const isMobileWebDevice = () => {
  if (NAVIGATOR) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(NAVIGATOR.userAgent)) {
      return true;
    }
    return false;
  }
  return false;
};

export const isMobileSafari = () => {
  if (NAVIGATOR) {
    if (/iPhone|iPad|iPod/i.test(NAVIGATOR.userAgent)) {
      return true;
    }
    return false;
  }
  return false;
};

// THIS FUNCTION WILL RETURN TRUE IF APPLICATION IS RUN
// INSIDE AN IFRAME
export const isRunningOnChromeExtension = () => {
  if (WINDOW) {
    if (WINDOW.self !== WINDOW.top) {
      return true;
    }
  }
  return false;
};

export default isMobileWebDevice;
