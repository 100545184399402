/* eslint-disable react/no-unstable-nested-components */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { ListRenderItemInfo, StyleSheet } from 'react-native';
import Update from 'components/molecules/notification-updates';
import { Flex, Spacer } from '@kanvas/andromeda';
import PaginatedFlatList, { PaginatedFlatListHandler } from 'organisms/paginated-flatlist';
import MessagesService from 'domain/shared/services/messages-service';
import colors from 'theme/colors';
import { EventRegister } from 'react-native-event-listeners';
import { ActivityListEvents, emitActivityListEvent } from 'domain/shared/events/activities-list';
import { Notification } from 'domain/shared/types/notification';
import NotificationNotFound from 'components/molecules/notification-not-found';
import { useSelector } from 'react-redux';
import Spinner from 'components/atoms/spinner';
import Pusher from 'pusher-js/react-native';
import { PUSHER_API_KEY } from 'env';
import { RootState } from 'domain/shared/store';
import useAuth from '../../../domain/shared/hooks/useAuth';

const styles = StyleSheet.create({
  ListFooterComponentStyle: { paddingVertical: 16 },
  contentContainerStyle: { paddingTop: 16, paddingBottom: 20 },
  container: { backgroundColor: colors.WHITE, flex: 1 },
});

const NotificationListUpdate = () => {
  const [loading, setLoading] = useState(true);
  const listRef = useRef<PaginatedFlatListHandler>(null);
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  const reloadEventHandler = useRef('');
  const { currentStore } = useAuth();
  const company_id = currentStore?.id;
  const renderItem = useCallback((e: ListRenderItemInfo<Notification>) => {
    const { item } = e;

    const {
      content,
      read,
      entity,
      created_at,
    } = item;

    return (
      <Update
        content={content}
        time={created_at}
        read={read}
        lead_id={entity.leads_id}
        entity={item.entity}
        message_variable={entity.message_variable}
      />
    );
  }, []);

  const keyExtractor = useCallback((notification: Notification) => `${notification.id.toString()}`, []);

  const fetchData = useCallback(async (pageNumber: number) => {
    if (pageNumber > 1) {
      setLoading(true);
    }
    const notifications = await MessagesService.getAllUpdatePaginated(
      pageNumber,
      company_id!,
    );

    setLoading(false);
    return notifications;
  }, [company_id]);

  const listener = () => {
    const pusher = new Pusher(PUSHER_API_KEY, {
      cluster: 'us2',
    });

    const channelName = `total-notification-counter-${userId}-company-${company_id}`;
    const channel = pusher.subscribe(channelName);

    channel.bind('newNotification', () => {
      emitActivityListEvent(
        ActivityListEvents.ACTIVITY_LIST_UPDATE,
        null,
      );
    });
  };

  useEffect(() => {
    if (userId && company_id) {
      listener();
    }
  }, [userId, company_id]);

  useEffect(() => {
    reloadEventHandler.current = EventRegister.on(ActivityListEvents.ACTIVITY_LIST_UPDATE, () => {
      setTimeout(() => {
        listRef.current?.refreshData();
      }, 1000);
    }).toString();

    return () => {
      EventRegister.rm(reloadEventHandler.current);
    };
  }, []);

  return (
    <Flex flex={1} paddingHorizontal={16} style={styles.container}>
      {
        company_id !== undefined && (
          <PaginatedFlatList
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            ItemSeparatorComponent={() => <Spacer size={6} />}
            fetchData={fetchData}
            ListFooterComponent={loading ? <Spinner /> : null}
            showsVerticalScrollIndicator={false}
            ListFooterComponentStyle={styles.ListFooterComponentStyle}
            contentContainerStyle={styles.contentContainerStyle}
            ListEmptyComponent={!loading ? <NotificationNotFound /> : null}
            // @ts-ignore
            ref={listRef}
          />
        )
      }
    </Flex>

  );
};

export default NotificationListUpdate;
