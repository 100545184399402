import { useSelector } from "react-redux";

import { RootState } from "domain/shared/store";
import { IS_WEB } from "domain/shared/constants";
import { LeadAction } from "domain/shared/types/lead-action";
import ActionService from "domain/shared/services/action-service";
import OpportunityService from "domain/shared/services/opportunity-service";
import fileSystemUploadFiles from "domain/shared/graphql/fileSystemUploadFiles";
import { File } from "domain/shared/types/message";
import CustomFieldsApi from "domain/shared/services/custom-fields";

export default function useSubmitForm(action: LeadAction) {
  const { activeOpportunity: lead } = useSelector((state: RootState) => state.opportunity);

  const uploadToFilesystem = async (files?: any[]) => {
    if (!files || files.length <= 0) return [];
    // if (!IS_WEB) return await fileSystemUploadFiles(files) as any as File[];
    return OpportunityService.uploadPhoto(files);
  }

  const submit = async (values: any, files?: any[]): Promise<boolean> => {
    if (!lead) return false;

    const { uuid } = lead.leads_receivers;
    const { slug, id, description } = action;

    try {
      const uploadedFiles = await uploadToFilesystem(files);
      const messageFiles = uploadedFiles.map((file) => ({ field_name: file.name, filesystem_id: file.id }));

      
      const content = await ActionService.getActionPreview(slug, {
        extraField: {},
        lead_id: lead.uuid,
        receiver_id: uuid || 0,
        company_action_id: id.toString(),
        contact_id: lead.people?.uuid || 0,
      });

      const { customFields } = await CustomFieldsApi.get({ entity_id: lead.uuid, name: 'check_list_status' });

      let activeTaskListId: number = 0;

      if (customFields.check_list_status) {
        activeTaskListId = customFields.check_list_status.activeTaskListId
      }

      const data = {
        verb: slug,
        leads_uuid: lead.uuid,
        files: messageFiles,
        message: {
          data: values,
          via: 'checklist',
          source: 'mobile',
          text: description,
          files: messageFiles,
          status: 'submitted',
          user_uuid: content.params.users_id,
          visitor_id: content.params.visitor_id,
          contact_uuid: content.params.contact_id,
          checklistId: activeTaskListId,
        }
      }

      await ActionService.postReceiversMessage(uuid, data); 

      return true;
    } catch (e: any) {
      console.log('ERROR');
      console.log(JSON.stringify(e?.response));
      return false;
    }
  }


  const separateFilesFromFields = (values: Record<string, any>) => {
    const entries = Object.entries(values);

    const uploadFields = Object.keys(values).filter((key) => typeof (values as any)[key] === 'object');
    const formField = Object.keys(values).filter((key) => typeof (values as any)[key] !== 'object');

    const form = Object.fromEntries(entries.filter(([key]) => formField.includes(key)));
    const files = Object.fromEntries(entries.filter(([key]) => uploadFields.includes(key)));

    return { form, files };
}

  return { submit, separateFilesFromFields };
}