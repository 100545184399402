// Generate required css
import MaterialIcons from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import Ionicons from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import FontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import Feather from 'react-native-vector-icons/Fonts/Feather.ttf';
import Foundation from 'react-native-vector-icons/Fonts/Foundation.ttf';
import SimpleLineIcons from 'react-native-vector-icons/Fonts/SimpleLineIcons.ttf';
import FA5IconSolid from 'react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf';
import FA5IconRegular from 'react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf';
import MaterialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';

const FONTS = {
  MaterialIcons,
  Ionicons,
  FontAwesome,
  'FontAwesome5_Solid': FA5IconSolid,
  'FontAwesome5_Regular': FA5IconRegular,
  Feather,
  Foundation,
  MaterialCommunityIcons,
  SimpleLineIcons,
}

const iconFontStyles = Object.keys(FONTS).map((fontName) => (
  `
  @font-face {
    src: url(${FONTS[fontName]});
    font-family: ${fontName};
  }
  `
));

console.log(iconFontStyles);

const fontString = iconFontStyles.join('');
// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = fontString;
} else {
  style.appendChild(document.createTextNode(fontString));
}

// Inject stylesheet
document.head.appendChild(style);