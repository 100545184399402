import { gql } from "@apollo/client";

export const queryLeadSourceById = gql`
  query getLeadSources($id: Mixed) {
    leadSources(where: { column: ID, operator: EQ, value: $id }) {
      data {
        id,
        uuid,
        name,
        description
      }
    }
  }
`;

export const queryLeadSourceByIdAndDescription = gql`
  query getLeadSources($id: Mixed, $description: Mixed) {
    leadSources(where: { 
      column: ID, operator: EQ, value: $id 
      AND: {column: DESCRIPTION, operator: EQ, value: $description }
    }) {
      data {
        id,
        uuid,
        name,
        description
      }
    }
  }
`;

export const queryLeadSourceByDescription = gql`
  query getLeadSources($id: Mixed, $description: Mixed) {
    leadSources(where: {
      column: ID, operator: NOT_LIKE, value: $id, 
      AND: {column: DESCRIPTION, operator: EQ, value: $description }
    }) {
      data {
        id,
        uuid,
        name,
        description
      }
    }
  }
`;

export const queryLeadSource = gql`
  query getLeadSources($id: Mixed) {
    leadSources(where: { column: ID, operator: NOT_LIKE, value: $id }) {
      data {
        id,
        uuid,
        name,
        description
      }
    }
  }
`;
