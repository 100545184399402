import * as React from 'react';
import Svg, {
  Defs, ClipPath, Path, G,
} from 'react-native-svg';
import Colors from 'theme/colors';

function CreditApp(props: any) {
  const { fillColor = Colors.SECONDARY_TEXT } = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={1080}
      height={1080}
      viewBox="0 0 1080 1080"
      {...props}
    >
      <Defs>
        <ClipPath id="prefix__a">
          <Path d="M0 0h1080v1080H0z" />
        </ClipPath>
      </Defs>
      <G data-name="Custom Size \u2013 9" clipPath="url(#prefix__a)">
        <Path d="M0 0h1080v1080H0z" />
        <Path
          data-name="Path 582"
          d="M189.193 789.436q-19.839 0-32.419-12.339t-12.581-31.21v-85.161q0-19.355 12.581-31.452t32.419-12.1h112.258q20.323 0 32.661 12.1t12.339 31.452v85.161q0 18.871-12.339 31.21t-32.661 12.339zM0 439.113V333.629h1080v105.484zm147.58 494.516h784.839q73.548 0 110.564-36.774t37.016-108.871V292.017q0-72.1-37.016-108.871t-110.564-36.775H147.58q-73.064 0-110.322 36.533T0 292.017v495.967q0 72.581 37.258 109.113t110.322 36.532z"
          fill={fillColor}
        />
      </G>
    </Svg>
  );
}

export default CreditApp;
