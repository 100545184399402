import { GenericAbortSignal } from 'axios';
import { Lead } from 'domain/opportunity-list/types';
import axios from 'domain/shared/services/axios';

interface Params {
  format?: boolean;
  page?: number;
  limit?: number;
  text?: string;
  sort?: string;
  filters?: string;
  ExactMatch?: boolean;
}

export interface FormatedResponse<T> {
  limit: number;
  page: number;
  search_job_key: string;
  total_pages: number;
  data: T[];
}

const COMMON_PARAMS: Params = {
  format: true,
  page: 1,
  limit: 25,
  text: '',
};

export default class SearchApi {
  static async get(data?: Params): Promise<FormatedResponse<Lead>> {
    const params: Params = { ...COMMON_PARAMS, ...data };
    const response = await axios.get<FormatedResponse<Lead>>('/search/leads', { params });
    return response.data;
  }

  static async search(data?: Params, signal?: GenericAbortSignal): Promise<FormatedResponse<Lead>> {
    const params: Params = { ...COMMON_PARAMS, ...data };
    const response = await axios.get<FormatedResponse<Lead>>(
      '/search/eLeads',
      { params, signal },
    );
    return response.data;
  }

  static async find(uuid: string): Promise<Lead | Lead[]> {
    const response = await axios.get<Lead | Lead[]>(`/leads/${uuid}`, {
      params: {
        third_party: 'true',
      },
    });
    return response.data;
  }
}
