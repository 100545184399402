import React, {
  ForwardedRef, forwardRef, useImperativeHandle, useMemo, useRef, useState,
} from 'react';
import Modal from 'react-native-modal';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { Flex } from '@kanvas/andromeda';
import colors from 'theme/colors';
import {
  Body3, Button1, Header2,
} from 'components/molecules/text';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { TouchableOpacity } from 'components/atoms/touchable';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import FilterSlider, { FilterSliderRef } from 'components/molecules/filter-slider';
import PillListFilter, { PillFilterItem, PillListFilterRef } from 'components/molecules/pill-list-filter';
import { useAsync } from 'react-use';
import InventoryService from 'domain/shared/services/inventory-service';
import { Attributes } from 'domain/shared/types/attributes';

const condition = [
  {
    key: undefined,
    title: 'All',
  },
  {
    key: 'new',
    title: 'New',
  },
  {
    key: 'used',
    title: 'Used',
  },
];

const Container = styled(Flex)`
  width: 100%;
  min-height: 71%;
  padding: 30px 0;
  gap: 18px;
  background-color: ${colors.WHITE};
  border-radius: 12px;
`;

const Content = styled.ScrollView`
  flex: 1;
`;

const Root = styled(Modal)`
  margin: 0;
  justify-content: flex-end;
`;

const sortAttributes = (attrs: Attributes) => {
  const sortFilter = (a: any, b: any) => Number(a.value) - Number(b.value);
  const sorted = attrs.values.sort(sortFilter);
  return sorted.map((item) => Number(item.value)) as [number, number];
};

export interface FilterValues {
  millage?: [number, number];
  price?: [number, number];
  new?: string;
  body?: string;
}

interface Props {
  defaultValue?: FilterValues;
  // eslint-disable-next-line react/no-unused-prop-types
  onApply?: (value: FilterValues) => void;
}

export interface FilterModalRef {
  onOpen?: () => void;
  onClose?: () => void;
}

function useFilterModal(props: Props, ref: ForwardedRef<FilterModalRef>) {
  const [visible, setVisible] = useState(false);
  const milageRef = useRef<FilterSliderRef>(null);
  const priceRef = useRef<FilterSliderRef>(null);
  const conditionRef = useRef<PillListFilterRef>(null);
  const bodyRef = useRef<PillListFilterRef>(null);

  const [values, setValues] = useState<FilterValues>(props.defaultValue || {});

  const handleClose = () => setVisible(false);

  const handleReset = () => {
    milageRef.current?.onReset();
    priceRef.current?.onReset();
    conditionRef.current?.onReset();
    bodyRef.current?.onReset();
  };

  const handleApply = () => {
    // i got supper lazy to use state here lol so like this works
    const millage = milageRef.current?.value;
    const price = priceRef.current?.value;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const condition = conditionRef.current!.value;
    const body = bodyRef.current!.value;

    const response: any = {};

    if (millage) response.millage = millage;
    if (price) response.price = price;
    if (condition) response.new = condition;
    if (body) response.body = body;

    props.onApply?.(response);
    setValues(response);
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    onClose: () => handleClose(),
    onOpen: () => setVisible(true),
  }));

  const { value } = useAsync(async () => {
    const [bodyData, millageData, priceData] = await Promise.all(
      [
        InventoryService.getAttributes('body'),
        InventoryService.getAttributes('millage_range'),
        InventoryService.getAttributes('price_range'),
      ],
    );

    const body = bodyData[0];
    const millage = millageData[0];
    const price = priceData[0];

    return {
      body,
      millage,
      price,
    };
  }, []);

  const body = useMemo(() => {
    if (!value) return [];
    if (!value.body) return [];

    return value.body.values.map<PillFilterItem>(
      // eslint-disable-next-line @typescript-eslint/no-shadow
      ({ value }: any) => ({ key: value, title: value }),
    );
  }, [value]);

  const millage = useMemo<[number, number]>(() => {
    if (!value) return [0, 1];
    if (!value.millage) return [0, 1];
    return sortAttributes(value.millage);
  }, [value]);

  const price = useMemo<[number, number]>(() => {
    if (!value) return [0, 1];
    if (!value.price) return [0, 1];
    return sortAttributes(value.price);
  }, [value]);

  return {
    model: {
      values,
      visible,
      milageRef,
      priceRef,
      conditionRef,
      bodyRef,
      body,
      millage,
      price,
    },
    operations: {
      handleClose,
      handleReset,
      handleApply,
    },
  };
}

const FilterModal = forwardRef<FilterModalRef, Props>((props, ref) => {
  const { defaultValue } = props;
  const { model, operations } = useFilterModal(props, ref);

  const {
    visible, milageRef, priceRef, bodyRef, conditionRef, body, millage, price, values,
  } = model;
  const { handleClose, handleReset, handleApply } = operations;

  return (
    <Root
      isVisible={visible}
      animationIn="slideInUp"
      useNativeDriverForBackdrop
      animationOut="slideOutDown"
      onBackdropPress={handleClose}
      onBackButtonPress={handleClose}
      useNativeDriver={Platform.OS === 'android'}
    >
      <Container>
        <Flex row justify="space-between" align="center" style={{ paddingHorizontal: 16 }}>
          <Header2 text={translate('filter', TextTransform.CAPITAL)} />

          <TouchableOpacity onPress={handleReset}>
            <Button1
              color={colors.ORANGE}
              text={translate('reset', TextTransform.CAPITAL)}
            />
          </TouchableOpacity>
        </Flex>

        <Content
          contentContainerStyle={{ gap: 18, paddingHorizontal: 16 }}
        >
          <Flex gap={12}>
            <Body3 text={translate('condition', TextTransform.CAPITAL)} />
            <PillListFilter
              only={defaultValue?.new}
              value={values.new}
              ref={conditionRef}
              items={condition}
            />
          </Flex>

          <Flex gap={4}>
            <Body3 text={translate('milage', TextTransform.CAPITAL)} />
            <FilterSlider
              value={values.millage}
              min={millage[0]}
              max={millage[1]}
              ref={milageRef}
              label="milageSort"
            />
          </Flex>

          <Flex gap={4}>
            <Body3 text={translate('price', TextTransform.CAPITAL)} />
            <FilterSlider
              value={values.price}
              min={price[0]}
              max={price[1]}
              ref={priceRef}
              label="priceSort"
            />
          </Flex>

          <Flex gap={12}>
            <Body3 text={translate('bodyType', TextTransform.CAPITAL)} />
            <PillListFilter
              ref={bodyRef}
              value={values.body}
              items={[{ key: undefined, title: 'All' }, ...body]}
            />
          </Flex>
        </Content>

        <Flex style={{ paddingHorizontal: 16 }}>
          <ButtonWithIcon
            onPress={handleApply}
            text={translate('apply', TextTransform.CAPITAL)}
          />
        </Flex>
      </Container>
    </Root>
  );
});

export default FilterModal;
