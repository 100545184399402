import React, { useCallback, useContext, useMemo } from 'react';
import { TouchableWithoutFeedback } from 'components/atoms/touchable';
import { ViewProps } from 'react-native';

import { Flex } from '@kanvas/andromeda';

import styled from 'styled-components/native';
import Colors from 'theme/colors';
import FastImage from 'react-native-fast-image';
import { currencyFromNumber } from '@kanvas/andromeda/lib/components/currency';
import Checkbox from 'atoms/checkbox';
import { ProductListContext } from 'domain/share-product/product-list-context';
import { ProductVariant } from 'domain/shared/types/product-variants';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Badge from 'atoms/badge';

import { DropShadow } from 'domain/shared/styles';
import { IS_WEB } from 'domain/shared/constants';
import { RootState } from 'domain/shared/store';
import { useSelector } from 'react-redux';
import { Body3, Body4, Meta2 } from '../text';

interface IProps {
  item: ProductVariant;
  onPress?: (item: ProductVariant, multipleSelectEnabled?: boolean) => void;
}

const Container = styled(Flex) <{ liked: boolean }>`
  background-color: ${({ liked = false }) => (liked ? Colors.PRIMARY_DEEMPH : Colors.WHITE)};
  border-width: 1px;
  border-color: ${Colors.BUTTON_BORDER};
  border-radius: 8px;
  padding-right: 12px;
  width: 100%;
  min-height: 78px;
  ${DropShadow}
  ${IS_WEB ? 'cursor: pointer;' : ''}
`;

const ImageContainer = styled.View<ViewProps & { $bg: boolean }>`
  width: 100px;
  min-height: 78px;
  background-color: ${({ $bg }: any) => ($bg ? Colors.IMAGE_BACKGROUND : 'transparent')};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
`;

const Image = styled(FastImage)`
  width: 100%;
  height: 100%;
`;

const CardBadge = styled.View`
  /* top: 0px; */
  z-index: 1;
  position: absolute;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

const ProductName = styled(Body3)`
  ${() => (IS_WEB ? 'line-height: 12px;' : '')}
  height: 20px;
`;

function ProductListItem(props: IProps) {
  const {
    item,
    onPress,
  } = props;
  const { multipleSelectEnabled, selectedItems } = useContext(ProductListContext);
  const { interactions: { like = false, dislike = false } = {} } = item;
  const selected = Boolean(selectedItems[item.id]);
  const activeOpportunity = useSelector((state: RootState) => state.opportunity.activeOpportunity);

  const isPrimaryVehicle = useMemo(() => {
    if (!activeOpportunity) return false;
    const { vehicle_of_interest = {} } = activeOpportunity.custom_fields as any;
    return vehicle_of_interest?.vin === item?.slug;
  }, [activeOpportunity, item]);

  const getProductName = useCallback(() => (
    `${item.name}`
  ), [item]);

  const getProductInfoFirstRow = useCallback(() => {
    const year = item.attributes.find((i) => i.name === 'year');
    const mileage = item.attributes.find((i) => i.name === 'odometer');

    return `${year?.value} | ${mileage?.value} miles`;
  }, [item]);

  const getProductInfoSecondRow = useCallback(() => {
    const stock = item.attributes.find(
      (attr) => attr.name === 'stock_number',
    );

    return `Stock: ${stock?.value}`;
  }, [item]);

  const getItemPrice = useCallback(() => (
    currencyFromNumber(
      item.channels.price,
      { precision: 0 },
    )
  ), [item]);

  const onItemPress = useCallback(() => {
    onPress?.(item, multipleSelectEnabled);
  }, [item, onPress, multipleSelectEnabled]);

  const cover = item.files.data[0];
  return (
    <TouchableWithoutFeedback onPress={onItemPress}>
      <Container liked={like}>
        <Flex row flex={1} align="center">
          <Flex row gap={8} flex={1} style={{ height: 78, overflow: 'hidden' }}>
            <ImageContainer $bg={!cover?.url} style={{ position: 'relative' }}>
              <CardBadge>
                {
                  dislike && (
                    <Badge style={{ position: 'absolute', top: 0 }}>
                      <MaterialCommunityIcons name="thumb-down" size={14} color={Colors.WHITE} />
                    </Badge>
                  )
                }
                {
                  like && (
                    <Badge style={{ position: 'absolute', top: 0 }}>
                      <MaterialCommunityIcons name="thumb-up" size={14} color={Colors.WHITE} />
                    </Badge>
                  )
                }
                {
                  isPrimaryVehicle && (
                    <Badge box style={{ width: '100%', position: 'absolute', bottom: 0 }}>
                      <Meta2 text="Primary" color={Colors.WHITE} />
                    </Badge>
                  )
                }
              </CardBadge>
              <Image source={{ uri: cover?.url, headers: { Accept: '*/*' } }} />
            </ImageContainer>

            <Flex flex={1} style={{ paddingTop: 16 }}>
              <ProductName
                numberOfLines={1}
                link
                text={getProductName()}
                color={Colors.PRIMARY_TEXT}
              />
              <Meta2 numberOfLines={1} text={getProductInfoFirstRow()} />
              <Meta2 numberOfLines={1} text={getProductInfoSecondRow()} />
            </Flex>
          </Flex>
          <Body4 link text={getItemPrice()} color={Colors.PRIMARY_TEXT} />
          {
            multipleSelectEnabled && (
              <Flex paddingHorizontal={12} pointerEvents="none">
                <Checkbox value={selected} />
              </Flex>
            )
          }
        </Flex>
      </Container>
    </TouchableWithoutFeedback>
  );
}

export default React.memo(ProductListItem);
// export default ProductListItem;
