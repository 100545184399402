import styled from 'styled-components/native';
import React from 'react';
import { connect } from 'react-redux';
import WebView from 'react-native-webview';
import PreviewHeader from 'components/molecules/preview-header';
import { Flex } from '@kanvas/andromeda';
import Colors from 'theme/colors';
import { useRoute } from '@react-navigation/native';
import { RootState } from 'domain/shared/store';

const Container = styled(Flex)``;

const Background = styled(Flex)`
  background-color: ${Colors.WHITE};
  flex: 1;
`;

const INJECTED_JAVASCRIPT = `(function() {
  const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta);
})();`;

const InAppBrowse = ({ title }: any) => {
  const route = useRoute();
  const { url = '' } = route.params as any;

  return (
    <Flex flex={1}>
      <PreviewHeader
        // actionName={title}
        iconName="close"
      />
      <Background flex={1}>
        <Container flex={1}>
          <Flex flex={1} style={{ backgroundColor: Colors.SUBTILE }}>
            <WebView
              source={{ uri: url }}
              scalesPageToFit
              injectedJavaScript={INJECTED_JAVASCRIPT}
              style={{ overflow: 'hidden' }}
              showsHorizontalScrollIndicator={false}
            />
          </Flex>
        </Container>
      </Background>
    </Flex>
  );
};

const mapStateToProps = ({ opportunity }: RootState) => ({
  title: opportunity.activeOpportunity?.title,
});

export default connect(mapStateToProps, null)(InAppBrowse);
