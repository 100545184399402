import { getBottomSpace, isIphoneX } from "react-native-iphone-x-helper";
import Modal, { ModalProps } from 'react-native-modal';
import React, { PropsWithChildren } from "react";
import styled from "styled-components/native";
import { Flex } from "@kanvas/andromeda";
import { Platform } from "react-native";

// constants
import colors from "theme/colors";

interface Props extends PropsWithChildren {
  visible: boolean;
  onClose?: VoidFunction;
}

export default function ChecklistModal(props: Props) {
  const { visible, children, onClose } = props;

  return (
    <Modal {...modalProps} isVisible={visible} onBackdropPress={onClose}>
      <Container gap={12}>
        {children}
      </Container>
    </Modal>
  );
}

const modalProps: Partial<ModalProps> = {
  hasBackdrop: true,
  animationIn: 'slideInUp',
  animationOut: 'slideOutDown',
  useNativeDriver: Platform.OS === 'android',
  useNativeDriverForBackdrop: true,
  style: { margin: 0, flex: 1, justifyContent: 'flex-end' },
}

function bottom() {
  if (Platform.OS === 'android') return 30;
  if (isIphoneX()) return getBottomSpace();
  return 40;
}

const Container = styled(Flex)`
  width: 100%;
  padding: 12px;
  padding-bottom: ${bottom()}px;
  background-color: ${colors.WHITE};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;