import { TabBarHeight } from 'components/organisms/CXTabBar/constants';
import { useLayoutEffect, useState } from 'react';

// @ts-ignore;
const WINDOW = window;

function useWindowSize() {
  const [size, setSize] = useState([WINDOW.innerWidth, WINDOW.innerHeight - TabBarHeight]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([WINDOW.innerWidth, WINDOW.innerHeight - TabBarHeight]);
    }
    WINDOW.addEventListener('resize', updateSize);
    updateSize();
    return () => WINDOW.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default useWindowSize;
