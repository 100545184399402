/* eslint-disable no-unused-expressions */
import React, {
  useCallback, useState, useImperativeHandle, forwardRef,
} from 'react';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from 'theme/colors';
import { Flex } from '@kanvas/andromeda';

function TimerComponent(props: any, ref: any) {
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSeconds] = useState(0);
  const [started, setStarted] = useState(false);

  const { onTimerStopped, onTimerStarted } = props;

  const startTimer = useCallback(() => {
    if (!started) {
      setStarted(true);
      onTimerStarted && onTimerStarted();
    }
  }, [started, onTimerStarted]);

  const setTimerInitialValue = useCallback((seconds = 0, minutes = 0, hours = 0) => {
    setMin(minutes);
    setSeconds(seconds);
    setHour(hours);
  }, []);

  const killTimer = useCallback(() => {
    setStarted(false);
    setSeconds(0);
    setMin(0);
    setHour(0);
  }, []);

  const stopTimer = useCallback(() => {
    killTimer();
    onTimerStopped && onTimerStopped({ hour, min, sec });
  }, [onTimerStopped, hour, min, sec, killTimer]);

  useImperativeHandle(ref, () => ({
    start: startTimer,
    stop: stopTimer,
    setTimerValues: setTimerInitialValue,
    kill: killTimer,
  }));

  return (
    started ? (
      <Flex row align="center" gap={5}>
        <Icon name="pause" size={24} color={colors.WHITE} />
      </Flex>
    ) : null
  );
}

const Timer = forwardRef(TimerComponent);

export default Timer;
