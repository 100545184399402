import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import Modal from 'react-native-modal';
import styled from 'styled-components/native';
import { Flex } from '@kanvas/andromeda';
import { Button2 } from '../text';
import { TouchableOpacity } from 'components/atoms/touchable';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { ButtonBase } from 'components/atoms/button-with-icon';
import { translate } from 'locales';
import LeadsService from 'domain/shared/services/leads.service';
import WebView from 'react-native-webview';
import { ActivityIndicator } from 'react-native';
import colors from 'theme/colors';
import { ACTION_ENGINE_FILE_VIEWER } from 'env';
import { IS_DESKTOP_WEB } from 'domain/shared/constants';
import CRMExtensionService from 'domain/shared/services/crm-extension-service';


enum State {
  LOADING = 'loading',
  COMPLETED = 'completed',
  DISPLAY = 'display',
}

export type OfacModalRef = {
  execute: (leadId: string) => void;
};

const states = {
  [State.LOADING]: {
    text: translate('ofac.loading'),
  },
  [State.COMPLETED]: {
    text: translate('ofac.completed'),
  },
  [State.DISPLAY]: {
    text: '',
  }
}

const INJECTED_JAVASCRIPT = `(function() {
  const meta = document.createElement('meta'); 
  meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'); 
  meta.setAttribute('name', 'viewport'); 
  document.getElementsByTagName('head')[0].appendChild(meta);
})();`;

const Root = styled(Flex)`
  background-color: white;
  padding: 20px 16px;
  border-radius: 16px;
`;

const PdfRoot = styled(Flex)`
  background-color: white;
  border-radius: 16px;
  max-height: 80%;
  flex: 1;
  overflow: hidden;
`;

const View = styled(WebView)`
  overflow: hidden;
  flex: 1;
`;

const MessageContainer = styled(Flex)`
  padding: 20px 16px 0px;
`;

const DEFAULT_STATE = states[State.LOADING];
const OfacModal = forwardRef<OfacModalRef, { name: string }>((props, ref) => {
  const [state, setState] = useState(DEFAULT_STATE);
  const [openOfacModal, setOpenOfacModal] = useState(false);
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<string>();

  const handleDismiss = () => {
    setState(DEFAULT_STATE);
    setLoading(false);
    setResult(false);
    setUrl(undefined);
  }

  const changeState = (current: State) => setState(states[current]);

  const execute = async (leadId: string) =>{
    setOpenOfacModal(true);
    try {
      const response = await LeadsService.Ofac(leadId);

      const link = `${ACTION_ENGINE_FILE_VIEWER}?lid=${leadId}&mid=${response.uuid}`;
      setUrl(link);
    } catch (e) {
      console.log(e)
    } finally {
      setOpenOfacModal(true);
      changeState(State.COMPLETED);
    }
  };

  const handleExecution = () => {
    if (IS_DESKTOP_WEB) {
      CRMExtensionService.openFileViewer(`${url}&ia=1`, props.name);
      handleDismiss();
      setOpenOfacModal(false);
      return;
    }
    
    setResult(true);
    setLoading(true);
    changeState(State.DISPLAY);
  };

  const Message = useCallback(() => {
    return (
      <Flex row justify='space-between'>
        <Button2 text={state.text} />
        <TouchableOpacity onPress={() => setOpenOfacModal(false)}>
          <Icon name='close' size={24} />
        </TouchableOpacity>
      </Flex>
    );
  }, [state, setOpenOfacModal]);

  const Loading = useCallback(() => {
    if (!loading) return null;

    return (
      <Flex flex={1} align='center' justify='center'>
        <ActivityIndicator
          size="large"
          animating
          color={colors.ORANGE}
        />
      </Flex>
    )
  }, [loading]);
  
  useImperativeHandle(ref, () => ({ execute }));

  return (
   <>
     <Modal
      isVisible={openOfacModal}
      animationIn="fadeIn"
      animationOut="fadeOut"
      backdropOpacity={.4}
      onDismiss={handleDismiss}
    >
      <>
        {
          !result && (
            <Root gap={12}>
              <Message />

              {(!result && !!url) && <ButtonBase text={translate('seeResults')} onPress={handleExecution} />}
            </Root>
          )
        }

        {
          result && (
            <PdfRoot gap={12}>
              <MessageContainer>
                <Message />
              </MessageContainer>
              <Loading />
              <View
                scalesPageToFit
                source={{ uri: url! }}
                allowsInlineMediaPlayback
                onLoad={() => setLoading(false)}
                showsHorizontalScrollIndicator={false}
                mediaPlaybackRequiresUserAction={false}
                injectedJavaScript={INJECTED_JAVASCRIPT}
              />
            </PdfRoot>
          )
        }
      </>
    </Modal>
   </>
  );
});

export default OfacModal;