/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useState } from 'react';
import { SafeAreaView } from 'react-native';
import styled from 'styled-components/native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { Flex } from '@kanvas/andromeda';
import colors from 'theme/colors';
import Header from 'components/molecules/settings-header';
import { KeyboardAwareScrollView as ScrollView } from 'react-native-keyboard-aware-scroll-view';
import { InputWithLabel as TextInputWithLabel } from 'components/molecules/input-with-label';
import Screen from 'components/template/screen';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { ChangePasswordInput } from 'domain/shared/types/user';
import useAuth from 'domain/shared/hooks/useAuth';
import { Body2, Header3 } from 'molecules/text';
import LinkButton from 'components/atoms/link-button';
import UserService from 'domain/shared/services/user-service';
import { showSuccess, showError } from 'domain/shared/utils/toast';

import ConfirmModal from 'components/molecules/confirm-modal';

const ButtonContainer = styled(Flex)`
  margin-bottom: ${getBottomSpace()}px;
  padding-horizontal: 16px;
  padding-vertical: 16px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.BORDER_COLOR};
`;

const initialValues: ChangePasswordInput = {
  current_password: '',
  new_password: '',
  confirm_new_password: '',
};

const commonRequiredError = translate('fieldIsRequired');
const commonPasswordError = translate('passwordDoesNotMeetRequirement');

const validationSchema = Yup.object().shape({
  current_password: Yup.string().trim().required(commonRequiredError),
  new_password: Yup.string().trim().required(commonRequiredError).min(8, commonPasswordError),
  confirm_new_password: Yup.string().when('new_password', ([new_password], schema) => {
    const validate = new_password && new_password.length > 0;
    if (!validate) return schema;
    return schema.required(commonRequiredError).trim().oneOf([Yup.ref('new_password')], translate('passwordNotMatch'))
  })
});

const DeleteAccount = ({ onDelete }: { onDelete: () => void; }) => (
  <Flex row padding={16}>
    <Flex flex={1}>
      <Body2 text={translate('deleteYourAccount', TextTransform.CAPITALIZE)} />
    </Flex>
    <LinkButton
      text={translate('delete', TextTransform.CAPITAL)}
      color={colors.ERROR}
      onPress={onDelete}
      size={15}
    />
  </Flex>
);

const PasswordAndPrivacy = () => {
  const navigation = useNavigation() as any;
  const { user, logOut } = useAuth();
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const onSubmit = useCallback(async (values: ChangePasswordInput, context: any) => {
    if (!user) return;
    const cleanValues = validationSchema.cast(values);
    setSubmitting(true);

    try {
      await UserService.changeUserPassword(
        user.id,
        {
          confirm_new_password: cleanValues.confirm_new_password!,
          current_password: cleanValues.current_password!,
          new_password: cleanValues.new_password!,
        },
      );
      context.resetForm();
      navigation.openDrawer();
      showSuccess(translate('passwordChangedSuccessfully', TextTransform.CAPITAL));
    } catch (e: any) {
      const message = e?.response?.data?.errors?.message;
      showError(message || translate('fetchError'))
    } finally {
      setSubmitting(false);
    }
  }, [dispatch]);


  const onDeleteAccount = useCallback(async () => {
    try {
      if (user) {
        setSubmitting(true);
        await UserService.deleteAccount(user.id);
        setSubmitting(false);
        setConfirmVisible(false);
        showSuccess(
          translate('accountDeletedSuccessfully', TextTransform.CAPITAL),
        );
        setTimeout(() => {
          // Wait 1 second and then logout
          logOut();
        }, 1000);
      }
    } catch (e: any) {
      showError(e.message);
    }
  }, [user]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {
        ({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isValid,
          resetForm
        }) => (
          <Screen flex={1}>
            <SafeAreaView>
              <Header
                title={translate('passwordAndPrivacy', TextTransform.CAPITALIZE)}
                disabled={isSubmitting}
                onBack={() => resetForm()}
              />
            </SafeAreaView>
            <ScrollView>
              <Flex padding={16} gap={10}>
                <Flex gap={18}>
                  <Header3 text={translate('changePassword', TextTransform.CAPITALIZE)} />
                </Flex>
                <TextInputWithLabel
                  label={translate('currentPassword', TextTransform.CAPITALIZE)}
                  required
                  onChangeText={handleChange('current_password')}
                  onBlur={handleBlur('current_password')}
                  value={values.current_password}
                  errors={touched.current_password ? errors.current_password : ''}
                  secureTextEntry
                  showError
                />
                <TextInputWithLabel
                  label={translate('newPassword', TextTransform.CAPITALIZE)}
                  required
                  onChangeText={handleChange('new_password')}
                  onBlur={handleBlur('new_password')}
                  value={values.new_password}
                  errors={touched.new_password ? errors.new_password : ''}
                  secureTextEntry
                  showError
                />
                <TextInputWithLabel
                  label={translate('confirmNewPassword', TextTransform.CAPITALIZE)}
                  required
                  onChangeText={handleChange('confirm_new_password')}
                  onBlur={handleBlur('confirm_new_password')}
                  value={values.confirm_new_password}
                  errors={touched.confirm_new_password ? errors.confirm_new_password : ''}
                  secureTextEntry
                  showError
                />
              </Flex>
              <ButtonContainer>
                <ButtonWithIcon
                  onPress={() => handleSubmit()}
                  text={translate('save', TextTransform.CAPITAL)}
                  enabled={!isSubmitting}
                  loading={isSubmitting}
                />
              </ButtonContainer>
              <DeleteAccount onDelete={() => setConfirmVisible(true)} />
              <ConfirmModal
                visible={confirmVisible}
                onClose={() => setConfirmVisible(false)}
                text={translate('deleteAccountConfirmationMessage', TextTransform.CAPITAL)}
                okText={translate('deleteAccount', TextTransform.CAPITALIZE)}
                cancelText={translate('back', TextTransform.CAPITAL)}
                onOk={onDeleteAccount}
                onCancel={() => setConfirmVisible(false)}
              />
            </ScrollView>

          </Screen>
        )
      }
    </Formik>
  );
};

export default PasswordAndPrivacy;
