/* eslint-disable no-plusplus */
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import Colors from 'theme/colors';
import Icon from 'react-native-vector-icons/FontAwesome5';

import Collapse from './Collapse';

interface IProps {
  title: string;
  options: string[];
}

const ContentMargin = styled.View`
  flex-direction: row;
  margin-vertical: 8px;
  padding-horizontal: 24px;
`;

const ContentText = styled.Text`
  font-size: 12px;
  color: ${Colors.BLACK};
`;

const CheckIcon = styled(Icon)`
  font-size: 12px;
  margin-right: 4px;
  color: ${Colors.ORANGE};
`;

const ExteriorCollapse = ({ options = [], title } : IProps) => {
  const renderItem = (item: string, index: number) => (
    <ContentMargin key={index}>
      <CheckIcon name="check" />
      <ContentText>{item}</ContentText>
    </ContentMargin>
  );

  const humanize = (str:any) => {
    const frags = str.split('_');
    for (let i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  };

  return (
    <Collapse title={humanize(title)}>
      <View style={{ marginVertical: 16 }}>
        {options.map(renderItem)}
      </View>
    </Collapse>
  );
};

export default ExteriorCollapse;
