import React, { useRef, useState } from "react";
import { Flex, Spacer } from "@kanvas/andromeda";
import styled from "styled-components/native";
import FastImage from "react-native-fast-image";
import { Keyboard } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import colors from "theme/colors";
import TwoFactorManager, { TwoFactorManagerRef } from "components/organisms/two-factor-manager";
import GraphUserService, { Me } from "domain/shared/services/graph-user-service";
import TabNavigationScreens from "domain/shared/navigation/tab-nav-screens";
import BackButton from "domain/barcode-scanner/components/back-button";

import useAuth from "domain/shared/hooks/useAuth";
import SaBlackLogo from "assets/svgs/sa-black-logo";

export type RootStackParamList = {
  Setup2FA: { me: Me; };
};

type DetailsScreenRouteProp = RouteProp<RootStackParamList, 'Setup2FA'>;


interface Props {
  navigation: NativeStackNavigationProp<RootStackParamList, 'Setup2FA'>;
  route: DetailsScreenRouteProp;
}

export default function Setup2FA(props: Props) {
  const { route, navigation } = props;
  const [loading, setLoading] = useState(false);
  const { logOut } = useAuth();
  const ref = useRef<TwoFactorManagerRef>(null);

  const { me } = route.params;
  const { contact } = me;
  const shouldLogin = !!contact.two_step_phone_number;

  const handleLogin = async (code: string, dontAsk: boolean) => {
    Keyboard.dismiss();
    setLoading(true);

    try {
      if (dontAsk) await GraphUserService.update(me.id, { custom_fields: [{ name: 'two_fact_validate_in_thirty', data: 1 }] });
      const valid = await GraphUserService.verifyCode(code);
      if (!valid) throw new Error('invalid code');
      navigation.navigate(TabNavigationScreens.STACK_NAME as any);
    } catch(e: any) {
      ref.current?.showError();
    } finally {
      setLoading(false)
    }
  };

  return (
    <SafeArea>
      <Root gap={5}>
        <BackButton color={colors.BLACK} onPress={logOut} />
        <Spacer size={32} />
        <Flex justify="center" align="center">
          <SaBlackLogo />
        </Flex>

        <TwoFactorManager 
          ref={ref}
          id={me.id}
          loading={loading}
          onLogin={handleLogin}
          type={shouldLogin ? 'login' : undefined}
          phoneNumber={contact.two_step_phone_number}
        />
      </Root>
    </SafeArea>
  );
}

const SafeArea = styled.SafeAreaView`
  background-color: ${colors.BACKGROUND};
  flex: 1;
`;

const Root = styled(Flex)`
  flex: 1;
  padding: 16px;
`;

const FastLogo = styled(FastImage)`
  width: 300px;
  height: 150px;
`;