/* eslint-disable no-nested-ternary */
import Icon from 'react-native-vector-icons/MaterialIcons'
import { useNavigation } from '@react-navigation/native';
import { Flex, Spacer } from '@kanvas/andromeda';
import styled from 'styled-components/native';
import { SvgFromXml } from 'react-native-svg';
import React, { useCallback, useReducer, useState } from 'react';
import { useAsync } from 'react-use';

import { CONTENT_PREVIEW, BROWSE_BANKS } from 'domain/shared/screens';
import { OPENING_ACTION } from 'domain/shared/types/amplitude-event';
import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';
import ActionService from 'domain/shared/services/action-service';
import { LeadAction } from 'domain/shared/types/lead-action';
import useAuth from 'domain/shared/hooks/useAuth';

import Spinner from 'atoms/spinner';
import { Body2 } from 'components/molecules/text';

import colors from 'theme/colors';
import Colors from 'theme/colors';

const SUB_ACTION_LIST_LIMIT = 1 as const;

enum ACTION_NAME {
  PAYOFF_FORM = 'payoff-form',
  SEARCH_HUB = 'search-hub',
  VIEW_VEHICLE = 'view-vehicle',
  GET_DOCS = 'get-docs',
  ESIGN_DOCS = 'esign-docs',
}

type Entity = {
  label: string;
  value: string;
};

const ICON_SIZE = 20;
const ICON_CONTAINER_SIZE = 36;

type KeysForEntities = ACTION_NAME.SEARCH_HUB | ACTION_NAME.PAYOFF_FORM;

const ENTITIES: Record<KeysForEntities, Entity[]> = {
  'search-hub': [
    { label: 'BANKS', value: '2' },
    { label: 'INSURANCES ', value: '1' },
  ],
  'payoff-form': [
    { label: 'BANKS', value: '2' },
  ],
};

const Container = styled(Flex)`
  padding: 16px;
`;

const IconContainer = styled(Flex)`
  background-color: ${Colors.PRIMARY_DEEMPH};
  width: ${ICON_CONTAINER_SIZE}px;
  height: ${ICON_CONTAINER_SIZE}px;
  border-radius: ${ICON_CONTAINER_SIZE / 2}px;
`;

const Action = styled.TouchableOpacity`
  width: 100%;
  min-height: 60px;  
  padding-vertical: 12px;
  padding-horizontal: 16px;
  border-width: 1px;
  border-color:${Colors.BORDER_COLOR};
  background-color:${Colors.WHITE};
  border-radius: 12px;
  margin-bottom: 12px;
  shadow-color: ${Colors.BLACK};
  shadow-offset: 0px 4px;
  shadow-radius: 8px;
  shadow-opacity: .05;
`;

const SubAction = styled.TouchableOpacity`
  /* height: 44px; */
  padding: 12px;
  border-width: 1px;
  border-radius: 12px;
  border-color:${Colors.DIVIDER};
  margin-left: ${ICON_CONTAINER_SIZE + 10}px;
`;

const ShortCuts = (props: any) => {
  const { contact } = props;
  const { currentStore } = useAuth();

  const navigation = useNavigation() as any;

  const value = useAsync(async () => {
    if (!currentStore) return [];

    const { id } = currentStore;
    const data = await ActionService.getAllActions(id);

    // FIXME: this list should be on backend
    const list = ['credit-app', 'co-signer'];

    // getting enable actions
    const enableActions = data.filter(({ enable }) => enable);
    
    // getting subactions with main action
    const subActionList = list.map((item) => enableActions.filter(({ name }) => (name !== item && name.startsWith(item))));

    // getting only main actions
    const filteredActions = enableActions.filter(({ name }) => {
      if (list.includes(name)) return true;
      return !list.find((item: string) => name.startsWith(item));
    })

    const actions = filteredActions.map((item) => {
      if (!list.includes(item.name)) return item;

      const index = list.indexOf(item.name);
      const current = subActionList[index];
      if (current.length <= SUB_ACTION_LIST_LIMIT) return item;

      item.custom_action_list = current;
      return item;
    });

    return actions;
  }, [currentStore]);


  const isMultiSelect = (name: string) => {
    const actions = [
      'get-docs',
      'esign-docs',
      'cc-auth',
      'csi-approval',
      'credit-consent',
      'purchase-vehicle',
      'finance-and-insurance',
      'loaner-car-agreement'
    ];

    return actions.includes(name);
  };

  const getDataProps = useCallback((actionName: string, item: LeadAction) => {
    const {
      title = '',
      reasonEn = '',
      reasonEs = '',
      name = '',
      description = '',
      should_open_in_browser = false,
      id,
      form_config,
      custom_action_list,
    } = item;
    const isModal = !!(actionName === ACTION_NAME.SEARCH_HUB || ACTION_NAME.PAYOFF_FORM);
    const multiSelect = isMultiSelect(name);

    if (actionName === ACTION_NAME.PAYOFF_FORM || actionName === ACTION_NAME.PAYOFF_FORM) {
      const entities = ENTITIES[actionName];
      return {
        title,
        nameAction: name,
        namekey: name,
        reasonEn,
        reasonEs,
        contact,
        description,
        isModal,
        entities,
      };
    }

    return {
      props: {
        title,
        key: name,
        reasonEn,
        reasonEs,
        contact,
        description,
        isModal,
        multiSelect,
        should_open_in_browser,
        id,
        form_config,
        custom_action_list,
      },
    };
  }, [contact]);

  const handleActionPress = (item: LeadAction) => {
    const { name, title } = item;

    const navigateTo = name === 'search-hub' ? BROWSE_BANKS : name === 'payoff-form' ? BROWSE_BANKS : CONTENT_PREVIEW;
    const dataProps = getDataProps(name, item);

    navigation.navigate(navigateTo, dataProps);
    sendAmplitudeEvent(OPENING_ACTION, {
      event: OPENING_ACTION,
      // company_id,
      // lead_id,
      // userId,
      action_name: title,
    });
  }

  const renderItem = useCallback((action: LeadAction, index: number) => {
    const { custom_action_list } = action;

    if (custom_action_list && custom_action_list.length > SUB_ACTION_LIST_LIMIT) {
      return <ActionWithList key={`action-${index}`} item={action} onPress={handleActionPress} />;
    }

    return <ActionButton key={`action-${index}`} item={action} onPress={handleActionPress} />;
  }, [getDataProps, navigation]);

  return (
    <Container>
      {value.value?.map(renderItem)}
      {
        value.loading && (
          <Flex flex={1}>
            <Spinner />
          </Flex>
        )
      }
    </Container>
  );
};

type ActionButtonProps = {
  item: LeadAction;
  onPress?: (item: LeadAction) => void;
};

function ActionButton(props: ActionButtonProps) {
  const { item, onPress } = props;

  return (
    <Action onPress={() => onPress?.(item)} activeOpacity={0.5}>
      <ActionTitle item={item} />
    </Action>
  );
}

function ActionWithList(props: ActionButtonProps) {
  const { item, onPress } = props;
  const { custom_action_list } = item;
  
  const [active, toggle] = useReducer((item: boolean) => !item, false);

  const icon = active ? 'keyboard-arrow-up' : 'keyboard-arrow-down';

  const SubActionList = useCallback(() => {
    if (!active) return null;

    return (
      <Flex gap={8}>
        {custom_action_list?.map((action) => (
          <SubAction activeOpacity={0.5} onPress={() => onPress?.(action)}>
            <Body2 text={action.title} color={Colors.SECONDARY_TEXT} />
          </SubAction>
        ))}
      </Flex>
    );
  }, [custom_action_list, active])
 
  return (
    <Action onPress={toggle} activeOpacity={0.5}>
      <Flex gap={12}>
        <Flex row align='center' justify='space-between'>
          <ActionTitle item={item} />
          <Icon name={icon} size={20} color={colors.ORANGE} />
        </Flex>

        <SubActionList />
      </Flex>
    </Action>
  );
}

function ActionTitle(props: ActionButtonProps) {
  const { item } = props;
  const { icon, title } = item;

  return (
    <Flex row align="center" justify="flex-start">
      <IconContainer align="center" justify="center">
        <SvgFromXml width={ICON_SIZE} height={ICON_SIZE} xml={icon} />
      </IconContainer>
      <Spacer horizontal size={6} />
      <Body2 text={title} color={colors.SECONDARY_TEXT} />
    </Flex>
  );
}

export default ShortCuts;
