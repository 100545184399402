import { AppDispatch, RootState } from 'domain/shared/store';
import { setCurrentStore, setCurrentUser, setUserStores } from 'domain/shared/store/slice/auth';
import { setSettings } from 'domain/shared/store/slice/settings';
import { IStore } from 'domain/shared/types/store';
import { User } from 'domain/shared/types/user';
import React, { createContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export interface AuthContextProps {
  user: User | undefined;
  currentStore: IStore | undefined;
  stores: IStore[] | undefined;
  setUser: (user: any) => void;
  setCurrentStore: (store: any) => void;
  setStores: (stores: any) => void;
  setAppSettings: () => void;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthContextProvider = (props: React.PropsWithChildren<any>) => {
  const { children } = props;
  const user = useSelector((state: RootState) => state.auth?.user);
  const currentStore = useSelector((state: RootState) => state.auth?.store);
  const stores = useSelector((state: RootState) => state.auth.stores);
  const dispatch = useDispatch<AppDispatch>();

  const setUser = useCallback((usr: User) => {
    dispatch(setCurrentUser(usr));
  }, []);

  const setStore = useCallback((store: IStore) => {
    dispatch(setCurrentStore(store));
  }, []);

  const setStores = useCallback((useStores: IStore[]) => {
    dispatch(setUserStores(useStores));
  }, []);

  const setAppSettings = useCallback(() => {
    dispatch(setSettings());
  }, []);

  const value = React.useMemo<AuthContextProps>(() => ({
    user,
    currentStore,
    setUser,
    setCurrentStore: setStore,
    stores,
    setStores,
    setAppSettings,
  }), [user, currentStore?.id, stores]);
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
