import { Flex, Spacer } from '@kanvas/andromeda';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import React from 'react';
import { translate } from 'locales';
import { Meta2 } from '../text';

const NotificationNotFound = () => (
  <Flex align="center" marginVertical={10}>
    <Icon name="bell-outline" size={30} />
    <Spacer size={8} />
    <Meta2 text={translate('notificationNotFound')} />
  </Flex>
);

export default NotificationNotFound;
