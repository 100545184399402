import React from 'react';
import Icon from 'react-native-vector-icons/MaterialIcons';
import IconMat from 'react-native-vector-icons/MaterialCommunityIcons';

const ICON_SIZE = 16;

export type Stage = {
  created_at: string;
  has_rotting_days: string;
  id: string;
  is_deleted: number;
  name: string;
  pipelines_id: number;
  rotting_days: number;
  updated_at: string;
  weight: number;
  done?: boolean;
  slug: string;
};

export type EngagementType = {
  companies_id: number;
  created_at: string;
  id: number;
  is_deleted: number;
  name: string;
  slug: string;
  stages: Stage[];
  updated_at: string;
  users_id: number
};

export enum StageType {
  sent = 'sent',
  opened = 'opened',
  submitted = 'submitted',
  contact_page = 'contact_page',
}

type EngagementMetadata = {
  icon: React.ReactNode;
  iconOutline: React.ReactNode;
  label: string;
};

export const BadgeType: Record<StageType, EngagementMetadata> = {
  sent: {
    icon: <Icon size={ICON_SIZE} name="send" />,
    iconOutline: <IconMat size={ICON_SIZE} name="send-outline" />,
    label: 'Shared',
  },
  opened: {
    icon: <Icon size={ICON_SIZE} name="remove-red-eye" />,
    iconOutline: <IconMat size={ICON_SIZE} name="eye-outline" />,
    label: 'Read',
  },
  submitted: {
    icon: <Icon size={ICON_SIZE} name="check-circle" />,
    iconOutline: <IconMat size={ICON_SIZE} name="check-circle-outline" />,
    label: 'Submitted',
  },
  contact_page: {
    icon: <Icon size={ICON_SIZE} name="contact-page" />,
    iconOutline: <Icon size={ICON_SIZE} name="contact-page" />,
    label: 'Submitted',
  },
};
