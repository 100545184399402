import * as React from 'react';
import Svg, { Path, Circle } from 'react-native-svg';
import Colors from 'theme/colors';

function VideoChat(props: any) {
  const { fillColor = Colors.ICON_SECONDARY } = props;
  return (
    <Svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 58.12 58.12"
      width={60}
      height={60}
      {...props}
    >
      <Path
        fill={fillColor}
        d="M37.93 46.5H20.19l-.05-.81v-.43a8.94 8.94 0 1117.88 0v.43zM21.56 45h15a7.5 7.5 0 00-15 0z"
      />
      <Path
        fill={fillColor}
        d="M29.06 36.76a6.86 6.86 0 116.73-6.86 6.81 6.81 0 01-6.73 6.86zm0-12.25a5.4 5.4 0 105.29 5.39 5.35 5.35 0 00-5.29-5.39z"
      />
      <Circle cx={29.06} cy={12.32} r={5.11} />
      <Path
        fill={fillColor}
        d="M29.06 18.3a6 6 0 116-6 6 6 0 01-6 6zm0-10.22a4.24 4.24 0 104.24 4.24 4.24 4.24 0 00-4.24-4.24z"
      />
      <Circle fill={fillColor} cx={29.06} cy={12.32} r={2.98} />
      <Circle fill={fillColor} cx={28.37} cy={11.24} r={1.11} />
      <Path d="M1.42 44.72h55.29v7.06H1.42z" />
    </Svg>
  );
}

export default VideoChat;
