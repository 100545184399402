import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity, StatusBar, Keyboard } from 'react-native';
import Input from 'atoms/text-input';
import _ from 'lodash';
import styled from 'styled-components/native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/Ionicons';
import { Flex } from '@kanvas/andromeda';
import AvatarButton from 'components/atoms/AvatarButton/AvatarButton';
import Colors from 'theme/colors';
import { FONTS } from 'theme/typography';
import SafeAreaView from 'components/atoms/SafeAreaView';
import DocumentScannerButton from 'components/molecules/documment-scanner-button';

import { ScannedData } from 'domain/barcode-scanner/types';
import { useNavigation } from '@react-navigation/native';
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import { BUTTON_HIT_SLOP, IS_MOBILE } from 'domain/shared/constants';
import { EventRegister } from 'react-native-event-listeners';

import HeaderSearch from './Component/HeaderSearch';
import BackButton from './Component/BackButton';
import LeftContainer from './Component/LeftContainer';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { SCANNED_OPPORTUNITY, ScannerParams } from 'domain/create-opportunity/hooks/use-scanner-screen';
import useEventReciever from 'domain/shared/hooks/use-event-reciever';

export const OPEN_SEARCH_LIST = 'OPEN_SEARCH_LIST';

export function openSearchListEvent() {
  EventRegister.emit(OPEN_SEARCH_LIST);
}

const Container = styled(Flex)`
  background-color: ${Colors.WHITE};
`;

const InputWrap = styled(Flex)`
  background-color:${Colors.SUBTILE};
  border-radius: 8px;
`;
const SearchIcon = styled(Icon)`
  margin-left: 15px;
`;

const InputSearch = styled(Input)`
  font-size:15px;
  font-family:${FONTS.SFProText};
  font-weight: 400; 
  flex: 1;
  background-color: ${Colors.TRANSPARENT};
  border-color: ${Colors.TRANSPARENT};
  padding: 8px;
`;
const CleanIcon = styled(MCIcon)`
  border-width: 5px;
  margin-right: 13px;
  border-radius: 12px;
  border-color:${Colors.WHITE};
  background-color: ${Colors.WHITE};
  text-align: center;
  vertical-align: middle;
  overflow:hidden;
`;

const ScanIcon = styled(DocumentScannerButton)`
  margin-right: 13px;
`;

export interface OpportunitySearchProps {
  placeholder: string;
  clearInput: () => void;
  saveVin: (value: string, opp?: ScannedData) => void;
  onOpen?: (value: boolean) => void;
}

const HeaderBar = (props: OpportunitySearchProps) => {
  const [text, setText] = useState('');
  const [search, setSearch] = useState(false);
  const { clearInput, saveVin, placeholder } = props;
  const setValueToParent = (value: any) => saveVin(value);
  const navigation = useNavigation();

  const onClear = useCallback(() => {
    setText('');
  }, []);

  const handleBack = useCallback(() => {
    setText('');
    clearInput?.();
  }, [clearInput]);

  const handleOpenScan = () => {
    Keyboard.dismiss();
    const params: ScannerParams = { type: 'search' };
    // @ts-ignore
    navigation.navigate(OpportunityScreens.SCANNER as never, params as never);
  };

  const handleToggle = (value: boolean) => {
    const { onOpen } = props;
    setSearch(value);
    onOpen?.(value);
  };

  useEventReciever(SCANNED_OPPORTUNITY, (data: ScannerParams) => {
    if (data.type !== 'search') return;
    if (!data.opp) return;
    
    const { firstname, lastname } = data.opp;
    const current = `${firstname} ${lastname}`;
    setText(current);
    saveVin(current, data.opp);
  });

  useEventReciever(OPEN_SEARCH_LIST, () => setSearch(true))

  const renderBodySide = () => (
    <Flex row>
      <InputWrap row align="center" flex={1}>
        <SearchIcon
          name="ios-search"
          size={18}
          color={Colors.SECONDARY_TEXT}
        />
        <InputSearch
          value={text}
          maxLength={128}
          autoCorrect={false}
          autoCapitalize="none"
          returnKeyType="search"
          keyboardType="default"
          placeholder={placeholder}
          keyboardAppearance="light"
          onChangeText={(t) => setText(t)}
          placeholderTextColor={Colors.SECONDARY_TEXT}
          onSubmitEditing={() => setValueToParent(text)}
        />
        {!_.isEmpty(text) && (
          <TouchableOpacity onPress={onClear} hitSlop={BUTTON_HIT_SLOP}>
            <CleanIcon name="close" size={13} color={Colors.BLACK} />
          </TouchableOpacity>
        )}

        {IS_MOBILE && _.isEmpty(text) && <ScanIcon size={20} onPress={handleOpenScan} />}
      </InputWrap>
    </Flex>
  );

  return (
    <>
      <StatusBar />
      <SafeAreaView />

      <TouchableOpacity activeOpacity={1} onPress={() => Keyboard.dismiss()}>
        <Container>
          {!search && (
            <HeaderSearch
              RightSide={<AvatarButton />}
              Title={translate('opportunities', TextTransform.CAPITALIZE)}
              LeftSide={<LeftContainer handle={handleToggle} />}
            />
          )}
          {search && (
            <Flex>
              <HeaderSearch
                RightSide={<BackButton handle={handleToggle} clearInput={handleBack} />}
                Title={translate('search', TextTransform.CAPITALIZE)}
              />
              <Flex marginHorizontal={12} style={{ marginTop: 16, marginBottom: 6 }}>
                {renderBodySide()}
              </Flex>
            </Flex>
          )}
        </Container>
      </TouchableOpacity>
    </>
  );
};

export default HeaderBar;
