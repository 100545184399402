import { Flex, Spacer } from '@kanvas/andromeda';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Colors from 'theme/colors';
import { Header3 } from 'components/molecules/text';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { IS_MOBILE, IS_MOBILE_WEB, IS_WEB } from 'domain/shared/constants';
import { IPropsOpp, Participant } from 'domain/shared/types/opportunity';
import DropDown from 'components/atoms/dropdown-input';
import { translate as translateBase } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { FormLabel, TextInputWithLabel } from 'components/molecules/input-with-label';
import { RectButton } from 'components/atoms/touchable';
import { BUTTON_STYLE, MAX_PARTICIPANT } from 'domain/create-opportunity/add/constants';
import { FieldArrayRenderProps } from 'formik';
import DocumentScannerButton from 'components/molecules/documment-scanner-button';
import { useNavigation } from '@react-navigation/native';
import { ScannedData } from 'domain/barcode-scanner/types';
import CountryService from 'domain/shared/services/country-service';
import { useCompanySettings } from 'domain/shared/hooks/useAppSettings';
import { ListItem } from '../types';
import Cities from '../cities';
import useEventReciever from 'domain/shared/hooks/use-event-reciever';
import { SCANNED_OPPORTUNITY, ScannerParams } from 'domain/create-opportunity/hooks/use-scanner-screen';
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import IdValidationLoading, { IDValidationLoadingState, IdValidationLoadingRef } from 'components/molecules/id-validation-loading';
import Sentry from 'sentry';
import IntelicheckApi from 'domain/barcode-scanner/api/intelicheck.api';
import { IdValidateProcess } from 'domain/shared/hooks/use-validation-process';

const SaleInfoContainer = styled(Flex)`
  background-color:${Colors.WHITE}; 
  margin-horizontal: 15px;
  margin-bottom:15px;
`;

const InputContainer = styled(Flex)`
  background-color:${Colors.WHITE};
  height: 70px;
  margin-vertical:1px;
`;

const languages: ListItem[] = [
  {
    value: 'EN',
    label: 'English',
  },
];

const translate = (label: string) => translateBase(label, TextTransform.CAPITALIZE);

interface Props {
  values: IPropsOpp;
  relationship: ListItem[];
  arrayHelpers: FieldArrayRenderProps;
  handleChange: any;
  contries: ListItem[];
  states: ListItem[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors: any
}

export default function ParticipantsForm(props: Props) {
  const {
    values, handleChange, setFieldValue, arrayHelpers, contries, states, relationship, errors
  } = props;
  const [opp, setOpp] = useState(false);
  const navigation = useNavigation();
  const companySettings = useCompanySettings();
  const { participant_relationship_default_id = 0, id_verification = undefined } = companySettings!;
  const idVerificationScreenRef = useRef<IdValidationLoadingRef>(null);
  const [openIdValidationModal, setOpenIdValidationModal] = useState(false);

  const handleSubmit = () => {
    arrayHelpers.push({
      id: (values?.participants?.length || 0) + 1,
      firstname: '',
      middlename: '',
      lastname: '',
      phone: '',
      email: '',
      language: 'EN',
      type: 'NEW',
      relationship: participant_relationship_default_id,
    });
  };

  const processScannedData = async (data: ScannedData, index: number) => {
    if (!values.participants) return;
    const current = values.participants[index] as Participant;
    if (!current) return;

    setOpp(true);
    const { expDate, ...extra } = data.extra;

    let participant = {
      ...current,
      firstname: data.firstname,
      middlename: data.middlename,
      lastname: data.lastname,
      address1: data.addressOne,
      address2: data.addressTwo,
      zipcode: data.zipCode,
      country: 230,
    };

    arrayHelpers.replace(index, participant);
    const state = await CountryService.getStateByCode(data.state);
    const { firstname, lastname, middlename } = data;
    const getDocsData = { ...extra, exp_date: expDate, state_id: state?.id || '', firstname, lastname, middlename };

    participant = { ...participant, state: state?.id, get_docs_drivers_license: getDocsData };
    arrayHelpers.replace(index, participant);
    
    const city = await CountryService.getCityByName(state.id, data.city);
    participant = { ...participant, city: city?.id || '' };
    arrayHelpers.replace(index, participant);

    if (!id_verification) return;

    try {
      setOpenIdValidationModal(true)
      idVerificationScreenRef.current?.changeStatus(IDValidationLoadingState.VALIDATING);
      const idVerification = await IdValidateProcess.instance(companySettings).verify(data);

      participant = { ...participant, driver_license_images: { ...data.pictures, participant: participant.id }, id_verification: idVerification }
      arrayHelpers.replace(index, participant);
      idVerificationScreenRef.current?.changeStatus(IDValidationLoadingState.SUCCESS);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      idVerificationScreenRef.current?.changeStatus(IDValidationLoadingState.FAIL);
    }
  };

  const handleSelectedItem = (key: string, item: any) => {
    setFieldValue(key, item?.value);
  };

  const handleNavScanner = (index: number) => {
    const hadIdScannerAccess = !!id_verification;
    const params: ScannerParams = { type: 'participants', index };
    const { SCANNER, ID_SCANNER } = OpportunityScreens;
    // @ts-ignore
    navigation.navigate(hadIdScannerAccess ? ID_SCANNER : SCANNER, params);
  };

  useEventReciever(SCANNED_OPPORTUNITY, (response: ScannerParams) => {
    if (response.type !== 'participants') return;
    if (!response.opp) return;
    const { opp: data, index } = response;
    processScannedData(data, index!);
  }, [values]);

  return (
    <>
      <IdValidationLoading 
        ref={idVerificationScreenRef} 
        open={openIdValidationModal}
        onClose={() => setOpenIdValidationModal(false)}
      />
      {
        values.participants?.map((
          participant: Participant,
          index: number,
        ) => (
          <React.Fragment key={participant.id}>
            <Flex row>
              <Flex flex={1}>
                <Header3
                  text={`${translate('participant')} ${index + 1}`}
                  style={{ marginTop: 24 }}
                />
              </Flex>
              <Flex justify="flex-end">
                <RectButton onPress={() => arrayHelpers.remove(index)}>
                  <Icon color={Colors.WARNING} name="minus-circle" size={20} />
                </RectButton>
              </Flex>
            </Flex>
            <SaleInfoContainer />
            <Flex style={{ position: 'relative' }}>
              <TextInputWithLabel
                label="firstName"
                type="input"
                value={participant.firstname}
                handleChange={handleChange(`participants[${index}].firstname`)}
                required
                error={errors.participants && errors.participants[index]?.firstname}
              />

              <TextInputWithLabel
                label="middleName"
                type="input"
                value={participant.middlename}
                handleChange={handleChange(`participants[${index}].middlename`)}
              />

              <TextInputWithLabel
                label="lastName"
                type="input"
                value={participant.lastname}
                handleChange={handleChange(`participants[${index}].lastname`)}
                required
                error={errors.participants && errors.participants[index]?.lastname}
              />

              {(IS_MOBILE || IS_MOBILE_WEB) && (
                <DocumentScannerButton
                  onPress={() => handleNavScanner(index)}
                  style={{ ...BUTTON_STYLE }}
                />
              )}
            </Flex>
            <TextInputWithLabel
              label="mobile"
              type="input-mask"
              mask="(999)-999-9999"
              value={participant.phone}
              handleChange={handleChange(`participants[${index}].phone`)}
              required
              error={errors.participants && errors.participants[index]?.phone}
            />
            <TextInputWithLabel
              type="input"
              label="email"
              value={participant.email}
              handleChange={handleChange(`participants[${index}].email`)}
            />
            <InputContainer style={{ marginVertical: '5px' }}>
              <FormLabel text={translate('language')} />
              <DropDown
                placeHolder={translate('language')}
                value={participant.language}
                items={languages}
                onSelectItem={(item: any) => handleSelectedItem(`participants[${index}].Language`, item)}
              />
            </InputContainer>
            <InputContainer style={{ marginVertical: '5px' }}>
              <FormLabel text={translate('relationship')} />
              <DropDown
                placeHolder={translate('relationship')}
                value={participant.relationship.toString()}
                items={relationship}
                onSelectItem={(item: any) => handleSelectedItem(`participants[${index}].relationship`, item)}
              />
            </InputContainer>
            {
              opp && (
                <>
                  <TextInputWithLabel
                    label="addressLine1"
                    handleChange={handleChange('address1')}
                    value={participant.address1}
                    type="input"
                  />
                  <TextInputWithLabel
                    label="addressLine2"
                    handleChange={handleChange('address2')}
                    value={participant.address2}
                    type="input"
                  />
                  <InputContainer style={{ zIndex: 998 }}>
                    <FormLabel text={translate('country')} />
                    <DropDown
                      placeHolder={translate('country')}
                      value={participant.country}
                      items={contries}
                      onSelectItem={(item: any) => setFieldValue('country', item?.value)}
                    />
                  </InputContainer>
                  <InputContainer style={{ zIndex: 997 }}>
                    <FormLabel text={translate('state')} />
                    <DropDown
                      placeHolder={translate('state')}
                      value={participant.state}
                      items={states}
                      onSelectItem={(item: any) => setFieldValue('state', item?.value)}
                    />
                  </InputContainer>
                  <InputContainer style={{ zIndex: 996 }} gap={10}>
                    <FormLabel text={translate('city')} />
                    <Cities
                      id={participant.state || 0}
                      city={participant.city || 0}
                      onSelectItem={(item: any) => setFieldValue('city', item?.value)}
                    />
                  </InputContainer>
                </>
              )
            }
          </React.Fragment>
        ))
      }
      <InputContainer>
        <Spacer size={6} />
        <ButtonWithIcon
          enabled={(values.participants?.length || 0) < MAX_PARTICIPANT}
          onPress={() => handleSubmit()}
          icon={<Icon color={Colors.ORANGE} name="plus" size={24} />}
          textColor={Colors.ORANGE}
          text={translateBase('addParticipant')}
          color={Colors.WHITE}
        />
      </InputContainer>
      {IS_WEB && <Spacer size={10} />}
    </>
  );
}
