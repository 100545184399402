import React from 'react';
import styled from 'styled-components/native';
import { Flex, Spacer } from '@kanvas/andromeda';
import Colors from 'theme/colors';
import Text from 'atoms/text';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { DropShadow } from 'domain/shared/styles';
import { IS_ANDROID, IS_WEB } from 'domain/shared/constants';

enum Type {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

const ICONS: Record<Type, string> = {
  SUCCESS: 'check-circle',
  ERROR: 'cancel',
  WARNING: 'warning',
};

const COLORS: Record<Type, string> = {
  SUCCESS: Colors.SUCCESS,
  ERROR: Colors.ERROR,
  WARNING: Colors.WARNING,
};

interface IProps {
  message: string;
  type: keyof typeof Type
}

const Container = styled(Flex)`
  padding: 16px;
  border-radius: 12px;
  border-width: 1px;
  border-color: ${Colors.SHADOW};
  margin-bottom: 60px;
  background: ${Colors.SHADOW};
  ${IS_WEB ? `border: 1px solid ${Colors.SHADOW};` : ''};
  width: 330px;
  ${!IS_ANDROID ? DropShadow : undefined}
`;

const ToastMessage = (props: IProps) => {
  const { message, type = 'SUCCESS' } = props;
  const iconName = ICONS[type];
  const iconColor = COLORS[type];
  return (
    <Container row align="center" justify="center">
      <Icon name={iconName} size={24} color={iconColor} />
      <Spacer horizontal size={6} />
      <Text text={message} size={12} color={Colors.WHITE} />
    </Container>
  );
};

export default ToastMessage;
