export const changeActiveScreen = (type: any) => {
  return {
    type: 'CHANGE_ACTIVE_SCREEN',
    payload: type,
  };
};

export const changeActiveApp = (type: any) => {
  return {
    type: 'CHANGE_ACTIVE_APP',
    payload: type,
  };
};

export const changeActiveRooftop = (type: any) => {
  return {
    type: 'CHANGE_ACTIVE_ROOFTOP',
    payload: type,
  };
};

export const openAppFromAnother = (type: any) => {
  return {
    type: 'OPEN_APP_FROM_ANOTHER',
    payload: type,
  };
};

export const changeSelectedCar = (type: any) => {
  return {
    type: 'CHANGE_SELECTED_CAR',
    payload: type,
  };
};

export const changeSelectedTabIndex = (type: any) => {
  return {
    type: 'CHANGE_SELECTED_TAB_INDEX',
    payload: type,
  };
};

export const clearActiveRooftop = () => ({
  type: 'CLEAR_ACTIVE_ROOFTOP',
});

export const changeLeadsInfo = (type: any) => {
  return {
    type: 'CHANGE_LEADS_INFO',
    payload: type,
  };
};
