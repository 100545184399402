import React from 'react';
import { TouchableWithoutFeedback } from 'components/atoms/touchable';
import { Button1 } from 'components/molecules/text';
import Colors from 'theme/colors';

interface TextButtonProps {
  text: string;
  onPress?: () => void;
  disabled?: boolean;
}

const TextButton = (props: TextButtonProps) => {
  const { onPress, text, disabled = false } = props;
  return (
    <TouchableWithoutFeedback
      onPress={onPress}
      disabled={disabled}
    >
      <Button1
        text={text}
        color={Colors.WHITE}
      />
    </TouchableWithoutFeedback>
  );
};

export default TextButton;
