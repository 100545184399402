/* eslint-disable max-len */
import { Flex, Text } from '@kanvas/andromeda';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components/native';
import { EventRegister } from 'react-native-event-listeners';
import { OpportunityEvents } from 'domain/shared/events/opportunity-list';
import { BorderlessButton } from 'components/atoms/touchable';

export interface ButtonListItem {
  key: string;
  text: string;
  selected?: boolean;
  enabled?: boolean;
  value?: any;
}

type OnItemSelectedSingle = (item: ButtonListItem) => void;
type OnItemSelectedMultiple = (item: ButtonListItem[]) => void;
export type RenderItemFn = (
  item: ButtonListItem,
  onItemPress: OnItemSelectedSingle,
  enabled: boolean,
) => React.ReactNode;

interface SingleSelectionProps {
  items: ButtonListItem[];
  multiselect?: boolean;
  max?: number;
  renderItem?: RenderItemFn;
  onItemSelected?: OnItemSelectedSingle;
  defaultValue?: any;
  row?: boolean;
}

interface MultipleSelectionProps {
  items: ButtonListItem[];
  multiselect: boolean;
  max?: number;
  renderItem?: RenderItemFn;
  onItemSelected?: OnItemSelectedMultiple;
  defaultValue?: any;
  row?: boolean;
}

type IProps = SingleSelectionProps | MultipleSelectionProps;

const Container = styled(Flex)`
  flex-wrap: wrap;
`;

const ButtonList: React.FC<IProps> = (props) => {
  const {
    renderItem,
    multiselect = false,
    items = [],
    onItemSelected,
    max = 0,
    defaultValue = undefined,
    row = false,
  } = props;
  const listItems = useRef<ButtonListItem[]>(items);
  // used to force component render on item selection;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedItem, setSelectedItem] = useState<ButtonListItem>(items[0]);
  const [enabled, setEnabled] = useState(true);

  const onItemPress = useCallback((item: ButtonListItem) => {
    // copy array without references;
    const tempArray = JSON.parse(JSON.stringify(listItems.current)) as ButtonListItem[];
    const newItems = !multiselect ? [...tempArray.map((i) => ({ ...i, selected: false }))] : [...tempArray];
    const currentItem = newItems.find((i) => i.key === item.key);
    if (currentItem) {
      currentItem.selected = !currentItem.selected;
      // this state call is to force useRef array to render the component
      setSelectedItem(currentItem);
      listItems.current = newItems;
      if (multiselect) {
        const onSelect = onItemSelected as OnItemSelectedMultiple;
        const selected = listItems.current.filter((i) => i.selected);
        if (selected.length === max && max > 0) {
          setEnabled(false);
        } else {
          setEnabled(true);
        }
        onSelect?.(selected);
      } else {
        const onSelect = onItemSelected as OnItemSelectedSingle;
        onSelect?.(currentItem);
      }
    }
  }, [multiselect, onItemSelected, max]);

  useEffect(() => {
    listItems.current = items;
    setSelectedItem(items[0]);
  }, [items]);

  useEffect(() => {
    if (defaultValue !== undefined) {
      const item = listItems.current.find((i) => i.value === defaultValue);
      if (item) {
        item.selected = true;
        setSelectedItem(item);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    const refreshListEvent = EventRegister.addEventListener(
      OpportunityEvents.REFRESH,
      () => {
        const list = listItems.current.map((value) => ({ ...value, selected: value.value === defaultValue }));
        listItems.current = list;
      },
    ).toString();

    return () => {
      EventRegister.rm(refreshListEvent);
    };
  }, [defaultValue]);

  const defaultRenderItem = useCallback((item: ButtonListItem) => (
    <React.Fragment key={item.key}>
      <BorderlessButton
        onPress={() => onItemPress(item)}
        enabled={item.selected || enabled}
      >
        <Text>
          {item.text}
        </Text>
      </BorderlessButton>
    </React.Fragment>
  ), [onItemPress, enabled]);
  return (
    <Container row={row}>
      {
        listItems.current.map((item) => renderItem?.(item, onItemPress, item.selected || enabled) || defaultRenderItem(item))
      }
    </Container>
  );
};

export default ButtonList;
