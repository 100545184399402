import { Flex } from '@kanvas/andromeda';
import NotificationSettingsSection from 'components/molecules/notification-settings-section';
import { UserNotificationType } from 'domain/shared/types/user-notifications-settings';
import React, { useCallback } from 'react';

interface IProps {
  items: UserNotificationType[]
}

export default function NotificationSettingsList(props: IProps) {
  const { items } = props;

  const renderItem = useCallback((item: UserNotificationType) => (
    item.children.length > 0 ? (
      <NotificationSettingsSection
        key={`${item.name}-${item.notification_types_id}`}
        notificationType={item}
      />
    ) : null
  ), []);

  return (
    <Flex gap={10}>
      {items.map(renderItem)}
    </Flex>
  );
}
