import { Dimensions, Platform } from 'react-native';
import { isMobileWebDevice, isMobileSafari, isRunningOnChromeExtension } from 'domain/shared/utils/isMobileWebDevice';

export const IS_WEB = Platform.OS === 'web';
export const IS_ANDROID = Platform.OS === 'android';
export const IS_MOBILE_WEB = isMobileWebDevice();
export const IS_DESKTOP_WEB = IS_WEB && !IS_MOBILE_WEB;
export const IS_MOBILE = (Platform.OS === 'android' || Platform.OS === 'ios');
export const IS_IOS_WEB_DEVICE = isMobileSafari();
type DIMENSION = 'screen' | 'window';
const dimension: DIMENSION = IS_WEB ? 'window' : 'screen';

export const {
  width: DEVICE_WIDTH,
  height: DEVICE_HEIGHT,
} = Dimensions.get(dimension);

export const BUTTON_HIT_SLOP = {
  top: 30, bottom: 30, left: 30, right: 30,
};
export const USER_TOKEN_KEY = 'crm-token';
export const IS_CHROME_EXTENSION = isRunningOnChromeExtension();
export const GRAPH_TOKEN_KEY = 'graph-token';
export const DEFAULT_COMPANY_INFO_KEY = 'company-info-key';
export const APP_SETTING_KEY = '7d0488b2-632e-4045-9d2d-370d9161644a';
export const SHOW_POPUP_MSG = 'show-popup-msg';

export const PopUpInfoLink = 'https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop#zippy=%2Callow-pop-ups-and-redirects-from-a-site';