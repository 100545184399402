import Lead from "model/data/lead";
import LeadHubInterceptor from "model/interceptors/lead-hub.interceptor";
import { LeadHubPresenterInterface, LeadHubViewInterface } from "model/types/lead-hub.presenter";

export default class LeadHubPresenter implements LeadHubPresenterInterface {
  private readonly interceptor: LeadHubInterceptor;

  constructor(private readonly view: LeadHubViewInterface) {
    this.interceptor = new LeadHubInterceptor(this);
  }
  
  fetch () {
    this.interceptor.fetch();
  }

  refetch () {
    this.interceptor.refetch();
  }

  next () {
    this.interceptor.next();
  }
  
  get (items: Lead[]) {
    this.view.get(items)
  }
  
  clear() {
    this.interceptor.clear();
  }
  
  error () {
    this.view.error();
  }

  search(text: string) {
    this.interceptor.search(text);
  }

  notFound() {
    this.view.notFound();
  }

  limitReach() {
    this.view.limitReach();
  }
}