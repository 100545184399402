import React from 'react';
import { Flex } from '@kanvas/andromeda';
import { GoogleIcon, FacebookIcon } from 'assets/svgs';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import Popup from 'components/molecules/popup';
import colors from 'theme/colors';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { Reviews } from 'domain/shared/types/reviews';


const ReviewPopup = (props: any) => {
  const { showModal, openReview, setShowModal } = props;
  return (
    <Popup
      visible={showModal}
      showIconTitle={false} // eslint-disable-next-line react/no-unstable-nested-components
      Body={() => (
        <Flex style={{
          backgroundColor: 'white', flex: 1,
        }}
        >
          <ButtonWithIcon
            height={36}
            text={translate('google', TextTransform.CAPITALIZE)}
            color={colors.WHITE}
            textColor={colors.BLACK}
            icon={<GoogleIcon width={16} height={16} />}
            onPress={() => openReview(Reviews.GOOGLE)}
          />
          {/* //commented still get facebook review */}
          {/* <Flex style={{ marginTop: 8 }}>
            <ButtonWithIcon
              height={36}
              text={translate('facebook', TextTransform.CAPITALIZE)}
              color={colors.WHITE}
              textColor={colors.BLACK}
              icon={<FacebookIcon width={16} height={16} />}
              onPress={() => openReview(Reviews.FACEBOOK)}
            />
          </Flex> */}
        </Flex>
      )}
      close={() => setShowModal(false)}
      titleText={translate('selectReview')}
      font="Header2"
    />
  );
};

export default ReviewPopup;
