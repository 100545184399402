import * as React from 'react';
import Svg, {
  Defs, ClipPath, Path, G,
} from 'react-native-svg';
import Colors from 'theme/colors';

function GetReferrals(props: any) {
  const { fillColor = Colors.ICON_SECONDARY } = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      {...props}
    >
      <Defs>
        <ClipPath id="prefix__a">
          <Path d="M0 0h48v48H0z" />
        </ClipPath>
      </Defs>
      <G clipPath="url(#prefix__a)">

        <Path
          data-name="Path 699"
          d="M22.179 39.846h21.994a5.315 5.315 0 002.972-.613 2.076 2.076 0 00.854-1.8 7.407 7.407 0 00-1.031-3.576 12.638 12.638 0 00-2.963-3.5 15.585 15.585 0 00-4.671-2.658 17.524 17.524 0 00-6.158-1.024 17.524 17.524 0 00-6.158 1.024 15.585 15.585 0 00-4.672 2.654 12.638 12.638 0 00-2.963 3.5 7.407 7.407 0 00-1.031 3.576 2.076 2.076 0 00.854 1.8 5.315 5.315 0 002.973.617zm11-16.31a6.389 6.389 0 003.517-1.031 7.572 7.572 0 002.573-2.8 8.142 8.142 0 00.966-3.975 7.728 7.728 0 00-.966-3.854 7.42 7.42 0 00-2.582-2.721 6.533 6.533 0 00-3.511-1 6.443 6.443 0 00-3.5 1.012 7.577 7.577 0 00-2.582 2.74 7.722 7.722 0 00-.975 3.864 8 8 0 00.975 3.938 7.648 7.648 0 002.582 2.8 6.358 6.358 0 003.5 1.027zM3.195 39.846h13.244a3.957 3.957 0 01-.7-2.4 8.393 8.393 0 01.613-2.972 13.4 13.4 0 014.245-5.647 14.437 14.437 0 00-3.381-1.588 13.788 13.788 0 00-4.31-.622 14.1 14.1 0 00-5.35.966 13.122 13.122 0 00-4.064 2.547 11.651 11.651 0 00-2.591 3.446 8.4 8.4 0 00-.9 3.669 2.5 2.5 0 00.724 1.923 3.584 3.584 0 002.47.678zm9.715-15.92a5.586 5.586 0 003.055-.891 6.556 6.556 0 002.248-2.436 7.068 7.068 0 00.845-3.455 6.731 6.731 0 00-.845-3.372 6.431 6.431 0 00-2.248-2.359 5.718 5.718 0 00-3.056-.864 5.641 5.641 0 00-3.037.873 6.56 6.56 0 00-2.256 2.377 6.724 6.724 0 00-.854 3.381 6.977 6.977 0 00.844 3.43 6.576 6.576 0 002.248 2.425 5.586 5.586 0 003.056.891z"
          fill={fillColor}
        />
      </G>
    </Svg>
  );
}

export default GetReferrals;
