import AsyncStorage from '@react-native-async-storage/async-storage';

const LEAD_ID = 'leadId';
const UseLead = () => {
  const setLeadId = async (leadId: string) => {
    await AsyncStorage.setItem(LEAD_ID, leadId);
  };

  const getLeadId = async () => {
    const leadId = await AsyncStorage.getItem(LEAD_ID);
    return leadId;
  };
  const clearLeadId = async () => {
    const leadId = await AsyncStorage.removeItem(LEAD_ID);
    return leadId;
  };

  return {
    getLeadId,
    setLeadId,
    clearLeadId,
  };
};

export default UseLead;
