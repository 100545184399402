import { ProductVariant } from 'domain/shared/types/product-variants';
import { createContext } from 'react';

export interface ActionState {
  title: string | undefined;
  toolbarRight?: React.ReactNode | null;
  onBack?: () => void;
}

interface ContentPreviewContextType {
  setActionState: (state: ActionState) => void;
  setSelectedProducts: (product: (ProductVariant[])) => void;
  leadUUID?: string;
}

export const ContentPreviewContext = (
  createContext<ContentPreviewContextType>({} as ContentPreviewContextType)
);
