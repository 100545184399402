import TasksApi from "model/api/tasks/tasks.api";
import { Status } from "model/data/task";
import { TaskPresenterInterface } from "model/types/task.presenter";

export type ChangeStatusData = {id: number, status: Status};

export default class TaskInterceptor {
  private readonly api: TasksApi;

  constructor(private readonly presenter: TaskPresenterInterface) {
    this.api = new TasksApi();
  }

  async fetch(leadId: number, taskListId?: number) {
    const response = await this.api.get(leadId, taskListId);
    this.presenter.get(response);
  }

  connect(leadId: number, taskListId?: number) {
    return this.api.getSubscription(leadId, async () => {
      // refetching the data when subscription gets data
      const response = await this.api.get(leadId, taskListId);
      this.presenter.get(response);
    });
  }

  async changeStatus(leadId: number, data: ChangeStatusData, taskListId?: number) {
    try {
      const saved = await this.api.changeStatus(data.id, leadId, data.status);
    
      if (!saved) {
        this.presenter.error?.()
        return;
      }

      // this.fetch(leadId, taskListId);
    } catch (e: any) {
      console.log(JSON.stringify(e));
      this.presenter.error?.()
    }
  }
}