import React from 'react';
import { useScannerScreen } from 'domain/create-opportunity/hooks/use-scanner-screen';
import IdScanner from 'domain/barcode-scanner/screens/id-scanner';
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import { EventRegister } from 'react-native-event-listeners';
import { activeSession } from 'theme/images-uri';

export default function IdScannerScreen(props: any) {

  // Props
  const { navigation, route } = props;
  const { params } = route;

  // Hooks
  const { handleBack, handleOpportunity } = useScannerScreen();

  const onCobuyerAdded = (data: any) => {
    const { ADD_COBUYER } = OpportunityScreens;
    // EventRegister.emit('SCANNED_OPPORTUNITY', { ...params, opp: data });
    navigation.navigate(ADD_COBUYER, { scanData: data, activeSession: params.activeSession });
  };

  return (
    <IdScanner
      onScan={handleOpportunity}
      onBack={handleBack}
      addCobuyer={params.addCobuyer}
      onCobuyerAdded={onCobuyerAdded}
      activeSession={params.activeSession}
    />
  );
}
