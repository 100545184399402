import React from 'react';
import { StyleSheet } from 'react-native';
import { FONTS } from 'theme/typography';
import Colors from 'theme/colors';
import { TabBar } from 'react-native-tab-view';

const styles = StyleSheet.create({
  labelStyle: {
    fontFamily: FONTS.SFProTextSemiBold,
    fontSize: 15,
    color: Colors.PRIMARY_TEXT,
    textTransform: 'none',
  },
  container: {
    backgroundColor: Colors.WHITE,
    shadowColor: Colors.PRIMARY_TEXT,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
  },
  indicatorStyle: {
    backgroundColor: Colors.DARK_ORANGE,
  },
});

const CustomTabBar = (props: any) => (
  <TabBar
    {...props}
    labelStyle={styles.labelStyle}
    style={styles.container}
    indicatorStyle={styles.indicatorStyle}
    pressColor="transparent"
  />
);

export default CustomTabBar;
