import React, { useCallback } from 'react';
import { BadgeType } from 'domain/shared/types/engagement-types';
import { Indexable } from 'domain/shared/types/shared';
import Colors from 'theme/colors';
import { Flex, Text } from '@kanvas/andromeda';
import moment from 'moment';
import ItemCard from 'components/atoms/item-card';
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import OpportunityService from 'domain/shared/services/opportunity-service';
import { useDispatch } from 'react-redux';
import { navigateToStack } from 'domain/shared/services/navigation-service';
import { ACTIVE_SESSION } from 'domain/shared/screens';
import reactStringReplace from 'react-string-replace';
import AttachmentButton from 'components/atoms/attachment-button';
import { Entity } from 'domain/shared/types/notification';
import { setActiveOpportunity } from 'domain/shared/store/slice/opportunity';

import { Button2, Meta2 } from '../text';

const SUBMITTED = 'submitted';

type MessageVariable = {
  contact: string;
  postTitle: string;
  text: string;
};

interface IProps {
  content: string;
  time: string;
  read: number;
  lead_id: number;
  message_variable: MessageVariable;
  entity: Entity
}

const Update = (props: IProps) => {
  const {
    content = '',
    time = '',
    read,
    lead_id = 0,
    message_variable = {} as MessageVariable,
    entity,
  } = props;

  const dispatch = useDispatch();

  const navigateToWall = useCallback(async (oppId: number) => {
    const opp = await OpportunityService.getById(oppId);
    if (opp.data) {
      dispatch(setActiveOpportunity(opp.data));
      navigateToStack(OpportunityScreens.WALL, ACTIVE_SESSION, {
        openActivities: 1,
        back: true,
      });
    }
  }, []);

  const getIconFromContent = useCallback((_sentence?: string): string => {
    if (_sentence?.includes(SUBMITTED)) return SUBMITTED;
    if (_sentence?.includes('opened') || _sentence?.includes('readed') || _sentence?.includes('read')) return 'opened';
    if (_sentence?.includes('sent')) return 'sent';
    if (_sentence?.includes('in-app')) return 'in-app';
    if (_sentence?.includes('assigned')) return 'contact_page';
    return 'sent';
  }, []);

  const i = getIconFromContent(content);
  const { icon } = (BadgeType as Indexable)[i];
  const color = i === SUBMITTED ? Colors.SUCCESS : Colors.BLACK;
  const BadgeIcon = icon
    ? React.cloneElement(icon as any, { color, size: 18 }) : null;

  const { contact = '' } = message_variable;
  const { message } = entity;

  const navigateToLead = useCallback(() => {
    navigateToWall(lead_id);
  }, [navigateToWall, lead_id]);

  const renderMessage = useCallback(() => {
    if (contact) {
      const msg = reactStringReplace(content || '', contact.trim(), (match) => (
        <Button2 key={match} text={match} onPress={navigateToLead} />
      ));

      return msg;
    }
    return content;
  }, [content, contact]);

  return (
    <ItemCard highlight={read} {...props}>
      <Flex row align="flex-start">
        {BadgeIcon}
        <Flex gap={10} marginHorizontal={12} style={{ flex: 1, flexWrap: 'wrap' }}>
          <Text style={{ width: '100%' }}>
            {renderMessage()}
          </Text>
          <Meta2 text={moment(time).fromNow()} />
        </Flex>
        <Flex>
          {(message?.message.engagement_status === SUBMITTED && i === SUBMITTED) ? (
            <AttachmentButton
              leadId={entity.lead_uuid}
              messageId={message.file_message_uuid}
              hasAttachments={message.message.engagement_status === SUBMITTED
                && (message.message.verb !== 'digital-card'
                  && message.message.verb !== 'get-review'
                  && message.message.verb !== 'view-vehicle')}
              engagementStatus={message.message.engagement_status}
              linkPreview={message.message.link_preview}
              message={message.message}
              related_messages={message.related_messages}
              title={entity?.message_variable?.leadTitle || ' '}
            />
          ) : null}
        </Flex>
      </Flex>
    </ItemCard>
  );
};

export default Update;
