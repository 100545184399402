import Toast, { ToastShowParams } from 'react-native-toast-message';

const showMessage = (text1: string, type: string, options: ToastShowParams = {}) => {
  Toast.show({ text1, type, ...options });
};
export const showSuccess = (message: string, options: ToastShowParams = {}) => {
  showMessage(message, 'success', options);
};

export const showError = (message: string, options: ToastShowParams = {}) => {
  showMessage(message, 'error', options);
};

export const showWarning = (message: string, options: ToastShowParams = {}) => {
  showMessage(message, 'warning', options);
};
