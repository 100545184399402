import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import updates from './updates';

const Stack = createNativeStackNavigator();

export const NotificationScreens = {
  LIST: 'UPDATES_LIST',
};

export const NotificationStack = () => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    <Stack.Screen name={NotificationScreens.LIST} component={updates} />
  </Stack.Navigator>
);
