import React, { useMemo } from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { Flex } from '@kanvas/andromeda';
import TextLink from 'atoms/text-link';
import Text from 'atoms/text';
import Icon from 'react-native-vector-icons/Ionicons';
import moment from 'moment';
import { LeadStatus } from 'domain/shared/types/lead';
import colors from 'theme/colors';
import { TouchableOpacity } from 'components/atoms/touchable';
import { translate } from 'locales';
import { Meta2 } from 'components/molecules/text';
import { Lead } from 'domain/opportunity-list/types';
import { useSelector } from 'react-redux';
import { RootState } from 'domain/shared/store';

interface IProps extends Lead {
  title: string,
  onPress: (e?: any) => void,
  onLongPress?: (e?: any) => void,
  disabled?: boolean,
  people: any,
  is_chrono_running: number,
  updated_at: string,
  leads_status: any,
  searching?: boolean;
}

const ICON_SIZE = 15;

const Button = styled(TouchableOpacity)`
  border-radius: 8px;
  shadow-opacity: .05;
  shadow-color: ${colors.BLACK};
  shadow-offset: 0px 4px;
  shadow-radius: 8px;
  elevation: 1;
`;

const Container = styled(Flex)`
  width: 100%;
  background-color: ${colors.WHITE};
  ${Platform.OS === 'ios' ? 'min-height: 80px' : ''};
  padding: 10px;
  border-width: 1px;
  border-color: ${colors.BORDER_COLOR};
  background-color: ${colors.WHITE};
  justify-content: space-between;
`;

const ListCardItem = (props: IProps) => {
  const {
    onPress,
    onLongPress,
    disabled = false,
    people,
    is_chrono_running,
    updated_at,
    leads_status,
  } = props;
  const vehicle = useMemo(() => {
    const { vehicle_of_interest = null } = props.custom_fields as Record<string, any>;
    if (!vehicle_of_interest) return undefined;

    const { make, model, yearFrom, isNew } = vehicle_of_interest;

    const status = isNew ? '(New)' : '(Used)';
    const vehicle = [yearFrom, make, model, status].join(' ').trim();
    return vehicle;
  }, [props]);

  return (
    <Button
      onPress={onPress}
      onLongPress={onLongPress}
      disabled={disabled}
    >
      <Container>
        <Flex row>
          <Flex flex={1} row align="center">
            <Text
              color={colors.PRIMARY_TEXT}
              size={15}
              weight="800"
              text={people?.name}
              numberOfLines={1}
              style={{ maxWidth: '70%' }}
            />
            <Flex row>
              {is_chrono_running === 1 && <Icon name="md-location-sharp" size={ICON_SIZE} color={colors.PURPLE} />}
              {leads_status?.id === LeadStatus.WON && <Icon name="checkmark-circle-sharp" size={ICON_SIZE} color={colors.SUCCESS} />}
              {leads_status?.id === LeadStatus.LOST && <Icon name="ios-close-circle-sharp" size={ICON_SIZE} color={colors.ERROR} />}
            </Flex>
          </Flex>
          <Text
            color={colors.SECONDARY_TEXT}
            size={13}
            text={moment(updated_at).format('MM/DD/yyyy').replace(' ', '')}
          />
        </Flex>
        {vehicle && <Meta2 text={vehicle} color={colors.SECONDARY_TEXT} />}
        <TextLink text={people?.phone} />
        <TextLink text={people?.email} />

        <SearchingData {...props} />
      </Container>
    </Button>
  );
};

export default React.memo(ListCardItem);

function SearchingData(props: IProps) {
  const { owner, status_name } = props;
  const { full_name } = owner;

  if (!props.searching) return null;
  
  return (
    <>
      <Flex 
        style={{
          borderColor: colors.DIVIDER,
          borderBottomWidth: 1,
          marginVertical: 8,
        }}
      />

      { full_name ? <Meta2 text={`${translate('primarySales')}: ${full_name}`} color={colors.SECONDARY_TEXT} /> : null}
      { status_name ? <Meta2 text={`${translate('salesStatus')}: ${status_name}`} color={colors.SECONDARY_TEXT} /> : null}
    </>
  )
}