import React from 'react';
import { Flex, Spacer } from '@kanvas/andromeda';
import Colors from 'theme/colors';
import { Meta2 } from 'components/molecules/text';
import { BadgeType } from 'domain/shared/types/engagement-types';
import { Indexable } from 'domain/shared/types/shared';
import { transformText, TextTransform } from 'react-native-localized-text/lib/localized-text';

interface IProps {
  status: string;
  done?: boolean;
  name: string;
}

const EngagementBadge = (props: IProps) => {
  const { status, done = false, name } = props;
  const { icon, iconOutline } = (BadgeType as Indexable)[status];
  const tintColor = done ? Colors.DARK_ORANGE : Colors.SECONDARY_TEXT;
  const iconVersion = done ? icon : iconOutline;
  const BadgeIcon = icon ? React.cloneElement(iconVersion as any, { color: tintColor }) : null;
  return (
    <Flex row>
      {BadgeIcon}
      <Spacer horizontal size={2} />
      <Meta2 color={tintColor} text={transformText(name, TextTransform.CAPITAL)} />
    </Flex>
  );
};

export default EngagementBadge;
