/* eslint-disable global-require */
// //////////////////////////////////////////////////////
//           IMAGES | ICONS URL MANAGEMENT          //
// ///////////////////////////////////////////////////
// For color utilities on SVG import colors
import Colors from './colors';

export const defeaultImages = {
  // User Images
  carDefault: {
    uri: require('assets/images/vehicle/vehicle-deafult.jpg'),
  },
  placeholder: {
    uri: require('assets/images/vehicle/300x200.png'),
  },
  blankUser: {
    uri: require('assets/images/placeholders/blank-profile-picture.png'),
  },
};

export const dacBG = {
  sideMenu: {
    uri: require('assets/images/backgrounds/dac-bg-menu.png'),
  },
};

export const icons = {
  // peng for crazy side bar
  menu: {
    uri: require('assets/images/dac-icons/menu.png'),
    fillWhite: Colors.WHITE,
  },
  // svg for lists and acc
  collapse: {
    uri: require('assets/images/dac-icons/collapse.png'),
    fillWhite: Colors.WHITE,
  },
  expand: {
    uri: require('assets/images/dac-icons/expand.png'),
    fillWhite: Colors.WHITE,
  },
  markAsSold: {
    uri: require('assets/images/dac-icons/mark-as-sold.png'),
    fillWhite: Colors.WHITE,
  },
  settings: {
    uri: require('assets/images/dac-icons/settings.png'),
    fillWhite: Colors.WHITE,
  },
  sync: {
    uri: require('assets/images/dac-icons/sync.png'),
    fillWhite: Colors.WHITE,
  },
  camera: {
    uri: require('assets/images/dac-icons/camera.png'),
  },

  cameraWhite: {
    uri: require('assets/images/dac-icons/camera-icon.png'),
  },
  logOut: {
    uri: require('assets/images/dac-icons/log-out.png'),
  },
};

export const dacApps = {
  ih: {
    uri: require('assets/images/dac-icons/dac-app/ih.png'),
  },
  ihSelected: {
    uri: require('assets/images/dac-icons/dac-app/ih-selected.png'),
  },
  dm: {
    uri: require('assets/images/dac-icons/dac-app/dm.png'),
  },
  dmSelected: {
    uri: require('assets/images/dac-icons/dac-app/dm-selected.png'),
  },
  sh: {
    uri: require('assets/images/dac-icons/dac-app/sh-selected.png'),
  },
  shSelected: {
    uri: require('assets/images/dac-icons/dac-app/sh.png'),
  },
  cc: {
    uri: require('assets/images/dac-icons/dac-app/cc.png'),
  },
  ccSelected: {
    uri: require('assets/images/dac-icons/dac-app/cc-selected.png'),
  },
  sa: {
    uri: require('assets/images/dac-icons/dac-app/sa.png'),
  },
  saSelected: {
    uri: require('assets/images/dac-icons/dac-app/sa-selected.png'),
  },
  thumbSelected: {
    uri: require('assets/images/dac-icons/selector-check/dac-selector.png'),
  },
  thumbUnSelected: {
    uri: require('assets/images/dac-icons/selector-check/dac-to-select.png'),
  },
};

export const scanOverlays = {
  landscapeOverlay: {
    uri: require('assets/images/overlays/code-scan/landscape.png'),
  },
  portraitOverlay: {
    uri: require('assets/images/overlays/code-scan/portrait.png'),
  },
};

export const photoOverlays = {
  gridLine: {
    uri: require('assets/images/overlays/photo-helpers/grid_line.png'),
  },
  layerRight: {
    uri: require('assets/images/overlays/photo-helpers/bg_layer_right.png'),
  },
  layerRightDown: {
    uri: require('assets/images/overlays/photo-helpers/bg_layer_right_down_02.png'),
  },
  layerLeft: {
    uri: require('assets/images/overlays/photo-helpers/bg_layer_left.png'),
  },
  layerLeftDown: {
    uri: require('assets/images/overlays/photo-helpers/bg_layer_left_down_01.png'),
  },
};

export const bottomBar = {
  apps: {
    uri: require('assets/images/dac-icons/bottom-bar/Dashboard.png'),
  },
  plus: {
    uri: require('assets/images/dac-icons/bottom-bar/Plus.png'),
  },
  inventory: {
    uri: require('assets/images/dac-icons/bottom-bar/Inventory.png'),
  },
  profile: {
    uri: require('assets/images/dac-icons/bottom-bar/Profile.png'),
  },
};

export const activeSession = {
  dealerContent: {
    uri: require('assets/images/dac-icons/active-session/dealer-content.png'),
  },
  financeProducts: {
    uri: require('assets/images/dac-icons/active-session/finance-products.png'),
  },
  searchHub: {
    uri: require('assets/images/dac-icons/active-session/search-hub.png'),
  },
  tradeWalk: {
    uri: require('assets/images/dac-icons/active-session/trade-walk.png'),
  },
  vehicleBrochure: {
    uri: require('assets/images/dac-icons/active-session/vehicle-brochure.png'),
  },
  viewInventory: {
    uri: require('assets/images/dac-icons/active-session/view-inventory.png'),
  },
};

export const snapshot = {
  engine: {
    uri: require('assets/images/dac-icons/snapshot/engine.png'),
  },
  ecolor: {
    uri: require('assets/images/dac-icons/snapshot/ecolor.png'),
  },
  fuel: {
    uri: require('assets/images/dac-icons/snapshot/fuel.png'),
  },
  icolor: {
    uri: require('assets/images/dac-icons/snapshot/icolor.png'),
  },
  seats: {
    uri: require('assets/images/dac-icons/snapshot/seats.png'),
  },
  transmission: {
    uri: require('assets/images/dac-icons/snapshot/transmission.png'),
  },
};
