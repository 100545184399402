import { GenericAbortSignal } from "axios";
import axios from "domain/shared/services/axios";
import { FormatedResponse, Params } from "model/types/formated-response";

type Response = FormatedResponse<Record<string, any>>;

const COMMON_PARAMS: Params = {
  format: true,
  page: 1,
  limit: 25,
  text: '',
  sort: 'updated_at|desc',
};

export default class LeadsApi {
  async get(data: Params, signal: GenericAbortSignal) {
    const params: Params = { ...COMMON_PARAMS, ...data };
    
    const response = await axios.get<Response>('/search/eLeads', { params, signal });
    return response.data;
  }
}