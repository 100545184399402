import React, {
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { IS_WEB } from 'domain/shared/constants';
import convertFileToBase64String from 'domain/shared/utils/convertFileToBase64String';

export type UploadedFile = { base64: string, type: string, originalName: string };

interface IProps {
  onFileSelected?: (file: UploadedFile) => void;
}

export interface FileUploadRef {
  openFileUpload: () => void;
}

const FileUpload = forwardRef<FileUploadRef, IProps>((props, ref) => {
  const { onFileSelected } = props;
  const fileInput = useRef<HTMLInputElement>(null);

  const openFileUpload = useCallback(async () => {
    fileInput.current?.click();
  }, [fileInput.current]);

  const onImageSelected = useCallback(async (e) => {
    const target = e.target as any;
    const file = target.files[0];

    if (file) {
      const base64 = await convertFileToBase64String(file);
      const data = (base64 as string);
      onFileSelected?.({
        base64: data,
        type: file.type,
        originalName: file.name,
      });
      if (fileInput.current) {
        fileInput.current.value = '';
      }
    }
  }, []);

  useImperativeHandle(ref, () => ({
    openFileUpload,
  }));

  return (
    IS_WEB ? (
      <input
        type="file"
        onChange={onImageSelected}
        accept="image/*"
        // @ts-ignore
        capture="camera"
        style={{ display: 'none' }}
        ref={fileInput}
      />
    ) : null
  );
});

export default FileUpload;
