import * as Sentry from '@sentry/browser';

import { SENTRY_DNS } from './sentry-dns';

Sentry.init({
  dsn: SENTRY_DNS,
  // integrations: [new BrowserTracing({})],
  tracesSampleRate: 1.0,
});

export default Sentry;
