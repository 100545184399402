import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/native';
import LinearGradient from 'react-native-linear-gradient';
import Colors from 'theme/colors';
import { ViewStyle } from 'react-native';

interface IProps extends PropsWithChildren {
  width?: number;
  style?: ViewStyle
  box?: boolean;
}

const Container = styled(LinearGradient)<IProps>`
  border-top-left-radius: ${(({ box }) => (box ? 0 : 8))}px;
  border-bottom-right-radius: ${(({ box }) => (box ? 0 : 8))}px;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: ${({ width = 31 }) => (`${width}px`)};
`;

const Badge: React.FC<IProps> = (props) => {
  const { children, width, style, box } = props;
  return (
    <Container
      width={width}
      colors={[Colors.TAB_BAR_GRADIENT_ONE, Colors.TAB_BAR_GRADIENT_TWO]}
      start={{ x: 0.6, y: 0 }}
      end={{ x: 1, y: 1 }}
      locations={[0.20, 0.75]}
      style={style}
      box={box}
    >
      {children}
    </Container>
  );
};

export default Badge;
