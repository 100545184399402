import React, {
  forwardRef, useCallback, useEffect, useImperativeHandle, useState,
} from 'react';
import colors from 'theme/colors';
import { Flex } from '@kanvas/andromeda';
import styled from 'styled-components/native';
import { RangeSlider } from '@react-native-assets/slider';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { Body4 } from '../text';

const hitSlop = 30;

const ThumbContainer = styled(Flex)`
  width: 28px;
  height: 28px;
  background-color: white;
  border-radius: 40px;
  border-width: 2px;
  border-color: ${colors.ICON_PRIMARY};
`;

const SliderContainer = styled(Flex)`
  padding-horizontal: 10px;
`;

interface Props {
  value?: number[];
  min: number;
  max: number;
  label?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  onChange?: (value: number[]) => void;
}

export interface FilterSliderRef {
  onReset: () => void;
  value: [number, number] | undefined;
}

function Thumb() {
  return (
    <ThumbContainer align="center" justify="center">
      <Icon name="pause" size={14} color={colors.ICON_PRIMARY} />
    </ThumbContainer>
  );
}

function useFilterSlider(props: Props, ref: React.ForwardedRef<FilterSliderRef>) {
  const { min, max, value } = props;
  const [range, setRange] = useState([0, 1]);

  const currentValue = useCallback(() => {
    if (range[0] === min && range[1] === max) {
      return undefined;
    }

    return range as [number, number];
  }, [range]);

  const handleValueChange = (current: number[]) => {
    setRange(current);
    props.onChange?.(current);
  };

  useEffect(() => {
    if (!value) {
      setRange([min, max]);
      return;
    }

    setRange(value);
  }, [value]);

  useImperativeHandle(ref, () => ({
    onReset: () => handleValueChange([min, max]),
    value: currentValue(),
  }));

  return {
    model: {
      range,
    },
    operations: {
      handleValueChange,
    },
  };
}

const FilterSlider = forwardRef<FilterSliderRef, Props>((props, ref) => {
  const { min, max, label = 'defaultSort' } = props;
  const { model, operations } = useFilterSlider(props, ref);
  const { range } = model;
  const { handleValueChange } = operations;
  const interpolate = { min: range[0], max: range[1] };
  const currentlabel = translate(label, TextTransform.NONE, { interpolate });

  return (
    <Flex gap={4}>
      <Body4 text={currentlabel} link />

      <SliderContainer>
        <RangeSlider
          step={1}
          trackHeight={3}
          minimumValue={min}
          maximumValue={max}
          CustomThumb={Thumb}
          crossingAllowed={false}
          inboundColor={colors.SHADOW}
          outboundColor={colors.SHADOW}
          range={range as [number, number]}
          onValueChange={handleValueChange}
          hitSlop={{
            bottom: hitSlop,
            left: hitSlop,
            right: hitSlop,
            top: hitSlop,
          }}
        />
      </SliderContainer>
    </Flex>
  );
});

export default FilterSlider;
