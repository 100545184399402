import * as React from 'react';
import Svg, {
  Defs, ClipPath, Path, G,
} from 'react-native-svg';
import Colors from 'theme/colors';

function SearchHub(props: any) {
  const { fillColor = Colors.ICON_SECONDARY } = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      {...props}
    >
      <Defs>
        <ClipPath id="prefix__a">
          <Path d="M0 0h1080v1080H0z" />
        </ClipPath>
      </Defs>
      <G data-name="Custom Size \u2013 5" clipPath="url(#prefix__a)">
        <Path d="M0 0h1080v1080H0z" />
        <Path
          data-name="Path 578"
          d="M449.304 772.108q68.619 0 128.884-25.956a334.149 334.149 0 00105.912-71.9 341.184 341.184 0 0071.6-105.912q25.956-59.967 25.956-128.586 0-68.022-25.956-128.287a339.86 339.86 0 00-177.512-178.406q-60.265-26.253-128.884-26.253-68.022 0-128.586 26.254A339.031 339.031 0 00142.906 311.47q-25.956 60.265-25.956 128.287 0 68.619 25.956 128.586a341.184 341.184 0 0071.6 105.912 333.23 333.23 0 00106.21 71.9q60.566 25.953 128.588 25.953zm0 106.807q-90.7 0-170.354-34.011a439.206 439.206 0 01-140.221-94.873 446.09 446.09 0 01-94.574-140.223q-34.011-79.355-34.011-170.051 0-91.293 34.011-170.652a440.126 440.126 0 0194.873-139.923 450.333 450.333 0 01140.221-94.874Q358.608 0 449.304 0t170.354 34.308a445.031 445.031 0 01234.795 235.1q34.011 79.658 34.011 170.354t-34.011 170.046a446.089 446.089 0 01-94.575 140.221 439.2 439.2 0 01-140.22 94.879Q540 878.917 449.304 878.917zm557.3 201.083a80.311 80.311 0 01-28.044-5.072 64.036 64.036 0 01-24.464-16.409L656.95 761.967l106.807-104.42L1059.115 954.1a61.979 61.979 0 0115.812 23.867 80.309 80.309 0 015.072 28.044 78 78 0 01-9.249 37.889 66.679 66.679 0 01-25.956 26.552q-16.705 9.548-38.186 9.548z"
          fill={fillColor}
        />
      </G>
    </Svg>
  );
}

export default SearchHub;
