import { ScannedData } from 'domain/barcode-scanner/types';
import { EventRegister } from 'react-native-event-listeners';

export enum CXTabBarEvents {
  ON_SEARCH = 'ON_SEARCH',
  ON_CLEAR = 'ON_CLEAR',
}

export interface SearchEmitValues {
  value: string;
  opportunity?: ScannedData;
}

export const emitOnSearch = (search: SearchEmitValues) => {
  EventRegister.emit(CXTabBarEvents.ON_SEARCH, search);
};

export const emitOnClear = () => EventRegister.emit(CXTabBarEvents.ON_CLEAR);
