import React from "react";
import styled from "styled-components/native";
import { BorderlessButton } from "react-native-gesture-handler";
import Icon from 'react-native-vector-icons/MaterialIcons';

import colors from "theme/colors";
import { BUTTON_HIT_SLOP } from "domain/shared/constants";

import { Header2 } from "../text";

interface Props {
  title: string;
  onBack?: VoidFunction;
}

export default function ActionPreviewHeader({ title, onBack }: Props) {
  return (
    <Header>
      <BorderlessButton hitSlop={BUTTON_HIT_SLOP} onPress={onBack}>
        <Icon name='arrow-back' size={24} color={colors.BLACK} />
      </BorderlessButton>

      <Header2 text={title} />
    </Header>
  )
}

const Header = styled.View`
  gap: 4px;
  width: 100%;
  padding: 16px;
  flex-direction: row;
  border-bottom-width: 2px;
  border-color: ${colors.BORDER_COLOR};
`;