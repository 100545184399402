import ActionService from "domain/shared/services/action-service";
import { useAsync } from "react-use";

// TODO: search for every extra type on anys
type Config = {
  id: string;
  contact: any;
  action: string;
  amountReferral: string;
  bankSelectedItem: object;
  extraLinkParams: object | any[];
  onFail?: () => void;
};

export default function useActionMessage(config: Config) {
  const { extraLinkParams = {}, bankSelectedItem = {}, id = 0, action, contact, onFail, amountReferral } = config;

  const getContentData = async (contact: any) => {
    const body: any = {
      company_action_id: id,
      lead_id: contact.uuid,
      extraField: extraLinkParams,
      contact_id: contact.people?.uuid || 0,
      receiver_id: contact.leads_receivers?.uuid || 0,
    };

    if (action === 'search-hub' || ('payoff-form' && (extraLinkParams as any[]).length > 0)) {
      body.bank = bankSelectedItem;
    }

    const data = await ActionService.getActionPreview(action, body);
    return data;
  }
  
  const { value, loading } = useAsync(async () => {
    const data = await getContentData(contact);
    if (!data?.link_preview) {
      onFail?.();
      return;
    }

    return data;
  }, [contact]);
  
  return { message: value || {}, loading };
}