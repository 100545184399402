import { Flex, Spacer } from '@kanvas/andromeda';
import { Alert, ScrollView } from 'react-native';
import React, { useCallback } from 'react';
import styled from 'styled-components/native';
import colors from 'theme/colors';
import UserInfo from 'components/molecules/user-info';
import Header from 'components/molecules/preview-header';
import UserMenuItem, { UserMenuItemProps } from 'atoms/user-menu-item';
import Icon from 'react-native-vector-icons/MaterialIcons';

import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { Button2 as Text } from 'molecules/text';
import { BorderlessButton } from 'atoms/touchable';
import { useNavigation, useNavigationState } from '@react-navigation/native';

import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { IS_WEB } from 'domain/shared/constants';
import useAuth from 'domain/shared/hooks/useAuth';
import SafeAreaView from 'atoms/SafeAreaView';
import { DrawerScreens } from 'domain/screens';

const options = [
  {
    title: translate('notificationSettings', TextTransform.CAPITALIZE),
    icon: <Icon name="notifications-none" />,
    route: DrawerScreens.NOTIFICATION_SETTINGS,
  },
  {
    title: translate('passwordAndPrivacy', TextTransform.CAPITALIZE),
    icon: <MaterialCommunityIcons name="lock-outline" />,
    route: DrawerScreens.PASSWORD_AND_PRIVACY,
  },
  {
    title: translate('security', TextTransform.CAPITALIZE),
    icon: <MaterialCommunityIcons name="shield-check-outline" />,
    route: DrawerScreens.SECURITY,
  },
  {
    title: translate('preferences', TextTransform.CAPITALIZE),
    icon: <MaterialCommunityIcons name="heart-outline" />,
    route: DrawerScreens.PREFERENCES,
  },
  {
    title: translate('feedback', TextTransform.CAPITALIZE),
    icon: <MaterialCommunityIcons name="comment-outline" />,
    route: DrawerScreens.FEEDBACK,
  }
];

const UserMenu = () => {
  const navigation = useNavigation() as any;

  const { logOut, stores = [] } = useAuth();

  const navigate = useCallback((screen: string) => {
    navigation.navigate(screen, { initial: false });
  }, [navigation]);

  const confirmLogout = useCallback(() => {
    if (IS_WEB) {
      logOut();
    }
    Alert.alert(
      translate('logOut', TextTransform.CAPITAL),
      translate('logoutWarn', TextTransform.CAPITAL),
      [
        {
          text: translate('logOut', TextTransform.CAPITAL),
          style: 'destructive',
          onPress: () => logOut(),
        },
        { text: translate('cancel', TextTransform.CAPITAL), style: 'cancel' },
      ],
    );
  }, []);

  return (
    <Flex flex={1}>
      <SafeAreaView />
      <ScrollView contentContainerStyle={{ height: '100%' }}>
        <Header borderless showBottomBorder={false} onBack={() => navigate(DrawerScreens.ROOT)} />
        <Spacer size={10} />
        <BorderlessButton onPress={() => navigate(DrawerScreens.PROFILE)}>
          <Flex row paddingHorizontal={16}>
            <Flex flex={1}>
              <UserInfo />
            </Flex>
            <Flex align="flex-end">
              <Icon
                name="chevron-right"
                size={24}
                color={colors.ICON_SECONDARY}
              />
            </Flex>
          </Flex>
        </BorderlessButton>
        <Spacer size={6} />
        <OptionContainer flex={1} paddingHorizontal={16} paddingVertical={12}>
          <Text text={translate('appSettings', TextTransform.CAPITALIZE)} />
          <Spacer size={6} />
          {options.map((({ title, icon, route }) => <RowItem key={`option-${route}`} title={title} icon={icon} onPress={() => navigate(route)} />))}
        </OptionContainer>
      </ScrollView>
      <OptionContainer paddingVertical={12}>
        {stores.length > 1 && (
          <RowItem
            title={translate('switchStore', TextTransform.CAPITALIZE)}
            onPress={() => navigate(DrawerScreens.SWITCH_STORE)}
          />
        )}
        <RowItem title={translate('logOut', TextTransform.CAPITALIZE)} onPress={() => confirmLogout()} />
      </OptionContainer>
    </Flex>
  );
};

const RowItem = ({ title, icon, ...props }: UserMenuItemProps) => (
  <>
    <UserMenuItem title={title} icon={icon} {...props} />
    <Spacer size={12} />
  </>
);

const OptionContainer = styled(Flex)`
  border-top-width: 1px;
  border-top-color: ${colors.BORDER_COLOR};
`;

export default UserMenu;
