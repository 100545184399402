import { EventRegister } from 'react-native-event-listeners';

export enum ActivityListEvents {
  ACTIVITY_LIST_RELOAD = 'ACTIVITY_LIST.RELOAD',
  ACTIVITY_LIST_UPDATE = 'ACTIVITY_LIST.UPDATE',
}

export const emitActivityListEvent = (event: ActivityListEvents, data: any) => {
  EventRegister.emit(event, data);
};
