import React, { useMemo } from "react";
import styled from "styled-components/native";
import { ListRenderItemInfo } from "react-native";
import Icon from 'react-native-vector-icons/MaterialIcons';

import colors from "theme/colors";
import { CommonListContent } from "domain/opportunity-wall/hooks/use-doc-list";

import { BorderlessButton } from "components/atoms/touchable";
import { Body3 } from "../text";

interface Props {
  selected?: boolean;
  item: CommonListContent;
  onPress: (item: CommonListContent) => void;
}

export default function CheckInput(props: Props) {
  const { item, selected, onPress } = props;

  const check = selected || item.selected;
  const name = check ? 'check-box' : 'check-box-outline-blank';
  const color = check ? colors.DARK_ORANGE : colors.SECONDARY_TEXT;

  return (
    <Root>
      <Check onPress={() => onPress(item)}>
        <Icon name={name} color={color} size={24} />
        <Body3 text={item.label} />
      </Check>
    </Root>
  );
}

const Root = styled.View`
  padding: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.DIVIDER};
`;

const Check = styled(BorderlessButton)`
  gap: 4px;
  align-items: center;
  flex-direction: row;
`;