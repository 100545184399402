/* eslint-disable no-undef */
/* eslint-disable import/no-import-module-exports */
import 'sentry';
import { AppRegistry } from 'react-native';
import Application from 'domain/index';
import 'domain/shared/utils/injectFontStyle';
import { patchFlatListProps } from 'react-native-web-refresh-control';
import { name as appName } from './app.json';

if (module.hot) {
  module.hot.accept();
}
patchFlatListProps();

(() => {
  document.addEventListener('DOMContentLoaded', () => {
    const styles = document.createElement('style');

    styles.textContent = `
      div[aria-modal="true"] div.css-view-175oi2r.r-flex-13awgt0.r-top-ipm5af > div[tabindex="0"].css-view-175oi2r:first-child {
        width: 100% !important;
        height: 100% !important;
      }

      div[aria-modal="true"] div.css-175oi2r.r-13awgt0.r-ipm5af > div[tabindex="0"].css-175oi2r:first-child {
        width: 100% !important;
        height: 100% !important;
      }
    `;

    document?.head.appendChild(styles);
  });
})();

// initOneSignal();
AppRegistry.registerComponent(appName, () => Application);
AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('app-root'),
});
