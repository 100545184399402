import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function Twitter(props: any) {
  return (
    <Svg width={50} height={50} viewBox="0 0 27.029 21.961" {...props}>
      <G data-name="Group 3677">
        <Path
          data-name="Path 994"
          d="M27.029 2.6a11.553 11.553 0 01-3.193.875A5.51 5.51 0 0026.274.412a11.074 11.074 0 01-3.514 1.341 5.541 5.541 0 00-9.585 3.789 5.706 5.706 0 00.128 1.264 15.685 15.685 0 01-11.422-5.8 5.543 5.543 0 001.7 7.406 5.472 5.472 0 01-2.5-.682v.061a5.567 5.567 0 004.44 5.445 5.53 5.53 0 01-1.453.182 4.9 4.9 0 01-1.049-.095A5.594 5.594 0 008.2 17.187a11.134 11.134 0 01-6.871 2.363A10.38 10.38 0 010 19.475a15.6 15.6 0 008.5 2.487A15.663 15.663 0 0024.272 6.193c0-.245-.008-.481-.02-.716A11.054 11.054 0 0027.029 2.6z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
}

export default Twitter;
