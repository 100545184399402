import React, { useCallback } from 'react';
import Header from 'molecules/preview-header';
import { DrawerActions, useNavigation } from '@react-navigation/native';

interface IProps {
  title: string;
  disabled?: boolean
  onBack?: VoidFunction
}

const SettingsHeader = (props: IProps) => {
  const { title, disabled = false, onBack } = props;
  const navigation = useNavigation();
  const goBack = useCallback(() => {
    navigation.dispatch(DrawerActions.openDrawer());
    onBack?.();
  }, [navigation, onBack]);
  return (
    <Header
      actionName={title}
      onBack={goBack}
      showBottomBorder={false}
      disabled={disabled}
      borderless
    />
  );
};

export default SettingsHeader;
