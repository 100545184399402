import styled from 'styled-components/native';
import React from 'react';
import { currencyFromNumber } from '@kanvas/andromeda/lib/components/currency';
import colors from 'theme/colors';

const Container = styled.View`
  padding-horizontal: 24px;
  margin-top: 16px;
`;

const Title = styled.Text<any>`
  font-size: 14px;
  color: black;
  font-weight: bold;
`;

const Text = styled.Text<{ vin?: boolean, miles?: boolean, bold?: boolean }>`
  font-size: 12px;
  color: ${colors.PRIMARY_TEXT};
  ${({ vin = false }) => (vin ? 'margin-right: 24px;' : '')}
  ${({ miles = false }) => (miles ? 'margin-left: 64px;' : '')}
  ${({ bold = false }) => (bold ? 'font-weight: bold;' : '')}
`;

const TextLine = styled.View`
  margin-top: 16px;
  flex-direction: row;
`;

const Info = ({
  vin, stockNumber, condition, year, model, make, formsPrice, milleage,
}:any) => {
  const getValue = (value:any) => value || 'N/A';

  return (
    <Container>
      <Title>
        {`${getValue(condition)} ${getValue(year)} ${getValue(make)} ${getValue(model)}`}
      </Title>
      <TextLine>
        <Text>
          {`${currencyFromNumber(formsPrice)} | ${milleage ? `${currencyFromNumber(milleage, { unit: '' })} miles` : 'N/A'}`}
        </Text>
      </TextLine>
      <TextLine>
        <Text vin>
          <Text bold>VIN: </Text>
          {getValue(vin)}
        </Text>
        <Text>
          <Text bold>STOCK: </Text>
          {getValue(stockNumber)}
        </Text>
      </TextLine>
    </Container>
  );
};

export default Info;
