import { Alert } from 'react-native';
import { openLinkOnNewTab } from 'domain/shared/utils/openOnNewTab';
import { navigateToStack } from 'domain/shared/services/navigation-service';
import TabNavigationScreens from 'domain/shared/navigation/tab-nav-screens';
import { IS_WEB } from '../constants';

import { IN_APP_BROWSER, IN_APP_BROWSER_WALL } from '../screens';

export default async (url: string, actionName: string) => {
  try {
    if (IS_WEB) {
      openLinkOnNewTab(url);
      return;
    }

    navigateToStack(
      TabNavigationScreens.OPPORTUNITIES,
      IN_APP_BROWSER_WALL,
      {
        url,
        title: actionName,
      },
    );
  } catch (error: any) {
    Alert.alert(error.message);
  }
};

export const OpenInAppModalBrowser = (url: string) => {
  navigateToStack(
    TabNavigationScreens.OPPORTUNITIES,
    IN_APP_BROWSER,
    { url },
  );
};
