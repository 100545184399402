import React from 'react';
import styled from 'styled-components/native';
import { Flex } from '@kanvas/andromeda';
import SALogo from 'assets/images/logos/salesassist_white.png';

const Image = styled.Image`
  width: 32px;
  height: 32px;
`;

const Logo = () => (
 <Flex>
   <Image source={SALogo} />
 </Flex>
);

export default Logo;
