/* eslint-disable import/prefer-default-export */
import { ButtonListItem } from 'components/organisms/button-list';
import { translate } from 'locales';
import { SortType } from './types';

const SORT_TYPE = {
  DEFAULT: translate('lastUpdate'),
  MOST_RECENT: translate('mostRecent'),
  ASC: translate('asc'),
  DESC: translate('desc'),
};

export const SORT_CONTROL: Array<SortType> = [
  {
    NAME: SORT_TYPE.DEFAULT,
    FILTER: 'updated_at|desc',
  },
  {
    NAME: SORT_TYPE.MOST_RECENT,
    FILTER: 'created_at|desc',
  },
  {
    NAME: SORT_TYPE.DESC,
    FILTER: 'title|desc',
  },
  {
    NAME: SORT_TYPE.ASC,
    FILTER: 'title|asc',
  },
];