/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */

// Modules
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Platform,
  TouchableOpacity,
  Clipboard as RNWClipboard,
  Linking,
} from 'react-native';
import styled from 'styled-components/native';
import { Flex, Spacer } from '@kanvas/andromeda';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import MIcon from 'react-native-vector-icons/MaterialIcons';
import { getBottomSpace, isIphoneX } from 'react-native-iphone-x-helper';
import Clipboard from '@react-native-community/clipboard';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { useAsync, useEffectOnce } from 'react-use';
import AsyncStorage from '@react-native-async-storage/async-storage';
import IconMui from 'react-native-vector-icons/MaterialIcons';

// Atoms
import ModalContainer from 'atoms/ModelOption';
import { IPeople } from 'domain/shared/types/people';

// Molecules
import {
  Body3,
  Meta2,
} from 'molecules/text';

// Theme
import colors from 'theme/colors';
import { FONTS } from 'theme/typography';

// Constants
import {
  IS_ANDROID,
  IS_DESKTOP_WEB,
  IS_MOBILE,
  IS_WEB,
  PopUpInfoLink,
  SHOW_POPUP_MSG,
} from 'domain/shared/constants';

// Locales
import { translate } from 'locales';

// Types
import { IRelationship } from 'domain/shared/types/opportunity';

// Utils
import getPeopleRelationships from 'domain/shared/utils/getPeopleRelationships';
import AddParticipantModal from './add-participant-modal';
import IconButton from 'components/atoms/icon-button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'domain/shared/store';
import { setActiveOpportunity } from 'domain/shared/store/slice/opportunity';

export type ContactData = {
  id?: number;
  contact?: string;
  name?: string;
};

interface IProps {
  contacts: IPeople[];
  visible: boolean;
  leadId?: number;
  message?: string;
  onOpen: () => void;
  onClose: () => void;
  onSharePress: (index: any, data?: ContactData) => void;
}

interface ShareRowProps extends Pick<IProps, 'onSharePress'> {
  contact: IPeople,
  onCopy: () => void;
  message: string;
  disabled?: boolean;
  relationships: IRelationship[];
}

const BubbleButton = styled<any>(IS_ANDROID ? TouchableOpacity : TouchableWithoutFeedback)`
  padding-horizontal: 12px;
  padding-vertical: 8px;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-color: ${colors.DIVIDER};
  border-radius: 12px;
  ${({ disabled }) => (!disabled ? 'cursor: pointer;' : '')}
  ${({ disabled }) => (disabled ? `background-color:${colors.DISABLED_BUTTON_BACKGROUND}` : '')}
`;

const Container = styled(Flex)`
  margin-bottom: ${Platform.OS === 'android' ? '30' : isIphoneX() ? getBottomSpace() : 40}px;
`;

const IconContainer = styled.View`
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
`;

const MsgContainer = styled.View`
  height: 100%;
  flex-direction: row;
  width: 80%;
  align-items: center;
`;

const MsgText = styled.Text<any>`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color || colors.ICON_PRIMARY};
  font-family: ${FONTS.SFProText};
`;

const LearMoreButton = styled.TouchableOpacity`
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: -3px;
`;

const setClipboardData = (str: string) => {
  if (IS_WEB) {
    RNWClipboard.setString(str);
  } else {
    Clipboard.setString(str);
  }
};

const AddPersonComponent = ({ onPressAddPerson }: any) => (
  <IconButton
    onPress={onPressAddPerson}
    icon={<IconMui style={{ color: colors.ORANGE }} size={22} name="person-add-alt" />}
  />
);

export type MappedRelationship = { value: string, label: string, id: string };

const ShareRow = (props: ShareRowProps) => {
  const {
    contact,
    onSharePress,
    onCopy,
    message,
    relationships,
    disabled = false,
  } = props;

  const contactId = contact?.relationship ? contact.id : undefined;
  const contacts = [contact.phone, contact.email].filter((value) => !!value);
  const color = (IS_DESKTOP_WEB && disabled) ? colors.DISABLED : colors.ICON_PRIMARY;

  const RelationshipText = useCallback(() => {
    if (!contact) return null;
    if (!contact.relationship) return null;

    const relationship = relationships.find(({ id }) => id == contact.relationship);
    if (!relationship) return null;

    return <Meta2 text={relationship.name} />;
  }, [relationships, contact]);

  return (
    <Flex row align="center" style={{ marginTop: 24 }} paddingHorizontal={16}>

      <Flex flex={1}>
        <Body3 text={contact.name} />
        <RelationshipText />
        <TouchableOpacity onPress={onCopy}>
          <Flex row align="center">
            <MIcon
              name="open-in-new"
              size={12}
              color={colors.ORANGE}
              style={{ marginRight: 2 }}
            />
            <Meta2 text={translate((contact?.relationship && IS_DESKTOP_WEB) ? 'openCRM' : 'copyMessage')} color={colors.DARK_ORANGE} />
          </Flex>
        </TouchableOpacity>
      </Flex>

      {contacts.map((data: string, index: number) => {
        const icon = index === 0 ? 'message-processing-outline' : 'email-outline';

        const handlePress = () => {
          const contactData: ContactData = {
            contact: data,
            id: contactId,
            name: contact.name?.split(' ')[0],
          };

          setClipboardData(message);
          onSharePress(index, contactData);
        };

        return (
          <>
            <BubbleButton onPress={handlePress} disabled={IS_DESKTOP_WEB && disabled}>
              <Icon size={16} color={color} name={icon} />
            </BubbleButton>
            {(contacts.length !== index + 1) && <Spacer horizontal size={6} />}
          </>
        );
      })}

    </Flex>
  );
};

const ShareModal = (props: IProps) => {
  // Props
  const {
    contacts = [],
    visible,
    onClose,
    onOpen,
    onSharePress,
    message = '',
    leadId
  } = props;

  // States
  const [showModalMsg, setShowModalMsg] = useState(IS_WEB);
  const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);
  const [contactsList, setContactsList] = useState(contacts);

  // Store
  const dispatch = useDispatch();
  const activeOpportunity = useSelector((state: RootState) => state.opportunity.activeOpportunity);
  
  // Constants
  const [mainContact] = contacts;
  const formattedMessage = message.replace('{name}', mainContact?.name?.split(' ')[0].trim());

  const getPopUpMsgValue = async () => {
    const value = await AsyncStorage.getItem(SHOW_POPUP_MSG);
    const newValue = value === 'false' ? false : true || IS_WEB;
    setShowModalMsg(newValue);
  };

  const onPopUpMsgClose = async () => {
    setShowModalMsg(false);
    await AsyncStorage.setItem(SHOW_POPUP_MSG, 'false');
  };

  const onCopy = useCallback((name:string, id?: number) => {
    const copyMessage = message.replace('{name}', name.split(' ')[0].trim());
    setClipboardData(copyMessage);
    onSharePress(2, { id });
    onClose();
  }, [formattedMessage, onClose]);

  const onCloseAddParticipant = () => {
    setShowAddParticipantModal(false);
    setTimeout(() => onOpen(), 500);
  }

  const { value } = useAsync(async () => {
    const relationships = await getPeopleRelationships();
    return relationships;
  }, []);

  const renderItem = useCallback((contact: IPeople) => (
    <React.Fragment key={contact.id}>
      <ShareRow
        relationships={value || []}
        contact={contact}
        onSharePress={onSharePress}
        onCopy={() => onCopy(contact.name, contact?.relationship ? contact.id : undefined)}
        message={formattedMessage}
        disabled={contact.participant}
      />
      <Spacer horizontal size={18} />
    </React.Fragment>
  ), [onSharePress, value]);

  const MsgIcon = () => (
    <IconContainer>
      <MIcon name="feedback" color={colors.ORANGE} size={26} />
    </IconContainer>
  );

  const onSubmitParticipant = useCallback(async (participant: any) => {
    if (activeOpportunity?.participants) {
      dispatch(setActiveOpportunity({
        ...activeOpportunity,
        participants: [
          ...activeOpportunity.participants,
          participant
        ]
      }));
    } else if (activeOpportunity) {
      dispatch(setActiveOpportunity({
        ...activeOpportunity,
        participants: [
          participant
        ]
      }));
    }

    setContactsList([...contactsList, participant]);
    onCloseAddParticipant();
  }, [activeOpportunity]);

  const openLinkOnModal = () => {
    Linking.openURL(PopUpInfoLink);
  };

  const CustomModalMsg = () => (
    <MsgContainer>
      <MsgText>
        {translate('popUpMsg')}
        <LearMoreButton
          onPress={openLinkOnModal}
        >
          <MsgText
            color={colors.URL_LINK}
            style={{ textDecorationLine: 'underline' }}
          >
            {translate('learnMore')}
          </MsgText>
        </LearMoreButton>
      </MsgText>
    </MsgContainer>
  );

  useEffectOnce(() => {
    if (IS_WEB) {
      getPopUpMsgValue();
    }
  });

  return (
    <>
      <ModalContainer
        onPressClose={onClose}
        isVisible={visible}
        titleText="Share With"
        showMsg={showModalMsg}
        customText={(<CustomModalMsg />)}
        icon={(<MsgIcon />)}
        onMsgClose={() => onPopUpMsgClose()}
        customHeaderIcon={leadId && IS_MOBILE ? <AddPersonComponent onPressAddPerson={() => {
          onClose()
          setTimeout(() => setShowAddParticipantModal(true), 500)
        }} /> : null}
      >
        <Container>
          {contactsList.map(renderItem)}
          {
            IS_WEB && (
              <Spacer size={16} />
            )
          }
        </Container>
      </ModalContainer>

      <AddParticipantModal
        visible={showAddParticipantModal}
        onClose={onCloseAddParticipant}
        showModalMsg={showModalMsg}
        CustomModalMsg={(<CustomModalMsg />)}
        MsgIcon={(<MsgIcon />)}
        onMsgClose={() => onPopUpMsgClose()}
        leadId={leadId!}
        onSubmit={onSubmitParticipant}
      />
    </>
  );
};

export default ShareModal;
