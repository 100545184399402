import { gql } from "@apollo/client";

export const queryMe = gql`
  query QueryMe {
    me {
      id
      uuid
      verify_two_factor
      contact {
        two_step_phone_number
      }
    }
  }
`;

export const updateUser = gql`
  mutation updateUser($id: ID!, $data: UpdateUserInput!) {
    updateUser(id: $id, data: $data) {
      id
      uuid
      verify_two_factor
      contact {
        two_step_phone_number
      }
    }
  }
`;

export const sendVerificationCode = gql`
  mutation sendCode {
    sendVerificationCode
  }
`;

export const sendVerifyCode = gql`
  mutation verifyCode($code: String!) {
    verifyCode(code: $code)
  }
`;

export const logout = gql`
  mutation Logout {
    logout
  }
`;