/* eslint-disable no-useless-escape */
import React, { useCallback, useState } from 'react';
import { SafeAreaView } from 'react-native';
import styled from 'styled-components/native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { Flex } from '@kanvas/andromeda';
import colors from 'theme/colors';
import Header from 'components/molecules/settings-header';
import { KeyboardAwareScrollView as ScrollView } from 'react-native-keyboard-aware-scroll-view';
import DropDownWithLabel from 'components/molecules/dropdown-with-label';
import Screen from 'components/template/screen';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { PreferenceInput } from 'domain/shared/types/user';
import useAuth from 'domain/shared/hooks/useAuth';
import UserService from 'domain/shared/services/user-service';
import { showSuccess } from 'domain/shared/utils/toast';

const ButtonContainer = styled(Flex)`
  margin-bottom: ${getBottomSpace()}px;
  padding-horizontal: 16px;
  padding-vertical: 16px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.BORDER_COLOR};
`;

const initialValues: PreferenceInput = {
  language: '',
};

const validationSchema = Yup.object().shape({
  language: Yup.string().trim().required(),
});

const Preferences = () => {
  const navigation = useNavigation() as any;
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(async (values) => {
    const cleanValues = validationSchema.cast(values);
    setSubmitting(true);
    await UserService.updateUser(
      user.id,
      {
        language: cleanValues.language,
      },
    );
    setSubmitting(false);
    // showSuccess(translate('passwordChangedSuccessfully', TextTransform.CAPITAL));
    navigation.openDrawer();
  }, [dispatch]);

  return (
    <Formik
      initialValues={validationSchema.cast(user) || initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {
        ({
          handleSubmit,
          values,
          errors,
          touched,
          isValid,
          setFieldValue,
        }) => (
          <Screen flex={1}>
            {console.log(errors, values)}
            <SafeAreaView>
              <Header
                title={translate('preference', TextTransform.CAPITALIZE)}
                disabled={isSubmitting}
              />
            </SafeAreaView>
            <ScrollView>
              <Flex padding={16} gap={10} style={{ zIndex: 1 }}>
                <DropDownWithLabel
                  label={translate('language', TextTransform.CAPITALIZE)}
                  onItemSelected={(item) => setFieldValue('language', item.value)}
                  defaultValue={values.language || ''}
                  errors={touched.language ? errors.language : ''}
                  disabled
                  items={[
                    { label: 'English', value: 'EN' },
                    { label: 'Español', value: 'ES' },
                  ]}
                />
              </Flex>
              <ButtonContainer>
                <ButtonWithIcon
                  onPress={() => {
                    if (isValid) {
                      setSubmitting(true);
                      handleSubmit();
                    }
                  }}
                  text={translate('save', TextTransform.CAPITAL)}
                  enabled={!isSubmitting}
                  loading={isSubmitting}
                />
              </ButtonContainer>
            </ScrollView>

          </Screen>
        )
      }
    </Formik>
  );
};

export default Preferences;
