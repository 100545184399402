import React, { useState } from 'react';
import { useWindowDimensions } from 'react-native';
import {
  TabView, SceneRendererProps, Route, NavigationState,
} from 'react-native-tab-view';

export type TapBarProps = SceneRendererProps & {
  navigationState: NavigationState<Route>;
};
interface Props {
  routes: Route[];
  scene: SceneRendererProps;
  onIndex?: (current: number) => void;
  renderHeader?: (props: TapBarProps) => void;
}

export default function TabViewManager(props: Props) {
  const {
    scene, routes, renderHeader, onIndex,
  } = props;
  const layout = useWindowDimensions();
  const [index, setIndex] = useState(0);

  const handleIndexChange = (current: number) => {
    setIndex(current);
    onIndex?.(current);
  };

  return (
    <TabView
      renderScene={scene as any}
      onIndexChange={handleIndexChange}
      navigationState={{ index, routes }}
      initialLayout={{ width: layout.width }}
      swipeEnabled={false}
      renderTabBar={renderHeader as any}
    />
  );
}
