import { TouchableOpacity } from 'components/atoms/touchable';
import { BUTTON_HIT_SLOP } from 'domain/shared/constants';
import React from 'react';
import Icon from 'react-native-vector-icons/Ionicons';
import styled from 'styled-components/native';
import colors from 'theme/colors';

interface IProps {
  handle: Function
}

const ICON_SIZE = 28;

const IconStyled = styled(Icon)`
  margin-right: 12px;
`;

const LeftContainer: Function = (props: IProps) => {
  const { handle } = props;
  return (
    <TouchableOpacity hitSlop={BUTTON_HIT_SLOP} onPress={() => handle(true)}>
      <IconStyled
        name="search"
        size={ICON_SIZE}
        color={colors.SECONDARY_TEXT}
      />
    </TouchableOpacity>
  );
};

export default LeftContainer;
