import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { LOGIN, SET_UP_2_FACTOR } from 'domain/shared/screens';
import LoginScreen from 'domain/auth/screens/login';
import Setup2FA from './screens/setup-2fa';

const AuthScreens = {
  LOGIN,
  SET_UP_2_FACTOR,
};

const Stack = createNativeStackNavigator();

const AuthStack = () => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    <Stack.Screen name={AuthScreens.LOGIN} component={LoginScreen} />
    <Stack.Screen name={AuthScreens.SET_UP_2_FACTOR} component={Setup2FA} />
  </Stack.Navigator>
);

export default AuthStack;
