import React from 'react';
import { Flex } from '@kanvas/andromeda';
import colors from 'theme/colors';
import Icon from 'react-native-vector-icons/MaterialIcons';
import styled from 'styled-components/native';
import { TouchableOpacity } from 'components/atoms/touchable';
import { BUTTON_HIT_SLOP } from 'domain/shared/constants';

const Box = styled(Flex)`
  position: absolute;
  left: 30px;
  top: 20px;
  z-index: 9999;
`;

interface Props {
  color?: string;
  onPress?: () => void;
}

export default function BackButton(props: Props) {
  const { onPress, color = colors.WHITE } = props;
  return (
    <Box row>
      <TouchableOpacity hitSlop={BUTTON_HIT_SLOP} onPress={onPress}>
        <Icon name="arrow-back" size={24} color={color} />
      </TouchableOpacity>
    </Box>
  );
}
