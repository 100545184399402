import { gql } from '@apollo/client';
import Client from 'domain/shared/graphql/client';
import { IRelationship } from '../types/opportunity';

const defaultChannelQuery = gql`
  query {
    peopleRelationships(first: 50, page: 1) {
      data {
        id
        name
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;

type PeopleRelationshipResponse = { peopleRelationships: { data: IRelationship[] } };
const getPeopleRelationships = async (): Promise<IRelationship[]> => {
  try {
    const response = await Client.query<PeopleRelationshipResponse>({
      query: defaultChannelQuery,
      fetchPolicy: 'no-cache',
    });
    return response.data.peopleRelationships.data
  } catch (e) {
    return [];
  }
};

export default getPeopleRelationships;
