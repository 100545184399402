import moment from 'moment';
import axios from './axios';

type FormOfacData = {
  error: boolean;
  matches: any[];
  sourcesUsed: any[];
}

type FormData = {
  file: string;
  ofac: FormOfacData;
}

type OfacResponse = {
  data: {
    data: { form: FormData; }
    link_preview: string;
    status: string;
    verb: string;
  }
  id: string;
  uuid: string;
}

export default class LeadsService {
  static async toggleChrono(contact: any, started?: boolean): Promise<any> {
    const date = moment();

    const body = {
      is_chrono_running: started,
      title: contact?.people?.name,
      chrono_start_date: started ? date : null,
    };

    const { data } = await axios.put(`leads/${contact.id}`, body, {
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  }

  static async Ofac(leadId: string): Promise<OfacResponse> {
    const response = await axios.post<OfacResponse>(`/leads/${leadId}/messages/ofac/action`);
    return response.data;
  }
}
