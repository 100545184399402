import { Opportunity } from "domain/shared/types/opportunity";
import moment from "moment";

export default class Lead {
  firstname!: string;
  lastname!: string;
  middlename!: string;
  date!: string;
  phone?: string;
  email?: string;
  backendEntity!: Opportunity;

  static fromApi(record: Record<string, any>): Lead {
    const lead = new Lead();
    lead.firstname = record['firstname'];
    lead.middlename = record['middlename'];
    lead.lastname = record['lastname'];
    lead.date = record['updated_at'];
    lead.email = record['email'];
    lead.phone = record['phone'];
    lead.backendEntity = record as Opportunity;
    
    return lead;
  }

  get fullname() {
    return [this.firstname, this.middlename, this.lastname].join(' ');
  }

  get formatDate() {
    return moment(this.date).format('MM/DD/yyyy').replace(' ', '');
  }


}