import { gql } from '@apollo/client';
import Client from 'domain/shared/graphql/client';

const dashboardQuery = gql`
{
  productDashboard {
    total_products
  }
}
`;

export interface DashboardGetResponse {
  total_products?: number;
}

export default class DashboardService {
  static async get(): Promise<DashboardGetResponse> {
    const response = await Client.query<{ productDashboard: DashboardGetResponse }>({
      query: dashboardQuery,
    });

    return response.data.productDashboard;
  }
}
