import { AMPLITUDE_KEY } from 'env';
import amplitude from 'amplitude-js';

export const getAmplitudeInstance = (id: string) => {
  try {
    const instance = amplitude.getInstance();
    instance.init(AMPLITUDE_KEY);
    if (id) {
      instance.setUserId(id);
    }
    return instance;
  } catch (error: any) {
    console.log(error.message);
    return undefined;
  }
};
export const sendAmplitudeEvent = async (eventName: string, body: any) => {
  const store = await import('domain/shared/store');
  const state = store.default.getState();
  const { user, store: company_branch } = state.auth;
  if (user) {
    const {
      id: userId,
      default_company_branch: company_id,
      displayname: user_name,
      display_full_name,
    } = user;
    const amplitudeInstance = getAmplitudeInstance(userId.toString());

    amplitudeInstance?.logEvent(eventName, body);
    amplitudeInstance?.setUserProperties({
      user_name,
      company_id,
      company_name: company_branch?.name,
      name: display_full_name,
    });
  }
};
