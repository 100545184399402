import { Flex } from '@kanvas/andromeda';
import React from 'react';
import styled from 'styled-components/native';
import Colors from 'theme/colors';

interface IProps {
  highlight?: number,
  children: any
}

const ItemCardContainer = styled(Flex)<IProps>`
  width: 100%;
  border-radius: 8px;
  shadow-opacity: .05;
  shadow-color: ${Colors.BLACK};
  shadow-offset: 0px 4px;
  shadow-radius: 8px;
  padding: 12px;
  border-width: 1px;
  border-color: ${Colors.BORDER_COLOR};
  background-color: ${(props) => (props.highlight ? Colors.WHITE : Colors.PRIMARY_DEEMPH)};
  cursor: pointer;
`;

const ItemCard: React.FC<IProps> = ({ children, highlight = 1 }) => (
  <ItemCardContainer highlight={highlight}>
    {children}
  </ItemCardContainer>
);

export default ItemCard;
