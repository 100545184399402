/* eslint-disable default-case */
import React, { useContext } from 'react';
import styled from 'styled-components/native';
import { StageType, BadgeType } from 'domain/shared/types/engagement-types';
import { Message } from 'domain/shared/types/message';
import { Flex, Spacer } from '@kanvas/andromeda';
import { Meta2 } from 'molecules/text';
import moment from 'moment-timezone';
import colors from 'theme/colors';
import { ActivityListContext } from 'components/context/activity-list-context';
import { transformText, TextTransform } from 'react-native-localized-text/lib/localized-text';
import { useSelector } from 'react-redux';
import { RootState } from 'domain/shared/store';

type DataProduct = { interested: boolean, name: string, id: string };

const Container = styled(Flex)`
  flex-direction: row;
  align-items: flex-start;
`;

const Meta2Custom = styled(Meta2)`
  flex: 1;
  flex-wrap: wrap;
`;

const COPY = 'copy';

const getMessage = (status: StageType, str: string) => {
  const { icon } = BadgeType[status];
  const Icon = React.cloneElement(icon as any, { color: colors.SECONDARY_TEXT });
  return (
    <>
      <Container>
        {Icon}
        <Spacer horizontal size={2} />
        <Meta2Custom text={str} style={{ flex: 1, flexWrap: 'wrap' }} />
      </Container>
      <Spacer size={2} />
    </>
  );
};

const getHistoryMessage = (message: Message, lead: any) => {
  const {
    message: {
      status, text, via = 'email', data = {} as any,
    },
    users,
    created_at,
    message_types,
  } = message;

  const { people } = lead;

  const { verb } = message_types;
  const { engagementTypes } = useContext(ActivityListContext);
  const engagement = engagementTypes.find((i) => i.slug === verb);
  if (engagement) {
    const { stages } = engagement;
    const stage = stages.find((i) => i.slug === status);
    const userName = `${users.firstname.trim()} ${users.lastname.trim()}`.trim();
    const tz = moment.tz.guess();
    const date = moment.utc(created_at).tz(tz || '').format('MM/DD/YYYY [at] hh:mma');
    const statusType = status as StageType;
    const actionName = transformText(stage?.name || '', TextTransform.CAPITAL);

    switch (statusType) {
      case StageType.sent:
        return (
          getMessage(
            statusType,
            via !== COPY ? `${userName} ${via !== 'in-app' ? actionName : 'Read/Submitted'} ${via !== 'in-app' ? 'via' : ''} ${via || 'email'} on ${date}`
              : `${userName} ${actionName} ${text} ${via === 'in-app' ? 'in-app' : ''} on ${date}`,
          )
        );
      case StageType.opened:
        return (
          getMessage(
            statusType,
            `${people?.name.trim() || ''} ${actionName} ${text} on ${date}`,
          )
        );
      case StageType.submitted: {
        if (verb === 'view-vehicle') {
          const { products = [] } = data;
          return products.map((product: DataProduct) => {
            if (product) {
              const { interested, name, id } = product;
              return (
                <Flex key={id}>
                  {
                    getMessage(
                      statusType,
                      `${people?.name.trim() || ''} is ${!interested ? 'not ' : ''}interested in the ${name} that you shared on ${date}`,
                    )
                  }
                </Flex>
              );
            }
            return '';
          });
        }
        return (
          getMessage(
            statusType,
            `${people?.name.trim() || ''} ${actionName} ${text} on ${date}`,
          )
        );
      }
      default:
        return (
          getMessage(
            statusType,
            via !== COPY ? `${userName} ${via !== 'in-app' ? actionName : 'Read/Submitted'} ${via !== 'in-app' ? 'via' : ''} ${via || 'email'} on ${date}`
              : `${userName} ${via !== 'in-app' ? actionName : 'Read/Submitted'} ${text} ${via === 'in-app' ? 'in-app' : ''} on ${date}`,
          )
        );
    }
  }
  return null;
};

interface IProps {
  message: Message
}

const EngagementHistoryItem = (props: IProps) => {
  const { message } = props;
  const lead = useSelector((state: RootState) => state.opportunity.activeOpportunity);
  return (
    getHistoryMessage(message, lead)
  );
};

export default EngagementHistoryItem;
