/* eslint-disable no-param-reassign */
import React, {
  useCallback, useState, useContext, useEffect, useRef, useMemo,
} from 'react';
import ProductListItem from 'components/molecules/product-list-item';
import InventoryService from 'domain/shared/services/inventory-service';
import SearchInput from 'atoms/search-input';
import Colors from 'theme/colors';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import { Flex, Spacer } from '@kanvas/andromeda';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { VEHICLE_INFO_SA } from 'domain/shared/screens';
import { ProductListContext } from 'domain/share-product/product-list-context';
import getDefaultProductChannel from 'domain/shared/utils/getDefaultProductChannel';
import { ProductVariant } from 'domain/shared/types/product-variants';
import { Channel } from 'domain/shared/types/channels';
import { RecyclerListView, DataProvider, LayoutProvider } from 'recyclerlistview';
import useWindowSize from 'domain/shared/hooks/useWindowSize';
import Spinner from 'components/atoms/spinner';
import { EventRegister } from 'react-native-event-listeners';
import { CXTabBarEvents } from 'domain/shared/events/cx-tab-bar';
import { DEVICE_WIDTH } from 'domain/shared/constants';
import useAuth from 'domain/shared/hooks/useAuth';
import { RootState } from 'domain/shared/store';
import { InventoryStackContext } from 'domain/view-inventory/context';
import { useSelector } from 'react-redux';
import FilterButton from 'components/atoms/filter-button';
import FilterModal, { FilterModalRef, FilterValues } from './filter-modal';

const dataProvider = new DataProvider((r1, r2) => (r1 !== r2));

const sortLikedProducts = (items: any[]) => items.sort((a, b) => (
  b.interactions.like - a.interactions.like
));

const swapPositions = (array: any[], a: any, b: any) => {
  [array[a], array[b]] = [array[b], array[a]];
};

interface IProps {
  leadId?: string
}

export default function ProductList(props: IProps) {
  const { leadId } = props;
  const [width] = useWindowSize();
  const { defaultFilter } = useContext(InventoryStackContext);
  const [items, setItems] = useState<DataProvider>(dataProvider);
  const [layoutProvider, setLayoutProvider] = useState(new LayoutProvider(() => 0, (type, dim) => {
    dim.width = (width || DEVICE_WIDTH) - 20;
    dim.height = 88;
  }));
  const [channel, setChannel] = useState<Channel>({} as Channel);
  const [searchValue, setSearchValue] = useState('');
  const [query] = useDebounce(searchValue, 300);
  const [page, setPage] = useState(1);
  const [moreData, setMoreData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [attributes, setAttributes] = useState<FilterValues>(defaultFilter || {});
  const searchBarEvent = useRef('');
  const navigation = useNavigation() as any;
  const { currentStore, user } = useAuth();
  const activeOpportunity = useSelector((state: RootState) => state.opportunity.activeOpportunity);
  const ref = useRef<FilterModalRef>(null);

  const primaryVin = useMemo(() => {
    if (!activeOpportunity) return undefined;
    const { vehicle_of_interest = {} } = activeOpportunity.custom_fields as any;
    return vehicle_of_interest?.vin as string;
  }, [activeOpportunity]);

  const {
    onItemSelected,
    setToolbarItems,
    selectedItems,
    cancelMultiSelect,
  } = useContext(ProductListContext);
  // const {
  //   showProductListSearchBar = true,
  // } = useContext(InventoryStackContext);

  const onItemPress = useCallback((item: ProductVariant) => {
    navigation.navigate(VEHICLE_INFO_SA, {
      vehicle: item,
      channel,
      hideShareOptions: true,
      onClose: setToolbarItems,
    });
  }, [channel]);

  const onListItemSelected = useCallback((
    item: ProductVariant,
    multipleSelectEnabled?: boolean,
  ) => {
    if (multipleSelectEnabled) {
      onItemSelected?.(item);
    } else {
      onItemPress(item);
    }
  }, []);

  const rowRenderer = useCallback((_: any, item: ProductVariant) => (
    <ProductListItem
      item={item}
      onPress={onListItemSelected}
    />
  ), [onItemPress, onListItemSelected, selectedItems]);

  const onChangeText = useCallback((text: string) => {
    setSearchValue(text);
  }, []);

  const setSearchData = (current: Channel) => {
    setChannel(current);
    setPage(1);
    setMoreData(true);
    setLoading(false);
  };

  const fetch = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const channel = await getDefaultProductChannel();

    if (query) {
      const data = await InventoryService.getProducts(
        {
          query,
          page: 1,
          attributes,
          channel: channel.uuid,
          ...InventoryService.injectVisitor(leadId),
        },
      );

      setItems(dataProvider.cloneWithRows(
        [
          ...data,
        ],
      ));

      setSearchData(channel);
      return;
    }

    let productsIds = [] as any[];
    let likedProducts = [] as any;

    if (leadId) {
      // GET LEAD INTERACTIONS
      const interactions = await InventoryService.getProductsWithInteractions(leadId) as any[];

      if (interactions.length > 0) {
        productsIds = interactions
          .filter((i: any) => i.interactions.like === true)
          .map((i: any) => i.interacted_entity_id);

        likedProducts = await InventoryService.getProducts(
          {
            page: 1,
            attributes,
            channel: channel.uuid,
            ...InventoryService.injectVisitor(leadId),
          },
          {
            products: { operator: 'IN', items: productsIds },
          },
        );
      }

      if (primaryVin && likedProducts.length > 0) {
        const position = likedProducts.findIndex((item: any) => item.slug === primaryVin);
        if (position > 0) {
          swapPositions(likedProducts, 0, position);
          likedProducts = [...likedProducts];
        }
      }
    }

    const data = await InventoryService.getProducts(
      {
        query,
        page: 1,
        attributes,
        channel: channel.uuid,
        ...InventoryService.injectVisitor(leadId),
      },
      {
        products: { operator: 'NOT_IN', items: productsIds },
      },
    );

    setItems(dataProvider.cloneWithRows(
      [
        ...likedProducts,
        ...data,
      ],
    ));
    setSearchData(channel);
  }, [query, attributes, primaryVin]);

  const handleApply = async (current: FilterValues) => {
    setAttributes(current);
    // setLoading(true);
    // fetch();
  };

  useEffect(() => {
    setLoading(true);
    fetch();
  }, [fetch, currentStore, user]);

  useEffect(() => {
    setLayoutProvider(new LayoutProvider(() => 0, (type, dim) => {
      dim.width = width - 20;
      dim.height = 88;
    }));
  }, [width]);

  useFocusEffect(() => {
    searchBarEvent.current = EventRegister.addEventListener(
      CXTabBarEvents.ON_SEARCH,
      ({ value }) => setSearchValue(value),
    ).toString();

    return () => {
      EventRegister.rm(searchBarEvent.current);
    };
  });

  const onEndReached = useDebouncedCallback(() => {
    getDefaultProductChannel().then(async (response) => {
      const newPage = page + 1;
      if (!moreData) return;
      const data = await InventoryService.getProducts(
        {
          query,
          attributes,
          page: newPage,
          channel: response.uuid,
          ...InventoryService.injectVisitor(leadId),
        },
      );

      if (data.length) {
        const sourceData = items.getAllData();
        const newItems = sourceData.concat(data);
        setItems(dataProvider.cloneWithRows(newItems));
        setChannel(response);
        setPage(newPage);
        return;
      }

      setMoreData(false);
      setPage(page);
    });
  }, 500);

  return (
    <Flex flex={1}>
      <Flex>

        <SearchInput
          backgroundColor={Colors.SUBTILE}
          iconSize={20}
          containerStyle={{ paddingHorizontal: 15, height: 40 }}
          onChangeText={onChangeText}
          value={searchValue}
          onClear={() => setSearchValue('')}
          enableBarCodeScanner={false}
        />
        <Spacer size={8} />

        <Flex row>
          <FilterButton
            onPress={ref?.current?.onOpen}
            amount={Object.keys(attributes).length.toString()}
          />
        </Flex>
        <Spacer size={8} />
      </Flex>
      <Flex flex={1}>
        {
          loading ? (
            <Spinner />
          ) : (
            <RecyclerListView
              layoutProvider={layoutProvider}
              dataProvider={items}
              rowRenderer={rowRenderer}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{ paddingBottom: 130 }}
              onEndReached={onEndReached}
              onEndReachedThreshold={0.3}
              canChangeSize
              style={{ flex: 1 }}
              suppressBoundedSizeException
              renderFooter={() => (
                <Flex paddingVertical={8}>
                  <Spinner visible={moreData} />
                </Flex>
              )}
            />
          )
        }

      </Flex>

      <FilterModal defaultValue={defaultFilter} ref={ref} onApply={handleApply} />
    </Flex>

  );
}
