import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
  VIEW_INVENTORY, VEHICLE_INFO_SA, MODAL_COLLAGE, VEHICLE_SHARE_MENU, FULL_CARROUSEL,
} from 'domain/shared/screens';
import VehicleInformation from 'domain/vehicle-information';
import ShareProduct from 'domain/share-product';
import { FilterValues } from 'components/organisms/product-list/filter-modal';
import { InventoryStackContext, InventoryStackContextProps } from './context';

interface IProps {
  isShareProduct?: boolean;
  showSelectButton?: boolean;
  action?: 'view-vehicle';
  defaultFilter?: FilterValues;
}

export const OpportunityScreens = {
  INVETORY: VIEW_INVENTORY,
  VEHICLE_INFO: VEHICLE_INFO_SA,
  MODAL_COLLAGE,
  VEHICLE_SHARE_MENU,
  FULL_CARROUSEL,
};

const Stack = createNativeStackNavigator();

const getInventoryContextValue = (isShareProduct: boolean): InventoryStackContextProps => {
  if (isShareProduct) {
    return {
      highlightProductOfInterest: true,
      showProductListFilterButton: false,
      showProductListSearchBar: true,
      showProductListSortButton: false,
    };
  }
  return {
    highlightProductOfInterest: false,
    showProductListFilterButton: true,
    showProductListSearchBar: false,
    showProductListSortButton: true,
  };
};

const ViewInventoryStack = (props: IProps) => {
  const {
    isShareProduct = false,
    showSelectButton = true,
    action = 'view-vehicle',
    defaultFilter = {},
  } = props;
  const inventoryStackContextValue = getInventoryContextValue(isShareProduct);
  const otherProps = { showSelectButton, action, defaultFilter };
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { ...inventoryStackContextValue, ...otherProps };

  return (
    <InventoryStackContext.Provider value={value}>
      <Stack.Navigator
        initialRouteName={OpportunityScreens.INVETORY}
        screenOptions={{ headerShown: false }}
      >
        <Stack.Screen name={OpportunityScreens.INVETORY} component={ShareProduct} />
        <Stack.Screen name={OpportunityScreens.VEHICLE_INFO} component={VehicleInformation} />
      </Stack.Navigator>
    </InventoryStackContext.Provider>
  );
};

export default ViewInventoryStack;
