/* eslint-disable react/no-unused-prop-types */
import useWindowSize from 'domain/shared/hooks/useWindowSize';
import React, { PropsWithChildren } from 'react';
import { Platform, PlatformIOSStatic } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import styled from 'styled-components/native';
import Colors from 'theme/colors';

interface IProps extends PropsWithChildren {
  height?: number;
  width?: number;
}

const Gradient = styled(LinearGradient)<Pick<IProps, 'height' | 'width'>>`
  ${(props) => (props.height ? `height: ${props.height}px` : '')}
  ${(props) => (props.width ? `width: ${props.width}px` : '')}
`;

const GradientBar: React.FC<IProps> = (props) => {
  const { height, children } = props;
  const [width] = useWindowSize();
  const currentWidth = (Platform as PlatformIOSStatic).isPad ? undefined : width;

  return (
    <Gradient
      height={height}
      colors={[Colors.TAB_BAR_GRADIENT_ONE, Colors.TAB_BAR_GRADIENT_TWO]}
      start={{ x: 0.6, y: 0 }}
      end={{ x: 1, y: 1 }}
      locations={[0.20, 0.75]}
      width={currentWidth}
    >
      {children}
    </Gradient>
  );
};

export default GradientBar;
