import { IS_WEB } from '../constants';

/* eslint-disable func-names */
const convertFileToBase64String = (file: any) => new Promise((res, reject) => {
  if (IS_WEB) {
    // @ts-ignore
    const DOCUMENT = document;
    const reader = new FileReader();
    reader.onload = function (e) {
      const img = DOCUMENT.createElement('img');
      img.onload = function () {
        // Dynamically create a canvas element
        const canvas = DOCUMENT.createElement('canvas');

        // var canvas = document.getElementById("canvas");
        const ctx = canvas.getContext('2d');
        const aspect = img.naturalWidth / img.naturalHeight;
        const maxWidth = 1024;
        const width = img.naturalWidth < maxWidth ? img.naturalWidth : maxWidth;
        const height = width / aspect;
        canvas.width = width;
        canvas.height = height;
        // Actual resizing
        ctx?.drawImage(img, 0, 0, width, height);

        // Show resized image in preview element
        const dataUrl = canvas.toDataURL(file.type);
        res(dataUrl);
      };
      img.src = e.target?.result as any;
    };
    reader.readAsDataURL(file);
    reader.onerror = function (error: any) {
      reject(error);
    };
  }
});

export default convertFileToBase64String;
