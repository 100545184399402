import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/native';
import CategoryList from 'organisms/category-list';
import { Flex } from '@kanvas/andromeda';
import { ScrollView } from 'react-native';
import { translate } from 'locales';
import items, { ItemType } from './items';
import { ButtonListItem } from '../button-list';

interface Offset {
  x: number;
  y: number;
}

interface IProps {
  selectedCategory: ItemType;
  onCategorySelected?: (item: ItemType) => void;
}

const CategoryMenu = styled(Flex)`
  height: 50px;
`;

const ScrollContainer = styled.ScrollView`
  margin-vertical: 6px;
`;

export default function UploadCategories(props: IProps) {
  const { selectedCategory, onCategorySelected } = props;
  const [categories, setCategories] = useState(items);
  const [contentOffset, setContentOffset] = useState<Offset>({ x: 0, y: 0 });
  const scrollViewRef = useRef<ScrollView>(null);

  const handleScroll = useCallback((event: any) => {
    const { x, y } = event.nativeEvent.contentOffset;
    setContentOffset({ x, y });
  }, []);

  const handleSize = useCallback(() => {
    setTimeout(() => {
      scrollViewRef.current?.scrollTo({ x: contentOffset.x, animated: false });
    }, 300);
  }, [scrollViewRef.current]);

  const onItemSelected = useCallback((item: ButtonListItem) => {
    const newCategories = ([...categories.map((i) => ({ ...i, selected: false }))]);
    const itemSelected = newCategories.find((a) => a.key === item.key);
    if (itemSelected) {
      itemSelected.selected = true;
      setCategories(newCategories);
      onCategorySelected?.(itemSelected);
    }
  }, []);

  const current = useMemo(() => {
    return categories.map((item) => (
      { ...item, selected: item.key === selectedCategory.key }
    ))
  }, [categories, selectedCategory]);

  return (
    <CategoryMenu>
      <ScrollContainer
        ref={scrollViewRef}
        horizontal
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={16}
        onScroll={handleScroll}
        contentOffset={{ x: 0, y: 0 }}
        contentContainerStyle={{ paddingHorizontal: 10, alignItems: 'center' }}
        onContentSizeChange={handleSize}
      >
        <CategoryList
          onChange={onItemSelected}
          items={current.map((item) => ({ ...item, text: translate(item.text) }))}
        />
      </ScrollContainer>
    </CategoryMenu>
  );
}
