import { useNavigation, useRoute } from '@react-navigation/native';
import { ScannedData } from 'domain/barcode-scanner/types';
import { EventRegister } from 'react-native-event-listeners';

export const SCANNED_OPPORTUNITY = 'SCANNED_OPPORTUNITY';

export interface ScannerParams {
  type: 'lead' | 'participants' | 'search' | 'wall',
  index?: number;
  opp?: ScannedData;
}

export type RouteStack = { key: string; name: string; params: ScannerParams };

export function useScannerScreen() {
  const navigation = useNavigation();
  const route = useRoute<RouteStack>();

  const handleBack = () => {
    navigation.goBack();
  };

  const handleOpportunity = (opp: ScannedData) => {
    const { params } = route;
    EventRegister.emit(SCANNED_OPPORTUNITY, { ...params, opp });
    navigation.goBack();
  };

  return {
    handleBack,
    handleOpportunity,
  };
}
