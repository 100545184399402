import { useState } from 'react';
import { useBetween } from 'use-between';

const UseForms = () => {
  const [forms, setForms] = useState([]) as any;

  return {
    forms,
    setForms,
  };
};

export default () => useBetween(UseForms);
