/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { Flex } from '@kanvas/andromeda';
import SafeAreaView from 'components/atoms/SafeAreaView';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import { Body3, Header3 } from 'components/molecules/text';
import { PropsWithChildren } from 'react';
import { StatusBar, Platform, Linking } from 'react-native';
import FastImage from 'react-native-fast-image';
import styled from 'styled-components/native';
import colors from 'theme/colors';
import { translate } from 'locales';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Image from '../../../../assets/images/logos/sa-logo.png';

const link = Platform.select({
  ios: 'itms-apps://apps.apple.com/us/app/salesassist-app/id1617172663',
  android: 'http://play.google.com/store/apps/details?id=io.shiplove.salesassist.android',
  web: 'https://chrome.google.com/webstore/detail/salesassist/oehaiibnpneipeafkkhdiignfiopoknc',
})!;

const Container = styled(Flex)`
  background-color: ${colors.WHITE};
`;

const Content = styled(Flex)`
  padding-left: 24px;
  padding-right: 24px;
`;

const Header = styled(Flex)`
  width: 100%;
`;

const Paragraph = styled(Body3)`
  /* width: 100%; */
  color: ${colors.SECONDARY_TEXT};
`;

const Logo = styled(FastImage)`
  height: 45px;
  width: 220px;
`;

function Layout({ children }: PropsWithChildren) {
  const insets = useSafeAreaInsets();

  return (
    <>
      <StatusBar />
      <SafeAreaView />

      <Container flex={1} justify="center">
        <Content
          gap={22}
          align="center"
          justify="center"
          style={{ marginTop: -insets.top * 1.8 }}
        >
          {children}
        </Content>
      </Container>
    </>
  );
}

export default function Update() {
  const handlePress = () => {
    Linking.canOpenURL(link)
      .then((valid) => valid && Linking.openURL(link));
  };

  return (
    <Layout>
      <Logo source={Image} />

      <Header align="center">
        <Header3 text={translate('update.title')} />
        <Paragraph text={translate('update.description')} />
      </Header>

      <ButtonWithIcon
        onPress={handlePress}
        text={translate('update.button')}
      />
    </Layout>
  );
}
