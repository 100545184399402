import React, { useMemo } from 'react';
import { Platform } from 'react-native';
import { Flex } from '@kanvas/andromeda';
import styled from 'styled-components/native';

import Lead from 'model/data/lead';

import { TouchableOpacity } from 'components/atoms/touchable';

import colors from 'theme/colors';

import { Body3, Body4, Meta2 } from '../text';
import { translate } from 'locales';

interface Props {
  lead: Lead;
  searching?: boolean;
  onPress?: (lead: Lead) => void;
}

export default function LeadItem(props: Props) {
  const { lead, onPress } = props;

  const vehicle = useMemo(() => {
    const { vehicle_of_interest = null } = lead.backendEntity.custom_fields as Record<string, any>;
    if (!vehicle_of_interest) return undefined;

    const { make, model, yearFrom, isNew } = vehicle_of_interest;

    const status = isNew ? '(New)' : '(Used)';
    const vehicle = [yearFrom, make, model, status].join(' ').trim();
    return vehicle;
  }, [props]);

  return (
    <Button onPress={() => onPress?.(lead)}>
      <Container>
        <Flex row>
          <Flex flex={1} row align="center">
            <Body3 link numberOfLines={1} text={lead.fullname} style={{ maxWidth: '70%' }} />
          </Flex>
          <Body4 text={lead.formatDate} />
        </Flex>

        {vehicle && <Meta2 text={vehicle} color={colors.SECONDARY_TEXT} />}
        {lead.phone && <Meta2 text={lead.phone} color={colors.TEXT_ORANGE_LINK} />}
        {lead.email && <Meta2 text={lead.email} color={colors.TEXT_ORANGE_LINK} />}
        <SearchingData {...props} />
      </Container>
    </Button>
  );
}

function SearchingData(props: Props) {
  const { owner, status_name } = props.lead.backendEntity as any;
  const { full_name } = owner;

  if (!props.searching) return null;
  
  return (
    <>
      <Flex 
        style={{
          borderColor: colors.DIVIDER,
          borderBottomWidth: 1,
          marginVertical: 8,
        }}
      />

      { full_name ? <Meta2 text={`${translate('primarySales')}: ${full_name}`} color={colors.SECONDARY_TEXT} /> : null}
      { status_name ? <Meta2 text={`${translate('salesStatus')}: ${status_name}`} color={colors.SECONDARY_TEXT} /> : null}
    </>
  )
}

const Button = styled(TouchableOpacity)`
  border-radius: 8px;
  background-color: ${colors.WHITE};
  shadow-opacity: .05;
  shadow-color: ${colors.BLACK};
  shadow-offset: 0px 4px;
  shadow-radius: 8px;
  elevation: 1;
`;

const Container = styled(Flex)`
  width: 100%;
  background-color: ${colors.WHITE};
  ${Platform.OS === 'ios' ? 'min-height: 80px' : ''};
  padding: 10px;
  border-width: 1px;
  border-color: ${colors.BORDER_COLOR};
  justify-content: space-between;
`;