import React, { ReactNode } from 'react';
import { Flex } from "@kanvas/andromeda";
import { TouchableOpacity } from "components/atoms/touchable";
import styled from "styled-components/native";
import Colors from 'theme/colors';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Body3 } from "../text";
import colors from 'theme/colors';

const Container = styled(Flex)`
  padding: 16px;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.DIVIDER};
  flex-direction: row;
  width: 100%;
`;

interface Props {
  text: string;
  expand?: boolean;
  expandable?: boolean;
  onExpand?: () => void;
}

interface TouchableProps {
  expandable?: boolean;
  children: ReactNode;
  onPress?: () => void;
}

function Touchable(props: TouchableProps) {
  if (props.expandable) return <TouchableOpacity onPress={props.onPress}>{props.children}</TouchableOpacity>;
  return <>{props.children}</>
}

export default function ListTitle(props: Props) {
  const icon = props.expand ? 'expand-less' : 'expand-more';

  const handleExpand = () => {
    if (!props.expandable) return;
    props.onExpand?.();
  };

  return (
    <Touchable expandable={props.expandable} onPress={handleExpand}>
      <Container justify='space-between' align='center'>
        <Body3 text={props.text} link />
        <Icon 
          size={16}
          name={icon}
          color={colors.BLACK}
          style={{ display: props.expandable ? 'flex': 'none' }}
        />
      </Container>
    </Touchable>
  );
}