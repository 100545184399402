import axios from 'domain/shared/services/axios';

const COUNTRY = 230;

class CountryService {
  static async getAllCountry() {
    const { data } = await axios.get('/countries?limit=500&sort=name|ASC');
    return data;
  }

  static async getAllState() {
    const { data } = await axios.get(
      `countries/${COUNTRY}?limit=500&sort=name|ASC`,
    );
    
    return data.states;
  }

  static async getAllCities(id: number) {
    const { data } = await axios.get(
      `/countries/${COUNTRY}/states/${id}/regions`,
    );
    return data.results;
  }

  static async getStateByCode(code: string) {
    if (code.length === 0) return undefined;

    const { data } = await axios.get(
      `countries/${COUNTRY}/states`,
      {
        params: {
          q: `(code:${code})`
        }
      }
    );

    return data?.at(0);
  }

  static async getCityByName(stateId: number, name: string) {
    if (name.length === 0) return undefined;
    const { data } = await axios.get(
      `countries/${COUNTRY}/states/${stateId}/regions`,
      {
        params: {
          q: `(name:${name})`
        }
      }
    );

    return data.results?.at(0);
  }
}

export default CountryService;
