import client from "domain/shared/graphql/client";
import { logout, queryMe, sendVerificationCode, sendVerifyCode, updateUser } from "domain/shared/graphql/user";

export interface Me {
  id: number;
  uuid: string;
  verify_two_factor: boolean;
  contact: {
    two_step_phone_number: string;
  }
}

interface CustomField {
  name: string;
  data: any;
}

export default class GraphUserService {
  static async me(): Promise<Me> {
    const response = await client.query<{ me: Me }>({ query: queryMe, fetchPolicy: 'no-cache' });
    return response.data.me;
  }

  static async update(id: number, data: { two_step_phone_number?: string; custom_fields: CustomField[] }): Promise<Me | undefined> {
    const response = await client.mutate<{ updateUser: Me }>({ mutation: updateUser, variables: { id, data } });
    return response.data?.updateUser;
  }

  static async sendVerificationCode() {
    await client.mutate({ mutation: sendVerificationCode });
  }

  static async verifyCode(code: string): Promise<boolean | undefined> {
    const response = await client.mutate<{ verifyCode: boolean }>({ mutation: sendVerifyCode, variables: { code } });
    return response.data?.verifyCode;
  }

  static async logout(): Promise<void> {
    await client.mutate({ mutation: logout });
  }
}