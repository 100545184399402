/* eslint-disable no-param-reassign */
/* eslint-disable react/static-property-placement */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import Spinner from 'atoms/spinner';
import styled from 'styled-components/native';
import {
  StatusBar, ScrollView,
} from 'react-native';
import Colors from 'theme/colors';
import { Flex } from '@kanvas/andromeda';
import { ContentPreviewContext } from 'domain/opportunity-wall/screens/content-preview/content-preview-context';
import { TextTransform } from 'react-native-localized-text';
import { translate } from 'locales';
import { Attribute } from 'domain/shared/types/product-variants';
import PreviewHeader from 'components/molecules/preview-header';
import MobileStatusBar from 'components/molecules/mobile-status-bar';
import Carrousel from './components/Carrousel';
import ExteriorCollapse from './components/ExteriorCollapse';
import Snapshot from './components/Snapshot';
import Info from './components/Info';

const Background = styled.View`
  background-color: ${Colors.WHITE};
  padding-bottom: 72px;
`;

class VehicleInformation extends Component<any, any> {
  static contextType = ContentPreviewContext;

  declare context: React.ContextType<typeof ContentPreviewContext>;

  constructor(props: any) {
    super(props);
    this.state = {
      vehicleInfo: {},
      isLoading: true,
      showHeader: false,
    } as any;
  }

  componentDidMount() {
    const { setActionState } = this.context;
    setActionState?.({
      title: translate('productInfo', TextTransform.CAPITALIZE),
      toolbarRight: null,
      onBack: this.onBack,
    });
    if (!setActionState) {
      // this condition is meet when opening this screen from inventory option
      this.setState({ showHeader: true });
    }
    this.getVehicleInfo();
  }

  onBack = () => {
    const { navigation } = this.props;
    const { setActionState, setSelectedProducts } = this.context;
    const { onClose } = this.props.route.params;

    setSelectedProducts?.([]);
    setActionState({
      title: undefined,
      toolbarRight: null,
    });
    onClose?.();
    navigation.goBack();
  };

  getVehicleInfo = async () => {
    const { vehicle } = this.props.route.params;
    const { setSelectedProducts } = this.context;
    setSelectedProducts?.([vehicle]);
    this.setState({ vehicleInfo: vehicle, isLoading: false });
  };

  renderItem = (item: Attribute, index: number) => {
    const options = Array.isArray(item.value) ? item.value : [item.value];
    return <ExteriorCollapse key={index} title={item.name} options={options} />;
  };

  getVehicleSpec = (attributes: Attribute[] = []) => {
    const display_attributes = attributes.find((item) => item.name === 'display_attributes');
    if (display_attributes) {
      const values = display_attributes.value as unknown as string[];
      const attributeNames = values.join(';');
      return attributes.filter((item) => attributeNames.includes(item.name));
    }
    return attributes;
  };

  getVehicleAttributes = () => {
    const { vehicleInfo } = this.state;
    const { attributes = [] } = vehicleInfo;

    return attributes.reduce((acc: any, current: Attribute) => {
      acc[current.name] = current.value;
      return acc;
    }, {});
  };

  render() {
    const { vehicleInfo, showHeader } = this.state;
    const liveInformation = this.getVehicleAttributes();

    const attributes = this.getVehicleSpec(vehicleInfo.attributes);

    const stock = vehicleInfo?.attributes?.find(
      (attr: Attribute) => attr.name === 'stock_number',
    ) as Attribute | undefined;

    return (
      <>
        {
          showHeader && (
            <>
              <MobileStatusBar />
              <PreviewHeader actionName="Product Info" />
            </>
          )
        }
        <ScrollView
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          <StatusBar />
          <Background>
            {this.state.isLoading ? (
              <Spinner />
            ) : (
              <Flex>
                <Carrousel
                  photos={vehicleInfo.files.data}
                  isLoading={this.state.isLoading}
                  vehicleId={vehicleInfo.id}
                />
                <Info
                  condition={liveInformation.new === '0' ? 'Used' : 'New'}
                  year={liveInformation?.year}
                  model={liveInformation?.model}
                  make={liveInformation?.make}
                  vin={this.state.vehicleInfo?.slug}
                  stockNumber={stock?.value}
                  formsPrice={vehicleInfo.channels.price}
                  milleage={liveInformation?.odometer}
                />
                <Snapshot
                  engine={liveInformation?.engine}
                  fuelType={liveInformation?.fuel_type}
                  transmission={liveInformation?.transmission}
                  exteriorColor={liveInformation?.color}
                  interiorColor={liveInformation?.interior_color}
                  seats="N/A"
                />
                {attributes.map(this.renderItem)}
              </Flex>
            )}
          </Background>
        </ScrollView>
      </>
    );
  }
}

VehicleInformation.contextType = ContentPreviewContext;

export default VehicleInformation;
