import { showAlert } from 'components/molecules/alerts';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import { IS_WEB } from '../constants';

export const openOnNewTab = (url: string) => {
  if (IS_WEB) {
    // @ts-ignore;
    const WINDOW = window;
    return WINDOW.open(url);
  }
  return undefined;
};

export const isPopUpBlocked = (popup: any) => {
  if (!popup || popup.closed || typeof popup.closed === 'undefined') {
    return true;
  }
  return false;
};

export const openLinkOnNewTab = (url: string) => {
  if (isPopUpBlocked(openOnNewTab(url))) {
    showAlert(translate('popupHasBeenBlocked', TextTransform.CAPITAL));
  }
};

export default openOnNewTab;
