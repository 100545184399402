/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Text, { TextProps } from 'atoms/text';
import Colors from 'theme/colors';
import { FONTS } from 'theme/typography';

export const Meta1 = (props: TextProps) => (
  <Text
    color={Colors.SECONDARY_TEXT}
    size={16}
    lineHeight={16}
    allowFontScaling={false}
    {...props}
  />
);

export const Meta2 = (props: TextProps) => (
  <Text
    color={Colors.SECONDARY_TEXT}
    size={12}
    lineHeight={16}
    allowFontScaling={false}
    {...props}

  />
);

export const Meta3 = (props: TextProps) => (
  <Text
    color={Colors.SECONDARY_TEXT}
    size={12}
    lineHeight={16}
    fontFamily={FONTS.SFProTextSemiBold}
    allowFontScaling={false}
    {...props}
  />
);

export const Body3 = (props: TextProps & { link?: boolean }) => (
  <Text
    color={Colors.ICON_PRIMARY}
    size={15}
    lineHeight={20}
    fontFamily={props.link ? FONTS.SFProTextSemiBold : FONTS.SFProText}
    allowFontScaling={false}
    {...props}
  />
);
export const Body4 = (props: TextProps & { link?: boolean }) => (
  <Text
    color={Colors.ICON_SECONDARY}
    size={13}
    lineHeight={16}
    fontFamily={props.link ? FONTS.SFProTextSemiBold : FONTS.SFProText}
    allowFontScaling={false}
    {...props}
  />
);

export const Header1 = (props: TextProps) => (
  <Text
    color={Colors.ICON_PRIMARY}
    size={24}
    lineHeight={24}
    fontFamily={FONTS.SFProTextSemiBold}
    allowFontScaling={false}
    {...props}
  />
);

export const Header2 = (props: TextProps) => (
  <Text
    color={Colors.ICON_PRIMARY}
    size={20}
    lineHeight={24}
    fontFamily={FONTS.SFProTextSemiBold}
    allowFontScaling={false}
    {...props}
  />
);

export const Header3 = (props: TextProps) => (
  <Text
    color={Colors.ICON_PRIMARY}
    size={17}
    lineHeight={20}
    fontStyle="semibold"
    fontFamily={FONTS.SFProTextSemiBold}
    allowFontScaling={false}
    {...props}
  />
);

export const Button1 = (props: TextProps) => (
  <Text
    color={Colors.ICON_PRIMARY}
    size={17}
    lineHeight={20}
    fontFamily={FONTS.SFProTextSemiBold}
    allowFontScaling={false}
    {...props}
  />
);

export const Button2 = (props: TextProps) => (
  <Text
    color={Colors.ICON_PRIMARY}
    size={15}
    lineHeight={20}
    fontFamily={FONTS.SFProTextSemiBold}
    allowFontScaling={false}
    {...props}
  />
);

export const Error = (props: TextProps) => (
  <Text
    color={Colors.ERROR}
    size={12}
    lineHeight={16}
    allowFontScaling={false}
    {...props}
  />
);

export const Body2 = (props: TextProps & { link?: boolean }) => (
  <Text
    color={Colors.ICON_PRIMARY}
    size={17}
    lineHeight={20}
    fontFamily={props.link ? FONTS.SFProTextSemiBold : FONTS.SFProText}
    allowFontScaling={false}
    {...props}
  />
);
