import React, { } from 'react';
import Colors from 'theme/colors';
import ButtonList, { ButtonListItem, RenderItemFn } from 'components/organisms/button-list';
import styled from 'styled-components';
import { Body3 } from 'components/molecules/text';
import { Spacer } from '@kanvas/andromeda';
import { StyleSheet } from 'react-native';
import { RectButton } from 'components/atoms/touchable';

interface ButtonProps {
  selected?: boolean;

}

const Button = styled(RectButton) <ButtonProps>`
    flex:1;
    padding-left:16px;
    justify-content:center;
    height: 44px;   
    border: 1px solid ${Colors.BUTTON_BORDER};
    box-shadow: 0px 4px 16px ${Colors.BUTTON_BORDER};
    border-radius: 12px;
    ${({ selected }) => (selected ? `
    background-color: ${Colors.PRIMARY_DEEMPH};
    border-color:${Colors.TEXT_ORANGE_LINK}
    ` : ` background-color: ${Colors.WHITE};`)}
    `;

const styles = StyleSheet.create({
  buttonContainer: {
    flex: 1,
    paddingLeft: 16,
    justifyContent: 'center',
    height: 44,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.BUTTON_BORDER,
    shadowOffset: { height: 4, width: 0 },
    shadowColor: Colors.BUTTON_BORDER,
    borderRadius: 12,
    shadowRadius: 5,
  },
  default: {
    backgroundColor: Colors.WHITE,
  },
  selected: {
    borderColor: Colors.TEXT_ORANGE_LINK,
    backgroundColor: Colors.PRIMARY_DEEMPH,
  },
});

interface IPropss {
  onChange?: (items: ButtonListItem) => void;
  value:number
}

const typeOfCustumer: ButtonListItem[] = [
  {
    key: '0', value: 0, text: 'Person', selected: true,
  },
  { key: '1', value: 1, text: 'Organization' },
];

const OppType = (props: IPropss) => {
  const { onChange, value } = props;
  const renderItem: RenderItemFn = (item, onItemPress, enabled) => {
    const textColor = value === item.value ? Colors.TEXT_ORANGE_LINK : Colors.PRIMARY_TEXT;
    return (
      <React.Fragment key={item.key}>
        <RectButton
          onPress={() => onItemPress(item)}
          enabled={enabled}
          style={[styles.buttonContainer, value === item.value ? styles.selected : styles.default]}
        >
          <Body3 text={item.text} color={textColor} />
        </RectButton>
        <Spacer horizontal size={4} />
      </React.Fragment>
    );
  };

  return (
    <ButtonList
      items={typeOfCustumer}
      onItemSelected={onChange}
      renderItem={renderItem}
      defaultValue={0}

      row
    />
  );
};

export default OppType;
