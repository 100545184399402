import { gql } from '@apollo/client';

export default gql`
  query getEntityInteractions($entityId: String!, $type: String!) {
    entityInteractions(
        entity_id: $entityId, 
        entity_namespace: $type,
    ){
        data {
            entity_id,
            entity_namespace,
            interacted_entity_id,
            interacted_entity_namespace,
            interactions{
              like,
              save
            },
            entity
        }
    }
  }
`;
