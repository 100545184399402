import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AppSettings, CompanySettings } from 'domain/shared/types/settings';
import AppSettingsService from 'domain/shared/services/app-settings-service';
import BrutalService, { BrutalConfig } from 'domain/shared/services/brutal.service';
import { setTranslations } from 'locales';

export const setSettings = createAsyncThunk(
  'settings/set-app-settings',
  async () => {
    const [appSettings, companySettings, brutalSettings] = await Promise.all([
      AppSettingsService.getAppSettings(),
      AppSettingsService.getCompanySettings(),
      BrutalService.getConfig(),
    ]);
    
    return {
      appSettings: appSettings?.settings,
      companySettings: companySettings?.settings,
      brutalSettings,
    };
  },
);

interface SettingsState {
  appSettings: AppSettings | undefined;
  companySettings: CompanySettings | undefined;
  brutalSettings: BrutalConfig | undefined;
}

const initialState: SettingsState = {
  appSettings: undefined,
  companySettings: undefined,
  brutalSettings: undefined,
};

const settingsSlice = createSlice({
  initialState,
  name: 'settings',
  reducers: {
    clearSettings(state) {
      state.appSettings = undefined;
      state.companySettings = undefined;
      state.brutalSettings = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSettings.fulfilled, (state, action: PayloadAction<SettingsState>) => {
      const { appSettings, companySettings, brutalSettings } = action.payload;

      // FIXME: IN THE FUTURE WE MUST CHANGE HOW THIS WORKS
      if (companySettings?.flag_company_multi_language) {
        setTranslations(companySettings.flag_company_multi_language.toLocaleUpperCase());
      }

      state.appSettings = appSettings;
      state.companySettings = companySettings;
      state.brutalSettings = brutalSettings;
    });
  },
});

export default settingsSlice.reducer;

export const {
  clearSettings,
} = settingsSlice.actions;
