// Modules
import React, { ReactNode } from 'react';
import { StyleProp } from 'react-native';
import { Flex } from '@kanvas/andromeda';
import styled from 'styled-components/native';
import { TextTransform } from 'react-native-localized-text';
import MIcon from 'react-native-vector-icons/MaterialIcons';

// Themes
import colors from 'theme/colors';

// Atoms
import ButtonWithIcon from 'components/atoms/button-with-icon';

// Molecules
import { Meta2 } from '../text';
import { FormLabel } from '../input-with-label';

// Locales
import { translate } from 'locales';

// Types
import { UploadFile } from 'domain/shared/types/file.interface';

type Props = {
  label: string;
  required?: boolean;
  buttonLabel: string;
  onPress?: VoidFunction;
  Icon?: ReactNode;
  value?: UploadFile;
  loading?: boolean;
  error?: string;
  onReset?: VoidFunction;
  styles?: StyleProp
};

const ButtonContainer = styled(Flex)`
  padding: 12px 16px;
  background: ${colors.WHITE};
  border: 1px solid ${colors.BUTTON_BORDER};
  box-shadow: 0px 4px 5px ${colors.BUTTON_BORDER};
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;

function Content(props: Props) {
  const {
    buttonLabel, onPress, onReset, Icon, loading, value,
  } = props;

  const buttonProps = {
    color: colors.WHITE,
    textColor: colors.DARK_ORANGE,
    paddingVertical: 8,
    height: 36,
    loading,
  };

  if (value) {
    return (
      <ButtonWithIcon
        {...buttonProps}
        onPress={onReset}
        text={value.name}
        icon={<MIcon name="close" size={16} color={colors.DARK_ORANGE} />}
      />
    );
  }

  return (
    <ButtonWithIcon
      {...buttonProps}
      icon={Icon}
      loading={loading}
      onPress={onPress}
      text={buttonLabel}
    />
  );
}

export default function UploadInput(props: Props) {
  const {
    label, error, required, styles,
  } = props;
  return (
    <Flex gap={4} style={styles}>
      <FormLabel required={required} text={label} />
      <ButtonContainer>
        <Content {...props} />
      </ButtonContainer>

      {(!!error && error.length > 0) && (
        <Meta2 text={translate(error, TextTransform.NONE)} color={colors.ERROR} />
      )}
    </Flex>
  );
}
