import React, { useState } from 'react';
import { Flex, Spacer } from '@kanvas/andromeda';
import styled from 'styled-components/native';
import Colors from 'theme/colors';
import { ActivityIndicator, Text } from 'react-native';
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import { ACTIVE_SESSION } from 'domain/shared/screens';
import { navigateToStack } from 'domain/shared/services/navigation-service';
import ActionEngineService, { IEleadsSyncParam } from 'domain/shared/services/actionEngine-service';
import Icon from 'react-native-vector-icons/MaterialIcons';
import TextLink from 'components/atoms/text-link';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'locales';
import { AppDispatch, RootState } from 'domain/shared/store';
import { setActiveOpportunity } from 'domain/shared/store/slice/opportunity';
import { TouchableWithoutFeedback } from 'components/atoms/touchable';
import OpportunityService from 'domain/shared/services/opportunity-service';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useLead from 'domain/shared/hooks/useLead';
import { Lead } from 'domain/opportunity-list/types';
import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';
import { OPENING_OPPORTUNITY } from 'domain/shared/types/amplitude-event';
import { Opportunity } from 'domain/shared/types/opportunity';
import moment from 'moment';
import { OPEN_CLOSE_OPP, SYNC_OPP } from 'domain/shared/types/amplitude-actions';
import MainContainer from './components/MainContainer';
import { Meta2 } from '../text';
import { hideModal } from '../alerts';

const ButtonContainer = styled(Flex)`
    width: 30px;
    height: 30px;
    border-radius: 8px;
    border-width: 1px;
    border-color: ${Colors.BORDER_COLOR};
    justify-content: center;
    alignItems: center;
    backgroundColor: ${Colors.PRIMARY_DEEMPH};
`;

const FIRSTTIME = 'firstTime';
const NAVIGATESTATUS = 'Active';
const OpportunityListMatchItem = (props: any) => {
  const { id: leadId } = useSelector(
    (state: RootState) => state.opportunity?.activeOpportunity,
  ) || {};

  const [disable, setDisable] = useState<boolean>(false);
  const { setLeadId } = useLead();
  const dispatch = useDispatch<AppDispatch>();
  const {

    opp: {
      id = 0,
      rank,
      title,
      type = '',
      status_name = '',
      uuid,
      updated_at,
      people: {
        name = '',
        phone = '',
        email = '',
      },
      owner: {
        full_name = '',
      },
      companies: {
        uuid: companyUuid,
      },
      custom_fields: {
        ELEAD_OPPORTUNITY_POPUP_UUID,
        ELEAD_OPPORTUNITY_ID,
      },
    },
    args: {
      dealId,
      personId,
      opportunity: {
        popupUuid,
      },
      setShowModal,
    },
    showToast,
  } = props;

  const sync: IEleadsSyncParam = {
    company: companyUuid,
    dealId,
    // @ts-ignore
    opportunity: {
      popupUuid: popupUuid || ELEAD_OPPORTUNITY_POPUP_UUID || '',
      uid: ELEAD_OPPORTUNITY_ID || '',
    },
    personId,
  };

  const matchPresition = `(${Math.round(rank * 100) || 0}% match)`;
  const navigateOppWall = async () => {
    setDisable(true);
    const {
      opp, args: {
        opportunity,
      },
    } = props;
    setLeadId(opp.uuid);
    if (uuid) {
      await OpportunityService.unSyncELeads(uuid);
    }
    const response = await ActionEngineService.syncELeads(sync);
    if (response) {
      dispatch(setActiveOpportunity({
        ...opp,
        sync: {
          company: companyUuid,
          dealId,
          opportunity: { ...opportunity, popupUuid: opportunity.uid, uid: '' },
          personId,
        },

      }));

      const firtTime = await AsyncStorage.getItem(FIRSTTIME) || '';
      if (!firtTime) {
        await AsyncStorage.setItem(FIRSTTIME, 'true');
      }
      AsyncStorage.setItem('titleName', opp?.title || '');
      navigateToStack(OpportunityScreens.WALL, ACTIVE_SESSION);
      setDisable(false);
      hideModal();
      showToast();
      sendAmplitudeEvent(SYNC_OPP, { event: SYNC_OPP, ...opp });
    }
  };

  const { opp, args } = props;

  const handleItem = (item: Lead) => {
    const { setShowModal } = args;
    setShowModal(false);
    dispatch(setActiveOpportunity(item as unknown as Opportunity));
    navigateToStack(OpportunityScreens.WALL as never, ACTIVE_SESSION as never, { hideSync: true });
    sendAmplitudeEvent(OPEN_CLOSE_OPP, { event: OPEN_CLOSE_OPP, ...item });
  };
  return (
    <MainContainer
      id={id}
      leadId={leadId}
    >
      {!disable ? (
        <TouchableWithoutFeedback
          onPress={status_name === NAVIGATESTATUS ? navigateOppWall : () => handleItem(opp as Lead)}
          disabled={disable}
        >
          <>
            <Flex row justify="space-between">
              <Flex>
                <Flex
                  row
                  justify="space-between"
                  align="center"
                  marginVertical={2}
                >
                  <Text>
                    <Meta2
                      size={15}
                      weight="600"
                      numberOfLines={1}
                      style={{ maxWidth: '70%' }}
                      text={title}
                      color={Colors.BLACK}
                    />
                    {status_name === NAVIGATESTATUS && <Spacer size={2} horizontal />}
                    <Spacer size={2} horizontal />
                    {status_name === NAVIGATESTATUS && <Meta2 text={`${matchPresition}`} color={Colors.SECONDARY_TEXT} />}
                  </Text>
                </Flex>
                <Meta2
                  text={name}
                  size={14}
                  weight="600"
                  style={{ maxWidth: '80%' }}
                  color={Colors.SECONDARY_TEXT}
                />
                { phone ? <TextLink text={phone} /> : null}
                { email ? <TextLink text={email} /> : null}
              </Flex>
              <ButtonContainer>
                <Icon
                  name={status_name === NAVIGATESTATUS ? 'sync' : 'open-in-new'}
                  size={24}
                  color={Colors.TEXT_ORANGE_LINK}
                />
              </ButtonContainer>
            </Flex>

            <Flex style={{
              borderColor: Colors.DIVIDER,
              borderBottomWidth: 1,
              marginVertical: 8,
            }}
            />
            { full_name ? <Meta2 text={`${translate('primarySales')}: ${full_name}`} color={Colors.SECONDARY_TEXT} /> : null}
            { type && status_name === NAVIGATESTATUS ? <Meta2 text={`${translate('uptype')}: ${type}`} color={Colors.SECONDARY_TEXT} /> : null}
            { status_name ? <Meta2 text={`${translate('salesStatus')}: ${status_name}`} color={Colors.SECONDARY_TEXT} /> : null}
            { updated_at && status_name !== NAVIGATESTATUS ? <Meta2 text={`${translate('closedOpp')}: ${moment(updated_at).format('MM/DD/yyyy').replace(' ', '')}`} color={Colors.SECONDARY_TEXT} /> : null}
          </>
        </TouchableWithoutFeedback>
      ) : (
        <Flex
          flex={1}
          justify="center"
          align="center"
          style={{ height: 150 }}
        >
          <TextLink text={translate('synchronizing')} />
          <ActivityIndicator size="small" color={Colors.ORANGE} />
        </Flex>
      )}
    </MainContainer>
  );
};

export default OpportunityListMatchItem;
