import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Colors from 'theme/colors';
import { Flex } from '@kanvas/andromeda';
import styled from 'styled-components/native';
import { TouchableOpacity } from 'components/atoms/touchable';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Body3 } from 'components/molecules/text';
import ListTitle from 'components/molecules/list-title';
import { translate } from 'locales';
import { Bundle, EsignFile } from 'domain/opportunity-wall/types/bundle.interface';
import { ButtonListItem } from 'components/organisms/button-list';
import { TextTransform } from 'react-native-localized-text';
import useForms from 'domain/shared/hooks/useForms';
import CheckList from '../check-list';

const Container = styled(Flex)`
  padding: 16px;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.DIVIDER};
  flex-direction: row;
  width: 100%;
`;

interface Props {
  item?: Bundle | null;
  items?: Bundle[];
  onPress?: (bundle: Bundle) => void;
  action: string;
}

interface ItemProps {
  item: Bundle;
  active?: boolean;
  onPress?: () => void;
  action: string;
}
type Item = { label: string, value: any, selected?: boolean };

function Item(props: ItemProps) {
  const { active = false, item: bundle, onPress, action } = props;
  const icon = !active ? 'radio-button-unchecked' : 'radio-button-checked';
  const color = active ? Colors.DARK_ORANGE : Colors.ICON_SECONDARY;
  const [list, setList] = useState<ButtonListItem[]>([]);
  const { setForms, forms } = useForms();

  const parseItems = useCallback((ef: EsignFile) => ({
    text: ef.name,
    value: ef.id,
    key: ef.name,
    selected: false,
  }), []);

  useEffect(() => {
    if (active) {
      const { files: items } = bundle;
      setList(items.map(parseItems));
      setForms({});
    }
  }, [active]);

  const removeFormFromList = (formList: ButtonListItem[]) => {
    let listForms = {};
    formList.forEach((item) => {
      if (forms[item.value]) {
        listForms = { ...listForms, [item.value]: forms[item.value] };
      }
    });
    setForms(listForms);
  };

  const onSelectionChange = (vals: ButtonListItem[]) => {
    removeFormFromList(vals);
  };

  return (
    <Flex>
      <TouchableOpacity activeOpacity={1} onPress={onPress}>
        <Container row gap={8} align="center">
          <Icon name={icon} size={24} color={color} />
          <Body3 text={bundle.name} color={Colors.BLACK} />
        </Container>
      </TouchableOpacity>
      <Flex style={{ paddingLeft: 20 }}>
        {
       (active === true && list.length > 0) && (
         <CheckList
           items={list.map((item:ButtonListItem) => ({ ...item, selected: true }))}
           onChange={onSelectionChange}
           preFillSelected
           action={action}
         />
       )
       }
      </Flex>
    </Flex>
  );
}

export default function BundleList(props: Props) {
  const active = useMemo(() => props.items?.findIndex((item) => props.item?.name === item.name), [props.item]);

  const handlePress = (index: number) => {
    if (!props.items) return;
    const bundle = props.items[index];
    props.onPress?.(bundle);
  };

  if (!props.items || !props.items?.length) {
    return <></>;
  }

  return (
    <Flex>
      <ListTitle text={translate('selectFromBundles', TextTransform.CAPITALIZE)} />
      {props.items?.map((bundle, index) => (
        <Item item={bundle} action={props.action} active={active === index} onPress={() => handlePress(index)} />
      ))}
    </Flex>
  );
}
