// Modules
import React, { useCallback, useMemo } from 'react';
import { useWindowDimensions } from 'react-native';
import styled from 'styled-components/native';
import { TabView } from 'react-native-tab-view';
import { Flex } from '@kanvas/andromeda';
import { useRoute } from '@react-navigation/native';

// Organisms
import ActivityList from 'components/organisms/activity-list';
import WallChecklist from 'components/organisms/wall-checklist';

// Screens
import ShortCuts from 'domain/opportunity-wall/screens/wall/components/Shortcuts';

// Themes
import colors from 'theme/colors';

// Utils
import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';

// Types
import { TABS } from 'domain/shared/types/amplitude-event';
import { ACTION_TAB, ACTIVITIES_TAB } from 'domain/shared/types/amplitude-actions';
import { Opportunity } from 'domain/shared/types/opportunity';

// Molecules
import CustomTabBar from './custom-tab-bar';
import { useSelector } from 'react-redux';
import { RootState } from 'domain/shared/store';

enum SCREENS {
  CHECKLIST = 'CHECKLIST',
  ACTIONS = 'ACTIONS',
  ACTIVITIES = 'ACTIVITIES',
}

interface IProps {
  activeOpportunity: Opportunity;
}

const ScrollSheet = styled.ScrollView`
  flex: 1;
  width: 100%; 

`;
const Sheet = styled.View`
  flex: 1;
  width: 100%;
  height: 100%;
`;

const CXShortcuts = (props: IProps) => {
  const { params: { openActivities = 0 } = {} as any } = useRoute();
  const { activeOpportunity } = props;
  const layout = useWindowDimensions();

  // States
  const [index, setIndex] = React.useState(openActivities || 0);
  const settings = useSelector((state: RootState) => state.settings.companySettings)!;

  const routes = useMemo(() => {
    const defaultRoutes = [
      { key: SCREENS.ACTIONS, title: 'Apps' },
      { key: SCREENS.ACTIVITIES, title: 'Activities' },
    ];

    if (!settings.CHECKLIST_AVAILABLE) return defaultRoutes;

    return [
      { key: SCREENS.CHECKLIST, title: 'Checklist' },
      ...defaultRoutes
    ]
  }, []);

  const renderScene = useCallback((e: any) => {
    const { route } = e;
    switch (route.key) {
      case SCREENS.CHECKLIST:
        if (!settings.CHECKLIST_AVAILABLE) return null;
        return (
          <WallChecklist leadId={activeOpportunity.uuid} />
        );
      case SCREENS.ACTIONS:
        return (
          <ScrollSheet>
            <ShortCuts contact={activeOpportunity} />
          </ScrollSheet>
        );
      case SCREENS.ACTIVITIES:
        return (
          <Sheet>
            <ActivityList leadId={activeOpportunity.uuid} />
          </Sheet>
        );
      default:
        return null;
    }
  }, [activeOpportunity, settings]);

  const onIndexChange = (itemSelected: number) => {
    setIndex(itemSelected);
    sendAmplitudeEvent(TABS, { event: itemSelected ? ACTIVITIES_TAB : ACTION_TAB });
  };

  return (
    <Flex style={{ flex: 1 }}>
      <TabView
        sceneContainerStyle={{ backgroundColor: colors.WHITE }}
        renderTabBar={(barProps: any) => <CustomTabBar {...barProps} />}
        renderScene={renderScene}
        navigationState={{ index, routes }}
        onIndexChange={(i) => { onIndexChange(i); }}
        initialLayout={{ width: layout.width }}
      />
    </Flex>
  );
};

export default CXShortcuts;
