import React, { ReactNode } from 'react';
import { Flex } from '@kanvas/andromeda';
import styled from 'styled-components';
import colors from 'theme/colors';
import Text from 'components/atoms/text';
import AvatarButton from 'components/atoms/AvatarButton/AvatarButton';
import MobileStatusBar from '../mobile-status-bar';

interface ThemeProps { $padding?: number };

const HeaderContainer = styled(Flex)<ThemeProps>`    
    flex-direction: row;
    align-items: center;
    padding: ${((props) => props.$padding)}px;
`;

const TitleText = styled(Text)`
  margin-left: 8px;
`;

interface IProps extends ThemeProps {
  title?: string | null,
  left?: React.ReactNode | null
  statusBar?: ReactNode
}

const TEXT_SIZE = 20;

const ViewTitle = (props: IProps) => {
  const { title, left, $padding = 16, statusBar = <MobileStatusBar /> }: IProps = props;
  return (
    <>
      {statusBar}
      <HeaderContainer $padding={$padding}>
        <AvatarButton />
        <Flex row align="center" justify="center">
          <TitleText
            size={TEXT_SIZE}
            fontStyle="semibold"
            weight="600"
            color={colors.BLACK}
            text={title || ''}
          />
        </Flex>
        <Flex row flex={1} justify="flex-end">
          {left}
        </Flex>
      </HeaderContainer>
    </>
  );
};

export default ViewTitle;
