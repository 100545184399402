import hmac from 'crypto-js/hmac-sha256';
import * as b from 'buffer/';
import { ScanPicture } from '../types';
import Sentry from 'sentry';
import store from 'domain/shared/store';
import { IntellicheckKeys } from 'domain/shared/services/brutal.service';

const Buffer = b.Buffer

interface BaseReponse<T> {
  public_data: {
    endpoint?: string;
    version?: string;
    message?: string;
    status_code?: number;
  };
  private_data: T;
} 

interface TransactionId {
  transaction_id: string;
}

interface StartResponse extends TransactionId {
  capture_url: string;
  signals: string[];
  ttl: number;
}

interface IdCheck {
  data: {
      address1: string;
      address2: string;
      age: number;
      city: string;
      dLIDNumberFormatted: string;
      dLIDNumberRaw: string;
      dateOfBirth: string;
      docCategory: string;
      docType: string;
      driverClass: string;
      duplicateDate: string;
      endorsements: string;
      expirationDate: string;
      expired: string;
      extendedResultCode: string;
      eyeColor: string;
      firstName: string;
      gender: string;
      hairColor: string;
      heightCentimeters: string;
      heightFeetInches: string;
      isDuplicate: string;
      isRealID: string;
      issueDate: string;
      issuingJurisdictionAbbrv: string;
      issuingJurisdictionCvt: string;
      lastName: string;
      mediaType: string;
      middleName: string;
      organDonor: string;
      postalCode: string;
      processResult: string;
      restrictions: string;
      socialSecurity: string;
      state: string;
      testCard: boolean;
      uniqueID: number;
      weightKilograms: string;
      weightPounds: string;
  };
  message: string;
  result: boolean;
  success: boolean;
}

export interface DocumentData {
  idcheck: IdCheck;
}

interface GetResultResponse {
  result: DocumentData;
}

export default class IntelicheckApi {

  constructor (protected intellicheck: IntellicheckKeys) {}

  private getSignature(data: object) {
    const payload = Base64.convert(data);
    const signature = "sha256=" + hmac(payload, this.intellicheck.key);
    return { signature, payload };
  }

  private getHeader(signature: string): HeadersInit {
    return {
      'Accept': 'application/json',
      'signature': signature,
      'customer-id': this.intellicheck.customer,
      'Content-type': 'application/json',
    };
  }

  private fetch(url: string, init?: RequestInit) {
    return fetch(`${this.intellicheck.url}/${url}`, init);
  }

  async start(): Promise<StartResponse> {
    const privateData = { document_type: 'na_dl', signals: ['idcheck'] };
    const data = { public_data: {}, private_data: privateData }
    
    const { signature, payload } = this.getSignature(data);
    const headers = this.getHeader(signature);

    const result = await this.fetch('start', { body: payload, headers, method: 'POST' })
    const text = await result.text();

    const { private_data } = Base64.parse<BaseReponse<StartResponse>>(text);

    return private_data;
  }

  private async step<T>(url: string, body: object): Promise<T> {
    const { signature, payload } = this.getSignature({ private_data: body, public_data: {} });
    const headers = this.getHeader(signature);

    const result = await this.fetch(url, { body: payload, headers, method: 'POST' })
    const text = await result.text();

    const { private_data, public_data } = Base64.parse<BaseReponse<T>>(text);
    if (public_data.status_code) throw new Error(public_data.message);
    return private_data;
  }

  async getResult(id: string): Promise<GetResultResponse> {
    return await this.step<GetResultResponse>('get-results', { transaction_id: id });;
  }

  static async run({ back, front }: ScanPicture): Promise<DocumentData | undefined> {
    try {
      const { settings: { brutalSettings } } = store.getState();
      if (!brutalSettings) return;
      const instance = new IntelicheckApi(brutalSettings.intellicheck);

      const start = await instance.start();
      const { transaction_id } = start;

      if (front) await instance.step<TransactionId>('submit-front', { transaction_id, front, is_error: false });
      if (back) await instance.step<TransactionId>('submit-back', { transaction_id, back, is_error: false });

      const result = await instance.step<TransactionId>('end', { transaction_id });
      const response = await instance.getResult(result.transaction_id);

      return response.result;
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      return undefined;
    }
  }
}

class Base64 {
  static convert(data: object) {
    return Buffer.from(JSON.stringify(data)).toString('base64');
  }

  static parse<T>(text: string): T {
    return JSON.parse(Buffer.from(text, 'base64').toString());
  }
}