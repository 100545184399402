import React, { FC, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
}

const Youtube: FC<Props> = ({ size = 20, ...props }) => (
  <svg {...props} id="Layer_1" width={size} height={size} enableBackground="new 0 0 1000 1000" version="1.1" viewBox="0 0 1000 1000" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g>
      <path fill="#FF0000" d="M500,1000L500,1000C223.9,1000,0,776.1,0,500v0C0,223.9,223.9,0,500,0h0c276.1,0,500,223.9,500,500v0   C1000,776.1,776.1,1000,500,1000z" />
      <path fill="#FFFFFF" d="M818.2,339.1c-7.6-28.8-30.1-51.4-58.7-59.1c-51.8-14-259.4-14-259.4-14s-207.7,0-259.4,14   c-28.6,7.7-51.1,30.3-58.7,59.1C168,391.2,168,500,168,500s0,108.8,13.9,160.9c7.6,28.8,30.1,51.4,58.7,59.1   c51.8,14,259.4,14,259.4,14s207.7,0,259.4-14c28.6-7.7,51.1-30.3,58.7-59.1C832,608.8,832,500,832,500S832,391.2,818.2,339.1z    M432.1,598.7V401.3L605.6,500L432.1,598.7z" />
    </g>

  </svg>
);

export default Youtube;
