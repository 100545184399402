import { GenericAbortSignal } from "axios";
import LeadsApi from "model/api/leads/leads.api";
import Lead from "model/data/lead";
import { Params } from "model/types/formated-response";
import { LeadHubPresenterInterface } from "model/types/lead-hub.presenter";

class Preload {
  constructor(
    readonly data: Lead[],
    readonly page: number,
    readonly totalPage: number | null,
  ) {}

  static create() {
    return new Preload([], 1, null);
  }

  static withData(info: Record<string, any>) {
    const { data, page, totalPage  } = info;
    return new Preload(data, page, totalPage);
  }

  get hasData() {
    return this.data.length >= 1;
  }
}

export default class LeadHubInterceptor {
  private readonly leadsApi: LeadsApi;

  private data: Lead[] = [];
  private page = 1;
  private totalPages: number | null = null;

  private preload = Preload.create();
  private text?: string;


  private controller?: AbortController;

  constructor(private readonly presenter: LeadHubPresenterInterface) {
    this.leadsApi = new LeadsApi();
  }

  async fetch() {
    try {
      if (this.shouldFetch()) return;

      this.controller?.abort();
      this.controller = new AbortController();

      const params: Params = { page: this.page, text: this.text || '' };
      const response = await this.leadsApi.get(params, this.controller.signal);

      console.log({ total: response.total_pages, page: response.page, items: response.data.length });

      if (response.total_pages === 0) {
        this.presenter.notFound();
        return;
      }

      const leads = response.data.map((data) => Lead.fromApi(data));
      if (!this.totalPages) this.totalPages = response.total_pages;

      this.data = [...this.data, ...leads];
      this.presenter.get(this.data);
    } catch (e: any) {
      if (e.name = 'CanceledError') return;
      console.log(JSON.stringify(e))
    }
  }

  async next() {
    this.page += 1;
    await this.fetch();
  }

  async search(text: string) {
    // if (this.shouldStorePreload()) {
    //   this.preload = Preload.withData({
    //     data: this.data,
    //     page: this.page,
    //     totalPages: this.totalPages
    //   });
    // }

    this.page = 1;
    this.data = [];
    this.text = text;
    this.totalPages = null;

    await this.fetch();
  }

  async clear() {
    this.text = undefined;
    
    this.page = 1;
    this.data = [];
    this.totalPages = null;
  }

  async refetch() {
    this.page = 1;
    this.data = [];
    this.totalPages = null;
    this.fetch();
  }

  private shouldFetch() {
    if (!this.totalPages) return false;
    const valid = this.page > this.totalPages;
    if (valid) this.presenter.limitReach();
    return valid;
  }

  private shouldStorePreload() {
    if (this.preload.hasData) return false;
    return this.data.length > 0;
  }
}