import { useCallback, useContext, useEffect } from 'react';
import AuthContext from 'components/context/auth';
import { useDispatch } from 'react-redux';
import OneSignal from 'react-native-onesignal';
import {
  clearActiveRooftop,
  changeLeadsInfo,
} from 'domain/shared/actions/sessionActions';
// import { clearLanguage } from 'domain/legacy/modules/preferences/language';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  CommonActions, useNavigation,
} from '@react-navigation/native';

import UserService from '../services/user-service';
import CompanyBranchService from '../services/company-branch-service';
import { DEFAULT_COMPANY_INFO_KEY, GRAPH_TOKEN_KEY, SHOW_POPUP_MSG, USER_TOKEN_KEY } from '../constants';
import { User } from '../types/user';
import { setMe } from '../store/slice/auth';
import GraphUserService from '../services/graph-user-service';

// const userChanged = (currentUser: User, newUser: User) => (
//   JSON.stringify(currentUser) === JSON.stringify(newUser)
// );

const useAuth = () => {
  const {
    user,
    setUser,
    currentStore,
    setCurrentStore,
    stores,
    setStores,
    setAppSettings,
  } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const removeDevice = async () => {
    try {
      if (!user) return;
      const device = await OneSignal.getDeviceState();
      if (!device) return;
      const { userId: deviceId } = device;
      await UserService.unregisterDevice(user.id, deviceId);
    } catch { /* empty */ }
  };

  const logOut = useCallback(async (redirect: boolean = true) => {
    await AsyncStorage.getItem(GRAPH_TOKEN_KEY).then((keys) => {
      if (!!keys) GraphUserService.logout();
    });
    removeDevice();
    // dispatch(clearLanguage());
    dispatch(clearActiveRooftop());
    dispatch(changeLeadsInfo(null));
    dispatch(setMe(undefined));
    setUser(undefined);
    setStores(undefined);
    setCurrentStore(undefined);
    AsyncStorage.removeItem('language');
    AsyncStorage.removeItem('userInfo');
    AsyncStorage.removeItem(USER_TOKEN_KEY);
    AsyncStorage.removeItem(GRAPH_TOKEN_KEY);
    AsyncStorage.removeItem(DEFAULT_COMPANY_INFO_KEY);
    AsyncStorage.removeItem(SHOW_POPUP_MSG);
    if (redirect) {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            { name: 'dac.Auth' },
          ],
        }),
      );
    }
  }, []);

  const setAuthenticatedUser = useCallback(async (usr: User) => {
    if (usr) {
      const [store, availableStores] = await Promise.all([
        CompanyBranchService.getCompanyBranchById(usr.default_company_branch),
        CompanyBranchService.get(),
        await AsyncStorage.setItem(DEFAULT_COMPANY_INFO_KEY, JSON.stringify({
          default_company_uuid: usr.default_company_uuid,
          default_company_branch_uuid: usr.default_company_branch_uuid,
        })),
      ]);
      
      setUser(usr);
      setStores(availableStores);
      setCurrentStore(store);
      setAppSettings();
    }
  }, []);

  useEffect(() => {
    // getUser();
  }, []);

  return {
    user,
    currentStore,
    setUser,
    setCurrentStore,
    logOut,
    stores,
    setStores,
    setAuthenticatedUser,
  };
};

export default useAuth;
