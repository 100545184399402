import React, { useCallback } from 'react';
import styled from 'styled-components/native';
import Colors from 'theme/colors';
import { Body3 } from 'components/molecules/text';
import { Spacer } from '@kanvas/andromeda';
import { IS_WEB } from 'domain/shared/constants';
import { RectButton } from 'components/atoms/touchable';
import ButtonList, { ButtonListItem, RenderItemFn } from '../button-list';

interface IProps {
  onChange?: (item: ButtonListItem) => void;
  items?: ButtonListItem[]
}

interface ButtonProps {
  selected?: boolean;
}

const Button = styled(RectButton) <ButtonProps>`
  height: 32px;
  border-radius: 16px;
  padding-vertical: 6px;
  padding-horizontal: 12px;
  ${({ selected }) => (selected ? `
    background-color: ${Colors.ORANGE};
    shadow-opacity: 0.20;
    shadow-color: ${Colors.BLACK};
    shadow-offset: 0px 3px;
    elevation: 6;
    ${IS_WEB ? `box-shadow: 0px 3px 5px ${Colors.SHADOW}` : ''};
  ` : `
    background-color: ${Colors.BORDER_COLOR};
  `)
}
`;

const OppListFilter = (props: IProps) => {
  const { onChange, items = [] } = props;
  const renderItem: RenderItemFn = useCallback((item, onItemPress, enabled) => {
    const textColor = item.selected ? Colors.WHITE : Colors.PRIMARY_TEXT;

    const handle = useCallback(() => {
      onItemPress(item);
    }, []);

    return (
      <React.Fragment key={item.key}>
        <Button
          selected={item.selected}
          enabled={enabled}
          onPress={handle}
        >
          <Body3 text={item.text} color={textColor} />
        </Button>
        <Spacer horizontal size={4} />
      </React.Fragment>
    );
  }, []);

  return (
    <ButtonList
      items={items}
      onItemSelected={onChange}
      renderItem={renderItem}
      defaultValue={3}
      row
    />
  );
};

export default OppListFilter;
