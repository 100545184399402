import * as React from 'react';
import Svg, {
  Defs, ClipPath, Path, G,
} from 'react-native-svg';
import Colors from 'theme/colors';

function FinanceProducts(props: any) {
  const { fillColor = Colors.ICON_SECONDARY } = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      {...props}
    >
      <Defs>
        <ClipPath id="prefix__a">
          <Path d="M0 0h1080v1080H0z" />
        </ClipPath>
      </Defs>
      <G data-name="Custom Size \u2013 6" clipPath="url(#prefix__a)">
        <Path d="M0 0h1080v1080H0z" />
        <Path
          data-name="Path 579"
          d="M540 1080q111.111 0 208.889-42.5a553.326 553.326 0 00288.889-288.889Q1080 651.111 1080 540t-42.222-208.611A553.577 553.577 0 00748.334 42.222Q650.556 0 539.445 0q-110 0-207.778 42.222A553.577 553.577 0 0042.223 331.389Q0 428.889 0 540t42.5 208.611A555.138 555.138 0 00331.667 1037.5Q429.445 1080 540 1080zm0-232.222q-11.111 0-18.333-6.944t-7.222-18.056v-38.334q-56.667-4.444-97.5-33.056t-53.056-74.166a74.972 74.972 0 00-1.667-8.889 36.528 36.528 0 01-1.111-8.889q0-15 9.722-25t25.833-10q12.778 0 21.944 6.944t14.167 21.389a85.059 85.059 0 0026.944 43.333q20.278 17.778 54.722 22.222V567.778l-6.667-1.111Q439.443 550 404.72 518.056T370 433.333q0-56.667 40.556-93.056t103.889-43.055v-36.667q0-11.667 7.222-18.611T540 235a25.636 25.636 0 0118.056 6.944q7.5 6.945 7.5 18.611v36.667q53.333 6.111 91.667 34.444t49.445 71.111a72.868 72.868 0 011.667 9.167 78.452 78.452 0 01.556 9.167q0 15-9.722 24.444t-24.722 9.444q-26.111 0-36.667-27.778-8.333-25.556-26.667-41.389t-45.556-20.833v142.223l10 1.667q70 13.333 106.111 46.667t36.111 89.445q0 61.667-43.889 98.056T565.556 785v37.778q0 11.111-7.5 18.056A25.636 25.636 0 01540 847.778zm-25.555-353.334V365q-32.778 5-51.111 22.778T445 428.889q0 23.333 15.833 38.611t49.722 25.833zM565.556 580v138.333q38.889-3.889 58.056-22.778t19.167-46.111q0-23.889-14.722-40.278t-52.5-25.833z"
          fill={fillColor}
        />
      </G>
    </Svg>
  );
}

export default FinanceProducts;
