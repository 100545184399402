import { gql } from '@apollo/client';

export const associateFilesToPeople = gql`
  mutation ($files: [Upload!]!, $id: ID!, $params: Mixed) {
    attachFilesToPeople(files: $files, id: $id, params: $params) {
      id
    }
  }
`;

export const getPeopleFiles = gql`
  query peoples($id: Mixed) {
    peoples(where: { column: ID, operator: EQ, value: $id }) {
      data {
        files {
          data {
            field_name
          }
        }
      }
    }
  }
`

