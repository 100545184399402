import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components/native';
import { Flex, Spacer } from '@kanvas/andromeda';
import Logo from 'components/atoms/logo';
import SearchInput from 'components/atoms/search-input';
import TabBarButton from 'components/atoms/tab-bar-button';
import Icon from 'react-native-vector-icons/MaterialIcons';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Colors from 'theme/colors';
import TabNavigationScreens from 'domain/shared/navigation/tab-nav-screens';
import GradientBar from 'atoms/gradient-bar';
import AvatarButton from 'components/atoms/AvatarButton/AvatarButton';
import { emitOnClear, emitOnSearch } from 'domain/shared/events/cx-tab-bar';
import { TouchableOpacity } from 'atoms/touchable';
import useWindowSize from 'domain/shared/hooks/useWindowSize';
import { DEVICE_WIDTH, IS_WEB } from 'domain/shared/constants';

import UserService from 'domain/shared/services/user-service';
import Pusher from 'pusher-js/react-native';
import { connect } from 'react-redux';
import { ActivityListEvents, emitActivityListEvent } from 'domain/shared/events/activities-list';
import { PUSHER_API_KEY } from 'env';
import useNavTab from 'domain/shared/hooks/useNavTab';
import CRMExtensionService from 'domain/shared/services/crm-extension-service';
import { useDebouncedCallback } from 'use-debounce';

import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { RootState } from 'domain/shared/store';
import { EventRegister } from 'react-native-event-listeners';
import { CXTabBarContext } from './cx-tab-bar-context';
import Badge from '../Badge';
import { TabBarHeight } from './constants';

const POPULATE_CX_TAB_BAR = 'POPULATE_CX_TAB_BAR';

export function populateCxTabBarEvent(value: string) {
  EventRegister.emit(POPULATE_CX_TAB_BAR, value);
}

interface ContainerProps {
  height: number;
  width?: number;
}

const ICON_SIZE = 24;

const Container = styled(Flex) <ContainerProps>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  align-items: center;
  background: white;
  position: absolute;
  overflow: hidden;
`;

const Content = styled(Flex)`
  padding-horizontal: 16px;
`;

export const CXHeader = styled.View<ContainerProps>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
`;

enum NotificationType {
  UPDATES = 16,
  MESSAGES = 18,
}

const CXTabBar: React.FC<any> = (props) => {
  const {
    state, navigation, userId, company_id,
  } = props;
  const [width] = IS_WEB ? useWindowSize() : [DEVICE_WIDTH];
  const [unRead, setUnRead] = useState(0);
  const [text, setText] = useState('');
  const { setActive } = useNavTab();

  const navigateToScreen = useCallback((routeName: string) => {
    navigation.navigate(routeName);
  }, [navigation]);

  const { visible } = useContext(CXTabBarContext);
  const onSearch = useCallback((value: string) => {
    emitOnSearch({ value });
  }, []);

  const cleanMessages = useCallback(async () => {
    await UserService.cleanNotification();
    setUnRead(0);
    CRMExtensionService.setNotificationCount(0);
    emitActivityListEvent(
      ActivityListEvents.ACTIVITY_LIST_UPDATE,
      null,
    );
  }, [CRMExtensionService]);

  const onChangeText = useCallback((value: string) => {
    setText(value);
  }, []);

  const handleClear = useCallback(() => {
    onChangeText('');
    emitOnClear();
  }, []);

  const triggerSearch = useCallback((e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key === 'Enter') {
      onSearch(text);
    }
  }, [text]);

  const dispatchNotificationEvent = useDebouncedCallback((payload: any) => {
    CRMExtensionService.setNotificationCount(payload.total);
    CRMExtensionService.onNewNotification(payload);
  }, 200);

  const notificationListener = useCallback(() => {
    const pusher = new Pusher(PUSHER_API_KEY, {
      cluster: 'us2',
    });
    const channelName = `total-notification-counter-${userId}-company-${company_id}`;
    const channel = pusher.subscribe(channelName);
    channel.bind('newNotification', (payload: any) => {
      setUnRead(payload.total);
      dispatchNotificationEvent(payload);

      if (NotificationType.MESSAGES === payload.notification_type) {
        setActive(0);
      }

      if (NotificationType.UPDATES === payload.notification_type) {
        setActive(1);
      }
    });
  }, [CRMExtensionService, dispatchNotificationEvent, userId, company_id]);

  useEffect(() => {
    if (company_id && userId) {
      notificationListener();
    }
  }, [company_id, userId, notificationListener]);

  useEffect(() => {
    // eslint-disable-next-line max-len
    const event = EventRegister.addEventListener(POPULATE_CX_TAB_BAR, (data: string) => setText(data)).toString();

    return () => {
      EventRegister.removeEventListener(event);
    };
  }, []);
  
  const navigateToNotificationScreen = useCallback(() => {
    cleanMessages();
    navigateToScreen(TabNavigationScreens.NOTIFICATION);
  }, [cleanMessages]);

  return (
    <Container width={width} height={visible ? TabBarHeight : 0}>
      <GradientBar height={TabBarHeight}>
        <Content flex={1} row align="center">
          <TouchableOpacity onPress={() => navigateToScreen(TabNavigationScreens.OPPORTUNITIES)}>
            <Logo />
          </TouchableOpacity>
          <Spacer horizontal size={6} />
          <Flex flex={1}>
            <SearchInput
              value={text}
              onChangeText={onChangeText}
              onClear={handleClear}
              onKeyPress={triggerSearch}
              returnKeyType="search"
            />
          </Flex>
          <Spacer horizontal size={8} />
          <Flex flex={1} row align="center" justify="space-between">
            <TabBarButton
              selected={state.index === 0}
              icon={<Icon name="contact-page" size={ICON_SIZE} color={Colors.WHITE} />}
              onPress={() => navigateToScreen(TabNavigationScreens.OPPORTUNITIES)}
            />
            <Flex>
              <Badge unRead={unRead} setUnRead={setUnRead} />
              <TabBarButton
                icon={<MCIcon name="bell" size={ICON_SIZE} color={Colors.WHITE} />}
                selected={state.index === 2}
                onPress={navigateToNotificationScreen}
              />
            </Flex>
            <AvatarButton />
          </Flex>
        </Content>
      </GradientBar>
    </Container>
  );
};

const mapStateToProps = ({ auth }: RootState) => ({
  userId: auth?.user?.id,
  company_id: auth.user?.default_company_branch,
});

export default connect(mapStateToProps, null)(CXTabBar);
